import React from 'react';
import { Grid, Container } from '@material-ui/core';

const NoItem: React.FC<{}> = () => {
    return (
        <React.Fragment>
            <Container>
                <Grid container direction='column' justify='center' alignItems='stretch' style={{padding: 50}}>
                    <Grid item container justify='center' alignItems='center'>
                        <Grid item>
                            <div >
                                검색된 정보 없음.
                                    <div style={{ margin: 5, textAlign: 'center' }}>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    )
}

export default NoItem;