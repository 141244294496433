import React from "react";
import { inject, observer } from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import ManufactureStore from "../../../../../data/store/pageStore/manufacture/ManufactureStore";
import { IRootStore } from "../../../../../data/store/RootStore";
import TitledPaper from "../../../../common/component/TitledPaper";
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Helper from "../../../../../module/Helper";
import DateUtil from '@date-io/date-fns';
import FormButtonSet from "../../../../common/component/FormButtonSet";
import Validatable from "../../../../../data/model/common/Validatable";
import dayjs from "dayjs";
import PTSMessage from "../../../../../data/model/common/PTSMessage";
import CommonErrors, { CommonError } from "../../../../../data/model/common/CommonErrors";
import ManufactureWork from "../../../../../data/model/manufacture/ManufactureWork";
import ManufactureInfoRefForm from "../component/ManufactureInfoRefForm";
import ManufactureNoteForm from "../component/ManufactureNoteForm";
import ManufactureItemsForm from "../component/ManufactureItemsForm";
import ManufactureNoteListForm from "../component/ManufactureNoteListForm";
import ManufactureCustomerRequestInfoComponent from "../component/ManufactureCustomerRequestInfoComponent";

interface Props extends RouteComponentProps<any>, IRootStore {
}

@inject('rootStore')
@observer
class ManufactureSalesExpectShippingRequestContainer extends React.Component<Props> {
    store = new ManufactureStore();

    globalStore = this.props.rootStore.globalStore;
    commentStore = this.props.rootStore.commentStore;

    constructor(props: Props) {
        super(props);
        this.globalStore.useComment = true;
        this.commentStore.mode = 'work';
        this.commentStore.stage = '영업 예상 출하일 입력';
    }

    componentDidMount = async () => {
        try {
            this.globalStore.isRunning = true;
            const workID = this.props.match.params.workID;
            const result = await this.store.getManufactureByWorkID(workID, this.globalStore.token);
            const track = await this.store.getManufactureTrack(workID, this.globalStore.token);
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                })
            }
            if (track.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                })
            }
            if (this.globalStore.userInfo.role.toLocaleLowerCase() !== 'admin') {
                if (track.data.data.current.departType !== this.globalStore.userInfo.departType) {
                    throw CommonErrors.NoAuth;
                }
            }
            this.store.workID = workID;
            this.store.data.fromJS(result.data.data);
            this.globalStore.currentTaskTitle = this.store.getTitleByType(track.data.data.current.workType);
            this.store.setItemList(result.data.data.itemList);
            this.store.setInputHeadersByType(track.data.data.current.workType);
            this.store.setReadonlyHeadersByType(track.data.data.current.workType);
            this.commentStore.workID = workID;
            this.commentStore.stage = this.globalStore.currentTaskTitle;
            await this.commentStore.setCommentsMany();
            this.store.setManufactureNumber(result.data.data.manufactureOrderNumberList);
            this.store.setManufactureNoteList(result.data.data.manufactureNoteList);
        } catch (err) {
            if (err instanceof CommonError || err instanceof PTSMessage) {
                alert(err.message);
            }
            else {
                alert('오류 발생');
            }
            this.props.history.push('/main/manufacture/list/process');

        } finally {
            this.globalStore.isRunning = false;
        }
    }

    onFormSubmit = async () => {
        if (!window.confirm('제출하시겠습니까?')) {
            return;
        }
        try {
            this.globalStore.isRunning = true;
            // api
            const result =
                await this.store.processManufacture(
                    this.globalStore.token
                );
            // result
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                });
            }
            alert('제출완료');
            this.props.history.push(`/main/manufacture/list/process`);
            window.location.reload();
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
            else {
                alert('오류 발생.');
            }
            this.store.isValid = false;
        } finally {
            this.globalStore.isRunning = false;
        }
    }

    onValidate = () => {
        try {
            this.store.data.resetErrors();
            this.store.isValid = true;
            if (!this.store.data.determinedReleaseDate) {
                this.store.data.setErrorTextByName('determinedReleaseDate', Validatable.errorTexts.needValue);
                this.store.isValid = false;
            }
            else if (dayjs(this.store.data.determinedReleaseDate).diff(dayjs(), 'day') <= -1) {
                this.store.data.setErrorTextByName('determinedReleaseDate', Validatable.errorTexts.pastDate);
                this.store.isValid = false;
            }
            else if (dayjs(this.store.data.manufactureExpectShippingRequestDate).format('YYYYMMDD')
                > dayjs(this.store.data.determinedReleaseDate).format('YYYYMMDD')) {
                this.store.data.setErrorTextByName('determinedReleaseDate', '제조 예상 출하 가능일보다 과거의 날짜는 불가능합니다.');
                this.store.isValid = false;
            }
            if (!this.store.data.determinedReleaseAMPM) {
                this.store.data.setErrorTextByName('determinedReleaseAMPM', Validatable.errorTexts.needValue);
                this.store.isValid = false;
            }
            if (!this.store.isValid) {
                alert('유효하지 않은 값이 있습니다.');
                return;
            }
        } catch (err) {

        } finally {

        }
    }

    render() {
        return (
            <React.Fragment>
                <TitledPaper title='제조의뢰 정보'>
                    <ManufactureInfoRefForm data={this.store.data} readOnly />
                    <ManufactureCustomerRequestInfoComponent
                        data={this.store.data}
                        readOnly
                    />
                </TitledPaper>
                <TitledPaper title='영업 예상 출하일 입력'>
                    <Grid container>
                        <Grid item xl lg md>
                            <TextField
                                margin='normal'
                                label='제조 예상 출하 가능일'
                                value={
                                    `${dayjs(this.store.data.manufactureExpectShippingRequestDate).format('YYYY/MM/DD')}` || null
                                }
                                name='manufactureExpectShippingRequestDate'
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xl lg sm md>
                            <MuiPickersUtilsProvider utils={DateUtil}>
                                <Grid container>
                                    <Grid item xl lg sm md>
                                        <FormControl margin='normal'>
                                            <KeyboardDatePicker
                                                variant='inline'
                                                format='yyyy/MM/dd'
                                                name='manufactureSalesExpectShippingRequestDate'
                                                label='영업 예상 출하일'
                                                value={
                                                    this.store.data.determinedReleaseDate || new Date()
                                                }
                                                onChange={(date) => { this.store.data.onChangeDateDefault('determinedReleaseDate', date) }}
                                                helperText={
                                                    Helper.getErrorText(
                                                        this.store.data.errors.determinedReleaseDate
                                                    )
                                                }
                                                error={
                                                    Helper.getErrorState(
                                                        this.store.data.errors.determinedReleaseDate
                                                    )
                                                } />
                                        </FormControl>
                                        <FormControl
                                            error={
                                                Helper.getErrorState(
                                                    this.store.data.errors.determinedReleaseAMPM
                                                )
                                            }
                                            style={{ width: 250 }}
                                            margin='normal'>
                                            <InputLabel id='determinedReleaseAMPM'>
                                                AM/PM
                                            </InputLabel>
                                            <Select
                                                labelId='determinedReleaseAMPM'
                                                onChange={this.store.data.onChangeDefault}
                                                value={this.store.data.determinedReleaseAMPM || ''}
                                                name='determinedReleaseAMPM'>
                                                {Object.keys(ManufactureWork.AMPMList).map((v, i) => (
                                                    <MenuItem key={`ampm${i}`} value={v}>{(ManufactureWork.AMPMList as any)[v]}</MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText>{Helper.getErrorText(this.store.data.errors.determinedReleaseAMPM)}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                </TitledPaper>
                <TitledPaper title='제조 아이템 목록' >
                    <ManufactureItemsForm
                        data={this.store.data}
                        readonlyHeaders={this.store.readonlyHeaders}
                        inputHeaders={this.store.inputHeaders} />
                </TitledPaper>
                <TitledPaper title='영업팀 NOTE'>
                    <ManufactureNoteForm data={this.store.data} depart='sales' readOnly />
                </TitledPaper>
                <TitledPaper title='제조파트 Note'>
                    <ManufactureNoteListForm data={this.store.data} type={this.store.data.type} />
                </TitledPaper>
                <Grid container>
                    <Grid item xl lg md >
                        <FormButtonSet
                            onSubmit={this.onFormSubmit}
                            onValidate={this.onValidate}
                            validated={this.store.isValid}
                            onFormClear={() => { window.location.reload() }} />
                    </Grid>
                </Grid>
            </React.Fragment >
        )
    }
}

export default withRouter(ManufactureSalesExpectShippingRequestContainer)