import React, { useState } from 'react';
import { Checkbox, TableCell, TableRow, Button, TextField } from '@material-ui/core';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import ManufactureWork from '../../../../../data/model/manufacture/ManufactureWork';
import ManufactureWorkListStore from '../../../../../data/store/pageStore/manufacture/ManufactureWorkListStore';
import ManufactureListItem from '../../../../../data/model/manufacture/ManufactureListItem';

interface Props {
    useCheckBox?: boolean;
    index?: number;
    data: ManufactureListItem;
    headers: string[];
    mode?: string;
    offset?: number;
    receiptHandler?: (workID: string) => void;
    //cancelHandler?: (workID: string) => void;
    changeHandler?: (workID: string, approve: string, refuseReason?: string) => void;
}

const ManufactureListRow: React.FC<Props> = observer((props) => {
    const [text, enableButton] = useState("");
    const useCheckBox = props.useCheckBox || false;
    const index = props.index || 0;
    const mode = props.mode || '';
    const offset = props.offset || 0;
    const receiptHandler = props.receiptHandler || ((workID: string) => { console.log('Function not defined') });
    //const cancelHandler = props.cancelHandler || ((workID: string) => { console.log('Function not defined') });
    const changeHandler = props.changeHandler || ((workID: string) => { console.log('Function not defined') });

    const handleTextChange = (e: any) => {
        enableButton(e.target.value);
        props.data.changeRequestHistory[0].refuseReason = e.target.value;
    };

    const onCheck = (e: any) => {
        props.data.checked = !props.data.checked;
    }

    const getBackgroundColor = () => {
        return props.data.workStatus === 'paused' ? `#e0e0e0` :
            props.data.type !== ManufactureWork.TypeList.manufactureComplete && props.data.workStatus === 'finished' ? `#ddeeed` :
                props.data.type === ManufactureWork.TypeList.manufactureComplete ? '#c7ebc6' : '';
    }

    const statusTranslation = (): string => {
        let str = '';
        switch (props.data.type) {
            case ManufactureWork.TypeList.manufactureRequest:
                str += '제조 의뢰';
                break;
            case ManufactureWork.TypeList.manufactureExpectShippingRequest:
                str += '제조 예상 출하 가능일 입력'
                break;
            case ManufactureWork.TypeList.manufactureSalesExpectShippingRequest:
                str += '영업 예상 출하일 입력'
                break;
            case ManufactureWork.TypeList.manufactureManufacture:
                str += '제조';
                break;
            case ManufactureWork.TypeList.manufactureShippingRequest:
                str += '출하 요청일 입력';
                break;
            case ManufactureWork.TypeList.manufacturePackaging:
                str += '포장';
                break;
            case ManufactureWork.TypeList.manufactureDelivery:
                str += '배송';
                break;
            case ManufactureWork.TypeList.manufactureComplete:
                str += '제조과정완료';
                break;
            // case ManufactureWork.TypeList.manufactureCancelShipping:
            //     str += '제조출하일변경요청';
            //     break;
            case ManufactureWork.TypeList.manufactureChangeExpectShipping:
                str += '예상 출하일 변경 요청';
                break;
            case ManufactureWork.TypeList.manufactureChangeShipping:
                str += '출하 요청일 변경 요청';
                break;
            default:
                str += 'Unknown';
                break;
        }
        switch (props.data.stage) {
            case ManufactureWork.StageList.abort:
                str += ' 보류중';
                break;
            case ManufactureWork.StageList.waitAccept:
                str += ' 접수대기';
                break;
            case ManufactureWork.StageList.cancel:
                str += ' 취소';
                break;
            case ManufactureWork.StageList.change:
                str += ' 변경';
                break;
            case ManufactureWork.StageList.finish:
                str += ' 완료';
                break;
            case ManufactureWork.StageList.processing:
                str += ' 진행중';
                break;
            case ManufactureWork.StageList.skipped:
                str += ' 건너뜀';
                break;
            case ManufactureWork.StageList.stop:
                str += ' 중지중';
                break;
            default:
                str += '';
                break;
        }
        switch (props.data.workStatus) {
            case 'finished':
                if (props.data.type !== ManufactureWork.TypeList.manufactureComplete) {
                    str += ' 완료'
                }
                break;
            case 'paused':
                str += ' 중단'
                break;
        }
        return str;
    }

    const getButtonsByMode = (mode: string): JSX.Element[] => {
        const arr: JSX.Element[] = [];
        switch (mode) {
            case ManufactureWorkListStore.ModeList.accept:
                arr.push(
                    <TableCell key={`but${index}${0}`}>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={() => receiptHandler(props.data.workID)}>
                            접수
                        </Button>
                    </TableCell>
                )
                break;
            case ManufactureWorkListStore.ModeList.process:
                arr.push(
                    <TableCell key={`but${index}${0}`}>
                        <Link to={`/main/manufacture/process/${props.data.workID}`}>
                            <Button
                                variant='contained'
                                color='primary'>
                                작업하기
                            </Button>
                        </Link>
                    </TableCell>
                )
                break;
            case ManufactureWorkListStore.ModeList.shipped:
                arr.push(
                    <TableCell key={`but${index}${0}`}>
                        {/* <Link to={`/main/manufacture/cancelshippingrequest/${props.data.workID}`} */}
                        <Link to={`/main/manufacture/changeshippingrequest/${props.data.workID}`}
                            style={{ textDecoration: 'none' }}>
                            <Button
                                variant='contained'
                                // color={props.data.shippingRequestDate ? 'primary' : 'secondary'}>
                                style={
                                    props.data.shippingRequestDate ?
                                        { backgroundColor: '#3f51b5', color: '#fff' } :
                                        { backgroundColor: '#00B0F0', color: '#fff' }
                                }>
                                {props.data.shippingRequestDate ? '출하 요청일 변경 요청' : '예상 출하일 변경 요청'}
                            </Button>
                        </Link>
                    </TableCell>
                )
                break;
            // case ManufactureWorkListStore.ModeList.cancelShipping:
            case ManufactureWorkListStore.ModeList.changeShipping:
                arr.push(
                    <TableCell key={`but${index}${0}`}>
                        <Button
                            style={{ width: '20%', margin: 3 }}
                            variant='contained'
                            color='primary'
                            // onClick={() => cancelHandler(props.data.workID)}>
                            disabled={!!text}
                            onClick={() => changeHandler(props.data.workID, 'Y', props.data.changeRequestHistory[0].refuseReason || '')}>
                            승인
                        </Button>
                        <Button
                            style={{ width: '20%', margin: 3 }}
                            variant='contained'
                            color='secondary'
                            // onClick={() => cancelHandler(props.data.workID)}>
                            disabled={!text}
                            onClick={() => changeHandler(props.data.workID, 'N', props.data.changeRequestHistory[0].refuseReason || '')}>
                            거부
                        </Button>
                    </TableCell >
                )
                break;
            default:
                break;
        }
        return arr;
    }

    const getData = (key: string) => {
        if (key.endsWith('Date')) {
            if (dayjs((props.data as any)[key]).format('YYYY-MM-DD') === 'Invalid Date') {
                return;
            }
            else if (key === 'shippingRequestDate') {
                return `${dayjs((props.data as any)[key]).format('YYYY-MM-DD')} ${props.data.shippingRequestAMPM ? (props.data.shippingRequestAMPM === 'pm' ? '오후' : '오전') : ''}`
            }
            else if (key === 'determinedReleaseDate') {
                return `${dayjs((props.data as any)[key]).format('YYYY-MM-DD')} ${props.data.determinedReleaseAMPM === 'pm' ? '오후' : '오전'}`
            }
        }
        else {
            if (key === 'representativeName') {
                return props.data.repInfo.name;
            }
            else if (key === 'productType') {
                return (ManufactureWork.ProductTypeList as any)[(props.data as any)[key]];
            }
            else if (key === 'coa') {
                return (props.data as any)[key] ? 'Y' : 'N';
            }
            else {
                return (props.data as any)[key];
            }
        }
    }

    const onDetailClick = (e: React.MouseEvent<any>) => {

    }

    const getchangeRequestDate = () => {
        return `${dayjs(props.data.changeRequestHistory[0].changeRequestDate).format('YYYY-MM-DD')} ${props.data.changeRequestHistory[0].changeRequestAMPM ?
            (props.data.changeRequestHistory[0].changeRequestAMPM.toLowerCase() === 'am' ? '오전' : '오후') : ''}`;
    }

    const getChangeReason = () => {
        return props.data.changeRequestHistory[0].changeReason;
    }

    // const getCancelReason = () => {
    //     return (props.data.current.data as any)['cancelReason'];
    // }

    return (
        <React.Fragment>
            <TableRow style={{ background: getBackgroundColor() }}>
                {useCheckBox &&
                    <TableCell padding='checkbox'>
                        <Checkbox
                            checked={props.data.checked}
                            onChange={onCheck} />
                    </TableCell>
                }
                <TableCell>
                    {offset + (index + 1)}
                </TableCell>
                {
                    props.headers.map((v, i) => (
                        <TableCell key={`headers${i}`}>
                            {getData(v)}
                        </TableCell>
                    ))
                }
                <TableCell>
                    {statusTranslation()}
                </TableCell>
                {
                    // props.mode === ManufactureWorkListStore.ModeList.cancelShipping
                    props.mode === ManufactureWorkListStore.ModeList.changeShipping
                    &&
                    <TableCell>
                        {getchangeRequestDate()}
                    </TableCell>
                }
                {
                    // props.mode === ManufactureWorkListStore.ModeList.cancelShipping
                    props.mode === ManufactureWorkListStore.ModeList.changeShipping
                    &&
                    <TableCell>
                        {getChangeReason()}
                        {/* {getCancelReason()} */}
                    </TableCell>
                }
                {
                    props.mode === ManufactureWorkListStore.ModeList.changeShipping
                    &&
                    <TableCell>
                        <TextField
                            fullWidth
                            multiline
                            type='text'
                            margin='normal'
                            onChange={handleTextChange}
                            value={props.data.changeRequestHistory[0].refuseReason}
                        />
                    </TableCell>
                }
                <TableCell>
                    <Link
                        style={{ textDecoration: 'none' }}
                        to={
                            {
                                pathname: `/main/manufacture/detail/${props.data.workID}`
                            }
                        }
                    // target='_blank'
                    >
                        <Button onClick={onDetailClick}>
                            자세히
                        </Button>
                    </Link>
                </TableCell>
                {
                    getButtonsByMode(mode).map((v, i) => (
                        v
                    ))
                }
            </TableRow>
        </React.Fragment>
    )
})

export default ManufactureListRow;