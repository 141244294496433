import { observable } from "mobx";
import Validatable from "../../../common/Validatable";
import TaskSubData from "../../DesignTaskSubData";

export default class QCExperiment extends TaskSubData {
    @observable QCprobeConc?: number;
    @observable dilFactor?: number;

    constructor() {
        super();
        this.QCprobeConc = null;
        this.dilFactor = null;
    }

    // override
    validateSelf(): void {
        // if (!this.QCprobeConc) {
        //     this.setErrorTextByName('QCprobeConc', Validatable.errorTexts.needValue)
        // }
        if (this.QCprobeConc !== null) {
            if (this.QCprobeConc < 0) {
                this.setErrorTextByName('QCprobeConc', Validatable.errorTexts.positive)
            }
        }
        // if (!this.dilFactor) {
        //     this.setErrorTextByName('dilFactor', Validatable.errorTexts.needValue)
        // }
        if (this.dilFactor !== null) {
            if (this.dilFactor <= 0) {
                this.setErrorTextByName('dilFactor', Validatable.errorTexts.positiveNumber)
            }
        }
    }
}