import React from 'react';
import { Grid, FormControl, FormControlLabel, Checkbox } from '@material-ui/core';
import ITaskForm from '../TaskFormProps';
import { observer, inject } from 'mobx-react';

import { observable } from 'mobx';
import ProcessStore from '../../../../../../../../data/store/pageStore/design/ProcessStore';
import DesignConfirm from '../../../../../../../../data/model/design/taskDatas/sales/DesignConfirm';
import TitledPaper from '../../../../../../../common/component/TitledPaper';
import FormButtonSet from '../../../../../../../common/component/FormButtonSet';

interface Props extends ITaskForm {
}


let store = observable(new ProcessStore<DesignConfirm>());
store.taskData.detail = observable(new DesignConfirm());

const DesignConfirmForm: React.FC<Props> = inject('rootStore')(observer((props) => {
    const globalStore = props.rootStore.globalStore;
    const workInfo = props.workInfo;
    store.taskData._id = (workInfo.taskList[0] as any)._id;
    store.taskData.workID = (workInfo.taskList[0] as any).workID;
    props.rootStore.commentStore.stage = '고객 합성 컨펌';

    const onFormSubmit = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        try {
            if (!store.taskData.detail.confirm) {
                return;
            }
            if(!window.confirm('제출하시겠습니까?')) {
                return;
            }
            globalStore.isRunning = true;

            props.postTaskData(store.taskData);
        } catch (err) {

        }
    }

    const onFormClear = (e: any) => {
        window.location.reload();
    }

    const onVliadte = (e: any) => {

    }

    const onCheckboxClick = (e: any) => {
        const { name } = e.target;
        (store.taskData.detail as any)[name] = !(store.taskData.detail as any)[name];
    }

    return (
        <React.Fragment>
            <Grid item container>
                <Grid xl lg sm item>
                    <TitledPaper
                        title='고객 합성 컨펌'>
                        <Grid item container alignItems='center'>
                            <Grid item>
                                <FormControl>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name='confirm'
                                                checked={store.taskData.detail.confirm}
                                                onChange={onCheckboxClick}
                                                value={store.taskData.detail.confirm}
                                                color='primary' />
                                        }
                                        label='고객 합성 컨펌 완료' />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </TitledPaper>
                </Grid>
            </Grid>
            <Grid item container xl lg sm style={{ marginTop: 10 }}>
                <Grid item xl lg sm>
                    <FormButtonSet
                        onSubmit={onFormSubmit}
                        onFormClear={onFormClear}
                        onValidate={onVliadte} />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}));

export default DesignConfirmForm;