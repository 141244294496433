import React from 'react';
import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import ManufactureWork from '../../../../../data/model/manufacture/ManufactureWork';
import ETSField from '../../../../common/component/ExtendedMUISTextfield';
import FormUtil from '../../../../../module/FormUtil';
import dayjs from 'dayjs';

interface Props {
    data: ManufactureWork;
    readOnly?: boolean;
}

const ManufactureInfoRefForm: React.FC<Props> = observer((props) => {
    const data = props.data;

    return (
        <React.Fragment>
            <Grid container direction='column'>
                {(data.orderNumber || data.shippingRequestDate) &&
                    <Grid container>
                        <Grid item xl lg md>
                            <ETSField
                                // translation={() => { return 'Order Num.' }}
                                label={'Order Num.'}
                                fullWidth
                                margin='normal'
                                rootdata={data}
                                value={data.orderNumber}
                                name='orderNumber'
                                readOnly={props.readOnly}
                            />
                        </Grid>
                        <Grid item xl lg md>
                            <ETSField
                                fullWidth
                                margin='normal'
                                rootdata={data}
                                value={data.customerAndPI}
                                name='customerAndPI'
                                readOnly={props.readOnly}
                            />
                        </Grid>
                    </Grid>
                }
                <Grid container>
                    <Grid item xl lg md>
                        {
                            props.readOnly
                                ?
                                <ETSField
                                    fullWidth
                                    margin='normal'
                                    rootdata={data}
                                    value={data.repInfo.departName}
                                    name='representativeDepart'
                                    readOnly={props.readOnly}
                                />
                                :
                                <ETSField
                                    fullWidth
                                    margin='normal'
                                    rootdata={data}
                                    value={data.representativeDepart}
                                    name='representativeDepart'
                                    readOnly={props.readOnly}
                                />
                        }
                    </Grid>
                    <Grid item xl lg md>
                        {
                            props.readOnly
                                ?
                                <ETSField
                                    fullWidth
                                    margin='normal'
                                    rootdata={data}
                                    value={data.repInfo.name}
                                    name='representativeName'
                                    readOnly={props.readOnly}
                                />
                                :
                                <ETSField
                                    fullWidth
                                    margin='normal'
                                    rootdata={data}
                                    value={data.representativeName}
                                    name='representativeName'
                                    readOnly={props.readOnly}
                                />
                        }
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xl lg md>
                        <ETSField
                            fullWidth
                            margin='normal'
                            rootdata={data}
                            value={data.writerDepart}
                            name='writerDepart'
                            readOnly={true}
                        />
                    </Grid>
                    <Grid item xl lg md>
                        <ETSField
                            fullWidth
                            margin='normal'
                            rootdata={data}
                            value={data.writerName}
                            name='writerName'
                            readOnly={true}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xl lg md>
                        <ETSField
                            fullWidth
                            margin='normal'
                            rootdata={data}
                            value={data.projectCode}
                            name='projectCode'
                            readOnly={props.readOnly}
                        />
                    </Grid>
                    <Grid item xl lg md>
                        <ETSField
                            fullWidth
                            margin='normal'
                            rootdata={data}
                            value={data.erpCode}
                            name='erpCode'
                            readOnly={props.readOnly}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xl lg md>
                        <ETSField
                            fullWidth
                            margin='normal'
                            rootdata={data}
                            value={FormUtil.getFieldLabel(data.productDivision)}
                            name='productDivision'
                            readOnly={props.readOnly}
                        />
                    </Grid>
                    <Grid item xl lg md>
                        <ETSField
                            fullWidth
                            margin='normal'
                            rootdata={data}
                            value={FormUtil.getFieldLabel(data.manufacturePurpose)}
                            name='manufacturePurpose'
                            readOnly={props.readOnly}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xl lg sm md>
                        <ETSField
                            fullWidth
                            margin='normal'
                            rootdata={data}
                            name='coa'
                            value={
                                data.coa ? 'YES' : 'NO'
                            }
                            readOnly={props.readOnly}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xl lg sm md>
                        <ETSField
                            fullWidth
                            margin='normal'
                            rootdata={data}
                            name='shippingRequestDate'
                            value={
                                data.shippingRequestDate ? dayjs(data.shippingRequestDate).format('YYYY/MM/DD') : ''
                            }
                            readOnly={props.readOnly}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
})

export default ManufactureInfoRefForm;