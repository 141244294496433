import axios from "axios";
import { action, observable } from "mobx";
import WorkDetailModel from "../../../model/common/WorkDetailModel";
import DesignWork from "../../../model/design/DesignWork";
import StoreBase from "../../StoreBase";
import { words as RouteWords } from '../../../../resource/data/APIRoutes.json';
import PTSMessage from "../../../model/common/PTSMessage";
import TaskInfo from '../../../../resource/data/tasks.json';
import DesignRequest from "../../../model/design/taskDatas/designReqeust/DesignRequest";
import SynthesisDesign from "../../../model/design/taskDatas/synthesisDesign/SynthesisDesign";
import BIAnalysis from "../../../model/design/taskDatas/bi/BIAnalysis";

interface IReworkTaskInfo {
    name?: string;
    type?: string;
}

export class ReworkTaskInfo {
    @observable name: string;
    @observable type: string;

    constructor(props: IReworkTaskInfo) {
        if (props) {
            Object.assign(this, props);
        }
        else {
            this.name = 'Unknown';
            this.type = 'Unknown';
        }
    }
}

export default class WorkDetailStore extends StoreBase {
    @observable workInfo: DesignWork;
    @observable workDetail: WorkDetailModel;
    @observable workStatus: 'paused' | 'running' | 'finished';
    @observable reworkDiagOpen: boolean;
    @observable reworkTaskList: ReworkTaskInfo[];
    @observable selectedTask: string;
    @observable selectedTaskIndex: number;
    @observable prevWorkID: string;
    @observable requestData: DesignRequest;
    @observable isRequestLoaded: boolean;
    @observable synthesisDesignData: SynthesisDesign;
    @observable isSynthesisDesignLoaded: boolean;
    @observable biAnalysisData: BIAnalysis;
    @observable isBiAnalysisLoaded: boolean;

    constructor() {
        super();
        this.workInfo = new DesignWork();
        this.workDetail = new WorkDetailModel();
        this.reworkDiagOpen = false;
        this.selectedTask = '';
        this.selectedTaskIndex = -1;
        this.reworkTaskList = [];
        this.isRequestLoaded = false;
        this.isSynthesisDesignLoaded = false;
        this.isBiAnalysisLoaded = false;
    }

    readonly Translate = {
        "designRequest": "디자인 요청",
        "synthDesign": "패널 합성 디자인",
        "designConfirm": "고객 디자인 컨펌",
        "chipDistribution": "Chip distribution",
        "synth1": "합성 Phase.1",
        "synth2": "합성 Phase.2",
        "synth3": "합성 Phase.3",
        "qcExperiment": "QC 실험",
        "qcSequencing": "QC 시퀀싱",
        "biAnalysis": "BI 분석",
        "biUpload": "BI 업로드",
        "panelConfirm": "패널 컨펌",
        "rebalancingRequest": "리밸런싱 요청",
        "rebalancingSynthDesign": "리밸런싱 패널 합성 디자인",
        "probeDetail": "Probe Detail",
    }

    onReworkDiagClose = () => {
        this.reworkDiagOpen = false;
    }

    openReworkDiag = () => {
        this.reworkDiagOpen = true;
    }

    getWorkInfo = async (id: string, token: string) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        return axios.get(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.design}/${RouteWords.works}/${id}`,
            config
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        });
    }

    filterDesignRequest = (data: WorkDetailModel) => {
        const tasks = data.tasks;
        const filter = tasks.filter((v) =>
            v.type === TaskInfo.types.designRequest
            ||
            v.type === TaskInfo.types.rebalancingRequest
        );
        if (filter.length > 0) {
            return filter[0];
        }
        else {
            return null;
        }
    }

    @action
    setDesignRequestData = (data: WorkDetailModel) => {
        const obj = this.filterDesignRequest(data);
        if (obj) {
            this.requestData = new DesignRequest();
            this.requestData = obj.detail as DesignRequest;
            this.isRequestLoaded = true;
        }
        else {
            this.isRequestLoaded = false;
            this.requestData = null;
        }
    }

    filterSynthesisDesign = (data: WorkDetailModel) => {
        const tasks = data.tasks;
        const filter = tasks.filter((v) =>
            v.type === TaskInfo.types.synthDesign
            ||
            v.type === TaskInfo.types.rebalancingSynthDesign
        );
        if (filter.length > 0) {
            return filter[0];
        }
        else {
            return null;
        }
    }

    @action
    setSynthesisDesignData = (data: WorkDetailModel) => {
        const obj = this.filterSynthesisDesign(data);
        if (obj) {
            this.synthesisDesignData = new SynthesisDesign();
            this.synthesisDesignData = obj.detail as SynthesisDesign;
            this.isSynthesisDesignLoaded = true;
        }
        else {
            this.isSynthesisDesignLoaded = false;
            this.synthesisDesignData = null;
        }
    }

    filterBiAnalysis = (data: WorkDetailModel) => {
        const tasks = data.tasks;
        const filter = tasks.filter((v) =>
            v.type === TaskInfo.types.biAnalysis
        );
        if (filter.length > 0) {
            return filter[0];
        }
        else {
            return null;
        }
    }

    @action
    setBiAnalysisData = (data: WorkDetailModel) => {
        const obj = this.filterBiAnalysis(data);
        if (obj) {
            this.biAnalysisData = new BIAnalysis();
            this.biAnalysisData = obj.detail as BIAnalysis;
            this.isBiAnalysisLoaded = true;
        }
        else {
            this.isBiAnalysisLoaded = false;
            this.biAnalysisData = null;
        }
    }

    setWorkInfo = async (id: string, token: string) => {
        try {
            const result = await this.getWorkInfo(id, token);
            this.workInfo = result.data.data;
            this.workStatus = result.data.data.workStatus;
        } catch (err) {
            throw err;
        }
    }

    setWorkDetail = async (id: string, token: string) => {
        try {
            const result = await this.getWorkDetail(id, token);
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                })
            }
            this.workDetail = result.data.data;
            this.workStatus = result.data.data.workStatus;
            this.prevWorkID = result.data.data.prevWorkID;
            const list = this.workDetail.tasks;
            this.reworkTaskList = list.map((v) => {
                return new ReworkTaskInfo({
                    type: v.type,
                    name: (this.Translate as any)[v.type] || 'Unknown',
                });
            });
            this.setDesignRequestData(this.workDetail);
            this.setSynthesisDesignData(this.workDetail);
            this.setBiAnalysisData(this.workDetail)
        } catch (err) {
            throw err;
        }
    }

    getWorkDetail = async (id: string, token: string) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        return axios.get(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.design}/${RouteWords.works}/${RouteWords.details}/${id}`,
            config
        ).then((response) => {
            return response;
        }).catch((err) => {
            throw err;
        });
    }

    requestPauseWork = async (id: string, token: string) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        return axios.put(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.design}/${RouteWords.works}/${RouteWords.pause}/${id}`,
            {},
            config
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        });
    }

    requestRunWork = async (id: string, token: string) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        return axios.put(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.design}/${RouteWords.works}/${RouteWords.run}/${id}`,
            {},
            config
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        });
    }

    requestFinishWork = async (id: string, token: string) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        return axios.put(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.design}/${RouteWords.works}/${RouteWords.finish}/${id}`,
            {},
            config,
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        })
    }

    requestNewReWork = async (id: string, token: string, index: number) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        return axios.post(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.design}/${RouteWords.works}/${RouteWords.rework}/${id}`,
            {
                index
            },
            config,
        ).then((res) => {
            return res;
        }).catch((err) => {
            return err;
        });
    }

    newReWork = async (id: string, token: string, index: number) => {
        try {
            const result = await this.requestNewReWork(id, token, index);
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                    type: result.data.type
                });
            }
            return result;
        } catch (err) {
            throw err;
        }
    }
}