import React, { useEffect, useState } from 'react';
import {
    Grid,
    TextField,
    Button,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography
} from '@material-ui/core';
import ITaskForm from '../TaskFormProps';
import { observer, inject } from 'mobx-react';

import SynthesisDesign from '../../../../../../../../data/model/design/taskDatas/synthesisDesign/SynthesisDesign';
import SynthesisID from '../../../../../../../../data/model/common/SynthesisID';
import PTSFile from '../../../../../../../../data/model/common/PTSFile';
import Helper from '../../../../../../../../module/Helper';
import TitledPaper from '../../../../../../../common/component/TitledPaper';
import FormUtil from '../../../../../../../../module/FormUtil';
import SynthesisIDInputCell from './SynthesisIDInputCell';
import FormButtonSet from '../../../../../../../common/component/FormButtonSet';
import PTSMessage from '../../../../../../../../data/model/common/PTSMessage';
import RebalancingSynthDesignProcessStore from '../../../../../../../../data/store/pageStore/design/RebalancingSynthDesignProcessStore';
import FileWithHelperText from '../../../../../../../common/component/FileWithHelperText';
import TableWithHelperText from '../../../../../../../common/component/TableWithHelperText';
import Validatable from '../../../../../../../../data/model/common/Validatable';

interface Props extends ITaskForm {
}

let store = new RebalancingSynthDesignProcessStore();
store.taskData.detail = new SynthesisDesign();

const RebalancingSynthesisDesignForm: React.FC<Props> = inject('rootStore')(observer((props) => {
    const [tmpSDContents, setTmpSDContents] = useState('');
    const [sid, setSID] = useState('');
    const [noSid, setNoSid] = useState(0);
    const globalStore = props.rootStore.globalStore;
    const workInfo = props.workInfo;
    store.taskData._id = (workInfo.taskList[0] as any)._id;
    store.taskData.workID = (workInfo.taskList[0] as any).workID;
    props.rootStore.commentStore.stage = 'Rebalancing 패널 합성 디자인';

    useEffect(() => {
        store.init(workInfo.prevWorkID, globalStore.token);
    }, [globalStore.token, workInfo.prevWorkID]);

    const onFormSubmit = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        try {
            if (!window.confirm('제출하시겠습니까?')) {
                return;
            }
            globalStore.isRunning = true;

            // upload files
            const files = store.getFileUploadForm();
            if (files) {
                const fileResult = await store.uploadFiles(files, globalStore.token);
                const mapping = Array.from(store.filesMapping.values());

                for (let i = 0; i < fileResult.data.data.length; i++) {
                    mapping[i].file_id = fileResult.data.data[i];
                }
            }

            // synth ids insert
            const sidResult = await store.addSynthIDs(store.sidObjList, globalStore.token);
            if (sidResult.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: sidResult.data.resMessage,
                })
            }

            // synth ids curr에 추가
            for (const sid of sidResult.data.data) {
                store.taskData.detail.synthesisIDs.push((sid as any).sid);
                store.taskData.detail.synthesisOIDs.push((sid as any)._id);
            }

            delete store.taskData.detail.tempSDContentError;
            props.postTaskData(store.taskData);
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
        } finally {
            globalStore.isRunning = false;
        }
    }

    const onFormClear = (e: any) => {
        window.location.reload();
    }

    const onVliadte = (e: any) => {
        store.taskData.detail.synthesisIDPrefix = sid;
        store.taskData.detail.initErrorsRecursive();
        store.taskData.detail.resetErrorsRecursive();
        store.taskData.detail.validateRecursive();
        store.isValid = !store.taskData.detail.hasErrorRecursive();
        if (store.sidObjList.length > 0) {
            store.taskData.detail.initErrorByKey('synthesisIDPrefix');
            store.isValid = !store.taskData.detail.hasErrorRecursive();
            for (let sidObj of store.sidObjList) {
                sidObj.initErrors();
                sidObj.resetErrorsRecursive();
                sidObj.validateRecursive();
                if (sidObj.hasErrorRecursive()) {
                    store.isValid = !sidObj.hasErrorRecursive()
                }
            }
        }
        if (!store.isValid) {
            alert(Validatable.errorTexts.notValid);
        }
        return true;
    }

    const handleSDContents = () => {
        try {
            const value = tmpSDContents;
            const split = value.split(/[\t\n\s]/);
            const list: string[] = [];
            split.map((v) => {
                if (v) {
                    list.push(v.trim());
                }
                return null;
            });
            store.taskData.detail.tempSDContents = list;
        } catch (err) {

        }
    }

    const handleSDContentsClear = () => {
        store.taskData.detail.tempSDContents = [];
        setTmpSDContents('');
    }

    const handleSIDList = async () => {
        if (!Helper.checkRegex(sid, /^[0-9][0-9](0[1-9]|1[0-2])SD[0-9]{3}$/)) {
            setSID('');
            return;
        }
        else if (noSid < 1) {
            return;
        }
        for (let sidObje of store.sidObjList) {
            if (sidObje.sid.substring(0, 9) === sid) {
                return;
            }
        }
        const sidResult = await store.getSynthIDs(workInfo.prevWorkID, globalStore.token);
        if (workInfo.workType === 'project_rebalance' && sidResult.data.data.length !== 0) {
            // 리밸런싱
            const list = sidResult.data.data.map((v: any) => {
                return new SynthesisID(v);
            });
            let max = list[0].sid.substring(list[0].sid.indexOf('-') + 1, list[0].sid.indexOf('.'));
            for (let sids of list) {
                if (max < sids.sid.substring(sids.sid.indexOf('-') + 1, sids.sid.indexOf('.'))) {
                    max = sids.sid.substring(sids.sid.indexOf('-') + 1, sids.sid.indexOf('.'));
                }
            }
            for (let i = 0; i < noSid; i++) {
                const obj = new SynthesisID();
                obj.sid = `${sid}-${(i + parseInt(max) + 1).toString()}`;
                obj.designer = globalStore.userInfo.name;
                obj.workID = workInfo.workID;
                store.sidObjList.push(obj);
            }
        } else if (workInfo.workType === 'designWork_re' && sidResult.data.data.length !== 0) {
            // 리밸런싱 -> 재작업
            const list = sidResult.data.data.map((v: any) => {
                return new SynthesisID(v);
            });
            let min = list[0].sid.substring(list[0].sid.indexOf('-') + 1, list[0].sid.indexOf('.'));
            for (let sids of list) {
                if (min > sids.sid.substring(sids.sid.indexOf('-') + 1, sids.sid.indexOf('.'))) {
                    min = sids.sid.substring(sids.sid.indexOf('-') + 1, sids.sid.indexOf('.'));
                }
            }
            for (let i = 0; i < noSid; i++) {
                const obj = new SynthesisID();
                obj.sid = `${sid}-${(i + parseInt(min)).toString()}`;
                obj.designer = globalStore.userInfo.name;
                obj.workID = workInfo.workID;
                store.sidObjList.push(obj);
            }
        } else {
            // 그외
            for (let i = 0; i < noSid; i++) {
                const obj = new SynthesisID();
                obj.sid = `${sid}-${(i + 1).toString()}`;
                obj.designer = globalStore.userInfo.name;
                obj.workID = workInfo.workID;
                store.sidObjList.push(obj);
            }
        }
        setSID('');
        setNoSid(0);
        store.taskData.detail.initErrorByKey('synthesisIDPrefix');
    }

    const handleSIDListDelete = (key: number) => {
        store.sidObjList.splice(key, 1);
        store.files.delete(`maskingRegionFile.${key}`);
        store.files.delete(`synthesisTargetBedFile.${key}`);
        store.filesMapping.delete(`maskingRegionFile.${key}`);
        store.filesMapping.delete(`synthesisTargetBedFile.${key}`);

    }

    const onFileChange = (name: string, files: FileList): PTSFile => {
        const file = files[0];
        const uniqueKey = Helper.generateRandomString();
        const ptsFileObj = Helper.generatePTSFileWithUniqueKey(file, uniqueKey);
        store.files.set(name, file);
        store.filesMapping.set(name, ptsFileObj);
        return ptsFileObj;
    }

    const onFileChangeArray = (name: string, files: FileList, index: number) => {
        const file = files[0];
        const uniqueKey = Helper.generateRandomString();
        const ptsFileObj = Helper.generatePTSFileWithUniqueKey(file, uniqueKey);
        store.files.set(`${name}.${index}`, file);
        store.filesMapping.set(`${name}.${index}`, ptsFileObj);
        return ptsFileObj;
    }

    return (
        <React.Fragment>
            <Grid item container>
                <Grid xl lg sm item>
                    <TitledPaper
                        title='Synthesis Design Infos'>
                        <TextField
                            fullWidth
                            margin='normal'
                            label={FormUtil.getFieldLabel('panelName')}
                            onChange={store.taskData.detail.onChangeDefault}
                            name='panelName'
                            value={
                                store.taskData.detail.panelName
                            }
                            helperText={
                                Helper.getErrorText(
                                    store.taskData.detail.errors.panelName
                                )
                            }
                            error={
                                Helper.getErrorState(
                                    store.taskData.detail.errors.panelName
                                )
                            } />
                        <TitledPaper title={FormUtil.getFieldLabel('tempSDContents')}>
                            <Grid xl lg sm item container alignItems='stretch'>
                                <Grid xl={10} lg={10} sm={10} container item alignItems='stretch'>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={5}
                                        margin='normal'
                                        label={FormUtil.getFieldLabel('tempSDContents')}
                                        onChange={(e) => setTmpSDContents(e.target.value)}
                                        value={tmpSDContents}
                                        helperText={
                                            Helper.getErrorText(
                                                store.taskData.detail.errors.tempSDContents
                                            )
                                        }
                                        error={
                                            Helper.getErrorState(
                                                store.taskData.detail.errors.tempSDContents
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid spacing={1} style={{ margin: 10 }} xl lg sm container item direction='column' alignItems='stretch'>
                                    <Grid spacing={1} xl lg sm item container alignItems='stretch'>
                                        <Button
                                            style={{ margin: 5 }}
                                            fullWidth
                                            onClick={(e) => handleSDContents()}
                                            variant='contained'
                                            color='primary'>적용</Button>
                                        <Button
                                            style={{ margin: 5 }}
                                            fullWidth
                                            variant='contained'
                                            onClick={(e) => handleSDContentsClear()}
                                            color='secondary'>비우기</Button>
                                    </Grid>
                                </Grid>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                {FormUtil.getFieldLabel('tempSDContents')}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {store.taskData.detail.tempSDContents.map((v, i) => (
                                            <TableWithHelperText
                                                key={i}
                                                index={i}
                                                data={v}
                                                helperText={
                                                    store.taskData.detail.tempSDContentError[i]
                                                }
                                                error={
                                                    store.taskData.detail.tempSDContentError[i] === '' || true
                                                } />
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </TitledPaper>
                    </TitledPaper>
                </Grid>
            </Grid>
            <Grid item xl lg sm>
                <TitledPaper
                    title='Synthesis design info files' >
                    <Grid item container xl lg sm style={{ padding: 10 }}>
                        <Grid item xl={4} lg={4} sm={4} style={{ marginRight: 10 }}>
                            <Typography variant='body1'>
                                {FormUtil.getFieldLabel('tempTargetBedFile')}
                            </Typography>
                        </Grid>
                        <Grid item xl lg sm>
                            <FileWithHelperText
                                name='tempTargetBedFile'
                                multiple={false}
                                onFileChange={onFileChange}
                                data={store.taskData.detail}
                                helperText={
                                    Helper.getErrorText(
                                        store.taskData.detail.errors.tempTargetBedFile
                                    )
                                }
                                error={
                                    Helper.getErrorState(
                                        store.taskData.detail.errors.tempTargetBedFile
                                    )
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid item container xl lg sm style={{ padding: 10 }}>
                        <Grid item xl={4} lg={4} sm={4} style={{ marginRight: 10 }}>
                            <Typography variant='body1'>
                                {FormUtil.getFieldLabel('homologRegionFile')}
                            </Typography>
                        </Grid>
                        <Grid item xl lg sm>
                            <FileWithHelperText
                                name='homologRegionFile'
                                multiple={false}
                                onFileChange={onFileChange}
                                data={store.taskData.detail}
                                helperText={
                                    Helper.getErrorText(
                                        store.taskData.detail.errors.homologRegionFile
                                    )
                                }
                                error={
                                    Helper.getErrorState(
                                        store.taskData.detail.errors.homologRegionFile
                                    )
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid item container xl lg sm style={{ padding: 10 }}>
                        <Grid item xl={4} lg={4} sm={4} style={{ marginRight: 10 }}>
                            <Typography variant='body1'>
                                {FormUtil.getFieldLabel('designReport')}
                            </Typography>
                        </Grid>
                        <Grid item xl lg sm>
                            <FileWithHelperText
                                name='designReport'
                                multiple={false}
                                onFileChange={onFileChange}
                                data={store.taskData.detail}
                                helperText={
                                    Helper.getErrorText(
                                        store.taskData.detail.errors.designReport
                                    )
                                }
                                error={
                                    Helper.getErrorState(
                                        store.taskData.detail.errors.designReport
                                    )
                                }
                            />
                        </Grid>
                    </Grid>
                </TitledPaper>
            </Grid>
            <Grid item xl lg sm md>
                <TitledPaper title={`기존 ${FormUtil.getFieldLabel('synthesisIDs')}`}>
                    {store.prevSIDs.map((v, i) => (
                        <div key={`key${i}`}>
                            {v}
                        </div>
                    ))}
                </TitledPaper>
            </Grid>
            <Grid item container>
                <Grid xl lg sm item>
                    <TitledPaper
                        title={`추가 ${FormUtil.getFieldLabel('synthesisIDs')}`}>
                        <Grid xl lg sm item container alignItems='stretch'>
                            <Grid xl={10} lg={10} sm={10} container item alignItems='stretch'>
                                <TextField
                                    margin='dense'
                                    label='ID prefix (YYMMSD###)'
                                    onChange={(e) => setSID(e.target.value)}
                                    value={
                                        sid
                                    }
                                    helperText={
                                        Helper.getErrorText(
                                            store.taskData.detail.errors.synthesisIDPrefix
                                        )
                                    }
                                    error={
                                        Helper.getErrorState(
                                            store.taskData.detail.errors.synthesisIDPrefix
                                        )
                                    }
                                />
                                <TextField
                                    margin='dense'
                                    type='number'
                                    label='수량'
                                    onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                                    onChange={(e) => setNoSid(parseInt(e.target.value))}
                                    value={
                                        noSid
                                    } />
                            </Grid>
                            <Grid spacing={1} style={{ margin: 10 }} xl lg sm container item direction='column' alignItems='flex-start'>
                                <Grid spacing={1} xl lg sm item container alignItems='center'>
                                    <Button
                                        style={{ margin: 5 }}
                                        fullWidth
                                        onClick={(e) => handleSIDList()}
                                        variant='contained'
                                        color='primary'>추가</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        {store.sidObjList.map((v, i) => (
                            <SynthesisIDInputCell
                                key={i}
                                onFileChange={onFileChangeArray}
                                onDeleteClick={(e, k) => handleSIDListDelete(k)}
                                index={i}
                                data={v} />
                        ))}
                    </TitledPaper>
                </Grid>
            </Grid>
            <Grid item container xl lg sm style={{ marginTop: 10 }}>
                <Grid item xl lg sm>
                    <FormButtonSet
                        validated={store.isValid}
                        onSubmit={onFormSubmit}
                        onFormClear={onFormClear}
                        onValidate={onVliadte} />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}))

export default RebalancingSynthesisDesignForm;