import React from 'react';
import { observer } from 'mobx-react';
import { Grid, FormControl, InputLabel, Select, MenuItem, TextField, Button } from '@material-ui/core';

interface Props {
    selectedField?: string;
    filterText?: string;
    onFilterChange?: (e: any) => void;
    onFilterClear?: (e: React.MouseEvent<HTMLElement>) => void;
    onSearch?: (e: React.MouseEvent<HTMLElement>) => void;
}

const ManufactureSearchFilterForm: React.FC<Props> = observer((props) => {
    const onFilterChange = props.onFilterChange || ((e: any) => { console.log('Function not defined') });
    const onSearch = props.onSearch || ((e: any) => { console.log('Function not defined') });
    const onFilterClear = props.onFilterClear || ((e: any) => { console.log('Function not defined') });

    return (
        <React.Fragment>
            <Grid item container>
                <Grid item xl lg sm>
                    <FormControl margin='normal'>
                        <InputLabel id='selectedField'>
                            검색 필드
                        </InputLabel>
                        <Select
                            style={{ minWidth: 200, marginRight: 8 }}
                            labelId='selectedField'
                            onChange={onFilterChange}
                            value={props.selectedField}
                            name='selectedField'>
                            <MenuItem value=''>None</MenuItem>
                            <MenuItem value='orderNumber'> Order Num.</MenuItem>
                            <MenuItem value='projectCode'> 고객 코드</MenuItem>
                            <MenuItem value='erpCode'> Project Code (ERP)</MenuItem>
                            <MenuItem value='productType'>제품타입</MenuItem>
                            <MenuItem value='customerAndPI'> Customer & PI</MenuItem>
                            <MenuItem value='representativeName'> 영업담당자</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        style={{ minWidth: 250, marginRight: 8 }}
                        name='filterText'
                        value={props.filterText}
                        onChange={onFilterChange}
                        label='검색어'
                        margin='normal' />
                    <Button onClick={onSearch} style={{ marginTop: 30, marginRight: 5 }} variant='contained' color='primary'>찾기</Button>
                    <Button onClick={onFilterClear} style={{ marginTop: 30 }} variant='contained' color='secondary'>Clear</Button>
                </Grid>
            </Grid>
        </React.Fragment>
    )
});

export default ManufactureSearchFilterForm;