import { action, observable } from "mobx";
import LoginUtil from "../../../module/LoginUtil";
import RootStore from "../RootStore";

class LayoutStore {
    @observable profileAnchorElement: HTMLElement | null;
    @observable menuOpen: boolean;
    rootStore: RootStore;

    constructor(rootStore?: RootStore) {
        this.profileAnchorElement = null;
        this.menuOpen = false;
        this.rootStore = rootStore;
    }

    @action.bound
    set setAnchorElement(anchor: HTMLElement | null) {
        this.profileAnchorElement = anchor;
    }

    @action.bound
    set setMenuOpen(menuOpen: boolean) {
        this.menuOpen = menuOpen;
    }

    handleProfileClose = () => {
        this.profileAnchorElement = null;
    }

    handleProfileMenu = (e: React.MouseEvent<HTMLElement>) => {
        this.profileAnchorElement = e.currentTarget;
    }

    onLogout = (e: React.MouseEvent<HTMLElement>) => {
        LoginUtil.logout(this.rootStore.globalStore);
        // window.location.reload();
    }

    onMenuClick = (e: React.MouseEvent<HTMLElement>) => {
        this.menuOpen = !this.menuOpen;
    }
}

export default LayoutStore;