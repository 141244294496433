import { Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import RebalancingRequestStore from '../../../../../../../data/store/pageStore/design/RebalancingRequestStore';
import { IRootStore } from '../../../../../../../data/store/RootStore';
import TitledPaper from '../../../../../../common/component/TitledPaper';

interface Props extends IRootStore {
    store?: RebalancingRequestStore;
}

const ProbeSDContentsComponent: React.FC<Props> = (props) => {
    return (
        <React.Fragment>
            <TitledPaper title='Probe ID & Final SD contents'>
                <div style={{ marginBottom: 15, marginTop: 15 }}>
                    <span style={{ marginBottom: 10 }}>
                        <Typography variant='body1'>
                            Probe ID
                        </Typography>
                    </span>
                    <div style={{ paddingLeft: 7 }}>
                        {props.store.probeID}
                    </div>
                </div>
                <div>
                    <span>
                        <Typography variant='body1'>
                            Final SD Contents
                        </Typography>
                    </span>
                    <div style={{ paddingLeft: 7 }}>
                        {
                            props.store.finalSDContents.map((v) => {
                                return (
                                    <div>
                                        {v}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </TitledPaper>
        </React.Fragment>
    )
}

export default observer(ProbeSDContentsComponent);