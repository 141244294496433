import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Grid, ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails, Paper } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import FormUtil from '../../../../../../../module/FormUtil';

interface Props {
    tupleKey?: string;
    value?: JSX.Element[];
    open?: boolean;
    index?: number;
}

const WorkDetailArray: React.FC<Props> = observer((props) => {
    const [open, setOpen] = useState(props.open || false);
    const titleColor = '#D4EFDF';

    return (
        <React.Fragment>
            <Grid container justify='flex-end'>
                <Grid item xl lg sm>
                    <Grid item container xl lg sm>
                        <Grid item xl={3} lg={3} sm={3}>
                            <Typography variant='body2'>
                                {FormUtil.getFieldLabel(props.tupleKey)}
                            </Typography>
                        </Grid>
                        <Grid item xl lg sm>
                            <ExpansionPanel style={{ width: '100%', marginLeft: 5 }}>
                                <ExpansionPanelSummary
                                    style={{
                                        background: titleColor,
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                    }}
                                    onClick={(e) => setOpen(!open)}
                                    expandIcon={<ExpandMore />}>
                                    <Typography variant='body1'>
                                        {`${FormUtil.getFieldLabel(props.tupleKey)} (리스트)`}
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails
                                    style={{
                                        padding: 8
                                    }}>
                                    <Grid item container>
                                        <Grid item xl lg sm>
                                            {props.value.map((v, i) => (
                                                <Paper key={i} style={{ margin: 5, padding: 8 }}>
                                                    <React.Fragment>
                                                        {v}
                                                    </React.Fragment>
                                                </Paper>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
});

export default WorkDetailArray;