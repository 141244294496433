import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import SnopManufactureItem from '../../../../../data/model/snop/SnopManufactureItem';
import ISnopTable from './ISnopTable';
import SnopManufactureItemCell from './SnopManufactureItemCell';

interface Props extends ISnopTable<SnopManufactureItem> {

}

const SnopManufactureTable: React.FC<Props> = (props) => {
    const datas: SnopManufactureItem[] = props.datas || [];

    return (
        <React.Fragment>
            <Table stickyHeader>
                <TableHead>
                    <TableRow key='tableheader'>
                        <TableCell>
                            Order No.
                        </TableCell>
                        <TableCell>
                            영업담당자
                        </TableCell>
                        <TableCell>
                            의뢰일시
                        </TableCell>
                        <TableCell>
                            고객 코드
                        </TableCell>
                        <TableCell>
                            고객 및 PI
                        </TableCell>
                        <TableCell>
                            작업 종류
                        </TableCell>
                        <TableCell>
                            작업 현황
                        </TableCell>
                        <TableCell>
                            작업 상태
                        </TableCell>
                        <TableCell>
                            예상 출하일
                        </TableCell>
                        <TableCell>
                            플랫폼
                        </TableCell>
                        <TableCell>
                            제조 시작일시
                        </TableCell>
                        <TableCell>
                            제조 완료일시
                        </TableCell>
                        <TableCell>
                            최종 출하 요청일
                        </TableCell>
                        <TableCell>
                            배송 완료일
                        </TableCell>
                        <TableCell>

                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {datas.map((v, i) => {
                        return (
                            <SnopManufactureItemCell data={v} key={`snopmanitem${i}`} />
                        )
                    })}
                </TableBody>
            </Table>
        </React.Fragment>
    )
}

export default observer(SnopManufactureTable);