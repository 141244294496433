import { observable, set } from "mobx";
import ModelBase from "../common/ModelBase";
import Depart from "./Depart";

export interface IUser {
    name: string;
    email: string;
    phone: string;
    pw: string;
    depart: Depart | string;
    departName: string;
    departCode: number;
    departType: string;
    departID: string;
    activate: boolean;
    role: string;
    code: number;
    lastLogin: Date;
    userID: string;
}

class User extends ModelBase {
    @observable name: string;
    @observable email: string;
    @observable pw: string;
    @observable phone: string;
    @observable depart: Depart | string;
    @observable departName: string;
    @observable departCode: number;
    @observable departType: string;
    @observable departID: string;
    @observable activate: boolean;
    @observable role: string;
    @observable code: number;
    @observable userID: string;
    @observable lastLogin: Date;

    constructor(data?: IUser) {
        super();
        this.departName = '';
        this.name = '';
        if(data) {
            set(this, data);
        }
    }
}

export default User;