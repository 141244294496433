import { action, observable } from "mobx";
import Comment from "../../model/common/Comment";
import RootStore from "../RootStore";
import StoreBase from "../StoreBase";
import axios from 'axios';
import { words as RouteWords } from '../../../resource/data/APIRoutes.json';
import PTSMessage from "../../model/common/PTSMessage";

export default class CommentStore extends StoreBase {
    @observable workID: string;
    @observable currentContent: string;
    @observable mode: 'tmp' | 'work';
    @observable scroll: boolean;
    @observable stage: string;
    @observable comments: Comment[];
    @observable isRunning: boolean;

    @observable writerName: string;
    @observable email: string;

    constructor(rootStore?: RootStore) {
        super();
        this.rootStore = rootStore;
        this.workID = '';
        this.currentContent = '';
        this.mode = 'tmp';
        this.stage = '';
        this.comments = [];
        this.isRunning = false;
        this.scroll = true;
        this.writerName = '';
        this.email = '';
    }

    addComment = async () => {
        try {
            const comment = new Comment({
                workID: this.workID,
                stage: this.stage,
                writerName: this.rootStore.globalStore.userInfo.name,
                content: this.currentContent,
                email: this.rootStore.globalStore.userInfo.email,
            });
            const config = {
                headers: {
                    'x-access-token': this.rootStore.globalStore.token
                }
            }
            const result = await axios.post(
                `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.comments}`,
                comment,
                config
            ).then((res) => {
                return res;
            }).catch((err) => {
                throw err;
            });
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                })
            }
        } catch (err) {
            throw err;
        }
    }

    addCommentsMany = async (workID?: string) => {
        try {
            const config = {
                headers: {
                    'x-access-token': this.rootStore.globalStore.token
                }
            }
            if (workID) {
                this.comments.map((v) => {
                    v.workID = workID;
                    return v;
                })
            }
            const result = await axios.post(
                `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.comments}/${RouteWords.many}`,
                this.comments,
                config
            )
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                })
            }
        } catch (err) {
            throw err;
        }
    }

    getCommentsMany = async () => {
        const config = {
            headers: {
                'x-access-token': this.rootStore.globalStore.token
            }
        }
        return axios.get(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.comments}/${this.workID}`,
            config
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        });
    }

    @action
    setCommentsMany = async () => {
        try {
            const comments = await this.getCommentsMany();
            let com: Comment[] = [];
            if (Array.isArray(comments.data.data)) {
                com = comments.data.data.map((v: any) => {
                    return new Comment(v);
                });
            }
            if (comments.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: comments.data.resMessage,
                });
            }
            this.comments = com;
            this.scrollBottom();
        } catch (err) {
            throw err;
        }
    }

    @action
    setTempComment = (workID?: string) => {
        const comment = new Comment({
            writerName: this.rootStore.globalStore.userInfo.name,
            email: this.rootStore.globalStore.userInfo.email,
            content: this.currentContent,
            stage: this.stage,
            createdDate: new Date(),
            workID: workID || null,
        });
        this.comments.push(comment);
        this.scrollBottom();
        this.clearValue();
    }

    clearValue = (e?: React.FormEvent<HTMLElement>) => {
        this.currentContent = '';
    }

    scrollBottom = () => {
        if (this.scroll) {
            const obj = document.getElementById('commentContentsList');
            obj.scrollTop = obj.scrollHeight;
        }
    }

    updateComment = async (data: object, id: string) => {
        const config = {
            headers: {
                'x-access-token': this.rootStore.globalStore.token,
            }
        }
        return axios.put(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.comments}/${id}`,
            data,
            config,
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        })
    }

    toggleCancel = async (key: number) => {
        try {
            this.isRunning = true;
            if (this.mode === 'tmp') {
                const comment = this.comments[key];
                if (!comment) {
                    return;
                }
                comment.cancel = !comment.cancel;
            }
            else {
                const comment = this.comments[key];
                if (!comment) {
                    return;
                }
                const _id = comment._id;
                const cancel = !comment.cancel;
                await this.updateComment({ cancel: cancel }, _id);
                this.setCommentsMany();
            }
        } catch (err) {
        } finally {
            this.isRunning = false;
        }
    }

    onInputClick = async (e: React.MouseEvent<HTMLElement>) => {
        if (!this.currentContent) {
            return;
        }
        try {
            this.isRunning = true;
            if (this.mode === 'tmp') {
                this.setTempComment();
            }
            else {
                if (!this.workID) {
                    return;
                }
                await this.addComment();
                this.setCommentsMany();
                this.clearValue();
            }
        } catch (err) {
        } finally {
            this.isRunning = false;
        }
    }
}