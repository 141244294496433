import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import RebalancingRequestStore from '../../../../../../../data/store/pageStore/design/RebalancingRequestStore';
import FormUtil from '../../../../../../../module/FormUtil';
import Helper from '../../../../../../../module/Helper';
import TitledPaper from '../../../../../../common/component/TitledPaper';
import GeneListTable from '../../../designRequest/component/GeneListTable';

interface Props {
    store: RebalancingRequestStore;
}

const RebalancingGeneListForm: React.FC<Props> = (props) => {
    const designRequestStore = props.store;

    // const onFileChange = (name: string, files: FileList) => {
    //     const file = files[0];
    //     const uniqueKey = Helper.generateRandomString();
    //     const ptsFileObj = Helper.generatePTSFileWithUniqueKey(file, uniqueKey);
    //     designRequestStore.files.set(name, file);
    //     designRequestStore.filesMapping.set(name, ptsFileObj);
    //     return ptsFileObj;
    // }

    // const resetGeneListStrs = () => {
    //     designRequestStore.resetGeneListStrs();
    //     designRequestStore.newData.detail.requestedGeneList.resetGeneListFile();
    //     designRequestStore.newData.detail.requestedGeneList.resetAllGeneLists();
    // }

    const renderGeneListComponent = (): JSX.Element => {
        return (
            <React.Fragment>
                <Grid item container xl lg sm style={{ marginTop: 10 }}>
                    <Grid item xl lg sm>
                        <TitledPaper title={FormUtil.getFieldLabel('geneList')}>
                            <Grid xl lg sm item container alignItems='stretch'>
                                <Grid xl={10} lg={10} sm={10} container item alignItems='stretch'>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={5}
                                        margin='normal'
                                        label='유전자 리스트'
                                        name='geneListStr'
                                        onChange={designRequestStore.onChangeDefault}
                                        value={designRequestStore.geneListStr}
                                        helperText={
                                            Helper.getErrorText(
                                                designRequestStore.newData.detail.requestedGeneList.errors.geneList
                                            )
                                        }
                                        error={
                                            Helper.getErrorState(
                                                designRequestStore.newData.detail.requestedGeneList.errors.geneList
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid spacing={1} style={{ margin: 10 }} xl lg sm container item direction='column' alignItems='stretch'>
                                    <Grid spacing={1} xl lg sm item container alignItems='stretch'>
                                        <Button
                                            style={{ margin: 5 }}
                                            fullWidth
                                            onClick={() => designRequestStore.handleGeneListChange()}
                                            variant='contained'
                                            color='primary'>적용</Button>
                                        <Button
                                            style={{ margin: 5 }}
                                            fullWidth
                                            variant='contained'
                                            onClick={() => designRequestStore.handleGeneListClear()}
                                            color='secondary'>지움</Button>
                                    </Grid>
                                </Grid>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                #
                                            </TableCell>
                                            <TableCell>
                                                Gene
                                            </TableCell>
                                            <TableCell>
                                                Remark
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {designRequestStore.newData.detail.requestedGeneList.geneList.map((v, i) => (
                                            <TableRow key={i}>
                                                <TableCell>
                                                    {i + 1}
                                                </TableCell>
                                                <TableCell>
                                                    {v.name}
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        fullWidth
                                                        multiline
                                                        label={FormUtil.getFieldLabel('remark')}
                                                        onChange={(e) => { v.remark = e.target.value }}
                                                        value={v.remark}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </TitledPaper>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }

    const renderCustomRegionListComponent = (): JSX.Element => {
        return (
            <React.Fragment>
                <Grid item container xl lg sm>
                    <Grid item xl lg sm>
                        <TitledPaper title={FormUtil.getFieldLabel('fusionList')}>
                            <Grid xl lg sm item container alignItems='stretch'>
                                <Grid xl={10} lg={10} sm={10} container item alignItems='stretch'>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={5}
                                        margin='normal'
                                        label={FormUtil.getFieldLabel('fusionList')}
                                        name='fusionListStr'
                                        onChange={designRequestStore.onChangeDefault}
                                        value={designRequestStore.fusionListStr}
                                        helperText={
                                            Helper.getErrorText(
                                                designRequestStore.newData.detail.requestedGeneList.errors.fusionList
                                            )
                                        }
                                        error={
                                            Helper.getErrorState(
                                                designRequestStore.newData.detail.requestedGeneList.errors.fusionList
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid spacing={1} style={{ margin: 10 }} xl lg sm container item direction='column' alignItems='stretch'>
                                    <Grid spacing={1} xl lg sm item container alignItems='stretch'>
                                        <Button
                                            style={{ margin: 5 }}
                                            fullWidth
                                            onClick={
                                                () =>
                                                    designRequestStore.handleListChange(
                                                        'designRequestStore.newData.detail.requestedGeneList.fusionList',
                                                        designRequestStore.fusionListStr
                                                    )
                                            }
                                            variant='contained'
                                            color='primary'>적용</Button>
                                        <Button
                                            style={{ margin: 5 }}
                                            fullWidth
                                            variant='contained'
                                            onClick={
                                                () =>
                                                    designRequestStore.handleListClear(
                                                        'designRequestStore.newData.detail.requestedGeneList.fusionList'
                                                    )
                                            }
                                            color='secondary'>지움</Button>
                                    </Grid>
                                </Grid>
                                <GeneListTable
                                    list={designRequestStore.newData.detail.requestedGeneList.fusionList} />
                            </Grid>
                        </TitledPaper>
                    </Grid>
                </Grid>
                <Grid item container xl lg sm>
                    <Grid item xl lg sm>
                        <TitledPaper title={FormUtil.getFieldLabel('hotspotList')}>
                            <Grid xl lg sm item container alignItems='stretch'>
                                <Grid xl={10} lg={10} sm={10} container item alignItems='stretch'>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={5}
                                        margin='normal'
                                        label={FormUtil.getFieldLabel('hotspotList')}
                                        name='hotspotListStr'
                                        onChange={designRequestStore.onChangeDefault}
                                        value={designRequestStore.hotspotListStr}
                                        helperText={
                                            Helper.getErrorText(
                                                designRequestStore.newData.detail.requestedGeneList.errors.hotspotList
                                            )
                                        }
                                        error={
                                            Helper.getErrorState(
                                                designRequestStore.newData.detail.requestedGeneList.errors.hotspotList
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid spacing={1} style={{ margin: 10 }} xl lg sm container item direction='column' alignItems='stretch'>
                                    <Grid spacing={1} xl lg sm item container alignItems='stretch'>
                                        <Button
                                            style={{ margin: 5 }}
                                            fullWidth
                                            onClick={
                                                () =>
                                                    designRequestStore.handleListChange(
                                                        'designRequestStore.newData.detail.requestedGeneList.hotspotList',
                                                        designRequestStore.hotspotListStr
                                                    )
                                            }
                                            variant='contained'
                                            color='primary'>적용</Button>
                                        <Button
                                            style={{ margin: 5 }}
                                            fullWidth
                                            variant='contained'
                                            onClick={
                                                () =>
                                                    designRequestStore.handleListClear(
                                                        'designRequestStore.newData.detail.requestedGeneList.hotspotList'
                                                    )
                                            }
                                            color='secondary'>지움</Button>
                                    </Grid>
                                </Grid>
                                <GeneListTable
                                    list={designRequestStore.newData.detail.requestedGeneList.hotspotList} />
                            </Grid>
                        </TitledPaper>
                    </Grid>
                </Grid>
                <Grid item container xl lg sm>
                    <Grid item xl lg sm>
                        <TitledPaper title={FormUtil.getFieldLabel('customList')}>
                            <Grid xl lg sm item container alignItems='stretch'>
                                <Grid xl={10} lg={10} sm={10} container item alignItems='stretch'>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={5}
                                        margin='normal'
                                        label={FormUtil.getFieldLabel('customList')}
                                        name='customListStr'
                                        onChange={designRequestStore.onChangeDefault}
                                        value={designRequestStore.customListStr}
                                        helperText={
                                            Helper.getErrorText(
                                                designRequestStore.newData.detail.requestedGeneList.errors.customList
                                            )
                                        }
                                        error={
                                            Helper.getErrorState(
                                                designRequestStore.newData.detail.requestedGeneList.errors.customList
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid spacing={1} style={{ margin: 10 }} xl lg sm container item direction='column' alignItems='stretch'>
                                    <Grid spacing={1} xl lg sm item container alignItems='stretch'>
                                        <Button
                                            style={{ margin: 5 }}
                                            fullWidth
                                            onClick={
                                                () =>
                                                    designRequestStore.handleListChange(
                                                        'designRequestStore.newData.detail.requestedGeneList.customList',
                                                        designRequestStore.customListStr
                                                    )
                                            }
                                            variant='contained'
                                            color='primary'>적용</Button>
                                        <Button
                                            style={{ margin: 5 }}
                                            fullWidth
                                            variant='contained'
                                            onClick={
                                                () =>
                                                    designRequestStore.handleListClear(
                                                        'designRequestStore.newData.detail.requestedGeneList.customList'
                                                    )
                                            }
                                            color='secondary'>지움</Button>
                                    </Grid>
                                </Grid>
                                <GeneListTable
                                    list={designRequestStore.newData.detail.requestedGeneList.customList} />
                            </Grid>
                        </TitledPaper>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }

    const renderSynthesisSequenceListComponent = (): JSX.Element => {
        return (
            <React.Fragment>
                <Grid item container xl lg sm>
                    <Grid item xl lg sm>
                        <TitledPaper title={FormUtil.getFieldLabel('synthRequestSequence')}>
                            <Grid xl lg sm item container alignItems='stretch'>
                                <Grid xl={10} lg={10} sm={10} container item alignItems='stretch'>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={5}
                                        margin='normal'
                                        label={FormUtil.getFieldLabel('synthRequestSequence')}
                                        // onChange={designRequestStore.onChangeDefault}
                                        onChange={(e) => designRequestStore.synthRequestSequenceStr = e.target.value}
                                        value={designRequestStore.synthRequestSequenceStr}
                                        helperText={
                                            Helper.getErrorText(
                                                designRequestStore.newData.detail.requestedGeneList.errors.synthRequestSequence
                                            )
                                        }
                                        error={
                                            Helper.getErrorState(
                                                designRequestStore.newData.detail.requestedGeneList.errors.synthRequestSequence
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid spacing={1} style={{ margin: 10 }} xl lg sm container item direction='column' alignItems='stretch'>
                                    <Grid spacing={1} xl lg sm item container alignItems='stretch'>
                                        <Button
                                            style={{ margin: 5 }}
                                            fullWidth
                                            onClick={() => designRequestStore.handleSynthRequestListChange()}
                                            variant='contained'
                                            color='primary'>적용</Button>
                                        <Button
                                            style={{ margin: 5 }}
                                            fullWidth
                                            variant='contained'
                                            onClick={() => designRequestStore.handleSynthRequestListClear()}
                                            color='secondary'>지움</Button>
                                    </Grid>
                                </Grid>
                                {/* <GeneListTable
                                    list={this.designRequestStore.data.detail.requestedGeneList.synthRequestSequence} /> */}
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                #
                                            </TableCell>
                                            <TableCell>
                                                Name
                                            </TableCell>
                                            <TableCell>
                                                Sequence
                                            </TableCell>
                                            <TableCell>
                                                Size
                                            </TableCell>
                                            <TableCell>
                                                Remark
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {designRequestStore.newData.detail.requestedGeneList.synthRequestSequence.map((v, i) => (
                                            <TableRow key={i}>
                                                <TableCell>
                                                    {i + 1}
                                                </TableCell>
                                                <TableCell>
                                                    {v.name}
                                                </TableCell>
                                                <TableCell>
                                                    {v.sequence.length <= 30 ?
                                                        v.sequence
                                                        : v.sequence.substring(0, 14) + '...' + v.sequence.substring(v.sequence.length - 15, v.sequence.length)
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {v.size}
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        fullWidth
                                                        multiline
                                                        // margin='normal'
                                                        label={FormUtil.getFieldLabel('remark')}
                                                        onChange={(e) => { v.remark = e.target.value }}
                                                        value={v.remark}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </TitledPaper>
                    </Grid>
                </Grid >
                {/* <Grid item container xl lg sm style={{ padding: 10 }}>
                    <Grid item xl={4} lg={4} sm={4} style={{ marginRight: 10 }}>
                        <Typography variant='body1'>
                            {FormUtil.getFieldLabel('geneListFile')}
                        </Typography>
                    </Grid>
                    <Grid item xl lg sm>
                        <input
                            name='geneListFile'
                            multiple={false}
                            onChange={
                                (e) => {
                                    const obj = onFileChange(e.target.name, e.target.files);
                                    designRequestStore.newData.detail.requestedGeneList.onChangeFileDefault(e.target.name, obj);
                                }
                            }
                            type='file' />
                    </Grid>
                </Grid> */}
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <TitledPaper title='영역추가 위치'>
                <Grid item container>
                    <Grid item xl lg sm>
                        <Typography variant='body1' color='secondary' style={{ marginTop: 10, marginBottom: 10 }}>
                            추가영역만 입력하세요.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container xl lg sm>
                    <Grid item xl lg sm>
                        <TitledPaper title='Requested Gene(NM ID) Info'>
                            <FormControl
                                fullWidth margin='normal'>
                                <InputLabel id='referenceVersion'>{FormUtil.getFieldLabel('geneListType')}</InputLabel>
                                <Select
                                    error={
                                        Helper.getErrorState(
                                            designRequestStore.newData.detail.requestedGeneList.errors.geneListType
                                        )
                                    }
                                    labelId='geneListType'
                                    onChange={designRequestStore.newData.detail.requestedGeneList.onChangeDefault}
                                    value={
                                        designRequestStore.newData.detail.requestedGeneList.geneListType
                                    }
                                    name='geneListType'
                                >
                                    <MenuItem value='None'>None</MenuItem>
                                    <MenuItem value='CDS'>CDS</MenuItem>
                                    <MenuItem value='CDS+UTR'>CDS+UTR</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                fullWidth
                                type='number'
                                margin='normal'
                                label={FormUtil.getFieldLabel('geneExpand')}
                                onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                                onChange={designRequestStore.newData.detail.requestedGeneList.onChangeDefault}
                                value={
                                    designRequestStore.newData.detail.requestedGeneList.geneExpand
                                }
                                helperText={
                                    Helper.getErrorText(
                                        designRequestStore.newData.detail.requestedGeneList.errors.geneExpand
                                    )
                                }
                                error={
                                    Helper.getErrorState(
                                        designRequestStore.newData.detail.requestedGeneList.errors.geneExpand
                                    )
                                }
                                name='geneExpand'
                            />
                            {renderGeneListComponent()}
                        </TitledPaper>
                    </Grid>
                </Grid>
                <Grid item container style={{ marginTop: 15 }}>
                    <Grid item xl lg sm>
                        <TitledPaper title='Custom Region List'>
                            {renderCustomRegionListComponent()}
                        </TitledPaper>
                    </Grid>
                </Grid>
                <Grid item container style={{ marginTop: 15 }}>
                    <Grid item xl lg sm>
                        <TitledPaper title='Synthesis Sequence List'>
                            {renderSynthesisSequenceListComponent()}
                        </TitledPaper>
                    </Grid>
                </Grid>
            </TitledPaper >
        </React.Fragment >
    )
}

export default observer(RebalancingGeneListForm);