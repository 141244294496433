import { observable } from "mobx";
import Helper from "../../../module/Helper";
import Validatable from "./Validatable";

export interface IChipDistributionInfo {
    CDSDID?: string;
    CDPanelName?: string;
    CDNumberOfUniqueProbe?: number;
    CDNumberOfRepeat?: number;
    CDNumberOfFinalProbe?: number;
    CDFlankSequence?: string;
    CDMixRatio?: number;
    chipID?: string;
    PCRConcentration?: number;
    transmix?: number;
    remainPCRAmount?: number;
    sid?: string;
    _id?: string;
    workID?: number | string;
    designName?: string;
    synthesisChipID?: string;
}

export default class ChipDistributionInfo extends Validatable {
    @observable CDSDID?: string;
    @observable CDPanelName?: string;
    @observable CDNumberOfUniqueProbe?: number;
    @observable CDNumberOfRepeat?: number;
    @observable CDNumberOfFinalProbe?: number;
    @observable CDFlankSequence?: number;
    @observable CDMixRatio?: number;
    @observable chipID?: string;
    @observable PCRConcentration?: number;
    @observable transmix?: number;
    @observable remainPCRAmount?: number;
    @observable sid?: string;

    @observable _id?: string;
    @observable workID?: number | string;
    @observable designName?: string;
    @observable synthesisChipID?: string;

    constructor(workID?: number | string | null) {
        super();
        this.workID = workID;
    }

    setDatas = (params: IChipDistributionInfo) => {
        Object.assign(this, params);
    }

    //override    
    validateSelf(): void {
        if (!this.chipID) {
            this.setErrorTextByName('chipID', Validatable.errorTexts.needValue);
        }
        else if (!Helper.checkRegex(this.chipID, /^[0-9][0-9](0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])_BChip[0-9]{1,2}_(Cel|Twi|Gen)$/)) {
            this.setErrorTextByName('chipID', `${Validatable.errorTexts.badFormat} -> 포맷: YYMMDD_BChip#_[CompanyName] \n ex) 190320_BChip1_Cel`)
        }
        if (!this.CDNumberOfUniqueProbe) {
            this.setErrorTextByName('CDNumberOfUniqueProbe', Validatable.errorTexts.needValue);
        }
        else if (this.CDNumberOfUniqueProbe <= 0) {
            this.setErrorTextByName('CDNumberOfUniqueProbe', Validatable.errorTexts.positiveNumber)
        }
        if (!this.CDNumberOfRepeat) {
            this.setErrorTextByName('CDNumberOfRepeat', Validatable.errorTexts.needValue);
        }
        if (!this.CDNumberOfFinalProbe) {
            this.setErrorTextByName('CDNumberOfFinalProbe', Validatable.errorTexts.needValue);
        }
        if (!this.CDFlankSequence) {
            this.setErrorTextByName('CDFlankSequence', Validatable.errorTexts.needValue);
        }
        else if (this.CDFlankSequence < 0 || this.CDFlankSequence > 29) {
            this.setErrorTextByName('CDFlankSequence', '0에서 29까지 입력 가능합니다.');
        }
        if (!this.CDMixRatio) {
            this.setErrorTextByName('CDMixRatio', Validatable.errorTexts.needValue);
        }

        //Synth1
        if (!this.synthesisChipID) {
            this.setErrorTextByName('synthesisChipID', Validatable.errorTexts.needValue);
        }
        else if (!Helper.checkRegex(this.synthesisChipID, /^[0-9][0-9](0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])_SChip[0-9]{1,2}_(Cel|Twi|Gen)$/)) {
            this.setErrorTextByName('synthesisChipID', `${Validatable.errorTexts.badFormat} -> 포맷: YYMMDD_SChip#_[CompanyName] \n ex) 190320_SChip1_Cel`)
        }

        //Synth2
        if (!this.PCRConcentration) {
            this.setErrorTextByName('PCRConcentration', Validatable.errorTexts.needValue);
        }

    }
}