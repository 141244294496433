import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { observable } from 'mobx';
import ManufactureInfoForm from '../component/ManufactureInfoForm';
import ManufactureItemsForm from '../component/ManufactureItemsForm';
import ManufactureNoteForm from '../component/ManufactureNoteForm';
import { inject, observer } from 'mobx-react';
import TestPrintPage from '../component/TestPrintPage';
import ManufacturePrintComponent from '../component/ManufacturePrintComponent';
import { IRootStore } from '../../../../../data/store/RootStore';
import ManufactureStore, { ReworkTaskInfo } from '../../../../../data/store/pageStore/manufacture/ManufactureStore';
import ManufactureTrackData from '../../../../../data/model/manufacture/ManufactureTrackData';
import ManufactureItem from '../../../../../data/model/manufacture/ManufactureItem';
import ManufactureWork from '../../../../../data/model/manufacture/ManufactureWork';
import CommonErrors, { CommonError } from '../../../../../data/model/common/CommonErrors';
import TitledPaper from '../../../../common/component/TitledPaper';
import PTSMessage from '../../../../../data/model/common/PTSMessage';
import FlowControlButtonSet from '../../deisgn/workDetail/old/component/FlowControlButtonSet';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import WorkInfo from '../../../../../resource/data/works.json';
import ManufactureCancelReasonContainer from './ManufactureCancelReasonContainer';
import Helper from '../../../../../module/Helper';
import ETSField from '../../../../common/component/ExtendedMUISTextfield';
import PTSFileLink from '../../../../common/component/PTSFileLink';
import { Close } from '@material-ui/icons';
import { saveAs } from 'file-saver';
import TaskInfo from '../../../../../resource/data/tasks.json';
import ManufactureTBDItemTable from '../component/ManufactureTBDItemTable';
import ManufactureNoteListForm from '../component/ManufactureNoteListForm';
import dayjs from 'dayjs';
import ManufactureWorkListStore from '../../../../../data/store/pageStore/manufacture/ManufactureWorkListStore';
import ManufactureCustomerRequestInfoComponent from '../component/ManufactureCustomerRequestInfoComponent';

interface Props extends RouteComponentProps<any>, IRootStore {
}

@inject('rootStore')
@observer
class ManufactureDetailContainer extends React.Component<Props> {
    @observable store = new ManufactureStore(true);
    @observable listStore = new ManufactureWorkListStore();
    @observable trackDatas: ManufactureTrackData[] = [];
    @observable isLoaded = false;
    @observable requestPrintable: boolean = false;
    @observable shippingPrintable: boolean = false;
    @observable cancelList: any[] = [];

    @observable isProbeIDInputValid: boolean = false;
    @observable acceptButtonDisabled = true;

    globalStore = this.props.rootStore.globalStore;
    commentStore = this.props.rootStore.commentStore;

    requestRef = React.createRef();
    shippingRef = React.createRef();
    coaFileUploadRef: any = null;
    invoiceFileUploadRef: any = null;

    readonly readonlyHeaders: (keyof ManufactureItem)[] = [
        ManufactureItem.HeaderKeyList.itemName as keyof ManufactureItem,
        ManufactureItem.HeaderKeyList.numberPerQty as keyof ManufactureItem,
        ManufactureItem.HeaderKeyList.unit as keyof ManufactureItem,
        ManufactureItem.HeaderKeyList.qty as keyof ManufactureItem,
        ManufactureItem.HeaderKeyList.pooling as keyof ManufactureItem,
        ManufactureItem.HeaderKeyList.hybRxn as keyof ManufactureItem,
        ManufactureItem.HeaderKeyList.probeID as keyof ManufactureItem,
        ManufactureItem.HeaderKeyList.remark as keyof ManufactureItem,
        ManufactureItem.HeaderKeyList.panelName as keyof ManufactureItem,
        ManufactureItem.HeaderKeyList.catNO as keyof ManufactureItem,
        ManufactureItem.HeaderKeyList.lotNO as keyof ManufactureItem,
        ManufactureItem.HeaderKeyList.expDate as keyof ManufactureItem,
        ManufactureItem.HeaderKeyList.storageTemp as keyof ManufactureItem,
        ManufactureItem.HeaderKeyList.shippingCondition as keyof ManufactureItem,
        ManufactureItem.HeaderKeyList.boxNO as keyof ManufactureItem,
    ]

    readonly readonlyTBDHeaders: (keyof ManufactureItem)[] = [
        ManufactureItem.HeaderKeyList.itemName as keyof ManufactureItem,
        ManufactureItem.HeaderKeyList.numberPerQty as keyof ManufactureItem,
        ManufactureItem.HeaderKeyList.unit as keyof ManufactureItem,
        ManufactureItem.HeaderKeyList.qty as keyof ManufactureItem,
        ManufactureItem.HeaderKeyList.pooling as keyof ManufactureItem,
        ManufactureItem.HeaderKeyList.hybRxn as keyof ManufactureItem,
        ManufactureItem.HeaderKeyList.probeID as keyof ManufactureItem,
        ManufactureItem.HeaderKeyList.remark as keyof ManufactureItem,
    ]

    constructor(props: Props) {
        super(props);
        this.globalStore.currentTaskTitle = '제조 정보 상세';
    }

    isRequestPrintable = (): boolean => {
        const filtered = this.trackDatas.filter((v) => {
            return v.type === ManufactureWork.TypeList.manufactureRequest
                && v.stage === ManufactureWork.StageList.finish;
        });
        return filtered.length > 0;
    }

    isShippingPrintable = (): boolean => {
        // const isTBD = this.store.data.itemList.some(v => (v.itemName === 'TC #3' || v.itemName === 'TC#3')
        //     && v.probeID && Helper.checkRegex(v.probeID, /^TBD.*/));
        // const filtered = this.trackDatas.filter((v) => {
        //     return v.type === ManufactureWork.TypeList.manufactureDelivery;
        // });
        // return !isTBD && filtered.length > 0;
        return true;
    }

    scrollBottom = () => {
        const obj = document.getElementById('commentContentsList');
        obj.scrollTop = obj.scrollHeight;
    }

    init = async () => {
        try {
            this.globalStore.isRunning = true;
            this.isLoaded = false;
            const workID = this.props.match.params.workID;
            const result = await this.store.getManufactureByWorkID(workID, this.globalStore.token);
            const track = await this.store.getManufactureTrack(workID, this.globalStore.token);
            this.store.workStatus = result.data.data.workStatus;
            if (!result.data.data) {
                throw CommonErrors.NetworkError;
            }
            if (!track.data.data.current.workType) {
                throw CommonErrors.NetworkError;
            }
            this.store.workID = workID;
            this.globalStore.useComment = true;
            this.commentStore.stage = '제조 정보 상세';
            this.commentStore.mode = 'work';
            this.commentStore.workID = workID;
            this.store.data.fromJS(result.data.data);
            this.store.setItemList(result.data.data.itemList, true);
            this.trackDatas = track.data.data.trackList;
            this.cancelList = this.trackDatas.filter((v) => {
                return v.type === ManufactureWork.TypeList.manufactureCancelShipping
            });
            const trackDatasReverse = [...this.trackDatas].reverse();
            this.store.reworkTaskList = trackDatasReverse.map((v: any) => {
                return new ReworkTaskInfo({
                    type: v.type,
                    name: (TaskInfo.translate as any)[v.type] || 'Unknown',
                })
            })
            this.requestPrintable = this.isRequestPrintable();
            this.shippingPrintable = this.isShippingPrintable();
            await this.commentStore.setCommentsMany();
            this.scrollBottom();

            const nowTask = this.trackDatas[0] ?? null;
            const nowDepart = nowTask === null ? '' : nowTask.departType;
            const user = this.globalStore.userInfo;

            if (nowTask.stage === TaskInfo.stage.waitAccept) {
                if (user.departType === nowDepart) {
                    this.acceptButtonDisabled = false;
                }
                else {
                    if (Helper.isAdmin(user.role)) {
                        this.acceptButtonDisabled = false;
                    }
                }
            }
            else {
                this.acceptButtonDisabled = true;
            }

            this.isLoaded = true;
            this.store.setManufactureNumber(result.data.data.manufactureOrderNumberList);
            this.store.setManufactureNoteList(result.data.data.manufactureNoteList);

        } catch (err) {
            if (err instanceof CommonError || err instanceof PTSMessage) {
                alert(err.message);
            }
            // else if (err.errorObj) {
            //     alert(err.message);
            // }
            else {
                alert('오류 발생');
            }
        } finally {
            this.globalStore.isRunning = false;
        }
    }

    componentDidMount = async () => {
        await this.init();
    }

    printManufactureRequest = () => {
    }

    printShipping = () => {
    }

    getManufactureRequestPage = () => {
        return (
            <TestPrintPage data={this.store.data} />
        )
    }

    onPauseClick = async () => {
        try {
            if (!window.confirm('작업을 중단 상태로 변경 합니다.')) {
                return;
            }
            await this.store.changeStatus(this.store.workID, WorkInfo.status.paused, this.globalStore.token);
            await this.init();
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
        }
    }

    onFinishClick = async () => {
        try {
            if (!window.confirm('작업을 완료 상태로 변경 합니다.')) {
                return;
            }
            await this.store.changeStatus(this.store.workID, WorkInfo.status.finished, this.globalStore.token);
            await this.init();
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
        }
    }

    onRunClick = async () => {
        try {
            if (!window.confirm('작업의 중단 상태를 해제 합니다.')) {
                return;
            }
            await this.store.changeStatus(this.store.workID, WorkInfo.status.running, this.globalStore.token);
            await this.init();
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
        }
    }

    onReworkClick = () => {
        try {
            this.store.openReworkDiag();
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
        }
    }

    onDialogOkClick = async () => {
        try {
            if (this.store.selectedTaskIndex < 1) {
                throw new PTSMessage({
                    message: '작업을 선택해주세요.',
                });
            }
            if (!window.confirm('선택한 작업부터 시작하는 새로운 Work를 생성합니다.')) {
                return;
            }
            if (this.globalStore.userInfo.role.toLocaleLowerCase() !== 'admin'
                && this.globalStore.userInfo.role.toLocaleLowerCase() !== 'manager') {
                throw new PTSMessage({
                    message: '권한이 없습니다.',
                });
            }
            const result = await this.store.newReWork(this.store.workID, this.globalStore.token, this.store.selectedTaskIndex);
            alert(`등록 성공. Work ID: ${result.data.data.workID}`);
        } catch (err) {
            // if (err instanceof PTSMessage && err.type === 'apiErr') {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
        }
        finally {
            this.onDialogClose();
        }
    }

    onDialogCancelClick = () => {
        this.onDialogClose();
    }

    onDialogClose = () => {
        this.store.reworkDialogOpen = false;
    }

    onDialogSelect = (e: any) => {
        this.store.selectedTaskIndex = e.target.value;
    }

    onCoaFileUploadClick = () => {
        try {
            if (!window.confirm('새로운 파일 업로드시 기존에 업로드 되었던 파일은 새로운 파일로 대체됩니다.')) {
                return;
            }
            this.coaFileUploadRef.click();
        } catch (err) {

        }
    }

    onCoaFileChange = async (e: any) => {
        try {
            if (!e.target.value) {
                return;
            }
            const file = e.target.files[0];
            const formData = new FormData();
            formData.append('files', file);
            const result = await this.store.uploadCoaFile(formData, this.globalStore.token);
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage
                });
            }
            alert('업로드 완료.');
            window.location.reload();
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
        }
    }

    onInvoiceFileUploadClick = () => {
        try {
            if (!window.confirm('새로운 파일 업로드시 기존에 업로드 되었던 파일은 새로운 파일로 대체됩니다.')) {
                return;
            }
            this.invoiceFileUploadRef.click();
        } catch (err) {

        }
    }

    onInvoiceFileChange = async (e: any) => {
        try {
            if (!e.target.value) {
                return;
            }
            const file = e.target.files[0];
            const formData = new FormData();
            formData.append('files', file);
            const result = await this.store.uploadInvoiceFile(formData, this.globalStore.token);
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage
                });
            }
            alert('업로드 완료.');
            window.location.reload();
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
        }
    }

    onProbeIDInputDialogOpen = () => {
        this.store.isProbeIDInputDialogOpen = true;
    }

    onProbeIDInputDialogClose = () => {
        this.store.isProbeIDInputDialogOpen = false;
        this.store.data.resetErrorsRecursive();
        this.isProbeIDInputValid = false;
    }

    onProbeIDInputDialogValidation = () => {
        this.store.data.resetErrorsRecursive();
        this.store.data.validateRecursive();
        this.store.data.itemList.map((v) => {
            return v.initErrorByKey('probeID');
        })
        this.isProbeIDInputValid = !this.store.data.hasErrorRecursive();
        if (!this.isProbeIDInputValid) {
            alert('유효하지 않은 값이 있습니다.');
        }

    }

    onProbeIDInputDialogOkClick = async (e: any) => {
        if (!window.confirm('ProbeID를 입력하시겠습니까?')) {
            return;
        }
        try {
            if (this.globalStore.userInfo.role.toLocaleLowerCase() !== 'admin'
                && this.globalStore.userInfo.departType.toLocaleLowerCase() !== 'sales') {
                throw new PTSMessage({
                    message: '권한이 없습니다.',
                });
            }
            this.onProbeIDInputDialogValidation();
            if (!this.isProbeIDInputValid) {
                return;
            }

            const workID = this.props.match.params.workID;

            const dataCopy = JSON.parse(JSON.stringify(this.store.data));
            dataCopy.itemList.map((v: any) => {
                if (v.probeIDInputData) {
                    v.probeID = v.probeIDInputData;
                }
                return v;
            })

            //api
            const result = await this.store.changeManufactureProbeID(dataCopy, workID, this.globalStore.token)

            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage
                });
            }
            alert('ProbeID 입력 완료.');
            window.location.reload();

        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
        }
    }

    downloadLabelXlsx = () => {
        const file = Helper.generateLabelXlsxFile(this.store.getItemList());
        saveAs(file, `box label ${this.store.workID}.xlsx`);
    }

    onDataDump = () => {
        const dump = this.store.data.dump();
        const blob = new Blob([dump], { type: 'application/json' });
        saveAs(blob, `manufacture_work_dump_${this.store.workID}.json`);
    }

    acceptWork = async () => {
        try {
            if (window.confirm('접수하시겠습니까?')) {
                this.globalStore.isRunning = true;
                const arr: string[] = [];
                arr.push(this.store.data.workID);
                const result = await this.listStore.requestAcceptWorks(arr, this.globalStore.token);
                if (result.data.type === 'apiErr') {
                    throw new PTSMessage({
                        message: result.data.resMessage,
                    })
                }
                alert('접수 완료');
                window.location.reload();
            }
        } catch (err) {
            if (err instanceof CommonError || err instanceof PTSMessage) {
                alert(err.message);
            }
            else {
                alert('오류 발생');
            }
        } finally {
            this.globalStore.isRunning = false;
        }
    }

    render() {
        return (
            <React.Fragment>
                <Dialog open={this.store.reworkDialogOpen}>
                    <DialogTitle>
                        재작업 위치 선택
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            재작업을 시작할 작업위치를 선택하세요.
                        </DialogContentText>
                        <FormControl fullWidth>
                            <InputLabel id='select'>
                                작업
                            </InputLabel>
                            <Select
                                onChange={this.onDialogSelect}
                                fullWidth
                                value={this.store.selectedTaskIndex}
                                id='select'>
                                <MenuItem value={-1}>
                                    선택
                                </MenuItem>
                                {this.store.reworkTaskList.length > 1 && this.store.reworkTaskList.map((v, i) => {
                                    // if (i + 1 === this.store.reworkTaskList.length) {
                                    if (i < 1 || i > 4) {
                                        return null;
                                    }
                                    return (
                                        <MenuItem value={i} key={`key${i}`}>
                                            {/* {i + 1}. */}
                                            {i}.
                                            {v.name}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onDialogCancelClick} color='secondary'>
                            취소
                        </Button>
                        <Button onClick={this.onDialogOkClick} color='primary'>
                            확인
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.store.isProbeIDInputDialogOpen}
                    fullWidth
                    maxWidth={'lg'}
                >
                    <DialogTitle>
                        ProbeID 입력 목록
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            - 'ProbeID 입력' 입력란에 확정된 ProbeID를 입력하세요.<br />
                            - 포맷 : 'YYMMPD###-V#' 또는 'YYMMPD###-V##' 또는 'TBD' 또는 'TBD + 문자열(한글,영문,숫자,특수문자)'
                        </DialogContentText>
                        <ManufactureTBDItemTable
                            data={this.store.data}
                            readonlyTBDHeaders={this.readonlyTBDHeaders}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onProbeIDInputDialogClose} color='secondary'>
                            취소
                        </Button>
                        <Button onClick={this.onProbeIDInputDialogValidation} color='secondary'>
                            데이터 검증
                        </Button>
                        <Button disabled={!this.isProbeIDInputValid} onClick={this.onProbeIDInputDialogOkClick} color='primary'>
                            확인
                        </Button>
                    </DialogActions>
                </Dialog>
                <Grid container>
                    <Grid item xl lg sm>
                        <ManufacturePrintComponent
                            data={this.store.data}
                            isLoaded={this.isLoaded}
                            requestPrintable={this.requestPrintable}
                            shippingPrintable={this.shippingPrintable} />
                    </Grid>
                    <Grid item xl lg sm>
                        <FlowControlButtonSet
                            useRework
                            onRunClick={this.onRunClick}
                            onPauseClick={this.onPauseClick}
                            onFinishClick={this.onFinishClick}
                            onReworkClick={this.onReworkClick}
                            status={this.store.workStatus}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xl lg sm>
                        {
                            this.store.data.coa &&
                            <Button variant='contained' color='primary' onClick={this.onCoaFileUploadClick} style={{ marginRight: 10 }}>
                                CoA file upload
                            </Button>
                        }
                        <Button variant='contained' color='primary' onClick={this.onInvoiceFileUploadClick} style={{ marginRight: 10 }}>
                            Invoice file upload
                        </Button>
                        {
                            (this.globalStore.userInfo.role.toLocaleLowerCase() === 'admin'
                                || this.globalStore.userInfo.departType.toLocaleLowerCase() === 'sales') &&
                            <Button
                                disabled={!(this.store.data.itemList.some(v => (v.itemName === 'TC #3' || v.itemName === 'TC#3')
                                    && v.probeID && Helper.checkRegex(v.probeID, /^TBD.*/)))}
                                variant='contained'
                                color='primary'
                                onClick={this.onProbeIDInputDialogOpen} >
                                ProbeID 입력
                            </Button>
                        }
                    </Grid>
                </Grid>
                <Grid container style={{ marginTop: 20 }}>
                    <Grid item xl lg sm>
                        <Button variant='contained' color='primary' onClick={this.downloadLabelXlsx} style={{ marginRight: 10 }}>
                            Label xlsx file download
                        </Button>
                        <Button variant='contained' color='primary' onClick={this.onDataDump}>
                            Data dump (json)
                        </Button>
                    </Grid>
                    <Grid item xl lg sm>
                    </Grid>
                </Grid>
                <Grid container direction='column' style={{ marginBottom: 15, marginTop: 20, paddingLeft: 5 }}>
                    <Grid container>
                        <Grid container alignItems='flex-end' direction='column'>
                            <Grid item style={{ margin: 3 }}>
                                <Button
                                    onClick={this.acceptWork}
                                    disabled={this.acceptButtonDisabled}
                                    variant='contained'
                                    color='primary'>접수</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xl lg md>
                            <span>
                                {`시작일시: ${Helper.getFormattedDate(this.store.data.startDate)}`}
                            </span>
                        </Grid>
                        <Grid item xl lg md>
                            <span>
                                {`종료일시: ${Helper.getFormattedDate(this.store.data.endDate)}`}
                            </span>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    this.store.data.prevWorkID
                    &&
                    <Typography variant='h6' style={{ background: '#3f51b5', color: 'white', padding: 5 }}>
                        이전 작업(복사된 작업) ID:&nbsp;
                        <Link to={`/main/manufacture/detail/${this.store.data.prevWorkID}`} target='_blank' style={{ color: 'white' }}>
                            {this.store.data.prevWorkID}
                        </Link>
                    </Typography>
                }
                <TitledPaper title='제조의뢰 정보'>
                    <ManufactureInfoForm data={this.store.data} readOnly />
                    <ManufactureCustomerRequestInfoComponent
                        data={this.store.data}
                        readOnly
                    />
                    <Grid container direction='column'>
                        <Grid container>
                            <Grid item xl lg sm md>
                                <ETSField
                                    fullWidth
                                    margin='normal'
                                    rootdata={this.store.data}
                                    name='coa'
                                    value={
                                        this.store.data.coa ? 'YES' : 'NO'
                                    }
                                />
                            </Grid>
                            <Grid item xl lg sm md>
                                <ETSField
                                    fullWidth
                                    margin='normal'
                                    rootdata={this.store.data}
                                    name='productEducation'
                                    value={
                                        this.store.data.productEducation ? 'YES' : 'NO'
                                    }
                                    readOnly
                                />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xl lg sm md>
                                <ETSField
                                    fullWidth
                                    margin='normal'
                                    rootdata={this.store.data}
                                    name='deliveryDate'
                                    value={
                                        this.store.data.deliveryDate ? dayjs(this.store.data.deliveryDate).format('YYYY/MM/DD') : ''
                                    }
                                    readOnly
                                />
                            </Grid>
                        </Grid>
                        <Grid container item style={{ marginTop: 20 }} xl={3} lg={3} sm={3} md={3}>
                            <Grid item xl lg sm>
                                CoA File
                            </Grid>
                            <Grid item xl lg sm>
                                {
                                    this.store.data.coaFile.file_id ?
                                        <PTSFileLink
                                            fileName={this.store.data.coaFile.name}
                                            fileID={this.store.data.coaFile.file_id}
                                            mode='icon'
                                        />
                                        :
                                        <Close />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction='column'>
                        <Grid container style={{ marginTop: 20 }} xl={3} lg={3} sm={3} md={3}>
                            <Grid item xl lg sm>
                                Invoice File
                            </Grid>
                            <Grid item xl lg sm>
                                {
                                    this.store.data.invoiceFile.file_id ?
                                        <PTSFileLink
                                            fileName={this.store.data.invoiceFile.name}
                                            fileID={this.store.data.invoiceFile.file_id}
                                            mode='icon'
                                        />
                                        :
                                        <Close />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </TitledPaper>
                <TitledPaper title='제조 아이템 목록'>
                    <ManufactureItemsForm
                        data={this.store.data}
                        readonlyHeaders={this.readonlyHeaders}
                        inputHeaders={[]} />
                </TitledPaper>
                <TitledPaper title='영업팀 NOTE'>
                    <ManufactureNoteForm data={this.store.data} depart={"sales"} readOnly />
                </TitledPaper>
                <TitledPaper title='제조파트 NOTE'>
                    <ManufactureNoteListForm data={this.store.data} type={this.store.data.type} readOnly />
                </TitledPaper>
                {
                    this.cancelList.length > 0
                    &&
                    <ManufactureCancelReasonContainer datas={this.cancelList} />
                }
                <input
                    type='file'
                    style={{ display: 'none' }}
                    onChange={(e) => { this.onCoaFileChange(e) }}
                    ref={(ref) => { this.coaFileUploadRef = ref }}
                    id='coaFileupload' />
                <input
                    type='file'
                    style={{ display: 'none' }}
                    onChange={(e) => { this.onInvoiceFileChange(e) }}
                    ref={(ref) => { this.invoiceFileUploadRef = ref }}
                    id='invoiceFileupload' />
            </React.Fragment>
        )
    }
}

export default withRouter(ManufactureDetailContainer);