import React from 'react';
import TaskFormProps from '../TaskFormProps';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Grid, TextField } from '@material-ui/core';
import ProcessStore from '../../../../../../../../data/store/pageStore/design/ProcessStore';
import QCSequencing from '../../../../../../../../data/model/design/taskDatas/qc/QCSequencing';
import TitledPaper from '../../../../../../../common/component/TitledPaper';
import FormUtil from '../../../../../../../../module/FormUtil';
import FormButtonSet from '../../../../../../../common/component/FormButtonSet';
import PTSMessage from '../../../../../../../../data/model/common/PTSMessage';
import Validatable from '../../../../../../../../data/model/common/Validatable';
import Helper from '../../../../../../../../module/Helper';

interface Props extends TaskFormProps {
}

@inject('rootStore')
@observer
class QCSequencingForm extends React.Component<Props> {
    globalStore = this.props.rootStore.globalStore;
    store = observable(new ProcessStore<QCSequencing>());

    constructor(props: Props) {
        super(props);
        this.store.taskData.detail = new QCSequencing();
        this.props.rootStore.commentStore.stage = 'QC 시퀀싱';
    }

    componentDidMount = async () => {
        try {
            this.globalStore.isRunning = true;
            const workInfo = this.props.workInfo;
            this.store.workInfo = workInfo;
            this.store.taskData._id = (workInfo.taskList[0] as any)._id;
            this.store.taskData.workID = (workInfo.taskList[0] as any).workID;
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
        } finally {
            this.globalStore.isRunning = false;
        }
    }

    onFormSubmit = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        try {
            if (!window.confirm('제출하시겠습니까?')) {
                return;
            }
            this.globalStore.isRunning = true;

            this.props.postTaskData(this.store.taskData);
        } catch (err) {

        } finally {
            this.globalStore.isRunning = false;
        }
    }

    onValidate = () => {
        this.store.taskData.detail.initErrorsRecursive();
        this.store.taskData.detail.resetErrorsRecursive();
        this.store.taskData.detail.validateRecursive();
        this.store.isValid = !this.store.taskData.detail.hasErrorRecursive();
        if (!this.store.isValid) {
            alert(Validatable.errorTexts.notValid);
        }
    }

    onFormClear = () => {
        window.location.reload();
    }

    render() {
        return (
            <React.Fragment>
                <Grid item container>
                    <Grid item xl lg sm>
                        <TitledPaper title='실험데이터'>
                            <TextField
                                fullWidth
                                margin='normal'
                                value={this.store.taskData.detail.runID || ''}
                                name='runID'
                                onChange={this.store.taskData.detail.onChangeDefault}
                                label={FormUtil.getFieldLabel('runID')}
                                helperText={
                                    Helper.getErrorText(
                                        this.store.taskData.detail.errors.runID
                                    )
                                }
                                error={
                                    Helper.getErrorState(
                                        this.store.taskData.detail.errors.runID
                                    )
                                } />
                        </TitledPaper>
                    </Grid>
                </Grid>
                <Grid item xl lg sm>
                    <FormButtonSet
                        validated={this.store.isValid}
                        onFormClear={(e) => this.onFormClear()}
                        onSubmit={(e) => this.onFormSubmit(e)}
                        onValidate={(e) => this.onValidate()} />
                </Grid>
            </React.Fragment>
        )
    }
}

export default QCSequencingForm;