import React from 'react';

import Typography from '@material-ui/core/Typography'

interface Props {

}

const CopyRight: React.SFC<Props> = () => {
    return (
        <div>
            <Typography variant="body2" color="textSecondary" align="center">
                {'Copyright © '}
                {new Date().getFullYear()}
                {'. Celemics Inc. All right reserved.'}
            </Typography>
        </div>
    )
}

export default CopyRight;