import { observer } from 'mobx-react';
import React from 'react';
import ModelBase from '../../../data/model/common/ModelBase';
import PTSFile from '../../../data/model/common/PTSFile';
import HelperText from './HelperText';

interface Props {
    name: string;
    error?: boolean;
    helperText?: string;
    multiple?: boolean;
    data: ModelBase;
    index?: number;
    onFileChange?: (name: string, files: FileList, index?: number) => PTSFile;
}

const FileWithHelperText: React.FC<Props> = (props: Props) => {

    const error = props.error || false;
    const helperText = props.helperText || '';
    const onFileChange = props.onFileChange || (() => { return null; });
    const name = props.name || '';
    const data = props.data;
    const multiple = props.multiple || false;

    return (
        <React.Fragment>
            <input
                id={name}
                name={name}
                multiple={multiple}
                onChange={
                    (e) => {
                        let obj = onFileChange(e.target.name, e.target.files, props.index);
                        data.onChangeFileDefault(e.target.name, obj);
                    }
                }
                type='file'
            />
            <br />
            <HelperText text={error && helperText} />
        </React.Fragment>
    )
}

export default observer(FileWithHelperText);