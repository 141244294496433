import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    RouteComponentProps,
    withRouter,
    Route
} from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { CssBaseline, Grid, Typography } from '@material-ui/core';
import { IRootStore } from '../../../../data/store/RootStore';
import CommentContainer from '../../../common/comment/CommentContainer';
import PrivateRoute from '../../../common/component/PrivateRoute';
import ManufactureRequestPage from './container/ManufactureRequestPage';
import ManufactureSelectContainer from './container/ManufactureSelectContainer';
import ManufactureListContainer from './container/ManufactureListContainer';
import ManufactureDetailContainer from './container/ManufactureDetailContainer';
import ManufactureShippingChangeRequestContainer from './container/ManufactureShippingChangeRequestContainer';
import TaskNotFound from '../../../common/container/TaskNotFound';
import ManufactureWorkListStore from '../../../../data/store/pageStore/manufacture/ManufactureWorkListStore';

interface Props extends RouteComponentProps<any>, IRootStore {
}

const ManufactureMainPage: React.FC<Props> = inject('rootStore')(observer((props) => {
    const globalStore = props.rootStore.globalStore;

    return (
        <React.Fragment>
            <CssBaseline />
            <Grid container justify='center'>
                <Grid xl lg md sm item style={{
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-line',
                    padding: 5,
                }}>
                    {globalStore.currentTaskTitle &&
                        <div>
                            <Typography variant='h4'>
                                {globalStore.currentTaskTitle}
                            </Typography>
                            <hr />
                        </div>
                    }
                    <Router>
                        <Switch>
                            <PrivateRoute
                                exact path={`${props.match.url}/request`}
                                component={ManufactureRequestPage} />
                            <PrivateRoute
                                exact
                                path={`${props.match.url}/process/:workID`}
                                component={ManufactureSelectContainer} />
                            <PrivateRoute
                                exact
                                path={`${props.match.url}/list/${ManufactureWorkListStore.ModeList.accept}`}
                                component={() =>
                                    <ManufactureListContainer stage={ManufactureWorkListStore.ModeList.accept} />}
                            />
                            <PrivateRoute
                                exact
                                path={`${props.match.url}/list/${ManufactureWorkListStore.ModeList.process}`}
                                component={() =>
                                    <ManufactureListContainer stage={ManufactureWorkListStore.ModeList.process} />}
                            />
                            <PrivateRoute
                                exact
                                path={`${props.match.url}/list/${ManufactureWorkListStore.ModeList.search}`}
                                component={() =>
                                    <ManufactureListContainer stage={ManufactureWorkListStore.ModeList.search} />}
                            />
                            {/* <PrivateRoute
                                exact
                                path={`${props.match.url}/list/${ManufactureWorkListStore.ModeList.shipped}`}
                                component={() =>
                                    <ManufactureListContainer stage={ManufactureWorkListStore.ModeList.shipped} />}
                            /> */}
                            {/* <PrivateRoute
                                exact
                                path={`${props.match.url}/list/${ManufactureWorkListStore.ModeList.cancelShipping}`}
                                component={() =>
                                    <ManufactureListContainer stage={ManufactureWorkListStore.ModeList.cancelShipping} />}
                            /> */}
                            {/* <PrivateRoute
                                exact
                                path={`${props.match.url}/list/${ManufactureWorkListStore.ModeList.changeShipping}`}
                                component={() =>
                                    <ManufactureListContainer stage={ManufactureWorkListStore.ModeList.changeShipping} />}
                            /> */}
                            <PrivateRoute
                                exact
                                path={`${props.match.url}/detail/:workID`}
                                component={ManufactureDetailContainer} />
                            {/* <PrivateRoute
                                exact
                                path={`${props.match.url}/cancelShippingRequest/:workID`}
                                component={ManufactureShippingCancelRequestContainer} /> */}
                            <PrivateRoute
                                exact
                                path={`${props.match.url}/changeShippingRequest/:workID`}
                                component={ManufactureShippingChangeRequestContainer} />
                            <Route
                                exact
                                component={TaskNotFound} />
                        </Switch>
                    </Router>
                </Grid>
                {globalStore.useComment &&
                    <Grid xl={3} lg={4} md={4} item>
                        <CommentContainer />
                    </Grid>
                }
            </Grid>
        </React.Fragment>
    )
}))

export default withRouter(ManufactureMainPage);