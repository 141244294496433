import { observable } from "mobx";
import ModelBase from "../common/ModelBase";

export default class Depart extends ModelBase {
    @observable name?: string;
    @observable division?: string;
    @observable code?: number;
    @observable type?: string;

    constructor() {
        super();
        this.name = '';
        this.division = '';
        this.code = 0;
        this.type = '';
    }
}