import React from 'react';
import {
    createStyles,
    Theme,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Drawer as SimpleDrawer, ListItemIcon, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import {
    Assignment,
    Timer,
    Keyboard,
    FindInPageOutlined,
    PersonAdd,
    Create,
    // HighlightOff,
    Close,
    Autorenew,
    Assessment
} from '@material-ui/icons';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import MenuItem from './PTSMenuItem';
import GlobalStore from '../../../data/store/common/GlobalStore';
import LayoutStore from '../../../data/store/common/LayoutStore';
import RootStore from '../../../data/store/RootStore';
// import ManufactureWork from '../../../data/model/manufacture/ManufactureWork';
import Helper from '../../../module/Helper';

const styles = ((theme: Theme) => {
    return createStyles({
        list: {
            width: 320,
        },
        toolbar: theme.mixins.toolbar
    })
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any> {
    rootStore?: RootStore;
    layoutStore?: LayoutStore;
}

@inject('rootStore')
@observer
class MenuDrawer extends React.Component<Props> {
    globalStore: GlobalStore;
    layoutStore: LayoutStore;
    classes = this.props.classes;

    filterMenu = (menus: JSX.Element[]) => {
        const arr: JSX.Element[] = [];
        if (menus.length < 1) {
            return arr;
        }
        const departType = this.globalStore.userInfo.departType;
        const role = this.globalStore.userInfo.role;
        let prev = menus[0].props.type;
        for (const e of menus) {
            if (e.props.type !== prev) {
                arr.push(<Divider />)
            }
            if (role.toLocaleLowerCase() === 'admin') {
                arr.push(e);
            }
            else if (e.props.departType === 'any') {
                arr.push(e);
            }
            else if (departType === e.props.departType) {
                arr.push(e);
            }
            prev = e.props.type;
        }
        return arr;
    }

    componentDidMount = () => {
        const menuList: JSX.Element[] = [];
        menuList.push(
            <MenuItem
                name='신규 Custom Panel 의뢰'
                link='/main/design/request'
                departType='sales'
                type='request'
                icon={<Assignment />}
                onClick={this.onMenuClick}
            />
        )
        menuList.push(
            <MenuItem
                name='Custom Panel 작업 접수'
                link='/main/design/task/wait'
                departType='any'
                type='task'
                icon={<Timer />}
                onClick={this.onMenuClick}
            />
        )
        menuList.push(
            <MenuItem
                name='Custom Panel 작업 기록'
                link='/main/design/task/process'
                departType='any'
                type='task'
                icon={<Keyboard />}
                onClick={this.onMenuClick}
            />
        )
        menuList.push(
            <MenuItem
                name='Custom Panel Work 조회'
                link='/main/design/work/list'
                departType='any'
                type='search'
                icon={<FindInPageOutlined />}
                onClick={this.onMenuClick} />
        )
        menuList.push(
            <MenuItem
                name='Rebalancing & 영역추가 요청'
                link='/main/design/rebalancing'
                departType='sales'
                type='task'
                icon={<Autorenew />}
                onClick={this.onMenuClick}
            />
        )
        menuList.push(
            <MenuItem
                name='SnOP Summary (디자인)'
                link='/main/view/snop/design'
                departType='any'
                type='search'
                icon={<Assessment />}
                onClick={this.onMenuClick} />
        )

        menuList.push(
            <>
                <Divider />
            </>
        )

        // manufacture
        menuList.push(
            <MenuItem
                name='신규 제조 의뢰'
                link='/main/manufacture/request'
                departType='sales'
                type='manufactureRequest'
                icon={<Create />}
                onClick={this.onMenuClick} />
        )
        menuList.push(
            <MenuItem
                name='제조 작업 접수'
                link='/main/manufacture/list/accept'
                departType='any'
                type='manufacture'
                icon={<Timer />}
                onClick={this.onMenuClick} />
        )
        menuList.push(
            <MenuItem
                name='제조 작업 기록'
                link='/main/manufacture/list/process'
                departType='any'
                type='manufacture'
                icon={<Keyboard />}
                onClick={this.onMenuClick} />
        )
        menuList.push(
            <MenuItem
                name='제조 Work 조회'
                link='/main/manufacture/list/search'
                departType='any'
                type='manufactureSearch'
                icon={<FindInPageOutlined />}
                onClick={this.onMenuClick} />
        )
        // menuList.push(
        //     <MenuItem
        //         name='제조 출하 취소 요청'
        //         link='/main/manufacture/list/shipped'
        //         departType='sales'
        //         type='manufactureCancelRequest'
        //         icon={<HighlightOff />}
        //         onClick={this.onMenuClick} />
        // )
        // menuList.push(
        //     <MenuItem
        //         name='제조 출하 취소 승인'
        //         link='/main/manufacture/list/cancelShipping'
        //         departType={ManufactureWork.DepartList.manufacturePart}
        //         type='manufactureCancel'
        //         icon={<HighlightOff />}
        //         onClick={this.onMenuClick} />
        // )

        // if (Helper.isAdmin(this.globalStore.userInfo.role)
        //     || Helper.isManager(this.globalStore.userInfo.role)
        //     || this.globalStore.userInfo.departType === 'sales'
        //     || this.globalStore.userInfo.departType === ManufactureWork.DepartList.manufacturePart) {
        //     if (this.globalStore.userInfo.departType !== ManufactureWork.DepartList.manufacturePart) {
        //         menuList.push(
        //             <MenuItem
        //                 //name='제조 출하일 변경 요청'
        //                 name='예상 출하일 변경 / 출하 요청일 변경 요청'
        //                 link='/main/manufacture/list/shipped'
        //                 departType='any'
        //                 type='manufactureChangeRequest'
        //                 icon={<HighlightOff />}
        //                 onClick={this.onMenuClick} />
        //         )
        //     }
        //     menuList.push(
        //         <MenuItem
        //             //name='제조 출하일 변경 승인'
        //             name='예상 출하일 변경 / 출하 요청일 변경 승인'
        //             link='/main/manufacture/list/changeShipping'
        //             departType='any'
        //             type='manufactureChange'
        //             icon={<HighlightOff />}
        //             onClick={this.onMenuClick} />
        //     )
        // }
        menuList.push(
            <MenuItem
                name='SnOP Summary (제조)'
                link='/main/view/snop/manufacture'
                departType='any'
                type='search'
                icon={<Assessment />}
                onClick={this.onMenuClick} />
        )
        menuList.push(
            <>
                <Divider />
            </>
        )
        if (Helper.isAdmin(this.globalStore.userInfo.role)
            || Helper.isManager(this.globalStore.userInfo.role)) {
            menuList.push(
                <MenuItem
                    name='유저등록'
                    link='/main/user/new'
                    departType='any'
                    type='Admin'
                    icon={<PersonAdd />}
                    onClick={this.onMenuClick} />
            )
        }
        this.globalStore.menuList = this.filterMenu(menuList);
    }

    constructor(props: Props) {
        super(props);
        this.globalStore = this.props.rootStore.globalStore;
        this.layoutStore = this.props.layoutStore;
    }

    onMenuClick = (e: React.MouseEvent<HTMLElement>) => {
        this.layoutStore.menuOpen = false;
        this.props.history.push(e.currentTarget.getAttribute('link'));
        window.location.reload();
    }

    closeMenu = (e: React.KeyboardEvent | React.MouseEvent) => {
        if (
            e.type === 'keydown' &&
            ((e as React.KeyboardEvent).key === 'Tab' ||
                (e as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        this.layoutStore.menuOpen = false;
    }

    getMenus = () => {
        return (
            <div
                className={this.classes.list}
                role="presentation"
                onKeyDown={() => this.layoutStore.menuOpen = !this.layoutStore.menuOpen}
            >
                <List>
                    <ListItem onClick={this.closeMenu}>
                        <ListItemIcon>
                            <Close />
                        </ListItemIcon>
                        <Typography >
                            <strong>
                                {`Celemics PTS V${this.globalStore.appVersion}`}
                            </strong>
                        </Typography>
                    </ListItem>
                    <Divider />
                    {this.globalStore.menuList.map((v, i) => (
                        <React.Fragment key={i}>
                            {v}
                        </React.Fragment>
                    ))}
                </List>
            </div>
        )
    }

    render() {
        return (
            <SimpleDrawer
                open={this.layoutStore.menuOpen}
                onClose={this.closeMenu}>
                {this.getMenus()}
            </SimpleDrawer>
        )
    }
}

export default withStyles(styles)(withRouter(MenuDrawer));
