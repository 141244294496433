import React from 'react';
import { Paper, createStyles, Theme, Typography, Divider } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

const styles = (theme: Theme) => createStyles({
    paper: {
        padding: 20,
        paddingBottom: 30,
        margin: 3,
        marginBottom: 6,
        background: '#FAFAFA'
    }
})

interface Props extends WithStyles<typeof styles> {
    title?: string;
    color?: string;
}

const TitledPaper: React.FC<Props> = (props) => (
    <Paper className={props.classes.paper} style={{background: props.color}}>
        <Typography variant='h6' style={{ marginBottom: 5 }}>
            {props.title}
        </Typography>
        <Divider />
        {props.children}
    </Paper>
)

export default withStyles(styles)(TitledPaper);