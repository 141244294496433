import GeneData from "./GeneData";
import { observable } from "mobx";
import TaskSubData from "../../DesignTaskSubData";
import Validatable from "../../../common/Validatable";
import PTSFile from "../../../common/PTSFile";
import SynthSequenceData from "./SynthSequenceData";

export default class RequestedGeneList extends TaskSubData {
    @observable geneListType?: string;
    @observable geneExpand?: number;
    @observable geneList: GeneData[];
    @observable fusionList: GeneData[];
    @observable hotspotList: GeneData[];
    @observable customList: GeneData[];
    @observable synthRequestSequence: SynthSequenceData[];
    @observable geneListFile: PTSFile;

    constructor() {
        super();
        this.geneList = [];
        this.fusionList = [];
        this.hotspotList = [];
        this.customList = [];
        this.synthRequestSequence = [];
        this.geneListType = 'None';
    }

    // override
    validateSelf(): void {
        if (this.geneList.length === 0) {
            if (this.geneListType !== 'None') {
                this.setErrorTextByName('geneListType', Validatable.errorTexts.notValid);
            }
            if (this.geneExpand) {
                this.setErrorTextByName('geneExpand', Validatable.errorTexts.notValid);
            }
        } else {
            if (this.geneListType === 'None') {
                this.setErrorTextByName('geneListType', Validatable.errorTexts.notValid);
            }
            if (!this.geneExpand) {
                this.setErrorTextByName('geneExpand', Validatable.errorTexts.needValue);
            }
            else if (this.geneExpand < 0) {
                this.setErrorTextByName('geneExpand', Validatable.errorTexts.positive);
            }
            else if (!Number.isInteger(Number(this.geneExpand))) {
                this.setErrorTextByName('geneExpand', Validatable.errorTexts.needInt);
            }
        }
        // if (!this.geneListType) {
        //     this.setErrorTextByName('geneListType', Validatable.errorTexts.needValue);
        // }
        // if (!this.geneExpand) {
        //     this.setErrorTextByName('geneExpand', Validatable.errorTexts.needValue);
        // }
        // else if (this.geneExpand < 0) {
        //     this.setErrorTextByName('geneExpand', Validatable.errorTexts.positive);
        // }
        // else if (!Number.isInteger(Number(this.geneExpand))) {
        //     this.setErrorTextByName('geneExpand', Validatable.errorTexts.needInt);
        // }
    }

    resetAllGeneLists(): void {
        this.geneList = [];
        this.fusionList = [];
        this.hotspotList = [];
        this.customList = [];
        this.synthRequestSequence = [];
    }

    resetGeneListFile(): void {
        this.geneListFile = null;
    }
}