import { inject, observer } from "mobx-react";
import React from "react";
import RootStore from "./data/store/RootStore";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import PrivateRoute from './view/common/component/PrivateRoute';

// pages
import LoginPage from './view/page/loginPage/LoginPage';

import RouteInfo from './resource/routes.json';
import MainPage from "./view/page/mainPage/MainPage";
import RootPage from "./view/page/RootPage";
import LoginUtil from "./module/LoginUtil";
import User from "./data/model/user/user";
import 'bootstrap/dist/css/bootstrap.min.css';

interface Props {
  rootStore?: RootStore;
}

const App: React.FC<Props> = inject('rootStore')(observer((props: Props) => {
  const globalStore = props.rootStore.globalStore;
  //console.log(`app component rendering`);
  const loggedIn = LoginUtil.isLoggedIn();
  if (loggedIn) {
    try {
      LoginUtil.apiTokenCheck();
      globalStore.token = LoginUtil.getToken();
      globalStore.userInfo = new User(LoginUtil.getUserInfo() as User);
      globalStore.isLoggedIn = loggedIn;
    } catch (err) {
      LoginUtil.logout(globalStore);
    }
  }

  return (
    <div className="App">
      <header className="App-header">
      </header>
      <React.Fragment>
        <Router>
          <Switch>
            <Route exact path={`${RouteInfo.routes.login}`} component={LoginPage} />
            <PrivateRoute path={`/${RouteInfo.words.main}`} component={MainPage} />
            <PrivateRoute excat path={RouteInfo.routes.root} component={RootPage} />
          </Switch>
        </Router>
      </React.Fragment>
    </div>
  );
}))

// @inject('rootStore')
// @observer
// class App extends React.Component<Props> {
//   render() {
//     return (
//       <div className="App">
//         <header className="App-header">
//         </header>
//         <React.Fragment>
//           <Router>
//             <Switch>
//               <Route path='/test' component={TestPage} />
//               <Route exact path={`${RouteInfo.routes.login}`} component={LoginPage} />
//             </Switch>
//           </Router>
//         </React.Fragment>
//       </div>
//     );
//   }
// }

export default App;
