import { action, observable } from "mobx";
import ManufactureWork from "../../../model/manufacture/ManufactureWork";
import StoreBase from "../../StoreBase";
import axios from 'axios';
import { words as RouteWords } from '../../../../resource/data/APIRoutes.json';
import User from "../../../model/user/user";
import RootStore from "../../RootStore";
import ManufactureItem from "../../../model/manufacture/ManufactureItem";
import ManufacturePanel from "../../../model/manufacture/ManufacturePanel";
import PTSMessage from "../../../model/common/PTSMessage";
import { CommonError } from "../../../model/common/CommonErrors";
import ManufactureStore from "./ManufactureStore";

export default class ManufactureRequestStore extends StoreBase {
    @observable data: ManufactureWork;
    @observable isValid: boolean;
    @observable test: boolean;
    @observable isReworkIndex: boolean;
    @observable workID: string;

    @observable panelList: ManufacturePanel[];

    @observable searchWorkDialogOpen: boolean;
    @observable foundWorks: ManufactureWork[];

    constructor(rootStore?: RootStore) {
        super(rootStore);
        this.data = new ManufactureWork();
        this.isValid = false;
        this.test = true;
        this.isReworkIndex = false;
        this.workID = '';
        this.panelList = [];
        this.searchWorkDialogOpen = false;
        this.foundWorks = [];
    }

    requestNewManufacture = async (data: object, token: string) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        return axios.post(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.manufacture}/${RouteWords.new}`,
            data,
            config,
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        });
    }

    // override
    onSelectUser = (user: User) => {
        this.data.representativeDepart = user.departName;
        this.data.representativeName = user.name;
        this.data.salesRepresentativeObj = user;
        const list: string[] = [];
        list.push(user.email);
        this.data.mailingList = list;
        this.onFindUserDiagClose();
    }

    static readonly inputHeaders = [
        ManufactureItem.HeaderKeyList.itemName as keyof ManufactureItem,
        ManufactureItem.HeaderKeyList.numberPerQty as keyof ManufactureItem,
        ManufactureItem.HeaderKeyList.unit as keyof ManufactureItem,
        ManufactureItem.HeaderKeyList.qty as keyof ManufactureItem,
        ManufactureItem.HeaderKeyList.pooling as keyof ManufactureItem,
        ManufactureItem.HeaderKeyList.hybRxn as keyof ManufactureItem,
        ManufactureItem.HeaderKeyList.probeID as keyof ManufactureItem,
        ManufactureItem.HeaderKeyList.remark as keyof ManufactureItem,
    ]

    @action
    setItemList = (list: any[]) => {
        try {
            const setList: ManufactureItem[] = [];
            for (const data of list) {
                const d = new ManufactureItem();
                d.fromJS(data);
                d.validateList = ManufactureRequestStore.inputHeaders;
                setList.push(d);
            }
            this.data.itemList = setList;
        } catch (err) {
        }
    }

    processManufacture = async (token: string) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        return axios.put(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.manufacture}/${this.workID}`,
            this.data,
            config,
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        });
    }

    getPanelList = async (token: string) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        return axios.get(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.panel}`,
            config,
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        });
    }

    onWorkSelect = async (workID: string) => {
        return this.loadExsistWork(workID);
    }

    onSearchWorks = async (searchText: string) => {
        const query = [
            {
                $match: {
                    "searchField.erpCode": searchText,
                },
            },
            {
                $lookup: {
                    from: 'manufactureworks',
                    localField: 'workID',
                    foreignField: 'workID',
                    as: 'works'
                }
            },
            {
                $unwind: '$works'
            },
            {
                $project: {
                    'works.workID': 1,
                    'works.erpCode': 1,
                    'works.customerAndPI': 1,
                    'works.salesRepresentativeObj.name': 1,
                    'works.type': 1,
                    'works.stage': 1,
                    'works.orderNumber': 1,
                }
            }
        ]
        const result = await this.getManufactureListData(query, this.rootStore.globalStore.token);
        const works = result.data.data.list;
        if (Array.isArray(works)) {
            works.forEach(v => this.foundWorks.push(v.works));
        }
    }

    getManufactureListData = async (filter: object, token: string) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        return axios.post(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.manufacture}/${RouteWords.lists}`,
            { findFilter: filter },
            config,
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        });
    }

    openSearchWorkDialog = () => {
        this.searchWorkDialogOpen = true;
    }

    closeSearchWorkDialog = () => {
        this.foundWorks = [];
        this.searchWorkDialogOpen = false;
    }

    /**
     *
     * @param item
     * @returns 불러오기시 초기화 되어야할 데이터가 초기화된 item
     */
    filterExsistItemData = (item: ManufactureItem) => {
        item.lotNO = '';
        item.catNO = '';
        item.storageTemp = '';
        item.shippingCondition = '';
        item.boxNO = '';
        item.expDate = null;
        return item;
    }

    loadExsistWork = async (workID: string) => {
        try {
            this.rootStore.globalStore.isRunning = true;
            const workStore = new ManufactureStore();
            const work = await workStore.getManufactureByWorkID(workID, this.rootStore.globalStore.token);
            if (work.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: work.data.resMessage,
                })
            }
            // this.data.fromJS(work.data.data);
            const incomeData = work.data.data;
            this.data.projectCode = incomeData.projectCode;
            this.data.productDivision = incomeData.productDivision;
            this.data.customerAndPI = incomeData.customerAndPI;
            this.data.manufacturePurpose = incomeData.manufacturePurpose;
            this.data.coa = incomeData.coa;
            this.data.productEducation = incomeData.productEducation;
            this.data.manufactureVersion = incomeData.manufactureVersion;
            this.data.additionalReview = incomeData.additionalReview;
            this.data.legalCompliance = incomeData.legalCompliance;
            this.data.customerRequest = incomeData.customerRequest;

            incomeData.itemList.forEach((v: ManufactureItem) => this.filterExsistItemData(v));
            this.setItemList(incomeData.itemList);
        } catch (err) {
            if (err instanceof PTSMessage || err instanceof CommonError) {
                alert(err.message);
            }
            else {
                alert('알수 없는 오류 발생');
            }
        }
        finally {
            this.rootStore.globalStore.isRunning = false;
        }
    }
}