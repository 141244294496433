import React from 'react';
import {
    Table,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    createStyles,
    Theme,
    Checkbox,
    Button,
    Typography
} from '@material-ui/core';
import TaskListTableCell from './TaskListTableCell';
import { WithStyles, withStyles } from '@material-ui/styles';
import { observer } from 'mobx-react';
import NoItem from './NoItem';
import WorkTaskSummary from '../../../../../data/model/design/taskDatas/designReqeust/WorkTaskSummary';
import Helper from '../../../../../module/Helper';

const style = (theme: Theme) => createStyles({

});

interface Props extends WithStyles<typeof style> {
    headers: string[];
    taskList: WorkTaskSummary[];
    perPage?: number;
    total?: number;
    offSet?: number;
    actionButtonText?: string;
    headerActionButtonText?: string;
    numSelected?: number;
    checkBoxes?: boolean;
    actionButton?: boolean;
    onHeaderCheckboxClick?: (e: any) => void;
    onHeaderActionButtonClick?: (e: any) => void;
    onCheckboxClick?: (e: any, key: number) => void;
    onDetailButtonClick?: (e: any, key: number) => void;
    onActionButtonClick?: (e: any, key: number) => void;
}

const TaskListTable: React.FC<Props> = observer((props) => {
    return (
        <React.Fragment>
            {props.checkBoxes &&
                <Button
                    style={{ margin: 5 }}
                    variant='outlined'
                    onClick={props.onHeaderActionButtonClick}>
                    {props.headerActionButtonText}
                </Button>
            }
            <Table>
                <TableHead>
                    <TableRow key={-123}>
                        {props.checkBoxes &&
                            <TableCell padding='checkbox'>
                                <Checkbox
                                    indeterminate={props.numSelected > 0 && props.numSelected < props.perPage}
                                    checked={props.numSelected === props.taskList.length && props.numSelected !== 0}
                                    onChange={props.onHeaderCheckboxClick}
                                />
                            </TableCell>
                        }
                        {props.headers.map((v, i) => (
                            <TableCell key={i} align='right'>
                                <Typography variant='subtitle1'>
                                    <strong>
                                        {Helper.fieldToString(v)}
                                    </strong>
                                </Typography>
                            </TableCell>
                        ))}
                        <TableCell align='right'>
                            <Typography variant='subtitle1'>
                                <strong>
                                    현재상태
                                </strong>
                            </Typography>
                        </TableCell>
                        <TableCell align='right'>

                        </TableCell>
                        <TableCell align='right'>

                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        props.taskList.length > 0 ?
                            props.taskList.map((v, i) => (
                                <TaskListTableCell
                                    key={i}
                                    taskInfo={v}
                                    headers={props.headers}
                                    index={i}
                                    checkBoxes={props.checkBoxes}
                                    actionButton={props.actionButton}
                                    actionsButtonText={props.actionButtonText}
                                    workID={v.workID}
                                    onActionButtonClick={(e, key) => props.onActionButtonClick(e, key)}
                                    onDetailButtonClick={(e, key) => props.onDetailButtonClick(e, key)}
                                    onCheckboxClick={(e, key) => props.onCheckboxClick(e, key)}
                                />
                            ))
                            :
                            <TableRow style={{ padding: 0 }}>
                                <TableCell colSpan={props.headers.length + 4} style={{ padding: 0 }}>
                                    <NoItem />
                                </TableCell>
                            </TableRow>
                    }
                </TableBody>
            </Table>
            {props.checkBoxes &&
                <Button
                    style={{ margin: 5 }}
                    variant='outlined'
                    onClick={props.onHeaderActionButtonClick}>
                    {props.headerActionButtonText}
                </Button>
            }
        </React.Fragment >
    )
})

export default withStyles(style)(TaskListTable);