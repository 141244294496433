import ModelBase from './ModelBase';
import { observable } from "mobx";

export interface IPTSFile {
    lastModified?: number;
    lastModifiedDate?: Date;
    name?: string;
    size?: number;
    type?: string;
    webkitRelativePath?: string;
    uniqueKey: string;
    file_id: string;
    mime?: string;
}

export default class PTSFile extends ModelBase {
    @observable lastModified?: number;
    @observable lastModifiedDate?: Date;
    @observable name?: string;
    @observable size?: number;
    @observable type?: string;
    @observable webkitRelativePath?: string;
    @observable mime?: string;

    uniqueKey: string;
    file_id: string;

    constructor(params?: IPTSFile) {
        super();
        if (params) {
            Object.assign(this, params);
        }
        this.type = 'file';
    }
}