import SynthesisID from "./SynthesisID";
import ChipDistributionInfo from "./ChipDistributionInfo";
import { observable } from "mobx";
import dayjs from "dayjs";
import TaskInfo from '../../../resource/data/tasks.json';
import ModelBase from "./ModelBase";
import DesignTask from "../design/DesignTask";
import TaskSubData from "../design/DesignTaskSubData";
import DesignRequest from "../design/taskDatas/designReqeust/DesignRequest";
import SynthesisDesign from "../design/taskDatas/synthesisDesign/SynthesisDesign";
import DesignConfirm from "../design/taskDatas/sales/DesignConfirm";
import ChipDistribution from "../design/taskDatas/chipDistribution/ChipDistribution";
import Synthesis1 from "../design/taskDatas/synthesis/Synthesis1";
import Synthesis2 from "../design/taskDatas/synthesis/Synthesis2";
import Synthesis3 from "../design/taskDatas/synthesis/Synthesis3";
import QCExperiment from "../design/taskDatas/qc/QCexperiment";
import QCSequencing from "../design/taskDatas/qc/QCSequencing";
import BIAnalysis from "../design/taskDatas/bi/BIAnalysis";
import BIUpload from "../design/taskDatas/bi/BIUpload";
import PanelConfirm from "../design/taskDatas/sales/PanelConfirm";
import GeneData from "../design/taskDatas/designReqeust/GeneData";
import Finish from "../design/taskDatas/sales/Finish";
import User from "../user/user";
import SynthSequenceData from "../design/taskDatas/designReqeust/SynthSequenceData";

class WorkDetailModel extends ModelBase {
    @observable projectCode?: string;
    @observable startDate?: Date;
    @observable finishDate?: Date;
    @observable endDate?: Date;
    @observable type?: string;
    @observable stage?: string;
    @observable customerAndPI?: string;
    @observable designName?: string;
    @observable erpCode?: string;
    @observable salesRepresentative?: string;
    @observable probeID?: string;
    @observable workID?: number | string;
    @observable tasks?: DesignTask<TaskSubData>[];
    @observable sids?: SynthesisID[];
    @observable chipInfos?: ChipDistributionInfo[];
    @observable repInfo?: User;

    constructor() {
        super();
        this.tasks = [];
        this.sids = [];
        this.chipInfos = [];
    }

    getTaskObjectByType = (type: string): DesignTask<TaskSubData> => {
        let obj = null;
        switch (type) {
            case TaskInfo.types.designRequest:
                obj = new DesignTask<DesignRequest>();
                obj.detail = new DesignRequest();
                return obj;
            case TaskInfo.types.synthDesign:
                obj = new DesignTask<SynthesisDesign>();
                obj.detail = new SynthesisDesign();
                return obj;
            case TaskInfo.types.designConfirm:
                obj = new DesignTask<DesignConfirm>();
                obj.detail = new DesignConfirm();
                return obj;
            case TaskInfo.types.chipDistribution:
                obj = new DesignTask<ChipDistribution>();
                obj.detail = new ChipDistribution();
                return obj;
            case TaskInfo.types.synth1:
                obj = new DesignTask<Synthesis1>();
                obj.detail = new Synthesis1();
                return obj;
            case TaskInfo.types.synth2:
                obj = new DesignTask<Synthesis2>();
                obj.detail = new Synthesis2();
                return obj;
            case TaskInfo.types.synth3:
                obj = new DesignTask<Synthesis3>();
                obj.detail = new Synthesis3();
                return obj;
            case TaskInfo.types.qcExperiment:
                obj = new DesignTask<QCExperiment>();
                obj.detail = new QCExperiment();
                return obj;
            case TaskInfo.types.qcSequencing:
                obj = new DesignTask<QCSequencing>();
                obj.detail = new QCSequencing();
                return obj;
            case TaskInfo.types.biAnalysis:
                obj = new DesignTask<BIAnalysis>();
                obj.detail = new BIAnalysis();
                return obj;
            case TaskInfo.types.biUpload:
                obj = new DesignTask<BIUpload>();
                obj.detail = new BIUpload();
                return obj;
            case TaskInfo.types.panelConfirm:
                obj = new DesignTask<PanelConfirm>();
                obj.detail = new PanelConfirm();
                return obj;
            case TaskInfo.types.finish:
                obj = new DesignTask<Finish>();
                obj.detail = new Finish();
                return obj;
            default:
                return null;
        }
    }

    fromJS = (json: object): void => {
        try {
            const keys: string[] = Object.keys(this);
            if (json == null) { return null }
            if (json.hasOwnProperty('tasks')) {
                for (const key in (json as any)['tasks']) {
                    const data = (json as any)['tasks'][key];
                    const obj = this.getTaskObjectByType(data.type);
                    obj.fromJS(data);
                    (this as any)['tasks'][key] = obj;
                }
            }
            if (json.hasOwnProperty('sids')) {
                for (const key in (json as any)['sids']) {
                    const data = (json as any)['sids'][key];
                    const obj = new SynthesisID();
                    obj.fromJS(data);
                    (this as any)['sids'][key] = obj;
                }
            }
            if (json.hasOwnProperty('chipInfos')) {
                for (const key in (json as any)['chipInfos']) {
                    const data = (json as any)['chipInfos'][key];
                    const obj = new ChipDistributionInfo();
                    obj.fromJS(data);
                    (this as any)['chipInfos'][key] = obj;
                }
            }
            for (const key in keys) {
                let val = (this as any)[keys[key]];
                if (key === 'tasks' || key === 'sids' || key === 'chipInfos') { continue; }
                if (typeof val === 'function') { continue; }
                if (typeof val !== 'object') {
                    if (!json.hasOwnProperty(keys[key])) { continue; }
                    if (keys[key].endsWith('Date')) {
                        const day = dayjs((json as any)[keys[key]]).format('YYYY-MM-DD HH:mm:ss');
                        if (day !== 'Invalid Date') {
                            (this as any)[keys[key]] = day;
                        }
                        else {
                            (this as any)[keys[key]] = (json as any)[keys[key]];
                        }
                    }
                    else {
                        (this as any)[keys[key]] = (json as any)[keys[key]];
                    }
                }
                else if (val instanceof ModelBase) {
                    val.fromJS((json as any)[keys[key]]);
                }
            }
            this.fillArray((json as any)['tasks'], this.tasks);
        } catch (err) {
            throw err;
        }
    }

    fillArray = (json: object, to: object) => {
        if (!json) {
            return;
        }
        try {
            const keys = Object.keys(json);
            for (const key of keys) {
                const val = (json as any)[key];
                if (!(to as any)[key]) { continue; }
                if (Array.isArray(val)) {
                    for (const data of val) {
                        if (!data) { continue; }
                        const obj = this.getArrayElement(key);
                        if (obj === null) {
                            (to as any)[key].push(data);
                        }
                        else {
                            obj.fromJS(data);
                            (to as any)[key].push(obj);
                        }
                    }
                }
                else if (typeof val === 'object') {
                    this.fillArray(val, (to as any)[key]);
                }
                else {
                    continue;
                }
            }

            const cd = this.tasks.filter(x => x.type === 'chipDistribution');
            if (cd.length > 0) {
                for (const task of this.tasks) {
                    if (task.type === 'chipDistribution') {
                        (task as any)['data']['chipInfos'] = this.chipInfos;
                    }
                }
            }
        } catch (err) {
            throw err;
        }
    }

    getArrayElement = (type: string): ModelBase => {
        switch (type) {
            case 'fusionList':
                return new GeneData();
            case 'hotspotList':
                return new GeneData();
            case 'customList':
                return new GeneData();
            case 'synthRequestSequence':
                return new SynthSequenceData();
            default:
                return null;
        }
    }
}

export default WorkDetailModel;