import { observable, reaction } from "mobx";
import dayjs from 'dayjs';
import TaskSubData from "../../DesignTaskSubData";
import Customer from "../../../customer/Customer";
import Validatable from "../../../common/Validatable";
import Helper from "../../../../../module/Helper";


export default class DesignRquestInfo extends TaskSubData {
    @observable geneDB?: string;
    @observable erpCode?: string;
    @observable projectCode?: string;
    @observable designName?: string;
    @observable diseaseName?: string;
    @observable referenceVersion: string;
    @observable requestDate?: Date;
    @observable dueDate?: Date;
    @observable dueDateBedFile?: Date;
    // @observable dueDateSeq?: Date;
    @observable masking?: string;
    @observable homologCutOffNumber?: number;
    @observable designRequestNote?: string;
    @observable pilotTestRunID?: string;

    @observable customer: Customer;

    constructor() {
        super();
        this.customer = new Customer();
        this.geneDB = 'Refseq';
        this.referenceVersion = '';
        this.masking = '';
        this.erpCode = '';
        this.projectCode = '';
        this.designName = '';
        this.diseaseName = '';
    }

    autoSetDueDateBedFile = reaction(
        () => this.requestDate,
        (value) => {
            const req = dayjs(value);
            this.dueDateBedFile = req.add(1, 'week').toDate();
        }
    )

    // override
    validateSelf(): void {
        if (!this.erpCode) {
            this.setErrorTextByName('erpCode', Validatable.errorTexts.needValue);
        }
        else if (!Helper.checkRegex(this.erpCode, /^[0-9][0-9](0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])A[0-9]{3}$/)) {
            this.setErrorTextByName('erpCode', `${Validatable.errorTexts.badFormat} -> 포맷: YYMMDDA###`);
        }
        if (!this.projectCode) {
            this.setErrorTextByName('projectCode', Validatable.errorTexts.needValue);
        }
        // else if (!Helper.checkRegex(this.projectCode, new RegExp(`^[a-zA-Z0-9]{6,7}-${this.erpCode}-[0-9]{3}$`))) {
        //     this.setErrorTextByName('projectCode', `${Validatable.errorTexts.badFormat} -> 포맷: [고객코드]-[ERP Code]-###`);
        // }
        if (this.diseaseName) {
            if (!Helper.checkRegex(this.diseaseName, /^[a-zA-Z0-9-_]+$/)) {
                this.setErrorTextByName('diseaseName', `${Validatable.errorTexts.badFormat} -> 포맷: 영문 및 - , _`);
            }
        }
        if (!this.designName) {
            this.setErrorTextByName('designName', Validatable.errorTexts.needValue);
        }
        else if (!Helper.checkRegex(this.designName, /^[0-9a-zA-Z-_]+$/)) {
            this.setErrorTextByName('designName', `${Validatable.errorTexts.badFormat} -> 포맷: 영문, 숫자, - , _`);
        }
        if (!this.referenceVersion) {
            this.setErrorTextByName('referenceVersion', Validatable.errorTexts.needValue);
        }
        else if (!Helper.checkRegex(this.referenceVersion, /^(etc.|GRCh37|GRCh38)$/)) {
            this.setErrorTextByName('referenceVersion', `${Validatable.errorTexts.badFormat} -> 포맷: etc. or GRCh37 or GRCh38`);
        }
        if (!this.requestDate) {
            this.setErrorTextByName('requestDate', Validatable.errorTexts.needValue);
        }
        if (!this.dueDate) {
            this.setErrorTextByName('dueDate', Validatable.errorTexts.needValue);
        }
        if (!this.masking) {
            this.setErrorTextByName('masking', Validatable.errorTexts.needValue);
        }
        if (!this.homologCutOffNumber) {
            this.setErrorTextByName('homologCutOffNumber', Validatable.errorTexts.needValue);
        }
        else if (this.homologCutOffNumber < 0) {
            this.setErrorTextByName('homologCutOffNumber', Validatable.errorTexts.positive);
        }
        else if (!Number.isInteger(Number(this.homologCutOffNumber))) {
            this.setErrorTextByName('homologCutOffNumber', Validatable.errorTexts.needInt);
        }
    }
}