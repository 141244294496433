import { TableCell, TableRow } from "@material-ui/core";
import React from "react";
import HelperText from "./HelperText";

interface Props {
    error?: boolean;
    helperText?: string;
    data?: string;
    index?: number;
}

const TableWithHelperText: React.FC<Props> = (props: Props) => {

    const error = props.error || false;
    const helperText = props.helperText || '';
    const data = props.data || '';
    const index = props.index || 0;

    return (
        <React.Fragment>
            <TableRow key={index}>
                <TableCell>
                    {data} &nbsp;
                    <HelperText text={error && helperText} />
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

export default TableWithHelperText