import { action, observable } from "mobx";
import DesignWork from "../../../model/design/DesignWork";
import StoreBase from "../../StoreBase";
import axios from 'axios';
import { words as RouteWords } from '../../../../resource/data/APIRoutes.json';
import PTSMessage from "../../../model/common/PTSMessage";
import RootStore from "../../RootStore";
import TaskInfo from '../../../../resource/data/tasks.json';
import DesignTask from "../../../model/design/DesignTask";

export default class ProcessTaskContainerStore extends StoreBase {
    @observable taskType: string;
    @observable workInfo: DesignWork;
    @observable redirect: boolean;

    constructor(rootStore?: RootStore) {
        super();
        this.workInfo = new DesignWork();
        this.taskType = '';
        this.redirect = false;
        if (rootStore) {
            this.rootStore = rootStore;
        }
    }

    @action
    setDatas = async (id: string, token: string) => {
        try {
            const result = await this.getWorkInfo(id, token);
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                })
            }
            const workInfo = new DesignWork(result.data.data);
            this.validationWork(workInfo);
            this.workInfo = workInfo;
            this.taskType = (this.workInfo.taskList[0] as any).type;
        } catch (err) {
            throw err;
        }
    }

    requestPostTaskData = async (data: DesignTask<any>, workID: string, token: string) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        return axios.put(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.design}/${RouteWords.tasks}/${workID}`,
            data,
            config
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        });
    }

    postTaskData = async (data: DesignTask<any>, workID: string, token: string) => {
        try {
            if (data.workID !== workID) {
                throw new PTSMessage({
                    message: 'Work ID가 일치 하지 않습니다.',
                });
            }
            const result = await this.requestPostTaskData(data, workID, token);
            if(result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                })
            }
            else {
                if(result.data.type === 'apiMsg') {
                    return true;
                }
            }
            return false;
        } catch (err) {
            throw err;
        }
    }

    getWorkInfo = async (id: string, token: string) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        return axios.get(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.design}/${RouteWords.works}/${id}`,
            config
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        })
    }

    validationWork = (workInfo: DesignWork) => {
        try {
            if (this.rootStore.globalStore.userInfo.role.toLowerCase() !== 'admin'
                && (workInfo.taskList[0] as any).departType !== this.rootStore.globalStore.userInfo.departType) {
                throw new PTSMessage({
                    message: '권한이 없습니다.'
                });
            }
            if ((workInfo.taskList[0] as any).stage !== TaskInfo.stage.processing) {
                throw new PTSMessage({
                    message: '잘못된 접근입니다.'
                });
            }
        } catch (err) {
            throw err;
        }
    }
}