import { inject, observer } from 'mobx-react';
import React from 'react';
import { Redirect, RouteComponentProps, Switch, withRouter, BrowserRouter as Router } from 'react-router-dom';
import RootStore from '../../../data/store/RootStore';
import PrivateRoute from '../../common/component/PrivateRoute';
import MainLayoutContainer from '../../layout/container/MainLayoutContainer';
import DashboardPage from '../dashboard/DashboardPage';
import DesignTaskMain from '../task/deisgn/DesignTaskMain';
import ManufactureMainPage from '../task/manufacture/ManufactureMainPage';
import AddUserPage from '../user/AddUserPage';
import ModifyUserInfoPage from '../user/ModifyUserInfoPage';
import SnopListViewPage from '../view/snop/SnopListViewPage';

interface Props extends RouteComponentProps<any> {
    rootStore?: RootStore;
}

@inject('rootStore')
@observer
class MainPage extends React.Component<Props> {
    render() {
        return (
            <div>
                {!this.props.rootStore.globalStore.isLoggedIn && <Redirect to={{ pathname: '/' }} />}
                <Router>
                    <MainLayoutContainer>
                        <Switch>
                            <PrivateRoute
                                exact
                                path={`${this.props.match.url}`}
                                component={DashboardPage} />
                            <PrivateRoute
                                path={`${this.props.match.url}/design`}
                                component={DesignTaskMain} />
                            <PrivateRoute
                                path={`${this.props.match.url}/manufacture`}
                                component={ManufactureMainPage} />
                            <PrivateRoute
                                exact path={`${this.props.match.url}/user/new`}
                                component={AddUserPage} />
                            <PrivateRoute
                                exact path={`${this.props.match.url}/user/modify`}
                                component={ModifyUserInfoPage} />
                            <PrivateRoute
                                exact path={`${this.props.match.url}/view/snop/:type`}
                                component={SnopListViewPage} />
                            {/* <PrivateRoute
                                exact path={`${this.props.match.url}/manual`}
                                component={ManualPage} /> */}
                        </Switch>
                    </MainLayoutContainer>
                </Router>
            </div>
        )
    }
}

export default withRouter(MainPage);