import { observable } from "mobx";
import Helper from "../../../../../module/Helper";
import PTSFile from "../../../common/PTSFile";
import Validatable from "../../../common/Validatable";
import TaskSubData from "../../DesignTaskSubData";

export default class SynthesisDesign extends TaskSubData {
    @observable panelName?: string;
    @observable synthesisIDs: string[];
    @observable synthesisOIDs: string[];
    @observable tempSDContents?: string[];
    @observable tempTargetBedFile?: PTSFile;
    @observable designReport?: PTSFile;
    @observable homologRegionFile?: PTSFile;
    @observable synthesisIDPrefix?: string;
    tempSDContentError?: string[];

    constructor() {
        super();
        this.synthesisIDs = [];
        this.synthesisOIDs = [];
        this.tempSDContents = [];
        this.tempTargetBedFile = new PTSFile();
        this.designReport = new PTSFile();
        this.homologRegionFile = new PTSFile();
        this.panelName = '';
        this.synthesisIDPrefix = '';
        this.tempSDContentError = [];
    }

    get SynthesisIDs() {
        for (let d of this.synthesisIDs) {
            d = d.substring(0, d.indexOf('.'));
        }
        return this.synthesisIDs
    }

    // override
    validateSelf(): void {
        if (!this.panelName) {
            this.setErrorTextByName('panelName', Validatable.errorTexts.needValue);
        }
        else if (!Helper.checkRegex(this.panelName, /^.{1,30}$/)) {
            this.setErrorTextByName('panelName', `30자 이하`);
        }
        if (this.tempSDContents.length < 1) {
            this.setErrorTextByName('tempSDContents', '입력값이 없거나 적용되지 않았습니다.');
        }
        else if (typeof this.tempSDContents !== 'undefined' && this.tempSDContents.length > 0) {
            for (let index in this.tempSDContents) {
                if (!Helper.checkRegex(this.tempSDContents[index], /^[0-9][0-9](0[1-9]|1[0-2])SD[0-9]{3}-([0-9][0-9]|[0-9])$/)) {
                    this.setErrorTextByName('tempSDContents', '');
                    this.tempSDContentError[index] = `${Validatable.errorTexts.badFormat} -> 포맷: YYMMSD###-#`;
                }
                else {
                    this.tempSDContentError[index] = '';
                }
            }
        }
        if (!this.synthesisIDPrefix) {
            this.setErrorTextByName('synthesisIDPrefix', Validatable.errorTexts.needValue);
        }
        else if (!Helper.checkRegex(this.synthesisIDPrefix, /^[0-9][0-9](0[1-9]|1[0-2])SD[0-9]{3}$/)) {
            this.setErrorTextByName('synthesisIDPrefix', `${Validatable.errorTexts.badFormat} -> 포맷: YYMMSD###`);
        }
        // if (!this.tempTargetBedFile.name) {
        //     this.setErrorTextByName('tempTargetBedFile', Validatable.errorTexts.fileValid);
        // }
        // else if (!Helper.checkExtensionRegex(this.tempTargetBedFile.name, 'bed')) {
        //     this.setErrorTextByName('tempTargetBedFile', `${Validatable.errorTexts.badFileFormat} -> 확장자: *.bed`);
        // }
        // if (!this.homologRegionFile.name) {
        //     this.setErrorTextByName('homologRegionFile', Validatable.errorTexts.fileValid);
        // }
        // else if (!Helper.checkExtensionRegex(this.homologRegionFile.name, 'txt')) {
        //     this.setErrorTextByName('homologRegionFile', `${Validatable.errorTexts.badFileFormat} -> 확장자: *.txt`);
        // }
        // if (!this.designReport.name) {
        //     this.setErrorTextByName('designReport', Validatable.errorTexts.fileValid);
        // }
        // else if (!Helper.checkExtensionRegex(this.designReport.name, 'xls')) {
        //     this.setErrorTextByName('designReport', `${Validatable.errorTexts.badFileFormat} -> 확장자: *.xlsx 또는 *.xls`);
        // }
    }
}
