import axios from "axios";
import ModelBase from "../model/common/ModelBase";
import RepositorySettings from "../repository/RepositorySettings";
import { words as RouteWords } from '../../resource/data/APIRoutes.json';
import PTSMessage from "../model/common/PTSMessage";
import { observable } from "mobx";
import User from "../model/user/user";
import RootStore from "./RootStore";

export default class StoreBase extends ModelBase {
    _baseURL: string;
    tokenName: string;
    contentType: string;

    @observable findUserOpen: boolean;
    @observable foundUsers: User[];
    @observable selectedUser: User;
    @observable rootStore: RootStore;

    constructor(rootStore?: RootStore) {
        super();
        if (rootStore) {
            this.rootStore = rootStore;
        }
        this._baseURL = RepositorySettings.apiBaseURL;
        this.tokenName = RepositorySettings.apiTokenName;
        this.contentType = RepositorySettings.contentType;
        this.findUserOpen = false;
        this.selectedUser = null;
        this.foundUsers = [];
    }

    get baseURL() {
        return RepositorySettings.apiBaseURL;
    }

    downLoadFile = async (id: string, token: string) => {
        axios({
            method: 'GET',
            url: `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.files}/${id}`,
            responseType: 'blob',
            headers: {
                'x-access-token': token,
            }
        }).then((res) => {
            if (res.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: res.data.resMessage,
                });
            }
            const type = res.headers['content-type'];
            const url = window.URL.createObjectURL(
                new Blob(
                    [res.data],
                    { type: type }
                )
            );
            const link = document.createElement('a');
            const contentDisposition = res.headers['content-disposition'];
            let fileName = 'Unknown';
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.split(';').filter((v: string) => {
                    return v.match(/filename\*=utf-8/i);
                });
                if (fileNameMatch.length > 0) {
                    const _fileName = fileNameMatch[0].trim().split(/filename\*=utf-8'/i)[1].trim().replace("'", '');
                    if (_fileName) {
                        fileName = decodeURI(_fileName);
                    }
                }
                else {
                    const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                    if (fileNameMatch.length === 2)
                        fileName = fileNameMatch[1];
                }
            }
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        }).catch((err) => {
            throw err;
        });
    }

    onFindUserDiagClose = () => {
        this.foundUsers = [];
        this.findUserOpen = false;
    }

    requestGetUserInfos = async (name: string, token: string): Promise<any> => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        return axios.get(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.users}/${name}`,
            config
        )
    }

    onFindUsers = async (name: string) => {
        try {
            const result = await this.requestGetUserInfos(name, this.rootStore.globalStore.token);
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                });
            }
            const users = result.data.data;
            if (!Array.isArray(users)) {
                throw new PTSMessage({
                    message: '오류 발생'
                });
            }
            else {
                const list = users.map((v) => {
                    return new User(v);
                });
                this.foundUsers = list;
            }
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
        }
    }

    onSelectUser = (user: User) => {
        this.selectedUser = user;
    }

    openFindUserDialog = () => {
        this.findUserOpen = true;
    }

    toggleFindUserDialog = () => {
        this.findUserOpen = !this.findUserOpen;
    }
}