import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import dayjs from 'dayjs';
import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import PTSMessage from '../../../data/model/common/PTSMessage';
import DashboardStore from '../../../data/store/pageStore/DashboardStore';
import { IRootStore } from '../../../data/store/RootStore';

interface Props extends IRootStore, RouteComponentProps<any> {

}

const paperStyle = {
    background: '#D35400',
    margin: 5
}

const mainGridStyle = {
    padding: 20,
}

// const paperTypo = {
//     color: '#424949',
// }

// const color1 = '';
// const color2 = '';
// const color3 = '';

// const fColor1 = '';
// const fColor2 = '';
// const fColor3 = '';

const links = {
    designWait: '/main/design/task/wait',
    designProc: '/main/design/task/process',
    manufactureWait: '/main/manufacture/list/accept',
    manufactureProc: '/main/manufacture/list/process',
    designAll: '/main/design/work/list',
    designRunning: '/main/design/work/list',
    designPaused: '/main/design/work/list',
    designFinished: '/main/design/work/list',
    manufactureAll: '/main/manufacture/list/search',
    manufactureRunning: '/main/manufacture/list/search',
    manufacturePaused: '/main/manufacture/list/search',
    manufactureFinished: '/main/manufacture/list/search',
}

const store = new DashboardStore();

const DashboardPage: React.FC<Props> = props => {

    const globalStore = props.rootStore.globalStore;
    const panelMarinTop = 30;

    const init = useCallback(async () => {
        try {
            await store.setDashboardData(globalStore.token);
            store.updatedTime = new Date();
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
        }
    }, [globalStore.token])

    useEffect(() => {
        init();
    }, [init]);

    const SummaryCard = (title?: string, num?: number, link?: string, cat?: string) => {
        const _title = title || 'Unknown';
        const _link = link || '';
        const _cat = cat || '';
        const _num = num === 0 ? 0 : num || -1;

        let colors = {
            fontColor: '',
            paperColor: '',
        };
        switch (_cat) {
            case 'wait':
                colors = {
                    fontColor: '',
                    paperColor: '',
                }
                break;
            case 'proc':
                colors = {
                    fontColor: '',
                    paperColor: '',
                }
                break;
            case 'total':
                colors = {
                    fontColor: '',
                    paperColor: '',
                }
                break;
            default:
                colors = {
                    fontColor: '#641E16',
                    paperColor: '#48C9B0',
                }
                break;
        }

        const onClick = () => {
            props.history.push(_link);
            window.location.reload();
        }

        return (
            <React.Fragment>
                <Paper style={{
                    ...paperStyle,
                    background: colors.paperColor
                }}>
                    <Grid container direction='column' style={mainGridStyle}>
                        <Grid item>
                            <Typography variant='h5' style={{ color: colors.fontColor }}>
                                <strong>
                                    {_title}
                                </strong>
                            </Typography>
                        </Grid>
                        <Grid container direction='column' alignItems='center'>
                            <Grid item>
                                <Typography variant='h2' style={{ color: colors.fontColor }}>
                                    <strong>
                                        {_num}
                                    </strong>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container direction='column' alignItems='flex-end'>
                            <Grid item>
                                {
                                    _link
                                    &&
                                    <Button
                                        onClick={onClick}
                                        variant='text'
                                        style={{ color: colors.fontColor }}
                                        disabled={_num < 1}>
                                        목록보기
                                    </Button>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <Grid container direction='column'>
                <Typography variant='h2'>
                    Dashboard
                </Typography>
                <Grid container>
                    <Grid item xl lg sm >
                        <hr />
                    </Grid>
                </Grid>
                <Grid container alignItems='flex-end'>
                    <Grid container direction='column' alignItems='flex-end'>
                        <Grid item>
                            <Button onClick={init}>
                                기준 일시: {dayjs(store.updatedTime).format('YYYY-MM-DD HH:mm:ss')}
                                <Refresh />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Typography variant='h5'>
                        접수 / 기록 대기
                    </Typography>
                </Grid>
                <Grid container>
                    {
                        <React.Fragment>
                            <Grid item xl={6} lg={6} sm={6}>
                                {
                                    SummaryCard(
                                        'Custom panel design 접수대기',
                                        store.designCounts.waitCount,
                                        links.designWait
                                    )
                                }
                            </Grid>
                            <Grid item xl={6} lg={6} sm={6}>
                                {
                                    SummaryCard(
                                        'Custom panel design 기록대기',
                                        store.designCounts.processingCount,
                                        links.designProc
                                    )
                                }
                            </Grid>
                        </React.Fragment>
                    }
                    {
                        <React.Fragment>
                            <Grid item xl={6} lg={6} sm={6}>
                                {
                                    SummaryCard(
                                        '제조 접수대기',
                                        store.manufactureCounts.waitCount,
                                        links.manufactureWait
                                    )
                                }
                            </Grid>
                            <Grid item xl={6} lg={6} sm={6}>
                                {
                                    SummaryCard(
                                        '제조 기록대기',
                                        store.manufactureCounts.processingCount,
                                        links.manufactureProc
                                    )
                                }
                            </Grid>
                        </React.Fragment>
                    }
                </Grid>
                <Grid container style={{ marginTop: panelMarinTop }}>
                    <Typography variant='h5'>
                        Custom panel design work 현황
                    </Typography>
                </Grid>
                <Grid container>
                    <Grid item xl={3} lg={3} sm={3}>
                        {
                            SummaryCard(
                                '기록된 모든 Custom panel design work',
                                store.designCounts.allCount,
                                // links.designAll
                            )
                        }
                    </Grid>
                    <Grid item xl={3} lg={3} sm={3}>
                        {
                            SummaryCard(
                                'Custom panel design work 진행중',
                                store.designCounts.runningCount,
                                // links.designRunning
                            )
                        }
                    </Grid>
                    <Grid item xl={3} lg={3} sm={3}>
                        {
                            SummaryCard(
                                'Custom panel design work 중단됨',
                                store.designCounts.pausedCount,
                                // links.designPaused
                            )
                        }
                    </Grid>
                    <Grid item xl={3} lg={3} sm={3}>
                        {
                            SummaryCard(
                                'Custom panel design work 완료됨',
                                store.designCounts.finishedCount,
                                // links.designFinished
                            )
                        }
                    </Grid>
                </Grid>
                <Grid container style={{ marginTop: panelMarinTop }}>
                    <Typography variant='h5'>
                        제조 work 현황
                    </Typography>
                </Grid>
                <Grid container>
                    <Grid item xl={3} lg={3} sm={3}>
                        {
                            SummaryCard(
                                '기록된 모든 제조 work',
                                store.manufactureCounts.allCount,
                                // links.manufactureAll
                            )
                        }
                    </Grid>
                    <Grid item xl={3} lg={3} sm={3}>
                        {
                            SummaryCard(
                                '제조 work 진행중',
                                store.manufactureCounts.runningCount,
                                // links.manufactureRunning
                            )
                        }
                    </Grid>
                    <Grid item xl={3} lg={3} sm={3}>
                        {
                            SummaryCard(
                                '제조 work 중단됨',
                                store.manufactureCounts.pausedCount,
                                // links.manufacturePaused
                            )
                        }
                    </Grid>
                    <Grid item xl={3} lg={3} sm={3}>
                        {
                            SummaryCard(
                                '제조 work 완료됨',
                                store.manufactureCounts.finishedCount,
                                // links.manufactureFinished
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default withRouter(inject('rootStore')(observer(DashboardPage)));