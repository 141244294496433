import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Grid, FormControl, Typography } from '@material-ui/core';
import { observable } from 'mobx';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateUtil from '@date-io/date-fns';
import { IRootStore } from '../../../../../data/store/RootStore';
import ManufactureStore from '../../../../../data/store/pageStore/manufacture/ManufactureStore';
import Validatable from '../../../../../data/model/common/Validatable';
import TitledPaper from '../../../../common/component/TitledPaper';
import ExtendedMUISTextField from '../../../../common/component/ExtendedMUISTextfield';
import Helper from '../../../../../module/Helper';
import FormButtonSet from '../../../../common/component/FormButtonSet';
import CommonErrors, { CommonError } from '../../../../../data/model/common/CommonErrors';
import ManufactureWork from '../../../../../data/model/manufacture/ManufactureWork';
import PTSMessage from '../../../../../data/model/common/PTSMessage';

interface Props extends RouteComponentProps<any>, IRootStore {
}

@inject('rootStore')
@observer
class ManufactureDeliveryContainer extends React.Component<Props> {
    @observable store = new ManufactureStore();
    globalStore = this.props.rootStore.globalStore;
    commentStore = this.props.rootStore.commentStore;

    constructor(props: Props) {
        super(props);
        this.globalStore.currentTaskTitle = '제조 배송 처리';
        this.globalStore.useComment = true;
        this.commentStore.mode = 'work';
        this.commentStore.stage = '제조 배송 처리';
    }

    scrollBottom = () => {
        const obj = document.getElementById('commentContentsList');
        obj.scrollTop = obj.scrollHeight;
    }

    componentDidMount = async () => {
        try {
            this.globalStore.isRunning = true;
            const workID = this.props.match.params.workID;
            const result = await this.store.getManufactureByWorkID(workID, this.globalStore.token);
            const track = await this.store.getManufactureTrack(workID, this.globalStore.token);
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                })
            }
            if (track.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                })
            }
            if (this.globalStore.userInfo.role.toLocaleLowerCase() !== 'admin') {
                if (track.data.data.current.departType !== this.globalStore.userInfo.departType) {
                    throw CommonErrors.NoAuth;
                }
            }
            this.store.workID = workID;
            this.store.data.fromJS(result.data.data);
            this.globalStore.currentTaskTitle = this.store.getTitleByType(track.data.data.current.workType);
            this.store.setItemList(result.data.data.itemList);
            this.store.setInputHeadersByType(track.data.data.current.workType);
            this.store.setReadonlyHeadersByType(track.data.data.current.workType);
            this.commentStore.workID = workID;
            this.commentStore.stage = this.globalStore.currentTaskTitle;
            await this.commentStore.setCommentsMany();
            this.store.data.changeRequestHistory = result.data.data.changeRequestHistory;
            this.store.setManufactureNumber(result.data.data.manufactureOrderNumberList);
            this.store.setManufactureNoteList(result.data.data.manufactureNoteList);
        } catch (err) {
            if (err instanceof CommonError || err instanceof PTSMessage) {
                alert(err.message);
            }
            // else if (err.errorObj) {
            //     alert(err.message);
            // }
            else {
                alert('오류 발생');
            }
            this.props.history.push('/main/manufacture/list/process');

        } finally {
            this.globalStore.isRunning = false;
        }
    }

    translate = (str: string): string => {
        return (ManufactureWork.Translations as any)[str] || str;
    }

    onFormSubmit = async () => {
        if (!window.confirm('제출하시겠습니까?')) {
            return;
        }
        try {
            this.globalStore.isRunning = true;

            // api
            const result =
                await this.store.processManufacture(
                    this.globalStore.token
                );

            // result
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                });
            }
            alert('제출완료');
            this.props.history.push(`/main/manufacture/list/process`);
            window.location.reload();
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
            else {
                alert('오류 발생.');
            }
            this.store.isValid = false;
        } finally {
            this.globalStore.isRunning = false;
        }
    }

    onValidate = () => {
        try {
            this.store.data.resetErrors();
            this.store.isValid = true;
            if (!this.store.data.deliveryDate) {
                this.store.data.setErrorTextByName('deliveryDate', Validatable.errorTexts.needValue);
                this.store.isValid = false;
            }
            if (this.store.data.deliveryPlace.length < 2) {
                this.store.data.setErrorTextByName('deliveryPlace', '2글자 이상 입력하세요.');
                this.store.isValid = false;
            }
            if (!this.store.isValid) {
                alert('유효하지 않은 값이 있습니다.');
                return;
            }
            this.store.isValid = true;
        } catch (err) {

        } finally {

        }
    }

    onFormClear = () => {
        window.location.reload();
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.store.data.prevWorkID
                    &&
                    <Typography variant='body2'>
                        이전 작업(복사된 작업) ID:&nbsp;
                        <Link to={`/main/manufacture/detail/${this.store.data.prevWorkID}`} target='_blank'>
                            {this.store.data.prevWorkID}
                        </Link>
                    </Typography>
                }
                <TitledPaper title='제조품 배송 정보'>
                    <Grid container direction='column'>
                        <Grid container>
                            <Grid item xl lg sm>
                                <ExtendedMUISTextField
                                    translation={this.translate}
                                    fullWidth
                                    margin='normal'
                                    name='deliveryPlace'
                                    rootdata={this.store.data}
                                    value={this.store.data.deliveryPlace}
                                />
                            </Grid>
                            <Grid item xl lg sm>
                                <MuiPickersUtilsProvider utils={DateUtil}>
                                    <FormControl fullWidth margin='normal'>
                                        <KeyboardDatePicker
                                            variant='inline'
                                            format='yyyy/MM/dd'
                                            name='deliveryDate'
                                            label='배송완료일'
                                            value={
                                                this.store.data.deliveryDate
                                            }
                                            onChange={(date) => { this.store.data.onChangeDateDefault('deliveryDate', date) }}
                                            helperText={
                                                Helper.getErrorText(
                                                    this.store.data.errors.deliveryDate
                                                )
                                            }
                                            error={
                                                Helper.getErrorState(
                                                    this.store.data.errors.deliveryDate
                                                )
                                            }
                                        />
                                    </FormControl>
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                    </Grid>
                </TitledPaper>
                <FormButtonSet
                    validated={this.store.isValid}
                    onFormClear={this.onFormClear}
                    onValidate={this.onValidate}
                    onSubmit={this.onFormSubmit} />
            </React.Fragment>
        )
    }
}

export default withRouter(ManufactureDeliveryContainer);