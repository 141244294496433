import React from 'react';
import { Paper, Theme, Grid, Button } from '@material-ui/core';
import { WithStyles, createStyles, withStyles } from '@material-ui/styles';
import { observer } from 'mobx-react';

const styles = (theme: Theme) => createStyles({
    paper: {
        padding: 5,
        margin: 3,
        marginBottom: 6,
        background: '#FAFAFA',
    },
    button: {
        padding: 15,
        marginLeft: 15,
        margin: 5
    }
})

interface Props extends WithStyles<typeof styles> {
    onSubmit?: (e: any) => void;
    onValidate?: (e: any) => void;
    onFormClear?: (e: any) => void;
    validated?: boolean;
}

const FormButtonSet: React.FC<Props> = observer((props) => {
    let validated = false;
    if (props.validated === null || props.validated === undefined) {
        validated = true;
    }
    else {
        validated = props.validated;
    }
    return (
        <Paper className={props.classes.paper}>
            <Grid item container>
                <Grid item container direction='column' alignItems='flex-end'>
                    <Grid item xl lg sm>
                        <Button
                            disabled={!validated}
                            onClick={props.onSubmit}
                            color='primary'
                            className={props.classes.button}
                        >
                            제출하기
                    </Button>
                        <Button
                            onClick={props.onValidate}
                            color='secondary'
                            className={props.classes.button}
                        >
                            데이터 검증
                    </Button>
                        <Button
                            onClick={props.onFormClear}
                            color='secondary'
                            className={props.classes.button}
                        >
                            전체 비우기
                    </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
})

export default withStyles(styles)(FormButtonSet);