import { Grid } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import DesignRequest from '../../../../../../../data/model/design/taskDatas/designReqeust/DesignRequest';
import { IDesignTaskStore } from '../../../../../../../data/store/pageStore/design/DesignTaskStore';
import RebalancingRequestStore from '../../../../../../../data/store/pageStore/design/RebalancingRequestStore';
import { IRootStore } from '../../../../../../../data/store/RootStore';
import RebalancingBasicInfoForm from '../component/RebalancingBasicInfoForm';

interface Props extends IRootStore, IDesignTaskStore<DesignRequest> {
    store: RebalancingRequestStore;
}

@inject('rootStore')
@observer
class RebalancingRequestMainForm extends React.Component<Props> {
    store = this.props.store;
    globalStore = this.props.rootStore.globalStore;

    render() {
        return (
            <React.Fragment>
                <Grid container direction='column'>
                    <Grid item xl lg sm md>
                        <RebalancingBasicInfoForm
                            store={this.store}
                            userInfo={this.globalStore.userInfo} />
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

export default RebalancingRequestMainForm;