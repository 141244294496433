import axios from "axios";
import { observable } from "mobx";
import PTSFile from "../../../model/common/PTSFile";
import SynthesisID from "../../../model/common/SynthesisID";
import DesignTask from "../../../model/design/DesignTask";
import TaskSubData from "../../../model/design/DesignTaskSubData";
import DesignWork from "../../../model/design/DesignWork";
import StoreBase from "../../StoreBase";
import { words as RouteWords } from '../../../../resource/data/APIRoutes.json';

export default class ProcessStore<T extends TaskSubData> extends StoreBase {
    @observable taskData?: DesignTask<T>;
    @observable workInfo?: DesignWork;

    @observable files: Map<string, File>;
    @observable filesMapping: Map<string, PTSFile>;
    @observable sidObjList?: SynthesisID[];
    @observable designRequestNote?: string;

    @observable isValid: boolean;


    constructor() {
        super();
        this.sidObjList = [];
        this.taskData = new DesignTask<T>();
        this.files = new Map<string, File>();
        this.filesMapping = new Map<string, PTSFile>();
        this.isValid = false;
    }

    getDesignRequestNote = () => {
        const workInfo = this.workInfo;
        if (Array.isArray(workInfo?.taskList)) {
            const filtered = workInfo.taskList.filter((v: any) => {
                return v.type === 'designRequest'
            });
            if (filtered.length > 0) {
                return (filtered[0] as any).detail?.designRequestInfo.designRequestNote ?? '';
            }
        }
        return '';
    }

    uploadFiles = (data: FormData, token: string): Promise<any> => {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'multipart/form-data'
            }
        };
        return axios.post(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.files}`,
            data,
            config
        ).then((response) => {
            return response;
        }).catch((err) => {
            alert('파일이 손상되었거나 존재하지 않습니다.')
            throw err;
        })
    }

    getFileUploadForm = (): FormData => {
        const formData = new FormData();
        const fe = Array.from(this.files.values());
        if (fe.length < 1) {
            return null;
        }
        for (const file of fe) {
            formData.append('files', file);
        }
        return formData;
    }

    getSynthIDs = (workID: string, token: string) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        };
        return axios.get(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.sids}/${workID}`,
            config
        ).then((response) => {
            return response;
        }).catch((err) => {
            throw err;
        })
    }

    addSynthIDs = (data: SynthesisID[], token: string): Promise<any> => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        };
        return axios.post(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.sids}`,
            { data: data },
            config
        ).then((response) => {
            return response;
        }).catch((err) => {
            throw err;
        })
    }
}