import React from 'react'
import { inject, observer } from 'mobx-react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { observable } from 'mobx';
import { IRootStore } from '../../../../../../../data/store/RootStore';
import WorkDetailModel from '../../../../../../../data/model/common/WorkDetailModel';
import TitledPaper from '../../../../../../common/component/TitledPaper';
import WorkDetailPanel from '../component/WorkDetailPanel';
import WorkDetailPanelContainer from './WorkDetailPanelContainer';
import PTSMessage from '../../../../../../../data/model/common/PTSMessage';
import WorkDetailStore from '../../../../../../../data/store/pageStore/design/WorkDetailStore';
import FlowControlButtonSet from '../component/FlowControlButtonSet';
import FormUtil from '../../../../../../../module/FormUtil';
import PrintDesignRequestConatiner from './PrintDesignRequestConatiner';
import DesignDownloadFileConatiner from './DesignDownloadFileConatiner';
import BiAnalysisDownloadFileContainer from './BiAnalysisDownloadFileContainer';
import TaskInfo from '../../../../../../../resource/data/tasks.json';
import Helper from '../../../../../../../module/Helper';
import TaskListStore from '../../../../../../../data/store/pageStore/design/TaskListStore';
import { CommonError } from '../../../../../../../data/model/common/CommonErrors';
import { saveAs } from 'file-saver';

interface Props extends RouteComponentProps<any>, IRootStore {
}

@inject('rootStore')
@observer
class WorkDetail extends React.Component<Props> {
    @observable data: any = {};
    @observable workDetailData = new WorkDetailModel();
    @observable dataLoaded = false;
    @observable open = false;
    @observable workID = '';

    @observable acceptButtonDisabled = true;

    globalStore = this.props.rootStore.globalStore;
    commentStore = this.props.rootStore.commentStore;
    store = new WorkDetailStore();
    listStore = new TaskListStore();

    error = {
        LoadFailError: {
            name: 'Load Fail',
            message: 'Data load fail'
        }
    }

    componentDidMount = async () => {
        try {
            this.globalStore.isRunning = true;
            this.globalStore.useComment = true;
            this.globalStore.currentTaskTitle = 'Work 상세정보';
            this.workID = this.props.match.params.workID;
            await this.store.setWorkDetail(this.workID, this.globalStore.token);
            const curr = this.store.workDetail.tasks.reverse()[0];
            const type = FormUtil.getFieldLabel((curr as any).type);
            const stage = FormUtil.getFieldLabel((curr as any).stage);
            this.commentStore.workID = this.workID;
            this.commentStore.stage = `${type} ${stage} : Work 상세정보`;
            this.commentStore.mode = 'work';
            await this.commentStore.setCommentsMany();

            const nowDepart = curr === null ? '' : curr.departType;
            const user = this.globalStore.userInfo;

            if (curr.stage === TaskInfo.stage.waitAccept) {
                if (user.departType === nowDepart) {
                    this.acceptButtonDisabled = false;
                }
                else {
                    if (Helper.isAdmin(user.role)) {
                        this.acceptButtonDisabled = false;
                    }
                }
            }
            else {
                this.acceptButtonDisabled = true;
            }

            this.dataLoaded = true;
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
        } finally {
            this.globalStore.isRunning = false;
        }
    }

    onPauseClick = async () => {
        try {
            if (!window.confirm('작업을 중단 상태로 합니다.')) {
                return;
            }
            const result = await this.store.requestPauseWork(this.workID, this.globalStore.token);
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                })
            }
            await this.store.setWorkDetail(this.workID, this.globalStore.token);
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
        }
    }

    onRunClick = async () => {
        try {
            if (!window.confirm('작업의 중단 상태를 해제 합니다.')) {
                return;
            }
            const result = await this.store.requestRunWork(this.workID, this.globalStore.token);
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                })
            }
            await this.store.setWorkDetail(this.workID, this.globalStore.token);
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
        }
    }

    onFinishClick = async () => {
        try {
            if (!window.confirm('작업을 완료 상태로 변경합니다.')) {
                return;
            }
            const result = await this.store.requestFinishWork(this.workID, this.globalStore.token);
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                })
            }
            await this.store.setWorkDetail(this.workID, this.globalStore.token);
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
        }
    }

    onReworkClick = () => {
        try {
            this.store.openReworkDiag();
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
        }
    }

    onDiagClose = () => {
        this.store.selectedTaskIndex = -1;
        this.store.selectedTask = '';
        this.store.reworkDiagOpen = false;
    }

    onDiagOkClick = async () => {
        if (this.store.selectedTaskIndex < 1) {
            throw new PTSMessage({
                message: '작업을 선택해주세요.',
            });
        }
        if (!window.confirm('선택한 작업부터 시작하는 새로운 Work를 생성합니다.')) {
            return;
        }
        try {
            const result = await this.store.newReWork(this.workID, this.globalStore.token, this.store.selectedTaskIndex);
            alert(`등록 성공. Work ID: ${result.data.data.workID}`);
        } catch (err) {
            if (err instanceof PTSMessage && err.type === 'apiErr') {
                alert(err.message);
            }
        }
        finally {
            this.onDiagClose();
        }
    }

    onDiagCancelClick = () => {
        this.onDiagClose();
    }

    onDiagSelect = (e: any) => {
        this.store.selectedTaskIndex = e.target.value;
    }

    componentWillUnmount = () => {
        // this.props.globalStore.useComment = false;
    }

    toggleOpenSwitch = (e: any) => {
        this.open = !this.open;
    }

    acceptWork = async () => {
        try {
            if (window.confirm('접수하시겠습니까?')) {
                this.globalStore.isRunning = true;
                const arr: object[] = [];
                arr.push(this.store.workDetail.tasks.reverse()[0]);
                const result = await this.listStore.requestAcceptTasks(arr, this.globalStore.token);
                if (result.data.type === 'apiErr') {
                    throw new PTSMessage({
                        message: result.data.resMessage,
                    })
                }
                alert('접수 완료');
                window.location.reload();
            }
        } catch (err) {
            if (err instanceof CommonError || err instanceof PTSMessage) {
                alert(err.message);
            }
            else {
                alert('오류 발생');
            }
        } finally {
            this.globalStore.isRunning = false;
        }
    }

    onDataDump = () => {
        const data = this.store.workDetail;
        const json = JSON.stringify(data, null, '\t');
        const blob = new Blob([json], { type: 'application/json' });
        saveAs(blob, `design_work_dump_${data.workID}.json`);
    }

    render() {
        return (
            <React.Fragment>
                <Dialog open={this.store.reworkDiagOpen}>
                    <DialogTitle>
                        재작업 위치 선택
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            재작업을 시작할 작업위치를 선택하세요.
                        </DialogContentText>
                        <FormControl fullWidth>
                            <InputLabel id='select'>
                                작업
                            </InputLabel>
                            <Select
                                onChange={this.onDiagSelect}
                                fullWidth
                                value={this.store.selectedTaskIndex}
                                id='select'>
                                <MenuItem value={-1}>
                                    선택
                                </MenuItem>
                                {this.store.reworkTaskList.length > 1 && this.store.reworkTaskList.map((v, i) => {
                                    if (i < 1) {
                                        return null;
                                    }
                                    return (
                                        <MenuItem value={i} key={`key${i}`}>
                                            {i}.
                                            {v.name}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onDiagCancelClick} color='secondary'>
                            취소
                        </Button>
                        <Button onClick={this.onDiagOkClick} color='primary'>
                            확인
                        </Button>
                    </DialogActions>
                </Dialog>
                <Grid item container>
                    <Grid item xl lg sm>
                        <Grid container item direction='column' alignItems='flex-end'>
                            <Grid item>
                                <Button
                                    onClick={this.acceptWork}
                                    disabled={this.acceptButtonDisabled}
                                    variant='contained'
                                    color='primary'>접수</Button>
                            </Grid>
                        </Grid>
                        <Button variant='contained' color='primary' onClick={this.onDataDump}>Data dump (json)</Button>
                        <PrintDesignRequestConatiner data={this.store.requestData} isLoaded={this.store.isRequestLoaded}
                            departType={this.globalStore.userInfo.departType} requestData={this.store.requestData} workID={this.workID} />
                        <DesignDownloadFileConatiner data={this.store.synthesisDesignData} isLoaded={this.store.isSynthesisDesignLoaded} />
                        <BiAnalysisDownloadFileContainer data={this.store.biAnalysisData} isLoaded={this.store.isBiAnalysisLoaded} />
                        {
                            this.store.prevWorkID
                            &&
                            <Typography variant='h6' style={{ background: '#3f51b5', color: 'white', padding: 5 }}>
                                이전 작업(복사된 작업) ID:&nbsp;
                                <Link to={`/main/design/work/detail/${this.store.prevWorkID}`} target='_blank' style={{ color: 'white' }}>
                                    {this.store.prevWorkID}
                                </Link>
                            </Typography>
                        }
                        <TitledPaper title={`Work Detail (No.${this.props.match.params.workID})`}>
                            <Grid container direction='column'>
                                <Grid
                                    style={{
                                        marginTop: 15, marginBottom: 10
                                    }}
                                    item
                                    container
                                    direction='column'
                                    alignContent='flex-end'>
                                    <FlowControlButtonSet
                                        useRework
                                        onFinishClick={this.onFinishClick}
                                        onPauseClick={this.onPauseClick}
                                        onRunClick={this.onRunClick}
                                        onReworkClick={this.onReworkClick}
                                        status={this.store.workStatus}
                                    />
                                </Grid>
                                <Grid container item style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                                    <Grid item xl lg sm md>
                                        {
                                            this.dataLoaded &&
                                            <WorkDetailPanel
                                                open={true}
                                                title={`Work. ${this.props.match.params.workID}`}
                                                marginLeft={1}>
                                                <WorkDetailPanelContainer data={this.store.workDetail} />
                                            </WorkDetailPanel>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </TitledPaper>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

export default withRouter(WorkDetail);
