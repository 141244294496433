import React from 'react';
import { observer, inject } from 'mobx-react';
import ManufactureRequestInfoForm from '../component/ManufactureRequestInfoForm';
import ManufactureItemsForm from '../component/ManufactureRequestItemsForm';
import ManufactureNoteForm from '../component/ManufactureNoteForm';
import dayjs from 'dayjs';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { IRootStore } from '../../../../../data/store/RootStore';
import ManufactureWork from '../../../../../data/model/manufacture/ManufactureWork';
import PTSMessage from '../../../../../data/model/common/PTSMessage';
import ManufactureTrackData from '../../../../../data/model/manufacture/ManufactureTrackData';
import TitledPaper from '../../../../common/component/TitledPaper';
import FormButtonSet from '../../../../common/component/FormButtonSet';
import CommonErrors, { CommonError } from '../../../../../data/model/common/CommonErrors';
import ManufactureRequestStore from '../../../../../data/store/pageStore/manufacture/ManufactureRequestStore';
import { observable } from 'mobx';
import FindUserDialog from '../../../../common/component/findUser/FindUserDialog';
import { Button, Grid, Typography } from '@material-ui/core';
import ManufactureStore from '../../../../../data/store/pageStore/manufacture/ManufactureStore';
import ManufacturePanel from '../../../../../data/model/manufacture/ManufacturePanel';
import ReactToPrint from 'react-to-print';
import PrintManufactureRequestComponent from '../component/print/PrintManufactureRequestComponent';
import ManufactureWorkListStore from '../../../../../data/store/pageStore/manufacture/ManufactureWorkListStore';
import FindManufactureDialog from '../component/FindManufactureDialog';
import ManufactureItemFormStore from '../../../../../data/store/pageStore/manufacture/ManufactureItemFormStore';
import ManufactureCustomerRequestInfoComponent from '../component/ManufactureCustomerRequestInfoComponent';

interface Props extends RouteComponentProps<any>, IRootStore {
}

interface PrintProps {
    children?: React.ReactNode;
    data?: ManufactureWork;
}

const RequestPrintComponent = React.forwardRef((props: PrintProps, ref: any) => (
    <PrintManufactureRequestComponent data={props.data} ref={ref} />
));

@inject('rootStore')
@observer
class ManufactureRequestMainContainer extends React.Component<Props> {
    store = new ManufactureRequestStore(this.props.rootStore);
    itemStore = new ManufactureItemFormStore();
    workListStore = new ManufactureWorkListStore();
    workStore = new ManufactureStore();

    data = this.store.data;
    globalStore = this.props.rootStore.globalStore;
    commentStore = this.props.rootStore.commentStore;
    @observable isValid: boolean = this.store.isValid;

    componentRef: any = null;

    constructor(props: Props) {
        super(props);
        this.globalStore.currentTaskTitle = '제조의뢰';
        this.data.writerDepart = this.globalStore.userInfo.departName;
        this.data.writerName = this.globalStore.userInfo.name;
    }

    componentDidMount = async () => {
        try {
            this.globalStore.isRunning = true;
            if (this.globalStore.userInfo.role.toLocaleLowerCase() !== 'admin'
                && this.globalStore.userInfo.departType !== ManufactureWork.DepartList.sales) {
                throw CommonErrors.NoAuth;
            }

            const panelResult = await this.store.getPanelList(this.globalStore.token);
            this.store.panelList = panelResult.data.data.sort((a: ManufacturePanel, b: ManufacturePanel) =>
                a.catalogPanelName.localeCompare(b.catalogPanelName));

            this.globalStore.useComment = true;
            this.commentStore.mode = 'tmp';
            this.commentStore.stage = '제조의뢰';
            this.store.data.legalCompliance = true;

            if (this.props.match.params.workID) {
                const workID = this.props.match.params.workID;
                const result = await this.workStore.getManufactureByWorkID(workID, this.globalStore.token);
                const track = await this.workStore.getManufactureTrack(workID, this.globalStore.token);
                if (result.data.type === 'apiErr') {
                    throw new PTSMessage({
                        message: result.data.resMessage,
                    })
                }
                if (track.data.type === 'apiErr') {
                    throw new PTSMessage({
                        message: result.data.resMessage,
                    })
                }
                if (this.globalStore.userInfo.role.toLocaleLowerCase() !== 'admin') {
                    if (track.data.data.current.departType !== this.globalStore.userInfo.departType) {
                        throw CommonErrors.NoAuth;
                    }
                }
                this.store.workID = workID;
                this.store.data.fromJS(result.data.data);
                this.globalStore.currentTaskTitle = this.workStore.getTitleByType(track.data.data.current.workType);
                this.store.setItemList(result.data.data.itemList);
                this.commentStore.workID = workID;
                this.commentStore.stage = this.globalStore.currentTaskTitle;
                await this.commentStore.setCommentsMany();
                this.store.isReworkIndex = true;
            }
        } catch (err) {
            if (err instanceof PTSMessage || err instanceof CommonError) {
                alert(err.message);
            }
            else {
                alert('알수 없는 오류 발생');
            }
            this.props.history.push('/main');
            window.location.reload();
        } finally {
            this.globalStore.isRunning = false;
        }
    }

    onValidate = () => {
        this.data.resetErrorsRecursive();
        this.data.validateRecursive();
        this.data.itemList.map((v) => {
            return (v.itemName === 'TC #3' || v.itemName === 'TC#3') && v.initErrorByKey('probeIDInputData');
        })
        this.isValid = !this.data.hasErrorRecursive();
        if (!this.isValid) {
            alert('유효하지 않은 값이 있습니다.');
        }
    }

    onFormClear = () => {
        window.location.reload();
    }

    onFormSubmit = async () => {
        try {
            if (window.confirm('제출하시겠습니까?')) {
                this.globalStore.isRunning = true;
                this.data.itemList.forEach(v => {
                    v.syncFromItemOptions();
                    v.itemOptions.readonly = false;
                });

                if (this.props.match.params.workID) {
                    const result = await this.store.processManufacture(this.globalStore.token);
                    if (result.data.type === 'apiErr') {
                        throw new PTSMessage({
                            message: result.data.resMessage,
                        });
                    }
                    alert('제출완료');
                    this.props.history.push(`/main/manufacture/list/process`);
                    window.location.reload();
                } else {
                    let data = this.data;
                    if (this.itemStore.packageOptions !== null) {
                        this.data.packageOption = {
                            platform: this.itemStore.packageOptions.platform,
                            adapter: this.itemStore.packageOptions.adapter,
                            lpep: this.itemStore.packageOptions.lpep,
                            indexType: this.itemStore.packageOptions.indexType,
                            tc1Enhancer: this.itemStore.packageOptions.tc1Enhancer,
                            tc2Enhancer: this.itemStore.packageOptions.tc2Enhancer,
                            probeState: this.itemStore.packageOptions.probeState,
                            selectedItemPreset: this.itemStore.packageOptions.selectedItemPreset,
                        }
                    }
                    const requestTrackData = new ManufactureTrackData();
                    const manufactureTrackData = new ManufactureTrackData();
                    if (data.isDeterminedReleaseDateSkipped) {
                        data.determinedReleaseDate = data.shippingRequestDate;
                        data.determinedReleaseAMPM = data.shippingRequestAMPM
                    }
                    data.type = ManufactureWork.TypeList.manufactureExpectShippingRequest;
                    data.stage = ManufactureWork.StageList.waitAccept;
                    requestTrackData.type = ManufactureWork.TypeList.manufactureRequest;
                    requestTrackData.stage = ManufactureWork.StageList.finish;
                    requestTrackData.startDate =
                        requestTrackData.endDate =
                        requestTrackData.receiptDate = dayjs(Date.now()).toDate();

                    manufactureTrackData.type = ManufactureWork.TypeList.manufactureExpectShippingRequest;
                    manufactureTrackData.stage = ManufactureWork.StageList.waitAccept;
                    manufactureTrackData.departType = ManufactureWork.DepartList.manufacturePart;

                    const result = await this.store.requestNewManufacture(data, this.globalStore.token);
                    if (result.data.type === 'apiErr') {
                        throw new PTSMessage({
                            message: result.data.resMessage,
                        });
                    }
                    await this.commentStore.addCommentsMany(result.data.data.workID);
                    alert(`등록 성공 ID: ${result.data.data.workID}`);
                    this.onFormClear();
                }
            }
        } catch (err) {
            if (err instanceof PTSMessage || err instanceof CommonError) {
                alert(err.message);
            }
            else {
                console.log(err);
                alert('오류 발생');
            }
            this.isValid = false;
        } finally {
            this.globalStore.isRunning = false;
        }
    }

    incomeDataToPresetOptions = () => {
        if (this.store.data.packageOption) {
            this.itemStore.presetItemOptions.platform = this.store.data.packageOption.platform;
            this.itemStore.presetItemOptions.adapterType = this.store.data.packageOption.adapter;
            this.itemStore.presetItemOptions.lpep = this.store.data.packageOption.lpep;
            this.itemStore.presetItemOptions.indexType = this.store.data.packageOption.indexType;
            this.itemStore.presetItemOptions.tc1Enhancer = this.store.data.packageOption.tc1Enhancer;
            this.itemStore.presetItemOptions.tc2Enhancer = this.store.data.packageOption.tc2Enhancer;
            this.itemStore.presetItemOptions.probeState = this.store.data.packageOption.probeState;
            this.itemStore.selectedItemPreset = this.store.data.packageOption.selectedItemPreset;
        }
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.store.data.prevWorkID
                    &&
                    <Typography variant='body2'>
                        이전 작업(복사된 작업) ID:&nbsp;
                        <Link to={`/main/manufacture/detail/${this.store.data.prevWorkID}`} target='_blank'>
                            {this.store.data.prevWorkID}
                        </Link>
                    </Typography>
                }
                <FindUserDialog
                    users={this.store.foundUsers}
                    onClose={this.store.onFindUserDiagClose}
                    open={this.store.findUserOpen}
                    onSearch={this.store.onFindUsers}
                    onSelect={(user) => { this.store.onSelectUser(user) }} />
                <FindManufactureDialog
                    works={this.store.foundWorks}
                    onClose={this.store.closeSearchWorkDialog}
                    onWorkSelect={async (workID) => {
                        this.itemStore.presetLoaded = false;
                        await this.store.onWorkSelect(workID);
                        this.itemStore.presetLoaded = true;
                    }}
                    onSearch={(txt) => this.store.onSearchWorks(txt)}
                    open={this.store.searchWorkDialogOpen}
                />
                <TitledPaper title='제조의뢰 정보'>
                    <Grid container>
                        <Grid item xl lg sm>
                            <Button
                                onClick={this.store.openFindUserDialog}
                                variant='contained'
                                color='primary'
                                style={{ marginTop: 30 }}>
                                담당자 검색
                            </Button>
                        </Grid>
                        <Grid item xl lg sm>
                            <Button
                                onClick={this.store.openSearchWorkDialog}
                                variant='contained'
                                color='primary'
                                style={{ marginTop: 30 }}>
                                Work 불러오기
                            </Button>
                        </Grid>
                    </Grid>
                    <ManufactureRequestInfoForm
                        data={this.data} />
                    <ManufactureCustomerRequestInfoComponent
                        data={this.store.data}
                    />
                </TitledPaper>
                <TitledPaper title='제조 아이템 목록'>
                    <ManufactureItemsForm
                        isReworkIndex={this.store.isReworkIndex}
                        panelList={this.store.panelList}
                        store={this.itemStore}
                        data={this.data} />
                </TitledPaper>
                <TitledPaper title='영업팀 NOTE'>
                    <ManufactureNoteForm
                        data={this.data} />
                </TitledPaper>
                <FormButtonSet
                    onSubmit={this.onFormSubmit}
                    onFormClear={this.onFormClear}
                    onValidate={this.onValidate}
                    validated={this.isValid}
                />
                <ReactToPrint
                    trigger={() =>
                        <Button
                            disabled={false}
                            color='primary'
                            variant='contained'
                            style={{ marginRight: 10 }}>
                            제조의뢰서 확인 및 출력
                        </Button>
                    }
                    content={() => this.componentRef}
                />
                <div style={{ display: 'none' }}>
                    <RequestPrintComponent data={this.data} ref={el => (this.componentRef = el)} />
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(ManufactureRequestMainContainer);