import { Button, TableCell, TableRow } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import SnopManufactureItem from '../../../../../data/model/snop/SnopManufactureItem';
import ISnopCell from './ISnopCell';

interface Props extends ISnopCell<SnopManufactureItem> {

}

const SnopManufactureItemCell: React.FC<Props> = (props) => {
    const data = props.data;

    return (
        <TableRow>
            <TableCell>
                {data.orderNumber}
            </TableCell>
            <TableCell>
                {data.RepName}
            </TableCell>
            <TableCell>
                {data.RequestDate}
            </TableCell>
            <TableCell>
                {data.projectCode}
            </TableCell>
            <TableCell>
                {data.customerAndPI}
            </TableCell>
            <TableCell>
                {`제조`}
            </TableCell>
            <TableCell>
                {data.WorkStage}
            </TableCell>
            <TableCell>
                {data.WorkStatus}
            </TableCell>
            <TableCell>
                {data.DeterminedReleaseDate}
            </TableCell>
            <TableCell>
                {data.ProductType}
            </TableCell>
            <TableCell>
                {data.ManufactureStartDate}
            </TableCell>
            <TableCell>
                {data.ManufactureEndDate}
            </TableCell>
            <TableCell>
                {data.FinalShippingRequestDate}
            </TableCell>
            <TableCell>
                {data.FinalDeliveryDate}
            </TableCell>
            <TableCell>
                <Link
                    style={{ textDecoration: 'none' }}
                    to={
                        {
                            pathname: `/main/manufacture/detail/${data.workID}`
                        }
                    }
                    target='_blank'
                >
                    <Button>
                        자세히
                    </Button>
                </Link>
            </TableCell>
        </TableRow>
    )
}

export default observer(SnopManufactureItemCell);