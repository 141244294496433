import { observable } from "mobx";
import Helper from "../../../module/Helper";
import Validatable from "../common/Validatable";

export default class Customer extends Validatable {
    @observable PI?: string;
    @observable representative?: string;
    @observable contact?: string;
    @observable email?: string;
    @observable code?: string;

    constructor() {
        super();
        this.PI = '';
        this.representative = '';
        this.contact = '';
        this.email = '';
        this.code = '';
    }

    // override
    validateSelf(): void {
        if (!this.PI) {
            this.setErrorTextByName('PI', Validatable.errorTexts.needValue);
        }
        if (this.representative && this.PI === this.representative){
            this.setErrorTextByName('representative', `고객 PI 와 중복됩니다.`);
        }
        if (this.contact) {
            if (!Helper.checkRegex(this.contact, /^(\d{10,11}|\d{2,3}-\d{3,4}-\d{4})$/)) {
                this.setErrorTextByName('contact', `${Validatable.errorTexts.badFormat} -> 포맷: ###-####-#### or -없이 숫자만`);
            }
        }
        if (this.email) {
            if (!Helper.checkRegex(this.email, /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/)) {
                this.setErrorTextByName('email', `${Validatable.errorTexts.badFormat} -> 포맷: xxx@xxxx.xxx`);
            }
        }
    }
}