import axios from "axios";
import { observable } from "mobx";
import TaskSubData from "../../../model/design/DesignTaskSubData";
import DesignWork from "../../../model/design/DesignWork";
import ProcessStore from "./ProcessStore"
import { words as RouteWords } from '../../../../resource/data/APIRoutes.json';
import PTSMessage from "../../../model/common/PTSMessage";

export default class RebalancingProcessStore<T extends TaskSubData> extends ProcessStore<T> {
    @observable prevWorkInfo: DesignWork;
    @observable prevWorkID: string;

    constructor() {
        super();
        this.prevWorkInfo = new DesignWork();
        this.prevWorkID = '';
    }

    getWholeWorkData = async (id: string, token: string) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        return axios.get(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.design}/${RouteWords.works}/${RouteWords.details}/${id}`,
            config
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        });
    }

    setPrevWorkInfo = async (id: string, token: string) => {
        try {
            const result = await this.getWholeWorkData(id, token);
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                })
            }
            else if (!result.data.data) {
                throw new PTSMessage({
                    message: '잘못된 데이터입니다.',
                })
            }
            else if (!result.data.data.tasks) {
                throw new PTSMessage({
                    message: '잘못된 데이터입니다.',
                })
            }
            else if (!Array.isArray(result.data.data.tasks)) {
                throw new PTSMessage({
                    message: '잘못된 데이터입니다.',
                })
            }
            else if (result.data.data.tasks.length < 1) {
                throw new PTSMessage({
                    message: '잘못된 데이터입니다.',
                })
            }
            this.prevWorkInfo.fromJS(result.data.data.tasks[0]);
            this.prevWorkID = result.data.data.workID;
        } catch (err) {
            throw err;
        }
    }
}