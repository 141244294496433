import React from 'react';
import { Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Checkbox, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core';
import { observer } from 'mobx-react';
import ManufactureWork from '../../../../../data/model/manufacture/ManufactureWork';
import ETSField from '../../../../common/component/ExtendedMUISTextfield';
import FormUtil from '../../../../../module/FormUtil';
import Helper from '../../../../../module/Helper';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateUtil from '@date-io/date-fns';

interface Props {
    data: ManufactureWork;
    readOnly?: boolean;
}

const ManufactureRequestInfoForm: React.FC<Props> = observer((props) => {
    const data = props.data;

    return (
        <React.Fragment>
            <Grid container direction='column'>
                <Grid container>
                    <Grid item xl lg md>
                        <ETSField
                            fullWidth
                            margin='normal'
                            rootdata={data}
                            value={data.representativeDepart}
                            name='representativeDepart'
                            readOnly={true}
                        />
                    </Grid>
                    <Grid item xl lg md>
                        <ETSField
                            fullWidth
                            margin='normal'
                            rootdata={data}
                            value={data.representativeName}
                            name='representativeName'
                            readOnly={true}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xl lg md>
                        <ETSField
                            fullWidth
                            margin='normal'
                            rootdata={data}
                            value={data.writerDepart}
                            name='writerDepart'
                            readOnly={true}
                        />
                    </Grid>
                    <Grid item xl lg md>
                        <ETSField
                            fullWidth
                            margin='normal'
                            rootdata={data}
                            value={data.writerName}
                            name='writerName'
                            readOnly={true}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xl lg md>
                        <ETSField
                            fullWidth
                            margin='normal'
                            rootdata={data}
                            value={data.erpCode}
                            name='erpCode'
                            readOnly={props.readOnly}
                        />
                    </Grid>
                    <Grid item xl lg md>
                        <ETSField
                            fullWidth
                            margin='normal'
                            rootdata={data}
                            value={data.projectCode}
                            name='projectCode'
                            readOnly={props.readOnly}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xl lg md>
                        <ETSField
                            fullWidth
                            margin='normal'
                            rootdata={data}
                            value={data.customerAndPI}
                            name='customerAndPI'
                            readOnly={props.readOnly}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xl lg md>
                        <FormControl
                            disabled={props.readOnly}
                            error={
                                Helper.getErrorState(data.errors.productDivision)
                            }
                            component='fieldset' margin='normal' fullWidth>
                            <FormLabel component='legend'>
                                {FormUtil.getFieldLabel('productDivision')}
                            </FormLabel>
                            <RadioGroup
                                value={
                                    data.productDivision
                                }
                                name='productDivision'
                                onChange={data.onChangeDefault}
                            >
                                {
                                    Object.keys(ManufactureWork.ProductDivisonList).map((v, i) => {
                                        return (
                                            <FormControlLabel
                                                key={i}
                                                value={v}
                                                control={<Radio color='secondary' />}
                                                label={(ManufactureWork.ProductDivisonList as any)[v]} />
                                        )
                                    })
                                }
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xl lg md>
                        <FormControl
                            disabled={props.readOnly}
                            error={
                                Helper.getErrorState(data.errors.manufacturePurpose)
                            }
                            component='fieldset' margin='normal' fullWidth>
                            <FormLabel component='legend'>
                                {FormUtil.getFieldLabel('manufacturePurpose')}
                            </FormLabel>
                            <RadioGroup
                                value={
                                    data.manufacturePurpose
                                }
                                name='manufacturePurpose'
                                onChange={data.onChangeDefault}
                            >
                                {
                                    Object.keys(ManufactureWork.ManufacturePurposeList).map((v, i) => {
                                        return (
                                            <FormControlLabel
                                                key={i}
                                                value={v}
                                                control={<Radio color='secondary' />}
                                                label={(ManufactureWork.ManufacturePurposeList as any)[v]} />
                                        )
                                    })
                                }
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xl lg sm>
                        <Grid item xl lg sm>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={data.coa}
                                        onChange={(e) => {
                                            data.coa = !data.coa
                                        }}
                                        name='coa' />
                                }
                                label='COA 여부' />
                        </Grid>
                    </Grid>
                    <Grid item xl lg sm>
                        <Grid item xl lg sm>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={data.productEducation}
                                        onChange={(e) => {
                                            data.productEducation = !data.productEducation
                                        }}
                                        name='productEducation' />
                                }
                                label='제품 사용 교육 요청' />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xl lg sm>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={data.isDeterminedReleaseDateSkipped}
                                    onChange={(e) => {
                                        data.isDeterminedReleaseDateSkipped = !data.isDeterminedReleaseDateSkipped
                                    }}
                                    name='isDeterminedReleaseDateSkipped' />
                            }
                            label='영업 예상출하일 입력 건너뛰기' />
                    </Grid>
                </Grid>
                {data.isDeterminedReleaseDateSkipped &&
                    <Grid container>
                        <Grid item xl lg sm>
                            <MuiPickersUtilsProvider utils={DateUtil}>
                                <FormControl margin='normal' fullWidth>
                                    <KeyboardDatePicker
                                        fullWidth
                                        variant='inline'
                                        format='yyyy/MM/dd'
                                        name='shippingRequestDate'
                                        label='출하 요청일'
                                        value={
                                            data.shippingRequestDate
                                        }
                                        onChange={(date) => { data.onChangeDateDefault('shippingRequestDate', date) }}
                                        helperText={
                                            Helper.getErrorText(
                                                data.errors.shippingRequestDate
                                            )
                                        }
                                        error={
                                            Helper.getErrorState(
                                                data.errors.shippingRequestDate
                                            )
                                        }
                                    />
                                </FormControl>
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xl lg sm>
                            <FormControl
                                error={Helper.getErrorState(data.errors.shippingRequestAMPM)}
                                fullWidth
                                style={{ width: 250 }}
                                margin='normal'>
                                <InputLabel id='shippingRequestAMPM'>
                                    AM/PM
                                </InputLabel>
                                <Select
                                    fullWidth
                                    labelId='shippingRequestAMPM'
                                    value={data.shippingRequestAMPM}
                                    onChange={(e: React.ChangeEvent<any>) => data.shippingRequestAMPM = e.target.value}
                                    name='shippingRequestAMPM'>
                                    {Object.keys(ManufactureWork.AMPMList).map((v, i) => (
                                        <MenuItem key={`ampm${i}`} value={v}>{(ManufactureWork.AMPMList as any)[v]}</MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{Helper.getErrorText(data.errors.shippingRequestAMPM)}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </React.Fragment>
    )
})

export default ManufactureRequestInfoForm;