/**
 * 2023년 10월
 * 신규 업데이트
 */

import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import SnopDesignItem from '../../../../../data/model/snop/SnopDesignItem';
import ISnopTable from './ISnopTable';
import NewSnopDesignItemCell from './NewSnopDesignItemCell';
import NewSnopDesignItem from '../../../../../data/model/snop/NewSnopDesignItem';

interface Props extends ISnopTable<NewSnopDesignItem> {

}

const NewSnopDesignTable: React.FC<Props> = (props) => {
    const datas: SnopDesignItem[] = props.datas || [];

    return (
        <React.Fragment>
            <Table stickyHeader>
                <TableHead>
                    <TableRow key='tableheader'>
                        <TableCell>
                            담당자
                        </TableCell>
                        <TableCell>
                            고객 및 PI
                        </TableCell>
                        <TableCell>
                            작업 종류
                        </TableCell>
                        <TableCell>
                            작업 현황
                        </TableCell>
                        <TableCell>
                            Design Name
                        </TableCell>
                        <TableCell>
                            Synthesis ID
                        </TableCell>
                        <TableCell>
                            Common name
                        </TableCell>
                        <TableCell>
                            Probe 개수
                        </TableCell>
                        <TableCell>
                            Design 종료 날짜
                        </TableCell>
                        <TableCell>
                            고객 Confirm 날짜
                        </TableCell>
                        <TableCell>
                            Chip distribution 날짜
                        </TableCell>
                        <TableCell>
                            합성진행(주문) 날짜
                        </TableCell>
                        <TableCell>

                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {datas.map((v, i) => {
                        return (
                            <NewSnopDesignItemCell data={v} key={`snopdesign${i}`} />
                        )
                    })}
                </TableBody>
            </Table>
        </React.Fragment>
    )
}

export default observer(NewSnopDesignTable);