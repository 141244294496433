import { observable } from "mobx";
import User from "../user/user";
import DesignModelBase, { IDesignModelBase } from "./DesignModelBase";

export interface IDesignWork extends IDesignModelBase {
    currTask?: string | object;
    projectCode?: string;
    erpCode?: string;
    data?: object;
    customerID?: string;
    customerAndPI?: string;
    customerInfo?: string | object;
    taskList?: object[];
    tasks?: any[];
    designName?: string;
    prevWorkID?: string;
    prevWorkOID?: string;
    workStatus?: string;
    probeID?: string;
    salesRepresentative?: string;
    salesRepresentativeObj?: User;
    mailingList?: string[];
    workType?: string;
}

class DesignWork extends DesignModelBase {
    @observable currTask?: string | object;
    @observable projectCode?: string;
    @observable erpCode?: string;
    @observable data?: object;
    @observable customerID?: string;
    @observable customerAndPI?: string;
    @observable customerInfo?: string | object;
    @observable taskList?: object[];
    @observable tasks?: any[];
    @observable designName?: string;
    @observable prevWorkID?: string;
    @observable prevWorkOID?: string;
    @observable workStatus?: string;
    @observable probeID?: string;
    @observable salesRepresentative?: string;
    @observable salesRepresentativeObj?: User;
    @observable mailingList?: string[] = [];
    @observable workType?: string;
}

export default DesignWork;