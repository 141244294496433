import React from 'react';
import { Grid, Button, TextField } from '@material-ui/core';
import { Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import { inject, observer } from 'mobx-react';
import { IRootStore } from '../../../data/store/RootStore';

const styles = (theme: Theme) => createStyles({
    buttons: {
        padding: 10,
        margin: 2,
    }
});

interface Props extends WithStyles<typeof styles>, IRootStore {
    clearValue: any;
    onInputClick: any;
    onRefresh: any;
}

@inject('rootStore')
@observer
class CommentInput extends React.Component<Props> {
    commentStore = this.props.rootStore.commentStore;
    globalStroe = this.props.rootStore.globalStore;

    constructor(props: Props) {
        super(props);
        this.commentStore.onChangeDefault = this.commentStore.onChangeDefault.bind(this);
    }

    onRefreshClick = () => {
        this.props.onRefresh(this.commentStore.workID);
    }

    render() {
        return (
            <Grid xs sm item container alignItems='stretch' style={{
                borderTop: '0px solid',
                background: '#F8F9F9',
            }}>
                <Grid xs={8} sm={8} lg={8} item container alignItems='stretch' style={{ padding: 6 }}>
                    <TextField
                        disabled={this.commentStore.isRunning}
                        style={{
                            margin: 0,
                            minWidth: 'auto',
                        }}
                        fullWidth
                        id='content'
                        name='currentContent'
                        label='Reply'
                        multiline
                        rows='6'
                        placeholder='댓글 입력'
                        margin='normal'
                        value={this.commentStore.currentContent}
                        onChange={this.commentStore.onChangeDefault}
                        variant='outlined' />
                </Grid>
                <Grid xs sm item container direction='column' alignItems='stretch'>
                    <Grid xs sm item container alignItems='stretch'>
                        <Button
                            onClick={this.props.onInputClick}
                            disabled={this.commentStore.isRunning}
                            className={this.props.classes.buttons}
                            fullWidth>
                            Send
                        </Button>
                        <Button
                            disabled={this.commentStore.isRunning}
                            className={this.props.classes.buttons}
                            fullWidth
                            onClick={this.props.clearValue}>
                            Clear
                        </Button>
                        <Button
                            disabled={this.commentStore.isRunning}
                            className={this.props.classes.buttons}
                            fullWidth
                            onClick={this.onRefreshClick}>
                            Refresh
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(CommentInput);