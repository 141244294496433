import { Grid, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import React from 'react';
import ManufactureItem from '../../../../../../data/model/manufacture/ManufactureItem';
import ManufactureWork from '../../../../../../data/model/manufacture/ManufactureWork';
import logo from '../../../../../../resource/img/celemics_logo.png';
// import Helper from '../../../../../../module/Helper';

interface Props {
    data?: ManufactureWork;
}

const tablePadding = 2;
const tableMarginTop = 20;

const marginTop = {
    marginTop: tableMarginTop,
}

const mainTable = {
    marginTop: tableMarginTop,
    borderCollapse: 'collapse' as 'collapse',
    width: '100%',
}

const tdItemKeyStyle = {
    background: '#e2e2e2',
    border: '1px solid',
    padding: tablePadding,
    textAlign: 'center' as 'center',
}

const tdItemValueStyle = {
    border: '1px solid',
    padding: tablePadding,
    textAlign: 'center' as 'center',
}

const tdPadding = {
    padding: tablePadding,
}

const trPageBreak = {
    pageBreakAfter: 'auto' as 'auto',
    pageBreakInside: 'avoid' as 'avoid',
}

const docNO = 'CLM-F701-06 (Rev. 5)'

const itemCell = (data: ManufactureItem, index: number) => {
    return (
        // <tr style={trPageBreak}>
        <>
            <td style={tdItemValueStyle}>
                {`${index + 1}`}
            </td>
            <td style={tdItemValueStyle}>
                {data.itemName}
            </td>
            <td style={{ ...tdItemValueStyle, borderRight: '0px solid' }}>
                {data.numberPerQty}
            </td>
            <td style={{ ...tdItemValueStyle, borderLeft: '0px solid' }}>
                {data.unit ? data.unit === 'rxn' ? 'Sample(s)' : data.unit === 'ml' && 'ml' : ''}
            </td>
            <td style={tdItemValueStyle}>
                {data.qty}
            </td>
            <td style={tdItemValueStyle}>
                {data.pooling}
            </td>
            <td style={tdItemValueStyle}>
                {data?.hybRxn ?? ''}
            </td>
            <td style={tdItemValueStyle}>
                {data.probeID}
            </td>
            <td style={tdItemValueStyle}>
                {data.remark}
            </td>
            <td style={tdItemValueStyle}>
                {data.panelName}
            </td>
            <td style={tdItemValueStyle}>
                {data.manipulatedCatNO ? data.manipulatedCatNO : ''}
            </td>
            <td style={tdItemValueStyle}>
                {data.lotNO}
            </td>
            <td style={tdItemValueStyle}>
                {data.expDate && dayjs(data.expDate).format('YYYY/MM')}
            </td>
            <td style={tdItemValueStyle}>
                {(ManufactureItem.StorageTempList as any)[data.storageTemp]
                    && (ManufactureItem.StorageTempList as any)[data.storageTemp]}
            </td>
            <td style={tdItemValueStyle}>
                {(ManufactureItem.ShippingCoditionList as any)[data.shippingCondition]
                    && (ManufactureItem.ShippingCoditionList as any)[data.shippingCondition]}
            </td>
            <td style={{
                ...tdItemValueStyle,
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                wordBreak: 'break-all',
                minWidth: 100,
            }}>
                {data.boxNO}
            </td>
        </>
        // </tr>
    )
}

@observer
class PrintManufactureShippingConfirmComponent extends React.Component<Props> {
    data = this.props.data;

    render() {
        return (
            <React.Fragment>
                <div style={{
                    breakInside: 'avoid',
                    breakAfter: 'auto',
                    width: '100%',
                }}>
                    <style type='text/css' media='print'>
                        {
                            `@page { margin: 1cm }
                            `
                        }
                    </style>
                    {/* Main container */}
                    <Grid
                        container
                        direction='column'>
                        {/* Logo, title */}
                        <Grid
                            container
                            alignContent='center'>
                            <Grid
                                container
                                item
                                xs
                                alignContent='center'>
                                <Grid item xs>
                                    <Typography variant='h4'>
                                        <strong>
                                            제품출하확인서
                                        </strong>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                item
                                xs
                                alignContent='flex-end'
                                direction='column'>
                                <Grid item xs>
                                    <img src={logo} alt='Logo here' style={{ height: 70 }} />
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* Logo, title end */}
                        {/* 문서번호 */}
                        <Grid container alignItems='flex-start'>
                            <Grid item>
                                <Typography variant='body2'>
                                    {`문서번호: ${docNO}`}
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* 문서번호 end */}
                        {/* 배송정보 테이블 */}
                        <Grid
                            container>
                            <table style={{ ...mainTable, borderTop: '4px solid', borderBottom: '4px solid', marginTop: 4 }}>
                                <colgroup>
                                    <col style={{ width: '20%' }} />
                                    <col style={{ width: '30%' }} />
                                    <col style={{ width: '20%' }} />
                                    <col style={{ width: '30%' }} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <td
                                            style={{
                                                padding: 3
                                            }}>
                                            <strong>
                                                배송지
                                            </strong>
                                        </td>
                                        <td
                                            style={{
                                                padding: 3
                                            }}>
                                            {this.data.customerAndPI}
                                        </td>
                                        <td
                                            style={{
                                                padding: 3
                                            }}>
                                            <strong>
                                                출하장소
                                            </strong>
                                        </td>
                                        <td
                                            style={{
                                                padding: 3
                                            }}>
                                            {this.data.representativeName}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            {`서울특별시 금천구 가산디지털로1로
                                            131 BYC하이시티 A동 20층
                                            셀레믹스`}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Grid>
                        {/* 배송정보 테이블 end */}
                        {/* 제품정보 */}
                        <Grid container>
                            <table style={{ ...mainTable, marginTop: tableMarginTop }}>
                                <colgroup>
                                    <col style={{ width: '50%' }} />
                                    <col style={{ width: '*%' }} />
                                    <col style={{ width: '*%' }} />
                                    <col style={{ width: '*%' }} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <td
                                            style={{
                                                padding: 3
                                            }}>
                                            <strong>
                                                {`Order Number: ${this.data.orderNumber}`}
                                            </strong>
                                        </td>
                                        <td
                                            style={{
                                                padding: 3
                                            }}>
                                        </td>
                                        <td
                                            style={{
                                                padding: 3
                                            }}>
                                        </td>
                                        <td
                                            style={{
                                                padding: 3
                                            }}>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table style={{ ...mainTable, marginTop: 0 }}>
                                <colgroup>
                                    <col style={{ width: '25%' }} />
                                    <col style={{ width: '25%' }} />
                                    <col style={{ width: '25%' }} />
                                    <col style={{ width: '25%' }} />
                                </colgroup>
                                <tr style={trPageBreak}>
                                    <td style={tdItemKeyStyle}>
                                        담당자 부서
                                    </td>
                                    <td style={tdItemValueStyle}>
                                        {this.data.representativeDepart}
                                    </td>
                                    <td
                                        style={tdItemKeyStyle}>
                                        담당자 이름
                                    </td>
                                    <td style={tdItemValueStyle}>
                                        {this.data.representativeName}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={tdItemKeyStyle}>
                                        작성자 부서
                                    </td>
                                    <td style={tdItemValueStyle}>
                                        {this.data.writerDepart}
                                    </td>
                                    <td style={tdItemKeyStyle}>
                                        작성자 이름
                                    </td>
                                    <td style={tdItemValueStyle}>
                                        {this.data.writerName}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={tdItemKeyStyle}>
                                        고객 코드
                                    </td>
                                    <td style={tdItemValueStyle}>
                                        {this.data.projectCode}
                                    </td>
                                    <td style={tdItemKeyStyle}>
                                        Project Code (ERP)
                                    </td>
                                    <td style={tdItemValueStyle}>
                                        {this.data.erpCode}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={tdItemKeyStyle}>
                                        고객사 & PI
                                    </td>
                                    <td style={tdItemValueStyle}>
                                        {this.data.customerAndPI}
                                    </td>
                                    <td style={tdItemKeyStyle}>
                                        목적
                                    </td>
                                    <td style={tdItemValueStyle}>
                                        {(ManufactureWork.ManufacturePurposeList as any)[this.data.manufacturePurpose]}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={tdItemKeyStyle}>
                                        제품 구분
                                    </td>
                                    <td style={tdItemValueStyle}>
                                        {(ManufactureWork.ProductDivisonList as any)[this.data.productDivision]}
                                    </td>
                                    <td style={tdItemKeyStyle}>
                                        출고 요청일시
                                    </td>
                                    <td style={tdItemValueStyle}>
                                        {`${dayjs(this.data.determinedReleaseDate).format('YYYY/MM/DD')} ${this.data.determinedReleaseAMPM === 'pm' ? '오후' : '오전'}`}
                                    </td>
                                </tr>
                            </table>
                            <table style={{
                                borderCollapse: 'collapse',
                                width: '100%',
                                marginTop: tableMarginTop,
                            }}>
                                <colgroup>
                                    <col style={{ width: '*%' }} />
                                    <col style={{ width: '*%' }} />
                                    <col style={{ width: '*%' }} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <td style={tdPadding}>
                                            <strong>
                                                {`Type : ${(ManufactureWork.ProductTypeList as any)[this.data.productType]}`}
                                            </strong>
                                        </td>
                                        <td style={tdPadding}>
                                            <strong>
                                                {`Index : ${(ManufactureWork.IndexList as any)[this.data.index]}`}
                                            </strong>
                                        </td>
                                        <td style={{ ...tdPadding }}>
                                            <strong>
                                                {`Probe State : ${(ManufactureWork.ProbeStateList as any)[this.data.probeState]}`}
                                            </strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {/* 제품 리스트 */}
                            <table
                                style={{ ...mainTable, marginTop: 0, width: '100%' }}>
                                <tbody>
                                    <tr>
                                        <td style={tdItemKeyStyle}>
                                            #
                                        </td>
                                        <td style={tdItemKeyStyle}>
                                            Items
                                        </td>
                                        <td style={tdItemKeyStyle} colSpan={2}>
                                            Unit
                                        </td>
                                        <td style={tdItemKeyStyle}>
                                            Q'ty
                                        </td>
                                        <td style={tdItemKeyStyle}>
                                            {`Multi
                                            plexing`}
                                        </td>
                                        <td style={tdItemKeyStyle}>
                                            Hyb-rxn
                                        </td>
                                        <td style={tdItemKeyStyle}>
                                            Probe ID
                                        </td>
                                        <td style={tdItemKeyStyle}>
                                            Remark
                                        </td>
                                        <td style={tdItemKeyStyle}>
                                            Panel Name
                                        </td>
                                        <td style={tdItemKeyStyle}>
                                            Cat No.
                                        </td>
                                        <td style={tdItemKeyStyle}>
                                            Lot No.
                                        </td>
                                        <td style={tdItemKeyStyle}>
                                            Exp. Date
                                        </td>
                                        <td style={tdItemKeyStyle}>
                                            Stor. temp
                                        </td>
                                        <td style={tdItemKeyStyle}>
                                            Ship. temp
                                        </td>
                                        <td style={tdItemKeyStyle}>
                                            Box No.
                                        </td>
                                    </tr>
                                    {/* {this.data.itemList.map((v, i) => {
                                        return itemCell(v, i);
                                    })} */}
                                    {this.data.itemList.map((v, i) => {
                                        return (
                                            <tr style={trPageBreak} key={i}>
                                                {itemCell(v, i)}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            {/* 제품리스트 end */}
                        </Grid>
                        {/* 제품정보 end */}
                        {/* 사인 */}
                        <Grid
                            item
                            style={{
                                ...marginTop,
                            }}
                        >
                            <table
                                style={{
                                    width: '90%',
                                    marginTop: 20,
                                }}>
                                <colgroup>
                                    <col style={{ width: '16%' }} />
                                    <col style={{ width: '16%' }} />
                                    <col style={{ width: '16.6%' }} />
                                    <col style={{ width: '16%' }} />
                                    <col style={{ width: '16%' }} />
                                </colgroup>
                                <tbody>
                                    <tr style={{ pageBreakInside: 'avoid', pageBreakAfter: 'auto' }}>
                                        <td
                                            style={{
                                                padding: 3,
                                                textAlign: 'right',
                                            }}>
                                            <strong>
                                                생산(출하)책임자
                                            </strong>
                                        </td>
                                        <td
                                            style={{
                                                padding: 3,
                                                borderBottom: '2px solid',
                                                textAlign: 'center',
                                            }}>

                                        </td>
                                        <td
                                            style={{
                                                padding: 3,
                                            }}>
                                        </td>
                                        <td
                                            style={{
                                                padding: 3,
                                                textAlign: 'right',
                                            }}>
                                            <strong>
                                                영업(배송)책임자
                                            </strong>
                                        </td>
                                        <td
                                            style={{
                                                padding: 3,
                                                borderBottom: '2px solid',
                                                textAlign: 'center',
                                            }}>
                                            {this.data.representativeName}
                                        </td>
                                    </tr>
                                    <tr style={{ pageBreakInside: 'avoid', pageBreakAfter: 'auto' }}>
                                        <td
                                            style={{
                                                padding: 3,
                                                textAlign: 'right',
                                            }}>
                                            <strong>
                                                출하일자
                                            </strong>
                                        </td>
                                        <td
                                            style={{
                                                padding: 3,
                                                borderBottom: '2px solid',
                                                textAlign: 'center',
                                            }}>
                                            {this.data.packagingDate &&
                                                dayjs(this.data.packagingDate).format('YYYY/MM/DD')}
                                        </td>
                                        <td
                                            style={{
                                                padding: 3,
                                            }}>
                                        </td>
                                        <td
                                            style={{
                                                padding: 3,
                                                textAlign: 'right',
                                            }}>
                                            <strong>
                                                배송일자
                                            </strong>
                                        </td>
                                        <td
                                            style={{
                                                padding: 3,
                                                borderBottom: '2px solid',
                                                textAlign: 'center',
                                            }}>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <hr style={{ border: '2px solid', marginTop: 40 }} />
                        </Grid>
                        {/* 사인 end */}
                    </Grid>
                    {/* Main container end */}
                </div>
            </React.Fragment>
        )
    }
}

export default PrintManufactureShippingConfirmComponent;