
import React, { useEffect, useState, useCallback } from 'react';
import { inject, observer } from 'mobx-react';
import {
    CssBaseline,
    Container,
    Grid,
    Typography,
    FormControl,
    Select,
    MenuItem,
    TextField,
    Button
} from '@material-ui/core';
import { observable } from 'mobx';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRootStore } from '../../../data/store/RootStore';
import StoreBase from '../../../data/store/StoreBase';
import User from '../../../data/model/user/user';
import TitledPaper from '../../common/component/TitledPaper';
import FormUtil from '../../../module/FormUtil';
import PTSMessage from '../../../data/model/common/PTSMessage';
import Depart from '../../../data/model/user/Depart';
import Axios from 'axios';
import { words as RouteWords } from '../../../resource/data/APIRoutes.json';

interface Props extends RouteComponentProps<any>, IRootStore {
}
class Store extends StoreBase {
    @observable selectedDepart?: Depart;
    @observable userInfo?: User;
    @observable departList?: Depart[];

    constructor() {
        super();
        this.selectedDepart = new Depart();
        this.userInfo = new User();
        this.departList = [];
    }

    getDepartList = async (token: string) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        return Axios.get(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.manage}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.departs}`,
            config,
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        });
    }

    setDepartList = async (token: string) => {
        try {
            const result = await this.getDepartList(token);
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                })
            }
            const list: Depart[] = [];
            for (const data of result.data.data) {
                const d = new Depart();
                d.fromJS(data);
                list.push(d);
            }
            this.departList = list;
        } catch (err) {
            throw err;
        }
    }

    requestCreateUser = async (data: User, token: string) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        return Axios.post(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.manage}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.users}`,
            data,
            config
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        });
    }
}

const store = new Store();

const AddUserPage: React.FC<Props> = inject('rootStore')(observer((props) => {
    const [pwConfirm, setPWConfirm] = useState('');
    const [pwValid, setPWValid] = useState(false);
    const globalStore = props.rootStore.globalStore;

    const pwReg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

    const getDepartList = useCallback(async () => {
        try {
            globalStore.isRunning = true;
            store.setDepartList(globalStore.token);
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
            else {
                alert('알수없는 오류발생');
            }
            props.history.push('/main');
            window.location.reload();
        } finally {
            globalStore.isRunning = false;
        }
    }, [globalStore, props.history]);

    useEffect(() => {
        if (store.departList.length > 0) {
            return;
        }
        getDepartList();
    }, [getDepartList]);


    const onDepartChange = (e: React.ChangeEvent<any>) => {
        const { value } = e.target;
        if (parseInt(value) === 0) {
            store.selectedDepart = new Depart();
        }
        else {
            const sel = store.departList.filter(x => x.code === parseInt(value));
            if (sel.length < 1) {
                store.selectedDepart = new Depart();
                return;
            }
            else {
                store.selectedDepart = Object.assign({}, sel[0]);
            }
        }
    }

    const onFormClear = () => {
        window.location.reload();
    }

    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (store.selectedDepart.code === 0) {
            alert('부서를 선택하세요.');
            return;
        }
        const mailDomain = store.userInfo.email.split('@')[1];
        if (mailDomain.toLowerCase() !== 'celemics.com') {
            alert('셀레믹스 메일 주소만 사용 가능합니다.');
            return;
        }
        if (!pwValid) {
            return;
        }
        try {
            globalStore.isRunning = true;
            const user = Object.assign({}, store.userInfo);
            user.depart = store.selectedDepart.name;
            user.departName = store.selectedDepart.name;
            user.departCode = store.selectedDepart.code;
            user.departType = store.selectedDepart.type;
            if (store.selectedDepart.type === 'Admin') {
                user.role = 'Admin';
            }
            user.activate = true;
            const result = await store.requestCreateUser(user, globalStore.token);
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                })
            }
            alert('등록 성공.');
            window.location.reload();
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
        } finally {
            globalStore.isRunning = false;
        }
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth='xl'>
                <Grid container justify='center'>
                    <Grid xl={9} lg={8} md={8} sm={8} item style={{
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-line',
                        padding: 5
                    }}>
                        <div>
                            <Typography variant='h4'>
                                사용자 등록
                            </Typography>
                            <hr />
                        </div>
                        <TitledPaper title='유저 정보'>
                            <form autoComplete='off' onSubmit={onFormSubmit}>
                                <Grid item xl lg sm style={{ marginTop: 20 }}>
                                    <FormControl id='selectDepart' fullWidth>
                                        부서
                                        <Select
                                            fullWidth
                                            margin='dense'
                                            labelId='selectDepart'
                                            onChange={onDepartChange}
                                            value={store.selectedDepart.code}
                                            name='selectedDepart'>
                                            <MenuItem value={0}>부서를 선택하세요.</MenuItem>
                                            {
                                                store.departList.map((v, i) => (
                                                    <MenuItem key={i} value={v.code}>{v.name}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        required
                                        fullWidth
                                        margin='dense'
                                        value={store.userInfo.name}
                                        name='name'
                                        label='이름'
                                        onChange={store.userInfo.onChangeDefault} />
                                    <TextField
                                        required
                                        fullWidth
                                        margin='dense'
                                        value={store.userInfo.email}
                                        name='email'
                                        type='email'
                                        label={FormUtil.getFieldLabel('email')}
                                        onChange={store.userInfo.onChangeDefault} />
                                    <TextField
                                        required
                                        fullWidth
                                        helperText='공백없이 숫자만 입력'
                                        margin='dense'
                                        value={store.userInfo.phone}
                                        name='phone'
                                        label={FormUtil.getFieldLabel('phone')}
                                        onChange={store.userInfo.onChangeDefault} />
                                    <TextField
                                        error={
                                            store.userInfo.pw
                                            && !pwReg.test(store.userInfo.pw)}
                                        helperText={
                                            store.userInfo.pw
                                            && !pwReg.test(store.userInfo.pw)
                                            && '영문, 숫자, 특수문자를 모두 사용하여 8자 이상 입력해주세요'
                                        }
                                        required
                                        fullWidth
                                        margin='dense'
                                        value={store.userInfo.pw}
                                        name='pw'
                                        type='password'
                                        label={FormUtil.getFieldLabel('pw')}
                                        onChange={
                                            (e) => {
                                                store.userInfo.onChangeDefault(e);
                                                pwConfirm === e.target.value ? setPWValid(true) : setPWValid(false)
                                            }
                                        } />
                                    <TextField
                                        error={!pwValid && pwConfirm !== '' && store.userInfo.pw !== ''}
                                        helperText={
                                            !pwValid
                                            && pwConfirm !== ''
                                            && store.userInfo.pw !== ''
                                            && '입력한 패스워드와 확인 패스워드가 일치하지 않습니다.'
                                        }
                                        required
                                        fullWidth
                                        type='password'
                                        margin='dense'
                                        value={pwConfirm}
                                        name='pwConfirm'
                                        label={FormUtil.getFieldLabel('pwConfirm')}
                                        onChange={(e) => {
                                            setPWConfirm(e.target.value);
                                            store.userInfo.pw === e.target.value ? setPWValid(true) : setPWValid(false)
                                        }} />
                                </Grid>
                                <Grid container alignItems='flex-end' direction='column' style={{ marginTop: 20 }}>
                                    <Grid item xl lg sm>
                                        <Button
                                            type='submit'
                                            variant='contained'
                                            color='primary'
                                            style={{ marginRight: 10 }}>
                                            생성
                                        </Button>
                                        <Button
                                            onClick={onFormClear}
                                            variant='contained'
                                            color='secondary'>
                                            비우기
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </TitledPaper>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    )
}))

export default withRouter(AddUserPage);