import React from 'react';
import { Divider, Paper, TextField, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import ManufactureWork from '../../../../../data/model/manufacture/ManufactureWork';

interface Props {
    data: ManufactureWork;
    readOnly?: boolean;
    type: string;
}

const ManufactureNoteListForm: React.FC<Props> = observer((props) => {
    const data = props.data;
    const type = props.type || '';
    const readOnly = props.readOnly || false;

    const setTypeText = (type: string) => {
        switch (type) {
            case ManufactureWork.TypeList.manufactureExpectShippingRequest:
                return '#2. 제조 예상 출하 가능일 입력';
            case ManufactureWork.TypeList.manufactureManufacture:
                return '#4. 제조';
            case ManufactureWork.TypeList.manufacturePackaging:
                return '#6. 포장';
            default:
                return '';
        }
    }

    const setIndexText = (index: number) => {
        switch (index) {
            case 0:
                return '#2. 제조 예상 출하 가능일 입력';
            case 1:
                return '#4. 제조';
            case 2:
                return '#6. 포장';
            default:
                return '';
        }
    }

    return (
        <React.Fragment>
            {
                // NoteList가 없으면 Paper 컴포넌트가 필요 없으므로 삼항 연산자로 구분
                Array.isArray(data.manufactureNoteList) && data.manufactureNoteList.length
                    && !data.manufactureNoteList.every((v) => v === '') ?
                    <Paper style={{
                        padding: 20, paddingBottom: 30, margin: 3, marginBottom: 6, backgroundColor: '#FAFAFA'
                    }} >
                        {
                            data.manufactureNoteList.map((v, i) => {
                                if (data.manufactureNoteList[i] === '') {
                                    return (
                                        <div key={i}></div>
                                    )
                                }
                                return (
                                    <div key={i}>
                                        <Typography style={{ marginBottom: 5, fontSize: '1rem' }} >
                                            {setIndexText(i)}
                                        </Typography>
                                        <Divider />
                                        <TextField
                                            style={{ marginBottom: 10 }}
                                            fullWidth
                                            multiline
                                            rows={5}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                            value={v}
                                        />
                                    </div>
                                )
                            })
                        }
                        {
                            // 제조 파트의 프로세스 작업화면 입력란 추가
                            !readOnly &&
                            (type === ManufactureWork.TypeList.manufactureExpectShippingRequest
                                || type === ManufactureWork.TypeList.manufactureManufacture
                                || type === ManufactureWork.TypeList.manufacturePackaging) &&
                            <>
                                <Typography style={{ marginBottom: 5, fontSize: '1rem' }} >
                                    {setTypeText(type)}
                                </Typography>
                                <Divider />
                                <TextField
                                    style={{ marginBottom: 10 }}
                                    fullWidth
                                    multiline
                                    rows={5}
                                    InputProps={{
                                        readOnly: false
                                    }}
                                    value={data.manufactureNoteText}
                                    onChange={data.onChangeDefault}
                                    name={`manufactureNoteText`}
                                />
                            </>

                        }
                    </Paper>
                    :
                    // 제조 파트의 프로세스 작업화면 입력란 추가 / 그 외 빈공간 출력
                    !readOnly &&
                        (type === ManufactureWork.TypeList.manufactureExpectShippingRequest
                            || type === ManufactureWork.TypeList.manufactureManufacture
                            || type === ManufactureWork.TypeList.manufacturePackaging) ?
                        <Paper style={{
                            padding: 20, paddingBottom: 30, margin: 3, marginBottom: 6, backgroundColor: '#FAFAFA'
                        }} >
                            <Typography style={{ marginBottom: 5, fontSize: '1rem' }} >
                                {setTypeText(type)}
                            </Typography>
                            <Divider />
                            <TextField
                                style={{ marginBottom: 10 }}
                                fullWidth
                                multiline
                                rows={5}
                                InputProps={{
                                    readOnly: false
                                }}
                                value={data.manufactureNoteText}
                                onChange={data.onChangeDefault}
                                name={`manufactureNoteText`}
                            />
                        </Paper>
                        :
                        <TextField
                            fullWidth
                            multiline
                            rows={10}
                            InputProps={{
                                readOnly: true
                            }}
                        />
            }
        </React.Fragment >
    )
})

export default ManufactureNoteListForm;