import React from 'react';
import { observer, inject } from 'mobx-react';
import { Grid, MenuItem, Paper, Select } from '@material-ui/core';
import ManufactureListTable from '../component/ManufactureListTable';
import ManufactureSearchFilterForm from '../component/ManufactureSearchFilterForm';
import { IRootStore } from '../../../../../data/store/RootStore';
import ManufactureListItem from '../../../../../data/model/manufacture/ManufactureListItem';
import { CommonError } from '../../../../../data/model/common/CommonErrors';
import ManufactureWorkListStore from '../../../../../data/store/pageStore/manufacture/ManufactureWorkListStore';
import ManufactureWork from '../../../../../data/model/manufacture/ManufactureWork';
import PTSPagination from '../../../../common/component/task/table/PTSPagination';
import PTSMessage from '../../../../../data/model/common/PTSMessage';
import WorkInfo from '../../../../../resource/data/works.json';
import { action } from 'mobx';

interface Props extends IRootStore {
    departType: string;
    stage: string;
}

const translate = (str: string) => {
    return (ManufactureListItem.Translations as any)[str];
}

@inject('rootStore')
@observer
class ManufactureListTableContainer extends React.Component<Props> {
    store = new ManufactureWorkListStore();

    globalStore = this.props.rootStore.globalStore;

    init = () => {
        this.setHeaders();
        this.store.stage = this.props.stage;
        this.store.departType = this.props.departType;
        this.store.setModeByStage();
        this.globalStore.currentTaskTitle = this.getTaskTitle();
        this.globalStore.useComment = false;
    }

    getTaskTitle = () => {
        switch (this.store.mode) {
            case ManufactureWorkListStore.ModeList.accept:
                return '제조 접수대기 목록';
            case ManufactureWorkListStore.ModeList.process:
                return '제조 작업대기 목록';
            case ManufactureWorkListStore.ModeList.search:
                return '제조 Work 목록';
            // case ManufactureWorkListStore.ModeList.shipped:
            //     return '예상 출하일 / 출하 요청일 변경 목록';
            // case ManufactureWorkListStore.ModeList.cancelShipping:
            //     return '제조 출하일 변경 요청 목록';
            // case ManufactureWorkListStore.ModeList.changeShipping:
            //     return '예상 출하일 / 출하 요청일 변경 요청 목록';
            default:
                return 'Unknown';
        }
    }

    setHeaders = () => {
        while (this.store.headers.length > 0) {
            this.store.headers.pop();
        }
        this.store.headers.push('orderNumber');
        this.store.headers.push('erpCode');
        this.store.headers.push('projectCode');
        this.store.headers.push('productType');
        this.store.headers.push('representativeName');
        this.store.headers.push('customerAndPI');
        this.store.headers.push('determinedReleaseDate');
        this.store.headers.push('shippingRequestDate');
        this.store.headers.push('coa');
    }

    componentDidMount = async () => {
        this.init();
        this.globalStore.useComment = false;
        await this.store.setManufactureListData(this.getFilter(), this.globalStore.token);
    }

    onPageChange = async (e: any, offset: number) => {
        this.store.offset = offset;
        await this.getDatas();
    }

    // data fetch
    getDatas = async () => {
        try {
            await this.store.setManufactureListData(this.getFilter(), this.globalStore.token);
        } catch (err) {
            if (err instanceof CommonError || err instanceof PTSMessage) {
                alert(err.message);
            }
            else {
                alert('오류 발생');
            }
        }
    }

    // build filter (aggregate)
    getFilter = (): object => {
        const filter = [];
        let match = {};

        if (this.globalStore.userInfo.role.toLocaleLowerCase() === 'admin') {
            if (this.store.mode === ManufactureWorkListStore.ModeList.accept) {
                match = {
                    $match: {
                        workStatus: WorkInfo.status.running,
                        'current.stage': ManufactureWork.StageList.waitAccept,
                    }
                };
            }
            else if (this.store.mode === ManufactureWorkListStore.ModeList.process) {
                match = {
                    $match: {
                        workStatus: WorkInfo.status.running,
                        'current.stage': ManufactureWork.StageList.processing,
                    }
                };
            }
            ////출하 취소 목록 (admin)
            // else if (this.store.mode === ManufactureWorkListStore.ModeList.shipped) {
            //     match = {
            //         $match: {
            //             workStatus: WorkInfo.status.running,
            //             $or: [
            //                 { 'current.workType': ManufactureWork.TypeList.manufactureDelivery },
            //                 { 'current.workType': ManufactureWork.TypeList.manufacturePackaging },
            //             ]
            //         }
            //     }
            // }
            ////출하 취소 요청 목록 (admin)
            // else if (this.store.mode === ManufactureWorkListStore.ModeList.cancelShipping) {
            //     match = {
            //         $match: {
            //             workStatus: WorkInfo.status.running,
            //             'current.workType': ManufactureWork.TypeList.manufactureCancelShipping,
            //         }
            //     }
            // }
            // else if (this.store.mode === ManufactureWorkListStore.ModeList.shipped) {
            //     match = {
            //         $match: {
            //             workStatus: WorkInfo.status.running,
            //             $or: [
            //                 { 'current.workType': ManufactureWork.TypeList.manufactureManufacture },
            //                 { 'current.workType': ManufactureWork.TypeList.manufactureDelivery },
            //                 { 'current.workType': ManufactureWork.TypeList.manufacturePackaging },
            //             ]
            //         }
            //     }
            // }
            // else if (this.store.mode === ManufactureWorkListStore.ModeList.changeShipping) {
            //     match = {
            //         $match: {
            //             workStatus: WorkInfo.status.running,
            //             $or: [
            //                 { 'current.workType': ManufactureWork.TypeList.manufactureChangeExpectShipping },
            //                 { 'current.workType': ManufactureWork.TypeList.manufactureChangeShipping },
            //             ]

            //         }
            //     }
            // }
            else if (this.store.mode === ManufactureWorkListStore.ModeList.search) {
                if (this.store.selectedStatus === WorkInfo.status.all) {
                    match = {
                        $match: {
                        }
                    };
                }
                else {
                    match = {
                        $match: {
                            workStatus: this.store.selectedStatus,
                        }
                    };
                }
            }
            else {
                match = {
                    $match: {
                        unknown: 'Unknown'
                    }
                };
            }
        }
        else {
            if (this.store.mode === ManufactureWorkListStore.ModeList.accept) {
                match = {
                    $match: {
                        workStatus: WorkInfo.status.running,
                        'current.stage': ManufactureWork.StageList.waitAccept,
                        'current.departType': this.globalStore.userInfo.departType,
                    }
                };
            }
            else if (this.store.mode === ManufactureWorkListStore.ModeList.process) {
                match = {
                    $match: {
                        workStatus: WorkInfo.status.running,
                        'current.stage': ManufactureWork.StageList.processing,
                        'current.departType': this.globalStore.userInfo.departType,
                    }
                };
            }
            //// 출하 취소 목록 (영업))
            // else if (this.store.mode === ManufactureWorkListStore.ModeList.shipped) {
            //     if (this.globalStore.userInfo.departType === 'sales') {
            //         match = {
            //             $match: {
            //                 workStatus: WorkInfo.status.running,
            //                 'current.workType': ManufactureWork.TypeList.manufactureDelivery,
            //             }
            //         }
            //     }
            //     else {
            //         match = {
            //             $match: {
            //                 unknown: 'Unknown',
            //             }
            //         }
            //     }
            // }
            //// 출하 취소 요청 목록 (제조)
            // else if (this.store.mode === ManufactureWorkListStore.ModeList.cancelShipping) {
            //     if (this.globalStore.userInfo.departType === ManufactureWork.DepartList.manufacturePart) {
            //         match = {
            //             $match: {
            //                 workStatus: WorkInfo.status.running,
            //                 'current.workType': ManufactureWork.TypeList.manufactureCancelShipping,
            //             }
            //         }
            //     }
            //     else {
            //         match = {
            //             $match: {
            //                 unknown: 'Unknown',
            //             }
            //         }
            //     }
            // }
            //// 출하 취소 요청 목록 (영업)
            // else if (this.store.mode === ManufactureWorkListStore.ModeList.cancelShipping) {
            //     if (this.globalStore.userInfo.departType === 'sales') {
            //         match = {
            //             $match: {
            //                 workStatus: WorkInfo.status.running,
            //                 'current.workType': ManufactureWork.TypeList.manufactureCancelShipping,
            //             }
            //         }
            //     }
            //     else {
            //         match = {
            //             $match: {
            //                 unknown: 'Unknown',
            //             }
            //         }
            //     }
            // }
            // else if (this.store.mode === ManufactureWorkListStore.ModeList.shipped) {
            //     if (this.globalStore.userInfo.departType === 'sales') {
            //         match = {
            //             $match: {
            //                 workStatus: WorkInfo.status.running,
            //                 $or: [
            //                     { 'current.workType': ManufactureWork.TypeList.manufactureManufacture },
            //                     { 'current.workType': ManufactureWork.TypeList.manufacturePackaging },
            //                     { 'current.workType': ManufactureWork.TypeList.manufactureDelivery },
            //                 ]
            //             }
            //         }
            //     }
            //     else {
            //         match = {
            //             $match: {
            //                 unknown: 'Unknown',
            //             }
            //         }
            //     }
            // }
            // else if (this.store.mode === ManufactureWorkListStore.ModeList.changeShipping) {
            //     if (this.globalStore.userInfo.departType === ManufactureWork.DepartList.manufacturePart
            //         || this.globalStore.userInfo.departType === 'sales') {
            //         match = {
            //             $match: {
            //                 workStatus: WorkInfo.status.running,
            //                 $or: [
            //                     { 'current.workType': ManufactureWork.TypeList.manufactureChangeExpectShipping },
            //                     { 'current.workType': ManufactureWork.TypeList.manufactureChangeShipping },
            //                 ]
            //             }
            //         }
            //     }
            //     else {
            //         match = {
            //             $match: {
            //                 unknown: 'Unknown',
            //             }
            //         }
            //     }
            // }
            else if (this.store.mode === ManufactureWorkListStore.ModeList.search) {
                if (this.store.selectedStatus === WorkInfo.status.all) {
                    match = {
                        $match: {
                        }
                    };
                }
                else {
                    match = {
                        $match: {
                            workStatus: this.store.selectedStatus,
                        }
                    };
                }
            }
            else {
                match = {
                    $match: {
                        unknown: 'Unknown'
                    }
                }
            }
        }
        if (this.store.selectedField) {
            (match as any).$match[`searchField.${this.store.selectedField}`] = {
                $regex: `^.*${this.store.filterText}.*$`,
                $options: 'i',
            }
        }
        filter.push(match);
        filter.push({
            $sort: {
                workID: -1,
            }
        });
        filter.push({
            $skip: this.store.offset,
        });
        filter.push({
            $limit: this.store.limit,
        });
        filter.push({
            $lookup: {
                from: 'manufactureworks',
                localField: 'workID',
                foreignField: 'workID',
                as: 'manufactureWork',
            }
        });
        filter.push({
            $unwind: '$manufactureWork',
        });
        // if (this.store.mode === ManufactureWorkListStore.ModeList.shipped) {
        //     filter.push({
        //         $match: {
        //             'manufactureWork.changeRequestHistory.0.status': { $nin: [WorkInfo.status.running] }
        //         }
        //     });
        // } else if (this.store.mode === ManufactureWorkListStore.ModeList.changeShipping) {
        //     filter.push({
        //         $match: {
        //             'manufactureWork.changeRequestHistory.0.status': WorkInfo.status.running,
        //         }
        //     })
        // }
        filter.push({
            $lookup: {
                from: 'users',
                localField: 'manufactureWork.salesRepresentativeObj.userID',
                foreignField: 'userID',
                as: 'repInfo',
            }
        });
        filter.push({
            $unwind: '$repInfo',
        });
        filter.push({
            $project: {
                trackList: 0,
                'manufactureWork.itemList': 0,
                'repInfo._id': 0,
                'repInfo.version': 0,
                'repInfo.phone': 0,
                'repInfo.activate': 0,
                'repInfo.code': 0,
                'repInfo.pw': 0,
                'repInfo.role': 0,
            }
        });
        return filter;
    }

    // send data(form submit)
    receiptWork = async (workID: string) => {
        try {
            if (window.confirm('접수하시겠습니까?')) {
                this.globalStore.isRunning = true;
                const arr: string[] = [];
                arr.push(workID);
                const result = await this.store.requestAcceptWorks(arr, this.globalStore.token);
                if (result.data.type === 'apiErr') {
                    throw new PTSMessage({
                        message: result.data.resMessage,
                    })
                }
                alert('접수 완료');
                this.getDatas();
            }
        } catch (err) {
            if (err instanceof CommonError || err instanceof PTSMessage) {
                alert(err.message);
            }
            else {
                alert('오류 발생');
            }
        } finally {
            this.globalStore.isRunning = false;
        }
    }

    receiptWorks = async () => {
        try {
            this.globalStore.isRunning = true;
            const selectedWorkIDs: string[] = this.store.workList.filter((v) => {
                return v.checked;
            }).map((v) => {
                return v.workID;
            });
            if (selectedWorkIDs.length < 1) {
                alert('선택된 항목이 없습니다.');
                return;
            }
            if (window.confirm('선택 항목 접수 하시겠습니까?')) {
                const result = await this.store.requestAcceptWorks(selectedWorkIDs, this.globalStore.token);
                if (result.data.type === 'apiErr') {
                    throw new PTSMessage({
                        message: result.data.resMessage,
                    })
                }
                alert('접수 완료');
                this.getDatas();
            }
        } catch (err) {
            if (err instanceof CommonError || err instanceof PTSMessage) {
                alert(err.message);
            }
            else {
                alert('오류 발생');
            }
        } finally {
            this.globalStore.isRunning = false;
        }
    }

    onSearch = async (e: any) => {
        this.store.offset = 0;
        await this.getDatas();
    }

    onClear = async (e: any) => {
        this.store.offset = 0;
        this.store.onFilterClear();
        await this.getDatas();
    }

    // onCancelShipping = async (workID: string) => {
    //     try {
    //         if (this.globalStore.userInfo.departType !== ManufactureWork.DepartList.manufacturePart
    //             && this.globalStore.userInfo.role.toLocaleLowerCase() !== 'admin') {
    //             throw new PTSMessage({
    //                 message: '권한이 없습니다.',
    //             })
    //         }
    //         if (!window.confirm('출하를 취소합니까?')) {
    //             return;
    //         }
    //         this.globalStore.isRunning = true;
    //         const result = await this.store.cancelShipping(workID, this.globalStore.token);
    //         if (result.data.type === 'apiErr') {
    //             throw new PTSMessage({
    //                 message: result.data.resMessage,
    //             });
    //         }

    //         alert('취소완료.');
    //         await this.getDatas();

    //     } catch (err) {
    //         if (err instanceof CommonError || err instanceof PTSMessage) {
    //             alert(err.message);
    //         }
    //         else {
    //             alert('오류 발생');
    //         }
    //     }
    //     finally {
    //         this.globalStore.isRunning = false;
    //     }
    // }

    // onChangeShipping = async (workID: string, approve: string, refuseReason?: string) => {
    //     try {
    //         if (this.globalStore.userInfo.departType !== ManufactureWork.DepartList.manufacturePart
    //             // && this.globalStore.userInfo.departType !== 'sales'
    //             && this.globalStore.userInfo.role.toLocaleLowerCase() !== 'admin') {
    //             throw new PTSMessage({
    //                 message: '권한이 없습니다.',
    //             })
    //         }

    //         if (approve === 'Y') {
    //             if (!window.confirm('변경 요청을 승인합니까?')) {
    //                 return
    //             };
    //         } else if (approve === 'N') {
    //             if (!window.confirm('변경 요청을 거부합니까?')) {
    //                 return
    //             };
    //         } else {
    //             return;
    //         }

    //         // if (!window.confirm('변경 요청을 승인합니까?')) {
    //         //     return;
    //         // }

    //         this.globalStore.isRunning = true;
    //         const result = await this.store.changeShipping(workID, approve, refuseReason, this.globalStore.token)
    //         if (result.data.type === 'apiErr') {
    //             throw new PTSMessage({
    //                 message: result.data.resMessage,
    //             });
    //         }

    //         alert('완료');
    //         // await this.getDatas();
    //         window.location.reload();

    //     } catch (err) {
    //         if (err instanceof CommonError || err instanceof PTSMessage) {
    //             alert(err.message);
    //         }
    //         else {
    //             alert('오류 발생');
    //         }
    //     }
    //     finally {
    //         this.globalStore.isRunning = false;
    //     }
    // }

    @action
    handleStatusChange = async (e: React.ChangeEvent<any>) => {
        this.store.offset = 0;
        this.store.selectedField = '';
        this.store.filterText = '';
        this.store.onChangeDefault(e);
        await this.store.setManufactureListData(this.getFilter(), this.globalStore.token);
    }

    render() {
        return (
            <React.Fragment>
                <Grid
                    xl
                    lg
                    sm
                    spacing={1}
                    container
                    item
                    direction='column'
                    alignItems='stretch'
                    style={{ marginTop: 4 }}>
                    <Paper style={{ padding: 30 }}>
                        <Grid item xl lg sm container direction='column'>
                            <Grid
                                xl
                                lg
                                sm
                                item
                                container
                                direction='column'>
                                <Grid container item alignContent='flex-start' style={{ margin: 10 }}>
                                    {
                                        this.store.mode === ManufactureWorkListStore.ModeList.search
                                        &&
                                        <Select
                                            style={{ minWidth: 200, marginRight: 8 }}
                                            labelId='selectedStatus'
                                            onChange={this.handleStatusChange}
                                            value={this.store.selectedStatus}
                                            name='selectedStatus'>
                                            <MenuItem value={WorkInfo.status.all}>All</MenuItem>
                                            <MenuItem value={WorkInfo.status.running}>진행중</MenuItem>
                                            <MenuItem value={WorkInfo.status.paused}>중단됨</MenuItem>
                                            <MenuItem value={WorkInfo.status.finished}>완료됨</MenuItem>
                                        </Select>
                                    }
                                    <ManufactureSearchFilterForm
                                        onSearch={this.onSearch}
                                        onFilterChange={this.store.onFilterChange}
                                        onFilterClear={this.onClear}
                                        filterText={this.store.filterText}
                                        selectedField={this.store.selectedField} />
                                </Grid>
                                <Grid container item alignContent='flex-end' direction='column'>
                                    <PTSPagination
                                        limit={this.store.limit}
                                        offset={this.store.offset}
                                        total={this.store.total}
                                        onClick={this.onPageChange} />
                                </Grid>
                            </Grid>
                            <Grid xl lg sm item>
                                <ManufactureListTable
                                    receiptHandler={this.receiptWork}
                                    offset={this.store.offset}
                                    translation={translate}
                                    headers={this.store.headers}
                                    useCheckBox={this.store.mode === ManufactureWorkListStore.ModeList.accept ? true : false}
                                    datas={this.store.workList}
                                    mode={this.store.mode}
                                    numSelected={this.store.selectedNum}
                                    allSelected={this.store.allSelected}
                                    perPage={this.store.limit}
                                    receiptHeaderHandler={this.receiptWorks} />
                            </Grid>
                            <Grid
                                style={{ padding: 5, margin: 20 }}
                                xl lg
                                sm
                                item
                                container
                                alignItems='flex-end'
                                direction='column'>
                                <Grid item xl lg sm>
                                    <PTSPagination
                                        limit={this.store.limit}
                                        offset={this.store.offset}
                                        total={this.store.total}
                                        onClick={this.onPageChange} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </React.Fragment>
        )
    }
}

export default ManufactureListTableContainer;