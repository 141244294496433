import { observable } from "mobx";
import ModelBase from "../common/ModelBase";

export interface IManufactureTrackData {
    receiptDate: Date;
    endDate: Date;
    startDate: Date;
    type: string;
    stage: string;
    departType: string;
    data: object;
    workID: number;
}

export default class ManufactureTrackData extends ModelBase {
    @observable receiptDate?: Date;
    @observable endDate?: Date;
    @observable startDate?: Date;
    @observable type?: string;
    @observable stage?: string;
    @observable departType?: string;
    @observable updatedDate?: Date;
    @observable createdDate?: Date;
    @observable data?: object;

    @observable selected: boolean = false;

    constructor() {
        super();
        this.data = {};
    }

    setDatas = (data: IManufactureTrackData) => {
        Object.assign(this, data);
    }
}