import { observable } from 'mobx';
import TaskSubData from '../../DesignTaskSubData';

export default class RebalancingInfo extends TaskSubData {
    @observable writer?: string;
    @observable requestDate?: Date;
    @observable dueDate?: Date;
    @observable isRebalancing?: boolean;
    @observable isAddPanelRegion?: boolean;
    @observable rebalancingNote?: string;
    @observable addPanelRegionNote?: string;

    constructor() {
        super();
        this.isRebalancing = false;
        this.isAddPanelRegion = false;
        this.requestDate = new Date();
        this.dueDate = new Date();
    }

    // override
    validateSelf(): void {
        return;
    }
}