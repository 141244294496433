import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, TextField } from "@material-ui/core";
import ManufactureWork from "../../../../../data/model/manufacture/ManufactureWork";
import { useState } from "react";
import { Search } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import Helper from "../../../../../module/Helper";

interface FindManufactureDialogProps {
    onSearch: (txt: string) => void;
    onClose: () => void;
    onWorkSelect: (workID: string) => void;
    works: ManufactureWork[];
    open: boolean;
}

const FindManufactureDialog: React.FC<FindManufactureDialogProps> = (props) => {
    const works = props.works ?? [];
    const [searchText, setSearchText] = useState('');

    const onCloseDialog = () => {
        setSearchText('');
        props.onClose();
    }

    const foundWorkCell = (work: ManufactureWork, index: number) => {
        return (
            <tr key={index}>
                <td style={{ width: 200 }}>
                    {work.erpCode}
                </td>
                <td style={{ width: 200 }}>
                    {work.orderNumber}
                </td>
                <td style={{ width: 150 }}>
                    {work.salesRepresentativeObj.name}
                </td>
                <td style={{ width: 300 }}>
                    {work.customerAndPI}
                </td>
                <td style={{ width: 300 }}>
                    {`${Helper.taskFieldToString(work.type)} ${Helper.fieldToString(work.stage)}`}
                </td>
                <td>
                    <Button variant='outlined' onClick={() => { props.onWorkSelect(work.workID); onCloseDialog(); }}>선택</Button>
                </td>
            </tr>
        )
    }

    return (
        <>
            <Dialog open={props.open} onClose={onCloseDialog} fullWidth maxWidth='lg'>
                <DialogTitle>
                    제조 Work 검색
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Project Code (ERP) 로 검색
                    </DialogContentText>
                    <FormControl>
                        <Grid container>
                            <Grid item xl lg sm md>
                                <TextField
                                    value={searchText}
                                    onChange={(e) => { setSearchText(e.target.value) }}
                                    onKeyUp={
                                        (e) => {
                                            if (e.key === 'Enter') {
                                                props.onSearch(searchText)
                                            }
                                        }
                                    }
                                />
                                <Search onClick={() => props.onSearch(searchText)} />
                            </Grid>
                        </Grid>
                    </FormControl>
                    <table style={{ width: '100%', marginTop: 20 }}>
                        <tbody>
                            <tr>
                                <td style={{ width: 200 }}>
                                    <strong>
                                        Project Code (ERP)
                                    </strong>
                                </td>
                                <td style={{ width: 200 }}>
                                    <strong>
                                        Order No.
                                    </strong>
                                </td>
                                <td style={{ width: 150 }}>
                                    <strong>
                                        담당자
                                    </strong>
                                </td>
                                <td style={{ width: 300 }}>
                                    <strong>
                                        고객 및 PI
                                    </strong>
                                </td>
                                <td style={{ width: 300 }}>
                                    <strong>
                                        상태
                                    </strong>
                                </td>
                                <td>

                                </td>
                            </tr>
                            {works.map((v, i) => {
                                return foundWorkCell(v, i)
                            })}
                        </tbody>
                    </table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseDialog} color='secondary'>닫기</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default observer(FindManufactureDialog);