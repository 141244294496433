import React from 'react';
import { inject, Provider } from 'mobx-react';
import { IRootStore } from '../../../../../data/store/RootStore';
import DesignRequestForm from './container/DesignRequestForm';
import DesignRequestStore from '../../../../../data/store/pageStore/design/DesignRequestStore';

interface Props extends IRootStore {

}

@inject('rootStore')
class DesignRequestPage extends React.Component<Props> {
    store: DesignRequestStore = new DesignRequestStore(this.props.rootStore);
    render() {
        return (
            <React.Fragment>
                <Provider store={this.store}>
                    <DesignRequestForm />
                </Provider>
            </React.Fragment>
        )
    }
}

export default DesignRequestPage;