import React from "react";
import WorkDetailPage from "./old/WorkDetailPage";

const DesignWorkDetailPage = () => {
    return (
        <React.Fragment>
            {/* <DesignWorkDetailMainContainer /> */}
            <WorkDetailPage />
        </React.Fragment>
    )
}

export default DesignWorkDetailPage;