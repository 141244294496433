import React from 'react';
import { observer } from 'mobx-react';
import { Table, TableHead, TableBody, TableRow, TableCell, Checkbox, Typography, Button } from '@material-ui/core';
import ManufactureListRow from './ManufactureListRow';
import ManufactureListItem from '../../../../../data/model/manufacture/ManufactureListItem';
import ManufactureWorkListStore from '../../../../../data/store/pageStore/manufacture/ManufactureWorkListStore';

interface Props {
    datas: ManufactureListItem[];
    useCheckBox?: boolean;
    headers: string[];
    translation?: (str: string) => string;
    mode?: string;
    numSelected?: number;
    allSelected?: boolean;
    perPage?: number;
    offset?: number;
    receiptHandler?: (workID: string) => void;
    receiptHeaderHandler?: () => void;
    changeShippingHandler?: (workID: string, approve: string, refuseReason?: string) => void;
}

const ManufactureListTable: React.FC<Props> = observer((props) => {
    const useCheckBox = props.useCheckBox || false;
    const translation = props.translation || ((str: string) => { return str });
    const mode = props.mode || '';
    const allSelected = props.allSelected;
    const offset = props.offset || 0;
    const perPage = props.perPage || 0;
    const receiptHandler = props.receiptHandler || ((workID: string) => { console.log('Function not defined') });
    const receiptHeaderHandler = props.receiptHeaderHandler || (() => console.log('Function not defined'));
    const changeShippingHandler = props.changeShippingHandler || (() => console.log('Function not defined'));

    const renderEmptyCol = (num: number): JSX.Element[] => {
        const arr: JSX.Element[] = [];
        for (let i = 0; i < num; i++) {
            arr.push(<TableCell key={`em${i}`}></TableCell>);
        }
        return arr;
    }

    const getEmptyColNumByMode = (): number => {
        switch (mode) {
            case ManufactureWorkListStore.ModeList.accept:
                return 1;
            case ManufactureWorkListStore.ModeList.process:
                return 1;
            case ManufactureWorkListStore.ModeList.search:
                return 0;
            case ManufactureWorkListStore.ModeList.shipped:
                return 1;
            case ManufactureWorkListStore.ModeList.changeShipping:
                return 1;
            // case ManufactureWorkListStore.ModeList.cancelShipping:
            //     return 1;
            default:
                return 0;
        }
    }

    const onMainCheckboxClick = (e: any) => {
        props.datas.map((v) => {
            return v.checked = e.target.checked;
        });
    }

    return (
        <React.Fragment>
            {
                mode === ManufactureWorkListStore.ModeList.accept &&
                <Button onClick={receiptHeaderHandler} style={{ margin: 10 }} variant='outlined'>선택접수</Button>
            }
            <Table>
                <TableHead>
                    <TableRow>
                        {
                            useCheckBox &&
                            <TableCell padding='checkbox'>
                                <Checkbox
                                    onClick={onMainCheckboxClick}
                                    indeterminate={props.numSelected > 0 && props.numSelected < perPage}
                                    checked={
                                        allSelected
                                    }
                                />
                            </TableCell>
                        }
                        <TableCell component='th'>
                            <Typography variant='subtitle1'>
                                <strong>#</strong>
                            </Typography>
                        </TableCell>
                        {
                            props.headers.map((v, i) => (
                                <TableCell component='th' key={`header${i}`}>
                                    <Typography variant='subtitle1'>
                                        <strong>
                                            {translation(v)}
                                        </strong>
                                    </Typography>
                                </TableCell>
                            ))
                        }
                        <TableCell>
                            <Typography variant='subtitle1'>
                                <strong>
                                    상태
                                </strong>
                            </Typography>
                        </TableCell>
                        {/* {
                            props.mode === ManufactureWorkListStore.ModeList.cancelShipping
                            &&
                            <TableCell>
                                <Typography variant='subtitle1'>
                                    <strong>
                                        변경요청사유
                                    </strong>
                                </Typography>
                            </TableCell>
                        } */}
                        {
                            props.mode === ManufactureWorkListStore.ModeList.changeShipping
                            &&
                            <TableCell>
                                <Typography variant='subtitle1'>
                                    <strong>
                                        변경 요청일
                                    </strong>
                                </Typography>
                            </TableCell>
                        }
                        {
                            props.mode === ManufactureWorkListStore.ModeList.changeShipping
                            &&
                            <TableCell>
                                <Typography variant='subtitle1'>
                                    <strong>
                                        변경 요청 사유
                                    </strong>
                                </Typography>
                            </TableCell>
                        }
                        {
                            props.mode === ManufactureWorkListStore.ModeList.changeShipping
                            &&
                            <TableCell>
                                <Typography variant='subtitle1'>
                                    <strong>
                                        승인 거부 사유
                                    </strong>
                                </Typography>
                            </TableCell>
                        }
                        <TableCell></TableCell>
                        {
                            renderEmptyCol(getEmptyColNumByMode())
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.datas.map((v, i) => (
                        <ManufactureListRow
                            receiptHandler={receiptHandler}
                            offset={offset}
                            mode={mode}
                            key={`row${i}`}
                            useCheckBox={useCheckBox}
                            index={i}
                            data={v}
                            headers={props.headers}
                            changeHandler={changeShippingHandler} />
                    )
                    )}
                </TableBody>
            </Table>
            {
                mode === ManufactureWorkListStore.ModeList.accept &&
                <Button onClick={receiptHeaderHandler} style={{ margin: 10 }} variant='outlined'>선택접수</Button>
            }
        </React.Fragment>
    )
})

export default ManufactureListTable;