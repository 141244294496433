import { observer } from "mobx-react";
import { MenuItem, Select } from "@material-ui/core";
import { ItemPresetKeyType, ItemPresetList } from "@celemicsbisw/manufacture-item-structure";

interface PresetSelectComponentProps {
    selectedItemPreset?: ItemPresetKeyType;
    itemPresetList?: ItemPresetKeyType[];
    onPresetChange?: (e?: any) => void;
    disabled?: boolean;
}

const PresetSelectComponent: React.FC<PresetSelectComponentProps> = (props) => {
    const itemPresetList = props.itemPresetList ?? [];
    const selectedItemPreset = props.selectedItemPreset ?? 'custom';
    const onPresetChange = props.onPresetChange ?? ((e: any) => { });
    const disabled = props.disabled ? true : false;

    return (
        <>
            <Select
                disabled={disabled}
                value={selectedItemPreset}
                fullWidth
                name='selectedItemPreset'
                onChange={onPresetChange}>
                {itemPresetList.map((v, i) => (
                    <MenuItem key={`psc${i}`} value={v}>
                        {ItemPresetList[v]}
                    </MenuItem>
                ))}
            </Select>
        </>
    )
}

export default observer(PresetSelectComponent);