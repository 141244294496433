import React from 'react';
import ManufactureRequestMainContainer from './ManufactureRequestMainContainer';

interface Props {

}

const ManufactureRequestPage: React.FC<Props> = (props) => {
    
    return (
        <React.Fragment>
            <ManufactureRequestMainContainer />
        </React.Fragment>
    )
}

export default ManufactureRequestPage;