import axios from 'axios';
import StoreBase from '../StoreBase';
import { words as RouteWords } from '../../../resource/data/APIRoutes.json';
import { action, observable } from 'mobx';
import PTSMessage from '../../model/common/PTSMessage';
// import LoginUtil from '../../../module/LoginUtil';

class DashboardData {
    @observable allCount = -1;
    @observable runningCount = -1;
    @observable finishedCount = -1;
    @observable pausedCount = -1;
    @observable waitCount = -1;
    @observable processingCount = -1;
}

class DashboardStore extends StoreBase {
    @observable designCounts: DashboardData;
    @observable manufactureCounts: DashboardData;
    @observable updatedTime: Date;

    constructor() {
        super();
        this.designCounts = new DashboardData();
        this.manufactureCounts = new DashboardData();
        this.updatedTime = new Date();
    }

    requestDashboardData = async (token: string) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        return axios.get(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.dashboard}`,
            config,
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        });
    }

    @action
    setDashboardData = async (token: string) => {
        try {
            const result = await this.requestDashboardData(token);
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                })
            }
            if (result.data.data.designCounts) {
                this.designCounts = result.data.data.designCounts;
            }
            else {
                this.designCounts = new DashboardData();
            }
            if (result.data.data.manufactureCounts) {
                this.manufactureCounts = result.data.data.manufactureCounts;
            }
            else {
                this.designCounts = new DashboardData();
            }
        } catch (err) {
            throw err;
        }
    }
}

export default DashboardStore;