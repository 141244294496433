import React from 'react';
import { observer, inject } from 'mobx-react';
import { createStyles, Theme } from '@material-ui/core/styles';
import { WithStyles, withStyles } from '@material-ui/styles';
import CommentContentsContainer from './CommentContentsContainer';
import { Grid, Paper } from '@material-ui/core';
import { IRootStore } from '../../../data/store/RootStore';
import CommentInput from './CommentInput';

const styles = (theme: Theme) => createStyles({

});

interface Props extends WithStyles<typeof styles>, IRootStore {
}

@inject('rootStore')
@observer
class CommentContainer extends React.Component<Props> {
    globalStore = this.props.rootStore.globalStore;
    commentStore = this.props.rootStore.commentStore;

    componentDidMount = async () => {
        try {
            await this.commentStore.setCommentsMany();
        } catch (err) {

        }
    }

    render() {
        return (
            <Paper>
                <Grid container>
                    <div>
                        <Grid xs sm item container direction='column' alignItems='stretch' style={{ border: '0px solid', margin: 0 }}>
                            <Grid xs sm item>
                                <CommentContentsContainer
                                    onBottomClick={this.commentStore.scrollBottom}
                                    onToggleCancel={this.commentStore.toggleCancel} />
                            </Grid>
                            <Grid xs sm item>
                                <CommentInput
                                    onRefresh={this.commentStore.setCommentsMany}
                                    onInputClick={this.commentStore.onInputClick}
                                    clearValue={this.commentStore.clearValue} />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Paper>
        )
    }
}

export default withStyles(styles)(CommentContainer);
