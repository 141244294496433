import React from 'react';
import { observer } from 'mobx-react';
import { Grid, TextField } from '@material-ui/core';
import ChipDistributionInfo from '../../../../../../../../data/model/common/ChipDistributionInfo';
import TitledPaper from '../../../../../../../common/component/TitledPaper';
import FormUtil from '../../../../../../../../module/FormUtil';
import Helper from '../../../../../../../../module/Helper';

interface Props {
    data: ChipDistributionInfo;
    index: number;
}
const Synth1InputCell: React.FC<Props> = observer((props) => {
    const data = props.data;

    return (
        <React.Fragment>
            <Grid item xl lg sm>
                <TitledPaper title={data.CDSDID}>
                    <TextField
                        margin='normal'
                        value={data.CDSDID}
                        name='CDSDID'
                        onChange={data.onChangeDefault}
                        InputProps={{ readOnly: true }}
                        label={FormUtil.getFieldLabel('CDSDID')} />
                    <TextField
                        margin='normal'
                        value={data.CDPanelName}
                        name='CDPanelName'
                        InputProps={{ readOnly: true }}
                        onChange={data.onChangeDefault}
                        label={FormUtil.getFieldLabel('CDPanelName')} />
                    <TextField
                        margin='normal'
                        value={data.chipID}
                        name='chipID'
                        InputProps={{ readOnly: true }}
                        onChange={data.onChangeDefault}
                        label={FormUtil.getFieldLabel('chipID')} />
                    <TextField
                        margin='normal'
                        value={data.CDNumberOfUniqueProbe}
                        type='number'
                        name='CDNumberOfUniqueProbe'
                        InputProps={{ readOnly: true }}
                        onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                        onChange={data.onChangeDefault}
                        label={FormUtil.getFieldLabel('CDNumberOfUniqueProbe')} />
                    <TextField
                        margin='normal'
                        value={data.CDNumberOfRepeat}
                        type='number'
                        name='CDNumberOfRepeat'
                        onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                        InputProps={{ readOnly: true }}
                        onChange={data.onChangeDefault}
                        label={FormUtil.getFieldLabel('CDNumberOfRepeat')} />
                    <TextField
                        margin='normal'
                        value={data.CDNumberOfFinalProbe}
                        type='number'
                        name='CDNumberOfFinalProbe'
                        InputProps={{ readOnly: true }}
                        onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                        onChange={data.onChangeDefault}
                        label={FormUtil.getFieldLabel('CDNumberOfFinalProbe')} />
                    <TextField
                        margin='normal'
                        value={data.CDFlankSequence}
                        name='CDFlankSequence'
                        InputProps={{ readOnly: true }}
                        onChange={data.onChangeDefault}
                        label={FormUtil.getFieldLabel('CDFlankSequence')} />
                    <TextField
                        margin='normal'
                        value={data.CDMixRatio}
                        type='number'
                        name='CDMixRatio'
                        InputProps={{ readOnly: true }}
                        onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                        onChange={data.onChangeDefault}
                        label={FormUtil.getFieldLabel('CDMixRatio')} />
                    <TextField
                        fullWidth
                        margin='normal'
                        value={data.synthesisChipID || ''}
                        name='synthesisChipID'
                        onChange={data.onChangeDefault}
                        label={FormUtil.getFieldLabel('synthesisChipID')}
                        helperText={
                            Helper.getErrorText(
                                props.data.errors.synthesisChipID
                            )
                        }
                        error={
                            Helper.getErrorState(
                                props.data.errors.synthesisChipID
                            )
                        } />
                </TitledPaper>
            </Grid>
        </React.Fragment>
    )
})

export default Synth1InputCell;