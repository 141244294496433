import { Grid } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import PTSMessage from '../../../../../../../data/model/common/PTSMessage';
import RebalancingRequestStore from '../../../../../../../data/store/pageStore/design/RebalancingRequestStore';
import { IRootStore } from '../../../../../../../data/store/RootStore';
import FormButtonSet from '../../../../../../common/component/FormButtonSet';
import FindWorkDesignWorkComponent from '../component/FindWorkDesignWorkComponent';
import RebalancingGeneListForm from '../component/RebalancingGeneListForm';
import RebalancingPrevBasicInfoForm from '../component/RebalancingPrevBasicInfoForm';
import RebalancingRequestMainForm from './RebalancingRequestMainForm';
import Lang from '../../../../../../../resource/lang/ko.json';
import ProbeSDContentsComponent from '../component/ProbeSDContentsComponent';

interface Props extends IRootStore {

}

@inject('rootStore')
@observer
class RebalancingRequestMainContainer extends React.Component<Props> {
    store = new RebalancingRequestStore();
    globalStore = this.props.rootStore.globalStore;
    commentStore = this.props.rootStore.commentStore;

    componentDidMount = () => {
        this.globalStore.currentTaskTitle = 'Rebalancing & 영역추가';
        this.store.newData.detail.rebalancingInfo.writer = this.globalStore.userInfo.name;
        this.commentStore.mode = 'tmp';
        this.commentStore.stage = 'Rebalancing 의뢰';
        this.commentStore.writerName = this.globalStore.userInfo.name;
        this.commentStore.email = this.globalStore.userInfo.email;
        this.globalStore.useComment = true;
        this.store.isPrevDataLoaded = false;
    }

    onWorkIDSearch = async (id: string) => {
        try {
            this.globalStore.isRunning = true;
            this.store.isPrevDataLoaded = false;
            await this.store.setPrevWorkInfo(id, this.globalStore.token);
            this.store.newData.detail.initErrorsRecursive();
            this.store.isPrevDataLoaded = true;
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
        } finally {
            this.globalStore.isRunning = false;
        }
    }

    onReload = () => {
        window.location.reload();
    }

    onValidate = () => {
        this.store.isValid = false;
        if (this.store.newData.detail.rebalancingInfo.isAddPanelRegion) {
            this.store.newData.detail.initErrorsRecursive();
            this.store.newData.detail.validateRecursive();
            this.store.isValid = !this.store.newData.detail.hasErrorRecursive();
        }
        else {
            this.store.isValid = true;
        }
        this.store.isValid = this.store.isValid && !this.store.data.detail.hasErrorRecursive();
        if (!this.store.isValid) {
            alert(Lang.message.notValid);
        }
    }

    onFormSubmit = async () => {
        try {
            this.globalStore.isRunning = true;
            if (!this.store.isValid) {
                alert('입력값이 유효 하지 않습니다.');
                this.globalStore.isRunning = false;
                return;
            }
            if (!window.confirm('제출 하시겠습니까?')) {
                return;
            }
            // upload files
            const files = this.store.getFileUploadForm();
            if (files) {
                const fileResult = await this.store.uploadFiles(files, this.globalStore.token);
                const mapping = Array.from(this.store.filesMapping.values());

                for (let i = 0; i < fileResult.data.data.length; i++) {
                    mapping[i].file_id = fileResult.data.data[i];
                }
            }

            const result = await this.store.requestRebalancing(this.globalStore.token);
            alert(`등록 성공 ID: ${result.data.data.workID}`);
            window.location.reload();
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
            else {
                alert('오류발생');
            }
        } finally {
            this.globalStore.isRunning = false;
        }
    }

    render() {
        return (
            <React.Fragment>
                <Grid container item xl lg sm spacing={1} direction='column' alignItems='stretch'>
                    <Grid item container>
                        <Grid xl lg sm item>
                            <FindWorkDesignWorkComponent
                                onSearch={(id: string) => { this.onWorkIDSearch(id) }} />
                        </Grid>
                    </Grid>
                    {
                        this.store.isPrevDataLoaded &&
                        <Grid container>
                            <Grid item container>
                                <Grid xl lg sm item>
                                    <ProbeSDContentsComponent
                                        store={this.store} />
                                </Grid>
                            </Grid>
                            <Grid item container>
                                <Grid xl lg sm item>
                                    <RebalancingRequestMainForm store={this.store} />
                                </Grid>
                            </Grid>
                            <Grid item container>
                                <Grid xl lg sm item>
                                    <RebalancingPrevBasicInfoForm
                                        store={this.store} />
                                </Grid>
                            </Grid>
                            <Grid item container>
                                {
                                    this.store.newData.detail.rebalancingInfo.isAddPanelRegion
                                    &&
                                    <Grid xl lg sm item>
                                        <RebalancingGeneListForm
                                            store={this.store} />
                                    </Grid>
                                }
                            </Grid>
                            <Grid item container>
                                <Grid xl lg sm item>
                                    <FormButtonSet
                                        onSubmit={this.onFormSubmit}
                                        onValidate={this.onValidate}
                                        onFormClear={this.onReload}
                                        validated={this.store.isValid}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </React.Fragment>
        )
    }
}

export default RebalancingRequestMainContainer;