import { Grid, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import React, { useState } from 'react';
import TitledPaper from '../../../../../../common/component/TitledPaper';

interface Props {
    onSearch: (id: string) => any;
    mode?: 'normal' | 'popup';
}

const FindWorkDesignWorkComponent: React.FC<Props> = (props) => {
    const [id, setID] = useState('');
    const mode = props.mode ? props.mode : 'normal';
    const onSearch = props.onSearch;

    const renderByMode = () => {
        if (mode === 'normal') {
            return (
                <Grid container direction='column' style={{ marginLeft: 5 }}>
                    <Grid container item alignItems='center'>
                        <TextField
                            margin='dense'
                            label='Work ID'
                            name='workID'
                            value={id}
                            onChange={(e) => { setID(e.target.value) }}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    onSearch(id);
                                }
                            }}
                        />
                        <Search style={{ marginTop: 20 }} onClick={() => { onSearch(id) }} />
                    </Grid>
                </Grid>
            )
        }
        else if (mode === 'popup') {
            return (
                <span>Pop up mode</span>
            )
        }
    }

    return (
        <React.Fragment>
            <TitledPaper title='Rebalancing 대상 Work ID'>
                {renderByMode()}
            </TitledPaper>
        </React.Fragment>
    )
}

export default FindWorkDesignWorkComponent;