import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import User from '../../../../data/model/user/user';

interface Props {
    open: boolean;
    users: User[];
    onClose: () => any;
    onSearch: (name: string) => any;
    onSelect: (user: User) => any;
}

const FindUserDialog: React.FC<Props> = (props) => {
    const [userName, setUserName] = useState('');

    useEffect(() => {
        setUserName('');
    }, [props.open]);

    const foundUserCell = (user: User, index: number): JSX.Element => {
        return (
            <tr key={`user#${index}`}>
                <td>
                    {user.name}
                </td>
                <td>
                    {user.departName}
                </td>
                <td>
                    {user.email}
                </td>
                <td>
                    <Button onClick={() => { props.onSelect(user) }}>선택</Button>
                </td>
            </tr>
        )
    }

    return (
        <React.Fragment>
            <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth='sm'>
                <DialogTitle>
                    유저 검색
                </DialogTitle>
                <DialogContent style={{ height: 300 }}>
                    <DialogContentText>
                        이름으로 유저 검색
                    </DialogContentText>
                    <FormControl>
                        <Grid container>
                            <Grid item xl lg sm md>
                                <TextField
                                    value={userName}
                                    onChange={(e) => { setUserName(e.target.value) }}
                                    onKeyUp={
                                        (e) => {
                                            if (e.key === 'Enter') {
                                                props.onSearch(userName)
                                            }
                                        }
                                    }
                                />
                                <Search onClick={() => props.onSearch(userName)} />
                            </Grid>
                        </Grid>
                    </FormControl>
                    <table style={{ width: '100%', marginTop: 20 }}>
                        <tbody>
                            <tr>
                                <td>
                                    <strong>
                                        이름
                                    </strong>
                                </td>
                                <td>
                                    <strong>
                                        부서
                                    </strong>
                                </td>
                                <td>
                                    <strong>
                                        Email
                                    </strong>
                                </td>
                                <td>

                                </td>
                            </tr>
                            {props.users.map((v, i) => {
                                return foundUserCell(v, i)
                            })}
                        </tbody>
                    </table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} color='secondary'>닫기</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default observer(FindUserDialog);