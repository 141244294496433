import { observable } from "mobx";
import Helper from "../../../../../module/Helper";
import PTSFile from "../../../common/PTSFile";
import Validatable from "../../../common/Validatable";
import TaskSubData from "../../DesignTaskSubData";

export default class Synthesis3 extends TaskSubData {
    @observable origProbeVol?: number;
    @observable finalAmount?: number;
    @observable probeID?: string;
    @observable probeName?: string;
    @observable probeConc?: number;
    @observable boxNumber?: string;
    @observable sdContents?: string[];
    @observable dilutionFactor?: number;
    @observable probeTapeStationFile?: PTSFile;
    @observable poolingExcelFile?: PTSFile;
    sdContentsError?: string[];

    constructor() {
        super();
        this.probeTapeStationFile = new PTSFile();
        this.poolingExcelFile = new PTSFile();
        this.dilutionFactor = 100;
        this.sdContents = [];
        this.sdContentsError = [];
    }

    // override
    validateSelf(): void {
        if (!this.probeID) {
            this.setErrorTextByName('probeID', Validatable.errorTexts.needValue);
        }
        else if (!Helper.checkRegex(this.probeID, /^[0-9][0-9](0[1-9]|1[0-2])PD[0-9]{3}-V[0-9]{1,2}\.[0-9]{1,2}\.[0-9]{1,2}$/)) {
            this.setErrorTextByName('probeID', `${Validatable.errorTexts.badFormat} -> 포맷: YYMMPD###-V##.##.##`);
        }
        if (!this.probeName) {
            this.setErrorTextByName('probeName', Validatable.errorTexts.needValue);
        }
        // else if (!Helper.checkRegex(this.probeName, /^[0-9a-zA-Z-_+#]+$/)) {
        //     this.setErrorTextByName('probeName', `${Validatable.errorTexts.badFormat} -> 포맷: 영문, 숫자, + , # , - , _ `);
        // }
        if (!this.probeConc) {
            this.setErrorTextByName('probeConc', Validatable.errorTexts.needValue);
        }
        else if (this.probeConc < 0) {
            this.setErrorTextByName('probeConc', Validatable.errorTexts.positive);
        }
        if (!this.dilutionFactor) {
            this.setErrorTextByName('dilutionFactor', Validatable.errorTexts.needValue);
        }
        else if (this.dilutionFactor < 0) {
            this.setErrorTextByName('dilutionFactor', Validatable.errorTexts.positive);
        }
        if (!this.origProbeVol) {
            this.setErrorTextByName('origProbeVol', Validatable.errorTexts.needValue);
        }
        else if (!Helper.checkRegex(this.origProbeVol.toString(), /^\d*(\.\d{1})?$/)) {
            this.setErrorTextByName('origProbeVol', `${Validatable.errorTexts.badFormat} -> 포맷: #.#`);
        }
        if (!this.finalAmount) {
            this.setErrorTextByName('finalAmount', Validatable.errorTexts.needValue);
        }
        else if (!Helper.checkRegex(this.finalAmount.toString(), /^\d*(\.\d{1})?$/)) {
            this.setErrorTextByName('finalAmount', `${Validatable.errorTexts.badFormat} -> 포맷: #.#`);
        }
        if (!this.boxNumber) {
            this.setErrorTextByName('boxNumber', Validatable.errorTexts.needValue);
        }
        // else if (this.boxNumber <= 0) {
        //     this.setErrorTextByName('boxNumber', Validatable.errorTexts.positiveNumber);
        // }
        if (!this.probeTapeStationFile.name) {
            this.setErrorTextByName('probeTapeStationFile', Validatable.errorTexts.fileValid);
        }
        if (!this.poolingExcelFile.name) {
            this.setErrorTextByName('poolingExcelFile', Validatable.errorTexts.fileValid);
        }
        if (this.sdContents.length < 1) {
            this.setErrorTextByName('sdContents', '입력값이 없거나 적용 되지 않았습니다.');
        }
        else if (typeof this.sdContents !== 'undefined' && this.sdContents.length > 0) {
            for (let index in this.sdContents) {
                if (!Helper.checkRegex(this.sdContents[index], /^[0-9][0-9](0[1-9]|1[0-2])SD[0-9]{3}-[0-9].[0-9]-[0-9]+$/)
                    && !Helper.checkRegex(this.sdContents[index], /^[0-9][0-9](0[1-9]|1[0-2])PD[0-9]{3}-V[0-9]\.[0-9]\.[0-9]$/)) {
                    this.setErrorTextByName('sdContents', '');
                    this.sdContentsError[index] = `${Validatable.errorTexts.badFormat} -> 포맷: YYMMSD###-#.#-# or YYMMPD###-V#.#.#`;
                }
                else {
                    this.sdContentsError[index] = '';
                }
            }
        }
    }
}