import { AppBar, createStyles, Divider, IconButton, LinearProgress, List, Theme, Toolbar, Typography, withStyles, WithStyles } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import LayoutStore from "../../../data/store/common/LayoutStore";
import RootStore, { IRootStore } from "../../../data/store/RootStore";
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import LoginUtil from "../../../module/LoginUtil";
import { AccountCircle, Close, Home, MeetingRoom, VpnKey } from "@material-ui/icons";
import Helper from "../../../module/Helper";
import PTSMenuItem from "./PTSMenuItem";
import strings from '../../../resource/lang/ko.json';

const styles = (theme?: Theme) => {
    return createStyles({
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
        appBar: {
            background: '#138D75'
        }
    })
}

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any>, IRootStore {
    layoutStore?: LayoutStore;
}

@inject('rootStore')
@observer
class MainMenuBar extends React.Component<Props> {
    readonly layoutStore: LayoutStore;
    readonly rootStore: RootStore;
    readonly classes: any;

    constructor(props: Props) {
        super(props);
        this.rootStore = this.props.rootStore;
        this.layoutStore = this.props.layoutStore;
        this.classes = this.props.classes;
    }

    onUserMenuClick = (e: React.MouseEvent<HTMLElement>) => {
        this.layoutStore.handleProfileClose();
        this.props.history.push(e.currentTarget.getAttribute('link'));
        window.location.reload();
    }

    onHomeClick = (e: React.MouseEvent<HTMLElement>) => {
        this.layoutStore.handleProfileClose();
        this.props.history.push('/main');
        window.location.reload();
    }

    render() {
        return (
            <React.Fragment>
                <div className={this.classes.root}>
                    <AppBar position="static" style={{ background: '#138D75' }}>
                        <Toolbar>
                            <IconButton
                                onClick={this.layoutStore.onMenuClick}
                                edge="start" className={this.classes.menuButton} color="inherit" aria-label="menu">
                                <MenuIcon />
                                <Typography variant="h6" className={this.classes.title} style={{ marginLeft: 10 }}>
                                    Menu
                                </Typography>
                            </IconButton>
                            <Typography variant="h6" className={this.classes.title} style={{ marginLeft: 10 }}>
                                <IconButton
                                    onClick={this.onHomeClick}
                                    edge="start"
                                    className={this.classes.menuButton}
                                    color="inherit"
                                    aria-label="home">
                                    <Home />
                                </IconButton>
                            </Typography>
                            {LoginUtil.isLoggedIn() && (
                                <div>
                                    <IconButton
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        color="inherit"
                                        onClick={this.layoutStore.handleProfileMenu}
                                    >
                                        <AccountCircle />
                                        <Typography className={this.classes.title} variant='body1' color='inherit' style={{ marginLeft: 10 }}>
                                            {this.rootStore.globalStore.userInfo.name}
                                        </Typography>
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={this.layoutStore.profileAnchorElement}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(this.layoutStore.profileAnchorElement)}
                                        onClose={this.layoutStore.handleProfileClose}
                                    >
                                        <List>
                                            <PTSMenuItem
                                                key={Helper.generateRandomKey()}
                                                icon={<Close />}
                                                onClick={this.layoutStore.handleProfileClose} />
                                            <Divider />
                                            <PTSMenuItem
                                                key={Helper.generateRandomKey()}
                                                name='비밀번호 변경'
                                                link='/main/user/modify'
                                                icon={<VpnKey />}
                                                onClick={this.onUserMenuClick} />
                                            <PTSMenuItem
                                                name={strings.global.words.logout}
                                                key={Helper.generateRandomKey()}
                                                onClick={this.layoutStore.onLogout}
                                                icon={<MeetingRoom />} />
                                            {/* <Link
                                            target='_blank'
                                            to={{ pathname: '/main/manual' }}
                                            style={{ textDecoration: 'none', color: 'inherit' }}>
                                            <PTSMenuItem
                                                onClick={() => this.layoutStore.handleProfileClose()}
                                                name='Help'
                                                key={Helper.generateRandomKey()}
                                                icon={<HelpOutline />} />
                                        </Link> */}
                                        </List>
                                    </Menu>
                                </div>
                            )}
                        </Toolbar>
                        {this.rootStore.globalStore.isRunning &&
                            <LinearProgress
                                color="secondary"
                                style={{
                                    height: 4,
                                    margin: -4
                                }} />
                        }
                    </AppBar>
                </div>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(withRouter(MainMenuBar));