import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Container, Grid } from '@material-ui/core';
import ManufactureListTableContainer from './ManufactureListTableContainer';
import { observable } from 'mobx';
import { IRootStore } from '../../../../../data/store/RootStore';
import ManufactureWorkListStore from '../../../../../data/store/pageStore/manufacture/ManufactureWorkListStore';
import ManufactureWork from '../../../../../data/model/manufacture/ManufactureWork';

interface Props extends RouteComponentProps<any>, IRootStore {
    stage?: string;
}

@inject('rootStore')
@observer
class ManufactureListContainer extends React.Component<Props> {
    @observable stage: string = '';

    globalStore = this.props.rootStore.globalStore;

    constructor(props: Props) {
        super(props);
        this.globalStore.useComment = false;
        const mode = this.checkAndGetStage(this.props.stage);
        this.stage = mode;
        if (!mode) {
            alert('잘못된 접근');
            this.props.history.push('/main/manufacture/list/search');
            window.location.reload();
        }
    }

    checkAndGetStage = (type: string): string => {
        switch (type) {
            case ManufactureWorkListStore.ModeList.accept:
                return ManufactureWork.StageList.waitAccept;
            case ManufactureWorkListStore.ModeList.process:
                return ManufactureWork.StageList.processing;
            case ManufactureWorkListStore.ModeList.search:
                return ManufactureWorkListStore.ModeList.search;
            case ManufactureWorkListStore.ModeList.shipped:
                return ManufactureWorkListStore.ModeList.shipped;
            case ManufactureWorkListStore.ModeList.cancelShipping:
                return ManufactureWorkListStore.ModeList.cancelShipping;
            case ManufactureWorkListStore.ModeList.changeShipping:
                return ManufactureWorkListStore.ModeList.changeShipping;
            default:
                return '';
        }
    }

    isChangeShippingCheck = () => {
        return this.stage === 'changeShipping';
    }

    render() {
        return (
            <React.Fragment>
                <Container
                    maxWidth={this.isChangeShippingCheck() ? false : 'xl'}
                    style={this.isChangeShippingCheck() ? { padding: 0 } : {}}>
                    <Grid container justify='center' direction='column'>
                        <Grid item xl lg sm style={{
                            wordWrap: 'break-word',
                            whiteSpace: 'pre-line',
                            padding: 5
                        }}>
                        </Grid>
                        <Grid item xl lg sm>
                            <ManufactureListTableContainer
                                stage={this.stage}
                                departType={this.globalStore.userInfo.departType} />
                        </Grid>
                    </Grid>
                </Container>
            </React.Fragment >
        )
    }
}

export default withRouter(ManufactureListContainer);