import { observable } from "mobx";
import PTSFile from "../../../common/PTSFile";
import TaskSubData from "../../DesignTaskSubData";

export default class BIAnalysis extends TaskSubData {
    @observable analysisStat: PTSFile;
    @observable analysisRD: PTSFile;
    @observable finalBedFile: PTSFile;

    constructor() {
        super();
        this.analysisStat = new PTSFile();
        this.analysisRD = new PTSFile();
        this.finalBedFile = new PTSFile();
    }

    // override
    validateSelf(): void {
        // if (!this.analysisStat.name) {
        //     this.setErrorTextByName('analysisStat', Validatable.errorTexts.fileValid)
        // }
        // else if (!Helper.checkExtensionRegex(this.analysisStat.name, 'xls')) {
        //     this.setErrorTextByName('analysisStat', `${Validatable.errorTexts.badFileFormat} -> 확장자: *.xlsx 또는 *.xls`);
        // }
        // if (!this.analysisRD.name) {
        //     this.setErrorTextByName('analysisRD', Validatable.errorTexts.fileValid)
        // }
        // else if (!Helper.checkExtensionRegex(this.analysisRD.name, 'xls')) {
        //     this.setErrorTextByName('analysisRD', `${Validatable.errorTexts.badFileFormat} -> 확장자: *.xlsx 또는 *.xls`);
        // }
        // if (!this.finalBedFile.name) {
        //     this.setErrorTextByName('finalBedFile', Validatable.errorTexts.fileValid)
        // }
        // else if (!Helper.checkExtensionRegex(this.finalBedFile.name, 'bed')) {
        //     this.setErrorTextByName('finalBedFile', `${Validatable.errorTexts.badFileFormat} -> 확장자: *.bed`);
        // }
    }
}