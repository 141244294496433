import dayjs from "dayjs";
import { observable } from "mobx";
import SnopItem, { ISnopItem } from "./SnopItem";
import ManufactureWork from '../../model/manufacture/ManufactureWork';
import ManufactureItem from "../manufacture/ManufactureItem";
import ManufactureTrackData from "../manufacture/ManufactureTrackData";
import FormUtil from '../../../module/FormUtil';
import TaskInfo from '../../../resource/data/tasks.json';

export interface ISnopManufactureItem extends ISnopItem {
    determinedReleaseDate?: Date;
    determinedReleaseAMPM?: string;
    productType?: string;
    index?: string;
    probeState?: string;
    unit?: string;
    qty?: number;
    pooling?: number;
    manufactureEndDate?: Date;
    finalShippingRequestDate?: Date;
    finalDeliveryDate?: Date;
    deliveryDate?: Date;
    deliveryPlace?: string;
    itemList?: ManufactureItem[];
    tracks?: object;
    trackList?: ManufactureTrackData[];
    shippingRequestDate?: Date;
    shippingRequestAMPM?: string;
    orderNumber?: string;
    projectCode?: string;
}

class SnopManufactureItem extends SnopItem implements ISnopManufactureItem {
    @observable determinedReleaseDate: Date = null;
    @observable determinedReleaseAMPM: string = '';
    @observable productType: string = '';
    @observable index: string = '';
    @observable probeState: string = '';
    @observable unit: string = '';
    @observable qty: number = null;
    @observable pooling: number = null;
    @observable manufactureStartDate: Date = null;
    @observable manufactureEndDate: Date = null;
    @observable finalShippingRequestDate: Date = null;
    @observable finalDeliveryDate: Date = null;
    @observable itemList: ManufactureItem[] = [];
    @observable tracks: { trackList: ManufactureTrackData[] };
    @observable trackList: ManufactureTrackData[] = [];
    @observable deliveryDate: Date = null;
    @observable deliveryPlace: string = '';
    @observable shippingRequestDate: Date = null;
    @observable shippingRequestAMPM?: string = '';
    @observable orderNumber: string = '';
    @observable projectCode: string = '';

    constructor(data?: ISnopManufactureItem) {
        super(data);
        if (data) {
            Object.assign(this, data);
        }
        this.setDatas(data);
    }

    setDatas(data: ISnopManufactureItem): void {
        this.trackList = this.tracks.trackList;
        this.finalDeliveryDate = this.deliveryDate;
        this.finalShippingRequestDate = this.shippingRequestDate;
        const curr = (this.tracks as any).current;
        this.currentStage = FormUtil.getFieldLabel(curr.stage);
        this.currentWorkType = (TaskInfo.translate as any)[curr.workType];
        let isLastManufactureTaskCheck = true;
        for (const d of this.trackList) {
            if (d.type === ManufactureWork.TypeList.manufactureManufacture && isLastManufactureTaskCheck) {
                this.manufactureStartDate = d.receiptDate;
                this.manufactureEndDate = d.endDate;
                isLastManufactureTaskCheck = false;
            }
            if (d.type === ManufactureWork.TypeList.manufactureRequest) {
                this.requestDate = d.endDate;
            }
        }
    }

    get ProductType() {
        return (ManufactureWork as any).ProductTypeList[this.productType];
    }

    get Index() {
        return (ManufactureWork as any).IndexList[this.index];
    }

    get ProbeState() {
        return (ManufactureWork as any).ProbeStateList[this.probeState];
    }

    get DeterminedReleaseDate(): string {
        return `${this.determinedReleaseDate ? dayjs(this.determinedReleaseDate).format('YYYY-MM-DD') + (this.determinedReleaseAMPM.toLowerCase() === 'am' ? ' 오전' : ' 오후') : ''}`;
    }

    get RequestDate(): string {
        return this.getFormattedDate(this.requestDate);
    }

    get ManufactureStartDate(): string {
        return this.getFormattedDate(this.manufactureStartDate);
    }

    get ManufactureEndDate(): string {
        return this.getFormattedDate(this.manufactureEndDate);
    }

    get FinalShippingRequestDate(): string {
        return `${this.finalShippingRequestDate ? dayjs(this.finalShippingRequestDate).format('YYYY-MM-DD') + (this.shippingRequestAMPM ? (this.shippingRequestAMPM.toLowerCase() === 'am' ? ' 오전' : ' 오후') : '') : ''}`;
        // return this.getFormattedDate(this.finalShippingRequestDate, 'YYYY-MM-DD');
    }

    get FinalDeliveryDate(): string {
        return this.getFormattedDate(this.finalDeliveryDate, 'YYYY-MM-DD');
    }
}

export default SnopManufactureItem;