import { action } from "mobx";
import PTSFile from './PTSFile';
import dayjs from 'dayjs';

export default class ModelBase {

    fromJS = (json: object): void => {
        try {
            const keys: string[] = Object.keys(this);
            if (json == null) { return null }
            for (const key in keys) {
                let val = (this as any)[keys[key]];
                if (typeof val === 'function') { continue; }
                if (keys[key].toLowerCase().endsWith('date')) {
                    if ((json as any)[keys[key]] !== undefined) {
                        const date = dayjs((json as any)[keys[key]]).format('YYYY-MM-DD HH:mm:ss');
                        if (date !== 'Invalid Date') {
                            (this as any)[keys[key]] = date;
                        }
                        else {
                            (this as any)[keys[key]] = (json as any)[keys[key]];
                        }
                    }
                    else {
                        (this as any)[keys[key]] = (json as any)[keys[key]];
                    }
                    continue;
                }
                if (val === null || typeof val !== 'object') {
                    if (!json.hasOwnProperty(keys[key])) { continue; }
                    (this as any)[keys[key]] = (json as any)[keys[key]];
                }
                else if (val instanceof ModelBase) {
                    val.fromJS((json as any)[keys[key]]);
                }
            }
        } catch (err) {
            throw err;
        }
    }

    fromJSWithDate = (json: object): void => {
        try {
            const keys: string[] = Object.keys(this);
            if (json == null) { return null }
            for (const key in keys) {
                let val = (this as any)[keys[key]];
                if (typeof val === 'function') { continue; }
                if (typeof val !== 'object') {
                    if (!json.hasOwnProperty(keys[key])) { continue; }
                    (this as any)[keys[key]] = (json as any)[keys[key]];
                }
                else if (val instanceof ModelBase) {
                    val.fromJS((json as any)[keys[key]]);
                }
                else if (val instanceof Date) {
                    val = dayjs(((json as any)[keys[key]])).format('YYYY-MM-DD HH:mm:SS')
                }
            }
        } catch (err) {
            throw err;
        }
    }

    toJS = (): object => {
        try {
            let obj = {};
            const keys: string[] = Object.keys(this);
            for (const key in keys) {
                const val = (this as any)[keys[key]];
                if (typeof val === 'function') {
                    continue;
                }
                if (val instanceof ModelBase) {
                    (obj as any)[keys[key]] = val.toJS();
                }
                else {
                    (obj as any)[keys[key]] = val;
                }
            }
            return obj;
        } catch (err) {
            throw err;
        }
    }

    toJSWithoutDate = (): object => {
        try {
            let obj = {};
            const keys: string[] = Object.keys(this);
            for (const key in keys) {
                const val = (this as any)[keys[key]];
                if (keys[key] === 'createdDate' || keys[key] === 'updatedDate') { continue; }
                if (typeof val === 'function') {
                    continue;
                }
                if (val instanceof ModelBase) {
                    (obj as any)[keys[key]] = val.toJS();
                }
                else {
                    (obj as any)[keys[key]] = val;
                }
            }
            return obj;
        } catch (err) {
            throw err;
        }
    }

    setData = (data: object) => {
        Object.assign(this, data);
    }

    // 추후 keyof를 이용하게 수정 필요
    @action
    onChangeDefault = (e: React.ChangeEvent<HTMLInputElement> | any): void => {
        try {
            const { name, value } = e.target;
            (this as any)[name] = value;
        } catch (err) {
        }
    }

    @action
    onCheckboxClick = (e: any): void => {
        const { name } = e.target;
        (this as any)[name] = !(this as any)[name];
    }

    @action
    onChangeDateDefault = (name: string, date: string | Date | null): void => {
        try {
            (this as any)[name] = date;
        } catch (err) {
        }
    }

    @action
    onChangeFileDefault = (name: string, data: PTSFile): void => {
        try {
            (this as any)[name] = data;
        } catch (err) {

        }
    }
}
