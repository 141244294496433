import { observable } from "mobx";
import Validatable from "../common/Validatable";

export interface IManufactureOrderNumberList {
    manufactureOrderNumber?: string;
}

export default class ManufactureOrderNumberList extends Validatable {
    @observable manufactureOrderNumber?: string;

    constructor(manufactureOrderNumber?: string) {
        super();
        this.manufactureOrderNumber = manufactureOrderNumber;
    }

    // override
    validateSelf(): void {

    }
}