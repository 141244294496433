import { Grid, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import React from 'react';
import ChipDistributionInfo from '../../../../../../../data/model/common/ChipDistributionInfo';
import ModelBase from '../../../../../../../data/model/common/ModelBase';
import PTSFile from '../../../../../../../data/model/common/PTSFile';
import SynthesisID from '../../../../../../../data/model/common/SynthesisID';
import WorkDetailModel from '../../../../../../../data/model/common/WorkDetailModel';
import DesignTask from '../../../../../../../data/model/design/DesignTask';
import TaskSubData from '../../../../../../../data/model/design/DesignTaskSubData';
import FormUtil from '../../../../../../../module/FormUtil';
import PTSFileLink from '../../../../../../common/component/PTSFileLink';
import WorkDetailArray from '../component/WorkDetailArray';
import WorkDetailPanel from '../component/WorkDetailPanel';
import WorkDetailTuple from '../component/WorkDetailTuple';
import Helper from '../../../../../../../module/Helper';
import { Close } from '@material-ui/icons';

interface Props {
    data?: WorkDetailModel;
}

const WorkDetailPanelContainer: React.FC<Props> = ({ data }) => {

    const getWorkStage = (data: WorkDetailModel) => {
        const tasks = data.tasks;
        const lastTask = tasks[tasks.length - 1];
        return `${Helper.fieldToString(lastTask.type)} ${Helper.fieldToString(lastTask.stage)}`;
    }

    const renderDetails = (): JSX.Element => {
        return (
            <React.Fragment>
                <WorkDetailTuple tupleKey='workID' tupleValue={data.workID} />
                <WorkDetailTuple tupleKey='projectCode' tupleValue={data.projectCode} />
                <WorkDetailTuple tupleKey='startDate' tupleValue={Helper.getFormattedDate(data.startDate)} />
                <WorkDetailTuple tupleKey='finishDate' tupleValue={Helper.getFormattedDate(data.finishDate)} />
                <WorkDetailTuple tupleKey='stage' tupleValue={getWorkStage(data)} />
                <WorkDetailTuple tupleKey='customerAndPI' tupleValue={data.customerAndPI} />
                <WorkDetailTuple tupleKey='designName' tupleValue={data.designName} />
                <WorkDetailTuple tupleKey='erpCode' tupleValue={data.erpCode} />
                <WorkDetailTuple tupleKey='salesRepresentative' tupleValue={data.repInfo.name} />
                <WorkDetailTuple tupleKey='probeID' tupleValue={data.probeID} />
                <WorkDetailPanel title='Tasks'>
                    {data.tasks.map((v, i) => (
                        <React.Fragment key={i}>
                            {getTaskDetails(v)}
                        </React.Fragment>
                    ))}
                </WorkDetailPanel>
                {getSids(data.sids)}
                {getChipInfos(data.chipInfos)}
            </React.Fragment>
        )
    }

    const getChipInfos = (arr: ChipDistributionInfo[]) => {
        const ele = [];
        for (const data of arr) {
            const obj = new ChipDistributionInfo();
            obj.fromJS(data);
            ele.push(getDetailElement(obj, obj.CDSDID, true));
        }
        return (
            <WorkDetailPanel title='chipInfos'>
                <WorkDetailArray tupleKey='chipInfos' value={ele} />
            </WorkDetailPanel>
        )
    }

    const getSids = (arr: SynthesisID[]) => {
        const ele = [];
        for (const data of arr) {
            const obj = new SynthesisID();
            obj.fromJS(data);
            delete obj.chipInfos;
            ele.push(getDetailElement(obj, obj.Sid, true));
        }
        return (
            <WorkDetailPanel title='sids'>
                <WorkDetailArray tupleKey='sids' value={ele} />
            </WorkDetailPanel>
        )
    }

    const getTaskDetails = <T extends DesignTask<TaskSubData>>(element: T) => {
        return (
            <React.Fragment>
                <WorkDetailPanel title={element.type}>
                    {Object.keys(element).map((v, i) => {
                        return (
                            <React.Fragment key={i}>
                                {getDetailElement((element as any)[v], v)}
                            </React.Fragment>
                        )
                    })}
                </WorkDetailPanel>
            </React.Fragment>
        )
    }

    const getArrayElement = (arr: any[], title?: string, open?: boolean) => {
        const elements = [];
        for (const key in arr) {
            elements.push(getDetailElement(arr[key], `${FormUtil.getFieldLabel(title)}/${key}`, open));
        }

        return (
            <WorkDetailArray tupleKey={title} value={elements} />
        )
    }

    const getTupleElement = (value: any, key?: string) => {
        let passValue = value;
        if (typeof value === 'boolean') {
            passValue = value.toString();
        }
        if (key.endsWith('Date')) {
            passValue = dayjs(value).format('YYYY-MM-DD HH:mm:ss');
        }
        if (key === 'dueDateBedFile') {
            passValue = dayjs(value).format('YYYY-MM-DD HH:mm:ss');
        }
        return (
            <WorkDetailTuple tupleKey={key} tupleValue={passValue} />
        )
    }

    const getObjectElement = (data: object, title?: string, open?: boolean) => {
        if ((data as PTSFile).type === 'file') {
            return (
                <React.Fragment>
                    <Grid item container xl lg sm style={{ margin: 10 }}>
                        <Grid item xl={4} lg={4} sm={4}>
                            <Typography variant='body2' style={{ whiteSpace: 'pre-line', marginRight: 5 }}>
                                {FormUtil.getFieldLabel(title)}
                            </Typography>
                        </Grid>
                        <Grid item xl lg sm>
                            {
                                (data as PTSFile).name ?
                                    <PTSFileLink
                                        fileID={(data as PTSFile).file_id}
                                        mode='icon'
                                    />
                                    :
                                    <Close />
                            }
                        </Grid>
                    </Grid>
                </React.Fragment>
            )
        }
        else {
            return (
                <WorkDetailPanel title={title} marginLeft={1} open={open}>
                    {Object.keys(data).map((v, i) => (
                        <React.Fragment key={i}>
                            {getDetailElement((data as any)[v], v)}
                        </React.Fragment>
                    ))}
                </WorkDetailPanel>
            )
        }
    }

    const getDetailElement = (data: any, title?: string, open?: boolean) => {
        if (title === 'SDIDtargetSize' || title === 'SDIDtargetRegions') {
            if (!data) { data = '-'; }
        }
        if (!data && data !== 0) { return; }
        if (title === 'updatedDate' || title === 'createdDate') { return; }
        if (typeof data === 'function') { return; }
        if (title === 'errors') { return; }
        if (title === 'salesRepresentativeObj') { return; }
        if (title === '_id') { return; }
        if (title === 'departType') { return; }
        const synthIDRegex = /^[0-9][0-9](0[1-9]|1[0-2])SD[0-9]{3}-([0-9][0-9]|[0-9])/g;
        if (title === 'synthesisIDs' || title === 'tempSDContents') {
            let sids = []
            for (let d of data) {
                if (d && d.match(synthIDRegex) !== null) {
                    sids.push(d.match(synthIDRegex)[0]);
                }
            }
            data = sids;
        }
        if (title === 'sid') {
            if (data && data.match(synthIDRegex) !== null) {
                data = data.match(synthIDRegex)[0];
            }
        }
        if (data instanceof ModelBase || typeof data === 'object') {
            return getObjectElement(data, title, open);
        }
        else if (Array.isArray(data)) {
            return getArrayElement(data, title, true);
        }
        else {
            return getTupleElement(data, title);
        }
    }

    return (
        <React.Fragment>
            {renderDetails()}
        </React.Fragment>
    )
}

export default WorkDetailPanelContainer;