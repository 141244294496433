export interface IPTSMessage {
    code?: number;
    type?: string;
    title?: string;
    message?: string;
}

export default class PTSMessage {
    code: number;
    type: string;
    title: string;
    message: string;

    constructor(msg: IPTSMessage) {
        Object.assign(this, msg);
    }
}