import React from 'react';
import { Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import { Paper, Typography, Grid, Divider, Button } from '@material-ui/core';

import dayjs from 'dayjs';
import Helper from '../../../module/Helper';

const styles = (theme: Theme) => createStyles({
    bubbleRight: {
        // position: 'relative',
        background: '#ffde5b',
        borderRadius: '.4em',
        padding: '15px',
    },
    bubbleLeft: {
        // position: 'relative',
        background: '#46CA64',
        borderRadius: '.4em',
        padding: '15px',
    },
    bubbleTypo: {
        marginBottom: 6,
        whiteSpace: 'pre-line',
        overflowWrap: 'break-word',
        maxWidth: 200,
    }
});

interface Props extends WithStyles<typeof styles> {
    writerName?: string;
    createdDate?: Date;
    updatedDate?: Date;
    content?: string;
    stage?: string;
    cancel?: Boolean;
    keyindex: number | string;
    mine?: Boolean;
    isRunning: boolean;
    onCancelClick: any;
}

const CommentBubble: React.FC<Props> = (props) => {

    const cancelClick = () => {
        props.onCancelClick(props.keyindex);
    }

    return (
        <Grid item container style={{ flexGrow: 1 }}>
            {props.mine
                ?
                <Grid item container direction='column' style={{
                    margin: 10,
                    marginBottom: 20,
                }}>
                    <Grid item container alignItems='flex-end'>
                        <Grid item xs sm container direction='column' alignItems='flex-end'>
                            <Grid item>
                                <Typography>
                                    {props.writerName}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems='flex-end'>
                        <Grid item xs sm container direction='column' alignItems='flex-end'>
                            <Grid xs sm item style={{ marginRight: 4 }}>
                                {
                                    //@ts-ignore
                                    <Button
                                        onClick={cancelClick}
                                        disabled={props.isRunning} style={{ padding: 1 }}>
                                        <Typography variant='body2' color='textSecondary' align='right'>
                                            취소선 Toggle
                                        </Typography>
                                    </Button>
                                }
                            </Grid>
                            <Grid xs sm item style={{ marginRight: 5 }}>
                                <Typography variant='body2' color='textSecondary' style={{ whiteSpace: 'pre-line' }} align='right'>
                                    {dayjs(props.createdDate).format('YYYY/MM/DD\nHH:mm:ss')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Paper className={props.classes.bubbleRight}>
                                <Typography variant='body2' className={props.classes.bubbleTypo}>
                                    {props.cancel
                                        ?
                                        <del>{props.content}</del>
                                        :
                                        props.content
                                    }
                                </Typography>
                                <Divider />
                                <Typography variant='body2'
                                    style={{
                                        marginTop: 6,
                                        marginBottom: -6,
                                    }}
                                    className={props.classes.bubbleTypo}
                                >
                                    {<strong>{Helper.fieldToString(props.stage)}</strong>}
                                    &nbsp;중 입력됨.
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                :
                <Grid container direction='column' style={{ margin: 10, marginBottom: 20 }}>
                    <Grid item container alignItems='flex-end'>
                        <Grid item container alignItems='flex-start'>
                            <Grid item>
                                <Typography>
                                    {props.writerName}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container direction='row'>
                        <Paper className={props.classes.bubbleLeft}>
                            <Grid item >
                                <Typography variant='body1' className={props.classes.bubbleTypo}>
                                    {props.cancel
                                        ?
                                        <del>{props.content}</del>
                                        :
                                        props.content
                                    }
                                </Typography>
                                <Divider />
                                <Typography variant='body2'
                                    style={{
                                        marginTop: 6,
                                        marginBottom: -6,
                                    }}
                                    className={props.classes.bubbleTypo}
                                >
                                    {<strong>{Helper.fieldToString(props.stage)}</strong>}
                                    &nbsp;중 입력됨.
                                </Typography>
                            </Grid>
                        </Paper>
                        <Grid xs item container alignItems='flex-end' style={{ marginLeft: 5 }}>
                            <Typography variant='body2' color='textSecondary' style={{ whiteSpace: 'pre-line' }} align='left'>
                                {dayjs(props.createdDate).format('YYYY/MM/DD\nHH:mm:ss')}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Grid>
    )
}

export default withStyles(styles)(CommentBubble);
