import { observable } from "mobx";
import CommentStore from "./common/CommentStore";
import GlobalStore from "./common/GlobalStore";
import LayoutStore from "./common/LayoutStore";

export interface IRootStore {
    rootStore?: RootStore;
}

export default class RootStore {
    @observable globalStore: GlobalStore;
    @observable commentStore: CommentStore;
    @observable layoutStore: LayoutStore;

    constructor() {
        this.globalStore = new GlobalStore(this);
        this.commentStore = new CommentStore(this);
        this.layoutStore = new LayoutStore(this);
    }
}