import { observable } from "mobx";
import TaskSubData from "../../DesignTaskSubData";

export default class GeneData extends TaskSubData {
    @observable chromosome?: string;
    @observable start?: number;
    @observable end?: number;
    @observable name?: string;
    @observable remark?: string;

    constructor(chromosome?: string, start?: number, end?: number, name?: string, remark?: string) {
        super();
        this.chromosome = chromosome;
        this.start = start;
        this.end = end;
        this.name = name;
        this.remark = remark;
    }

    // override
    validateSelf(): void {
        return;
    }
}