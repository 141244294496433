import { FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import RebalancingRequestStore from '../../../../../../../data/store/pageStore/design/RebalancingRequestStore';
import { IRootStore } from '../../../../../../../data/store/RootStore';
import FormUtil from '../../../../../../../module/FormUtil';
import Helper from '../../../../../../../module/Helper';
import TitledPaper from '../../../../../../common/component/TitledPaper';
import PTSTooltip from '../../../../../../common/component/PTSTooltip';

interface Props extends IRootStore {
    store?: RebalancingRequestStore;
}

const RebalancingPrevBasicInfoForm: React.FC<Props> = (props) => {
    const designRequestStore = props.store;

    const onWarning = (e: any): void => {
        if (!e.target.value || e.target.value === 0) {
            return;
        } else if (e.target.value === 'etc.') {
            designRequestStore.data.detail.designRequestInfo.geneDB = "N/A"
            alert("1. 디자인이 가능한지 BI와 사전 협의 필요합니다.\n2. GRCh37, GRCh38이 아닐 경우 pilot test가 진행되지 않습니다.");
        } else {
            designRequestStore.data.detail.designRequestInfo.geneDB = "Refseq"
        }
    }

    return (
        <React.Fragment>
            <TitledPaper
                title='이전 디자인 의뢰 정보'>
                <TextField
                    required
                    fullWidth
                    margin='normal'
                    label={FormUtil.getFieldLabel('erpCode')}
                    onChange={designRequestStore.newData.detail.designRequestInfo.onChangeDefault}
                    name='erpCode'
                    helperText={
                        Helper.getErrorText(
                            designRequestStore.newData.detail.designRequestInfo.errors.erpCode
                        )
                    }
                    error={
                        Helper.getErrorState(
                            designRequestStore.newData.detail.designRequestInfo.errors.erpCode
                        )
                    }
                    value={
                        designRequestStore.newData.detail.designRequestInfo.erpCode
                    } />
                <TextField
                    required
                    fullWidth
                    margin='normal'
                    label={FormUtil.getFieldLabel('projectCode')}
                    onChange={designRequestStore.newData.detail.designRequestInfo.onChangeDefault}
                    name='projectCode'
                    helperText={
                        Helper.getErrorText(
                            designRequestStore.newData.detail.designRequestInfo.errors.projectCode
                        )
                    }
                    error={
                        Helper.getErrorState(
                            designRequestStore.newData.detail.designRequestInfo.errors.projectCode
                        )
                    }
                    value={
                        designRequestStore.newData.detail.designRequestInfo.projectCode
                    } />
                <TextField
                    required
                    fullWidth
                    margin='normal'
                    label={FormUtil.getFieldLabel('customerPI')}
                    onChange={designRequestStore.newData.detail.designRequestInfo.customer.onChangeDefault}
                    name='PI'
                    helperText={
                        Helper.getErrorText(
                            designRequestStore.newData.detail.designRequestInfo.customer.errors.PI
                        )
                    }
                    error={
                        Helper.getErrorState(
                            designRequestStore.newData.detail.designRequestInfo.customer.errors.PI
                        )
                    }
                    value={
                        designRequestStore.newData.detail.designRequestInfo.customer.PI
                    } />
                <TextField
                    // required
                    fullWidth
                    margin='normal'
                    label={FormUtil.getFieldLabel('customerRepresentative')}
                    onChange={designRequestStore.newData.detail.designRequestInfo.customer.onChangeDefault}
                    value={
                        designRequestStore.newData.detail.designRequestInfo.customer.representative
                    }
                    helperText={
                        Helper.getErrorText(
                            designRequestStore.newData.detail.designRequestInfo.customer.errors.representative
                        )
                    }
                    error={
                        Helper.getErrorState(
                            designRequestStore.newData.detail.designRequestInfo.customer.errors.representative
                        )
                    }
                    name='representative'
                />
                <TextField
                    fullWidth
                    margin='normal'
                    label={FormUtil.getFieldLabel('customerContact')}
                    onChange={designRequestStore.newData.detail.designRequestInfo.customer.onChangeDefault}
                    value={
                        designRequestStore.newData.detail.designRequestInfo.customer.contact
                    }
                    helperText={
                        Helper.getErrorText(
                            designRequestStore.newData.detail.designRequestInfo.customer.errors.contact
                        )
                    }
                    error={
                        Helper.getErrorState(
                            designRequestStore.newData.detail.designRequestInfo.customer.errors.contact
                        )
                    }
                    name='contact'
                />
                <TextField
                    fullWidth
                    type='email'
                    margin='normal'
                    label={FormUtil.getFieldLabel('customerEmail')}
                    onChange={designRequestStore.newData.detail.designRequestInfo.customer.onChangeDefault}
                    value={
                        designRequestStore.newData.detail.designRequestInfo.customer.email
                    }
                    helperText={
                        Helper.getErrorText(
                            designRequestStore.newData.detail.designRequestInfo.customer.errors.email
                        )
                    }
                    error={
                        Helper.getErrorState(
                            designRequestStore.newData.detail.designRequestInfo.customer.errors.email
                        )
                    }
                    name='email'
                />
                <TextField
                    required
                    fullWidth
                    margin='normal'
                    label={FormUtil.getFieldLabel('designName')}
                    onChange={designRequestStore.newData.detail.designRequestInfo.onChangeDefault}
                    value={
                        designRequestStore.newData.detail.designRequestInfo.designName
                    }
                    helperText={
                        Helper.getErrorText(
                            designRequestStore.newData.detail.designRequestInfo.errors.designName
                        )
                    }
                    error={
                        Helper.getErrorState(
                            designRequestStore.newData.detail.designRequestInfo.errors.designName
                        )
                    }
                    name='designName'
                />
                <TextField
                    fullWidth
                    margin='normal'
                    label={FormUtil.getFieldLabel('diseaseName')}
                    onChange={designRequestStore.newData.detail.designRequestInfo.onChangeDefault}
                    value={
                        designRequestStore.newData.detail.designRequestInfo.diseaseName
                    }
                    helperText={
                        Helper.getErrorText(
                            designRequestStore.newData.detail.designRequestInfo.errors.diseaseName
                        )
                    }
                    error={
                        Helper.getErrorState(
                            designRequestStore.newData.detail.designRequestInfo.errors.diseaseName
                        )
                    }
                    name='diseaseName'
                />
                <FormControl fullWidth margin='normal'>
                    <InputLabel id='referenceVersion'>{FormUtil.getFieldLabel('referenceVersion')}</InputLabel>
                    <Select
                        labelId='referenceVersion'
                        onChange={designRequestStore.newData.detail.designRequestInfo.onChangeDefault}
                        value={
                            designRequestStore.newData.detail.designRequestInfo.referenceVersion
                        }
                        error={
                            Helper.getErrorState(
                                designRequestStore.newData.detail.designRequestInfo.errors.referenceVersion
                            )
                        }
                        name='referenceVersion'
                        onClick={onWarning}
                    >
                        <MenuItem value=''>None</MenuItem>
                        <MenuItem value='GRCh37'>GRCh37</MenuItem>
                        <MenuItem value='GRCh38'>GRCh38</MenuItem>
                        <MenuItem value='etc.'>etc.</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    required
                    fullWidth
                    margin='normal'
                    label={FormUtil.getFieldLabel('geneDatabase')}
                    onChange={designRequestStore.newData.detail.designRequestInfo.onChangeDefault}
                    value={
                        designRequestStore.newData.detail.designRequestInfo.geneDB
                    }
                    helperText={
                        Helper.getErrorText(
                            designRequestStore.newData.detail.designRequestInfo.errors.geneDB
                        )
                    }
                    error={
                        Helper.getErrorState(
                            designRequestStore.newData.detail.designRequestInfo.errors.geneDB
                        )
                    }
                    name='geneDB'
                    InputProps={{ readOnly: true }}

                />
                <FormControl
                    error={
                        Helper.getErrorState(
                            designRequestStore.newData.detail.designRequestInfo.errors.masking
                        )
                    }
                    component='fieldset' margin='normal' fullWidth>
                    <FormLabel component='legend'>
                        {FormUtil.getFieldLabel('masking')}
                    </FormLabel>
                    <RadioGroup
                        value={
                            designRequestStore.newData.detail.designRequestInfo.masking
                        }
                        name='masking'
                        onChange={designRequestStore.newData.detail.designRequestInfo.onChangeDefault}
                    >
                        <FormControlLabel
                            value='YES'
                            control={<Radio color='secondary' />}
                            label='Masking 함' />
                        <FormControlLabel
                            value='NO'
                            control={<Radio color='secondary' />}
                            label='Masking 하지않음' />
                    </RadioGroup>
                </FormControl>
                <PTSTooltip
                    title={Helper.getTooltip('homologCutOffNumber')}>
                    <TextField
                        required
                        helperText={
                            Helper.getErrorText(
                                designRequestStore.newData.detail.designRequestInfo.errors.homologCutOffNumber
                            )
                        }
                        error={
                            Helper.getErrorState(
                                designRequestStore.newData.detail.designRequestInfo.errors.homologCutOffNumber
                            )
                        }
                        fullWidth
                        type='number'
                        margin='normal'
                        label={FormUtil.getFieldLabel('homologCutOffNumber')}
                        onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                        onChange={designRequestStore.newData.detail.designRequestInfo.onChangeDefault}
                        value={
                            designRequestStore.newData.detail.designRequestInfo.homologCutOffNumber || -1
                        }
                        name='homologCutOffNumber'
                    />
                </PTSTooltip>
            </TitledPaper>
            <TitledPaper title='Note'>
                <TextField
                    label='Note'
                    fullWidth
                    multiline
                    rows={10}
                    value={designRequestStore.data.detail.designRequestInfo.designRequestNote}
                    onChange={designRequestStore.data.detail.designRequestInfo.onChangeDefault}
                    name='designRequestNote'
                />
            </TitledPaper>
        </React.Fragment>
    )
}

export default observer(RebalancingPrevBasicInfoForm);