import { Container, CssBaseline, Grid, Paper, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import SnopStore from '../../../../../data/store/pageStore/view/SnopStore';
import NoItem from '../../../../common/component/task/table/NoItem';
import SnopManufactureTable from '../component/SnopManufactureTable';
// import SnopDesignTable from '../component/SnopDesignTable';
import NewSnopDesignTable from '../component/NewSnopDesignTable';

interface Props {
    store: SnopStore;
    type: string;
}

const SnopListViewMainContainer: React.FC<Props> = (props) => {
    const store = props.store;

    useEffect(() => {
        if (props.type.toLowerCase() === 'manufacture') {
            store.setManufactureDatas();
        }
        else if (props.type.toLowerCase() === 'design') {
            store.setDesignDatas();
        }
        else {

        }
    }, [props.type, store, store.testNum]);


    const renderRunningTableByType = (type: string): JSX.Element => {
        if (type.toLowerCase() === 'manufacture') {
            return (
                <SnopManufactureTable datas={store.manufactureRunningItemList} />
            )
        }
        else if (type.toLowerCase() === 'design') {
            return (
                <NewSnopDesignTable datas={store.designRunningItemList} />
            )
        }
        else {
            return (
                <NoItem />
            )
        }
    }

    const renderNotRunningTableByType = (type: string): JSX.Element => {
        if (type.toLowerCase() === 'manufacture') {
            return (
                <SnopManufactureTable datas={store.manufactureNotRunningItemList} />
            )
        }
        else if (type.toLowerCase() === 'design') {
            return (
                <NewSnopDesignTable datas={store.designNotRunningItemList} />
            )
        }
        else {
            return (
                <NoItem />
            )
        }
    }

    const getPageNameByType = (type: string) => {
        if (type.toLowerCase() === 'manufacture') {
            return 'SnOP Summary (제조)';
        }
        else if (type.toLowerCase() === 'design') {
            return 'SnOP Summary (Custom panel design)';
        }
        else {
            return '';
        }
    }

    const getNotRunningTableNameByType = (type: string) => {
        if (type.toLowerCase() === 'manufacture') {
            return '작업 중단 및 완료 목록 (제조)';
        }
        else if (type.toLowerCase() === 'design') {
            return '작업 중단 및 완료 목록 (Custom panel design)';
        }
        else {
            return '';
        }
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth={false} style={{ padding: 10 }}>
                <Grid container justify='center' direction='column'>
                    <Grid item xl lg sm style={{
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-line',
                        padding: 5
                    }}>
                        <div>
                            <Typography variant='h4'>
                                {getPageNameByType(props.type)}
                            </Typography>
                            <hr />
                        </div>
                    </Grid>
                    <Grid xl lg sm spacing={1} container item direction='column' alignItems='stretch' style={{ marginTop: 4 }}>
                        <Paper style={{ padding: 10 }}>
                            <Grid item xl lg sm container direction='column'>
                                <Grid item xl lg sm>
                                    {renderRunningTableByType(props.type)}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>

            <Container maxWidth={false} style={{ padding: 10 }}>
                <Grid container justify='center' direction='column'>
                    <Grid item xl lg sm style={{
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-line',
                        padding: 5
                    }}>
                        <div>
                            <Typography variant='h4'>
                                {getNotRunningTableNameByType(props.type)}
                            </Typography>
                            <hr />
                        </div>
                    </Grid>
                    <Grid xl lg sm spacing={1} container item direction='column' alignItems='stretch' style={{ marginTop: 4 }}>
                        <Paper style={{ padding: 10 }}>
                            <Grid item xl lg sm container direction='column'>
                                <Grid item xl lg sm>
                                    {renderNotRunningTableByType(props.type)}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    )
}

export default observer(SnopListViewMainContainer);