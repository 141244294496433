import { Button, Grid } from '@material-ui/core';
import React from 'react';

interface Props {
    onPauseClick?: (e: any) => any;
    onRunClick?: (e: any) => any;
    onFinishClick?: (e: any) => any;
    onReworkClick?: (e: any) => any;
    useRework?: boolean;
    status?: 'paused' | 'running' | 'finished';
}

const FlowControlButtonSet: React.FC<Props> = (props) => {
    const onPauseClick = props.onPauseClick || (() => { });
    const onFinishClick = props.onFinishClick || (() => { });
    const onReworkClick = props.onReworkClick || (() => { });
    const onRunClick = props.onRunClick || (() => { });
    const useRework = props.useRework || false;
    const status = props.status || 'paused';

    const renderByStatus = () => {
        if (status === 'paused') {
            return (
                <Button
                    onClick={onRunClick}
                    style={{

                    }}>
                    중단해제
                </Button>
            )
        }
        else if (status === 'running') {
            return (
                <Button
                    onClick={onPauseClick}
                    style={{

                    }}>
                    중단
                </Button>
            )
        }
        else {
            return (
                <Button
                    disabled>
                    완료된 작업
                </Button>
            )
        }
    }

    return (
        <React.Fragment>
            <Grid container item direction='column'>
                <Grid container item direction='column' alignItems='flex-end'>
                    <Grid item>
                        {
                            renderByStatus()
                        }
                        <Button
                            disabled={status === 'finished' ? true : false}
                            onClick={onFinishClick}
                            style={{
                                marginLeft: 10
                            }}>
                            완료
                        </Button>
                        {
                            useRework
                            &&
                            <Button
                                onClick={onReworkClick}
                                style={{
                                    marginLeft: 10
                                }}>
                                재작업
                            </Button>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default FlowControlButtonSet;