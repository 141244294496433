import { Paper } from '@material-ui/core';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import React from 'react';

interface Props {
    requestDate?: Date;
    cancelDate?: Date;
    cancelReason?: String;
    requestPerson?: String;
    cancelPerson?: String;
    data?: any;
}

const ManufactureCancelReasonComponent: React.FC<Props> = props => {
    return (
        <React.Fragment>
            <Paper style={{ marginTop: 10 }}>
                <div>
                    {`변경요청자: ${props.data.requestPerson}`}
                </div>
                <div>
                    {`변경요청일시: ${props.data.requestDate ?
                        dayjs(props.data.requestDate).format('YYYY-MM-DD HH:mm:ss')
                        :
                        ''
                        }`
                    }
                </div>
                <div>
                    {`변경자: ${props.data.cancelPerson}`}
                </div>
                <div>
                    {`변경승인일시: ${props.data.cancelDate ?
                        dayjs(props.data.cancelDate).format('YYYY-MM-DD HH:mm:ss')
                        :
                        ''
                        }`
                    }
                </div>
                <div>
                    {`변경사유: ${props.data.cancelReason}`}
                </div>
            </Paper>
        </React.Fragment>
    )
}

export default observer(ManufactureCancelReasonComponent);