import React from 'react';
import { observer } from 'mobx-react';

interface Props {
    data?: object;
}

@observer
class TestPrintPage extends React.Component<Props> {

    render() {
        return (
            <React.Fragment>
                <div style={{padding: 30}}>
                    Now printing . . .
                </div>

            </React.Fragment>
        )
    }
}

export default TestPrintPage;