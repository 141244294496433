import { Button } from '@material-ui/core';
import axios from 'axios';
import { inject } from 'mobx-react';
import React from 'react';
import { IRootStore } from '../../../data/store/RootStore';
import RepositorySettings from '../../../data/repository/RepositorySettings';
import { words as RouteWords } from '../../../resource/data/APIRoutes.json';
import PTSMessage from '../../../data/model/common/PTSMessage';
import { GetApp } from '@material-ui/icons';

interface Props extends IRootStore {
    fileID: string;
    mode?: 'button' | 'icon' | 'text';
    fileName?: string;
}

const PTSFileLink: React.FC<Props> = inject('rootStore')((props) => {
    const mode = props.mode || 'icon';
    const fileName = props.fileName || 'Download';
    const baseURL = RepositorySettings.apiBaseURL;
    const token = props.rootStore.globalStore.token;
    const fileID = props.fileID;

    const downloadFile = async (id: string, token: string) => {
        axios({
            method: 'GET',
            url: `${baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.files}/${id}`,
            responseType: 'blob',
            headers: {
                'x-access-token': token,
            }
        }).then((res) => {
            if (res.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: res.data.resMessage,
                });
            }
            const type = res.headers['content-type'];
            const url = window.URL.createObjectURL(
                new Blob(
                    [res.data],
                    { type: type }
                )
            );
            const link = document.createElement('a');
            const contentDisposition = res.headers['content-disposition'];
            let fileName = 'Unknown';
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.split(';').filter((v: string) => {
                    return v.match(/filename\*=utf-8/i);
                });
                if (fileNameMatch.length > 0) {
                    const _fileName = fileNameMatch[0].trim().split(/filename\*=utf-8'/i)[1].trim().replace("'", '');
                    if (_fileName) {
                        fileName = decodeURI(_fileName);
                    }
                }
                else {
                    const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                    if (fileNameMatch.length === 2)
                        fileName = fileNameMatch[1];
                }
            }
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        }).catch((err) => {
            throw err;
        });
    }

    const renderComponent = () => {
        switch (mode) {
            case 'button':
                return (
                    <Button onClick={() => { downloadFile(fileID, token) }}>
                        Download
                    </Button>
                );
            case 'icon':
                return (
                    <GetApp style={{ cursor: 'pointer' }} onClick={() => { downloadFile(fileID, token) }} />
                );
            case 'text':
                return (
                    <span onClick={() => { downloadFile(fileID, token) }}>
                        {
                            fileName
                                ?
                                fileName
                                :
                                'Download'
                        }
                    </span>
                )
            default:
                return (
                    <span>

                    </span>
                )
        }
    }

    return (
        <React.Fragment>
            {renderComponent()}
        </React.Fragment>
    )
})

export default PTSFileLink;