import ModelBase from "./ModelBase";
import ValidateErrorObject from "./ValidateErrorObject";
import { observable } from "mobx";

export default abstract class Validatable extends ModelBase {

    @observable errors: { [key: string]: ValidateErrorObject } = {};

    abstract validateSelf(): void;

    static errorTexts = {
        needValue: "입력값이 필요합니다.",
        notValid: "입력값이 유효하지 않습니다. 입력값을 확인하세요.",
        fileValid: "파일이 필요합니다",
        needInt: "정수값만 가능합니다.",
        positive: "0이나 양수만 가능합니다.",
        positiveNumber: "양수만 가능합니다.",
        badFileFormat: "확장자가 맞지 않습니다.",
        badFormat: "포맷(형식)이 맞지 않습니다.",
        exceedCharacter: "제한 글자수를 초과했습니다.",
        pastDate: '과거의 날짜는 불가능합니다.',
        futureDate: '미래의 날짜는 불가능합니다.',
    }

    getErrors() {
        return this.errors;
    }

    getErrorList() {
        return Object.keys(this.errors).filter(v =>
            (this.errors as any)[v].isError
        )
    }

    setErrorObjectByName = (name: string, newError: ValidateErrorObject) => {
        this.errors[name] = newError;
    }

    setErrorTextByName = (name: string, text?: string) => {
        const error = this.errors[name];
        if (error) {
            if (text) {
                error.setErrorText(text);
            }
            else {
                error.setError(true, null, name);
            }
        }
    }

    initErrors = (): void => {
        for (const key of Object.keys(this)) {
            const prop = (this as any)[key];
            if (typeof prop === 'function') { continue; }
            // if (Array.isArray(prop)) { continue; }
            if (prop instanceof Validatable) { continue; }
            this.errors[key] = new ValidateErrorObject(null, null, key);
        }
    }

    initErrorByKey = (key: string) => {
        const prop = (this as any)[key];
        if (typeof prop === 'function') { return; }
        // if (Array.isArray(prop)) { continue; }
        if (prop instanceof Validatable) { return; }
        this.errors[key] = new ValidateErrorObject(null, null, key);
    }

    deleteErrors = (): void => {
        // this.errors = {};
        delete this.errors;
    }

    deleteErrorsRecursive = (): void => {
        this.deleteErrors();
        for (const key of Object.keys(this)) {
            const prop = (this as any)[key];
            if (typeof prop === 'function') { continue; }
            // if (Array.isArray(prop)) { continue; }
            if (Array.isArray(prop)) {
                for (const data of prop) {
                    if (data instanceof Validatable) {
                        data.deleteErrorsRecursive();
                    }
                }
            }
            if (prop instanceof Validatable) {
                prop.deleteErrorsRecursive();
            }
        }
    }

    getErrorDeletedThis = (): Validatable => {
        const newObj = Object.assign({}, this);
        newObj.deleteErrorsRecursive();
        return newObj;
    }

    initErrorsRecursive = (): void => {
        this.initErrors();
        for (const key of Object.keys(this)) {
            const prop = (this as any)[key];
            if (typeof prop === 'function') { continue; }
            // if (Array.isArray(prop)) { continue; }
            if (Array.isArray(prop)) {
                for (const data of prop) {
                    if (data instanceof Validatable) {
                        data.initErrorsRecursive();
                    }
                }
            }
            if (prop instanceof Validatable) {
                prop.initErrorsRecursive();
            }
        }
    }

    resetErrors = (): void => {
        for (const error of Object.values(this.errors)) {
            error.initError();
        }
    }

    resetErrorsRecursive = (): void => {
        this.resetErrors();
        const This = this as any;
        for (const prop of Object.values(This)) {
            if (typeof prop === 'function') { continue; }
            // if (Array.isArray(prop)) { continue; }
            if (Array.isArray(prop)) {
                for (const data of prop) {
                    if (data instanceof Validatable) {
                        data.resetErrorsRecursive();
                    }
                }
            }
            if (prop instanceof Validatable) {
                prop.resetErrorsRecursive();
            }
        }
    }

    hasError = (): boolean => {
        const errors = this.errors;
        for (const error of Object.values(errors)) {
            if (error.isError) {
                return true;
            }
        }
        return false;
    }

    hasErrorRecursive = (): boolean => {
        let result = false;
        if (this.hasError()) {
            return true;
        }
        const This = this as any;
        for (const prop of Object.values(This)) {
            if (typeof prop === 'function') { continue; }
            // if (Array.isArray(prop)) { continue; }
            if (Array.isArray(prop)) {
                for (const data of prop) {
                    if (data instanceof Validatable) {
                        if (!result) {
                            result = data.hasErrorRecursive();
                        }
                    }
                }
            }
            if (prop instanceof Validatable) {
                if (!result) {
                    result = prop.hasErrorRecursive();
                }
            }
        }
        return result;
    }

    validateRecursive = (): void => {
        this.validateSelf();
        const This = this as any;
        for (const prop of Object.values(This)) {
            if (typeof prop === 'function') { continue; }
            // if (Array.isArray(prop)) { continue; }
            if (Array.isArray(prop)) {
                for (const data of prop) {
                    if (data instanceof Validatable) {
                        data.validateRecursive();
                    }
                }
            }
            if (prop instanceof Validatable) {
                prop.validateRecursive();
            }
        }
    }
}