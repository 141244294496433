import { observable } from "mobx";
import ModelBase from "../common/ModelBase";

export default class ManufactureTrackCurrent extends ModelBase {
    @observable receiptDate: Date;
    @observable endDate: Date;
    @observable startDate: Date;
    @observable workType: string;
    @observable stage: string;
    @observable departType: string;
    @observable data: object = {};
    @observable updatedDate: Date;
    @observable createdDate: Date;
    @observable writer: string;
}