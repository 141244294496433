interface ICommonError {
    name: string;
    message: string;
    code: string;
    errorObj: boolean;
}

export class CommonError {
    name: string;
    message: string;
    code: string;
    errorObj: boolean;

    constructor(params: ICommonError) {
        Object.assign(this, params);
    }

    get errorString(): string {
        return `[Error]
* Name: ${this.name}
* Message: ${this.message}`
    }
}

export default class CommonErrors {
    static readonly InvalidParams = new CommonError(
        {
            name: 'Invalid Params',
            message: '잘못된 파라미터',
            code: '0000',
            errorObj: true,
        }
    )
    static readonly NetworkError = new CommonError(
        {
            name: 'Network Error',
            message: '네트워크 에러',
            code: '0100',
            errorObj: true,
        }
    )
    static readonly NoData = new CommonError(
        {
            name: 'No Data',
            message: '수신된 데이터가 없습니다.',
            code: '0101',
            errorObj: true,
        }
    )
    static readonly InvalidType = new CommonError(
        {
            name: 'Invalid type',
            message: '잘못된 타입',
            code: '0102',
            errorObj: true,
        }
    )
    static readonly DataNotValidated = new CommonError(
        {
            name: 'Data not validated',
            message: '유효하지 않은 값이 있습니다.',
            code: '0200',
            errorObj: true,
        }
    )
    static readonly AlreadyModified = new CommonError(
        {
            name: 'Data already modified',
            message: '데이터가 이미 변경되었습니다.',
            code: '0300',
            errorObj: true,
        }
    )
    static readonly NoAuth = new CommonError(
        {
            name: 'Not authorized',
            message: '권한이 없습니다.',
            code: '0010',
            errorObj: true,
        }
    )
}