import { observable } from "mobx";
import PTSFile from "../../../common/PTSFile";
import Validatable from "../../../common/Validatable";
import TaskSubData from "../../DesignTaskSubData";

export default class BIUpload extends TaskSubData {
    @observable ftpPW: string;
    @observable ftpNum: number;
    @observable customerStat: PTSFile;
    @observable downloadLink: string;
    @observable downloadPW: string;

    constructor() {
        super();
        this.customerStat = new PTSFile();
        this.downloadPW = '';
        this.downloadLink = '';
    }

    // override
    validateSelf(): void {
        // if (!this.customerStat.name) {
        //     this.setErrorTextByName('customerStat', Validatable.errorTexts.fileValid)
        // }
        // else if (!Helper.checkExtensionRegex(this.customerStat.name, 'xls')) {
        //     this.setErrorTextByName('customerStat', `${Validatable.errorTexts.badFileFormat} -> 확장자: *.xlsx 또는 *.xls`);
        // }
        if (!this.downloadLink) {
            this.setErrorTextByName('downloadLink', Validatable.errorTexts.needValue);
        }
        if (!this.downloadPW) {
            this.setErrorTextByName('downloadPW', Validatable.errorTexts.needValue);
        }
    }
}