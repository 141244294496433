import { observable } from "mobx";
import PTSFile from "./PTSFile";
import ChipDistributionInfo from "./ChipDistributionInfo";
import Validatable from "./Validatable";

export interface ISynthesisID {
    designer?: string;
    flankSize?: number;
    terminalProbes: boolean;
    tiling?: number;
    sid?: string;
    commonName?: string;
    SDIDnumberOfGene?: string;
    SDIDtargetSize?: string;
    SDIDtargetRegions?: string;
    SDIDuniqueProbes?: string;
    workID?: string;
    synthesisTargetBedFile?: PTSFile;
    maskingRegionFile?: PTSFile;
    chipInfos: ChipDistributionInfo[] | string[];
    sdidNumOfSelectedProbesForRebalance?: number;
    sdidNumOfTotalProbesIncludingRepeats?: number;
}

export default class SynthesisID extends Validatable {
    @observable designer?: string;
    @observable flankSize?: number;
    @observable terminalProbes: boolean;
    @observable tiling?: number;
    @observable sid?: string;
    @observable commonName?: string;
    @observable SDIDnumberOfGene?: string;
    @observable SDIDtargetSize?: string;
    @observable SDIDtargetRegions?: string;
    @observable SDIDuniqueProbes?: string;
    @observable workID?: string;
    @observable synthesisTargetBedFile?: PTSFile;
    @observable maskingRegionFile?: PTSFile;
    @observable sdidNumOfSelectedProbesForRebalance?: number;
    @observable sdidNumOfTotalProbesIncludingRepeats?: number;

    @observable chipInfos: ChipDistributionInfo[];

    constructor(params?: ISynthesisID) {
        super();
        this.terminalProbes = true;
        this.chipInfos = [];
        this.maskingRegionFile = new PTSFile();
        this.synthesisTargetBedFile = new PTSFile();
        if (params) {
            Object.assign(this, params);
        }
    }

    get Sid() {
        return this.sid.substring(0, this.sid.indexOf('.'));
    }

    //override    
    validateSelf(): void {
        if (!this.commonName) {
            this.setErrorTextByName('commonName', Validatable.errorTexts.needValue)
        }
        if (!this.flankSize) {
            this.setErrorTextByName('flankSize', Validatable.errorTexts.needValue)
        }
        else if (this.flankSize < 0) {
            this.setErrorTextByName('flankSize', `숫자 0 이상`);
        }
        if (!this.tiling) {
            this.setErrorTextByName('tiling', Validatable.errorTexts.needValue)
        }
        else if (this.tiling < 1) {
            this.setErrorTextByName('tiling', `숫자 1 이상`);
        }
        if (!this.SDIDnumberOfGene) {
            this.setErrorTextByName('SDIDnumberOfGene', Validatable.errorTexts.needValue)
        }
        // else if (this.SDIDnumberOfGene <= 0) {
        //     this.setErrorTextByName('SDIDnumberOfGene', Validatable.errorTexts.positiveNumber)
        // }
        // if (!this.SDIDtargetSize) {
        //     this.setErrorTextByName('SDIDtargetSize', Validatable.errorTexts.needValue)
        // }
        // else if (this.SDIDtargetSize <= 0) {
        //     this.setErrorTextByName('SDIDtargetSize', Validatable.errorTexts.positiveNumber)
        // }
        // if (!this.SDIDtargetRegions) {
        //     this.setErrorTextByName('SDIDtargetRegions', Validatable.errorTexts.needValue)
        // }
        // else if (this.SDIDtargetRegions <= 0) {
        //     this.setErrorTextByName('SDIDtargetRegions', Validatable.errorTexts.positiveNumber)
        // }
        if (!this.SDIDuniqueProbes) {
            this.setErrorTextByName('SDIDuniqueProbes', Validatable.errorTexts.needValue)
        }
        // else if (this.SDIDuniqueProbes <= 0) {
        //     this.setErrorTextByName('SDIDuniqueProbes', Validatable.errorTexts.positiveNumber)
        // }
        if (!this.sdidNumOfSelectedProbesForRebalance) {
            this.setErrorTextByName('sdidNumOfSelectedProbesForRebalance', Validatable.errorTexts.needValue)
        }
        else if (this.sdidNumOfSelectedProbesForRebalance < 0) {
            this.setErrorTextByName('sdidNumOfSelectedProbesForRebalance', Validatable.errorTexts.positiveNumber)
        }
        if (!this.sdidNumOfTotalProbesIncludingRepeats) {
            this.setErrorTextByName('sdidNumOfTotalProbesIncludingRepeats', Validatable.errorTexts.needValue)
        }
        else if (this.sdidNumOfTotalProbesIncludingRepeats <= 0) {
            this.setErrorTextByName('sdidNumOfTotalProbesIncludingRepeats', Validatable.errorTexts.positiveNumber)
        }
        // if (this.maskingRegionFile.name) {
        //     if (!Helper.checkExtensionRegex(this.maskingRegionFile.name, 'bed')) {
        //         this.setErrorTextByName('maskingRegionFile', `${Validatable.errorTexts.badFileFormat} -> 확장자: *.bed`);
        //     }
        // }
        // if (!this.synthesisTargetBedFile.name) {
        //     this.setErrorTextByName('synthesisTargetBedFile', Validatable.errorTexts.fileValid)
        // }
        // else if (!Helper.checkExtensionRegex(this.synthesisTargetBedFile.name, 'bed')) {
        //     this.setErrorTextByName('synthesisTargetBedFile', `${Validatable.errorTexts.badFileFormat} -> 확장자: *.bed`);
        // }
    }
}
