import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import ITaskForm from '../TaskFormProps';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import ChipDistributionInputContainer from './ChipDistributionInputContainer';
import ChipDistributionStore from '../../../../../../../../data/store/pageStore/design/ChipDistributionStore';
import ChipDistribution from '../../../../../../../../data/model/design/taskDatas/chipDistribution/ChipDistribution';
import TitledPaper from '../../../../../../../common/component/TitledPaper';
import FormButtonSet from '../../../../../../../common/component/FormButtonSet';
import DesignWork from '../../../../../../../../data/model/design/DesignWork';
import PTSMessage from '../../../../../../../../data/model/common/PTSMessage';
import Validatable from '../../../../../../../../data/model/common/Validatable';

interface Props extends ITaskForm {
}


let store = observable(new ChipDistributionStore());
store.taskData.detail = observable(new ChipDistribution());

const ChipDistributionForm: React.FC<Props> = inject('rootStore')(observer((props) => {
    const globalStore = props.rootStore.globalStore;
    const workInfo = props.workInfo as DesignWork;
    store.workInfo = workInfo;
    store.taskData._id = (workInfo.taskList[0] as any)._id;
    store.taskData.workID = (workInfo.taskList[0] as any).workID;
    props.rootStore.commentStore.stage = 'Chip distribution';

    useEffect(() => {
        if (store.sids.length > 0) {
            return;
        }
        if (store.designName) {
            return;
        }
        store.init(globalStore.token).then((res) => {
            return res;
        }).catch((err) => {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
        });
    }, [workInfo, globalStore.token]);

    const onListClear = (e: any, index: number) => {
        store.sids[index].chipInfos = [];
    }

    const onFormSubmit = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        try {
            if (!window.confirm('제출하시겠습니까?')) {
                return;
            }
            globalStore.isRunning = true;

            const result = await store.setPostData(store.workInfo.workID, globalStore.token);
            store.taskData.detail.chipInfos = result;
            store.taskData.detail.chipInfosOID = result.map(v => { return v._id });
            props.postTaskData(store.taskData);
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
        }
        finally {
            globalStore.isRunning = false;
        }
    }

    const onFormClear = (e: any) => {
        window.location.reload();
    }

    const onVlidate = (e: any) => {
        store.isValid = true;
        for (let sid of store.sids) {
            if (sid.chipInfos.length === 0) {
                continue;
            }
            for (let chipInfo of sid.chipInfos) {
                chipInfo.initErrors();
                chipInfo.resetErrorsRecursive();
                chipInfo.validateRecursive();
                chipInfo.initErrorByKey('synthesisChipID');
                chipInfo.initErrorByKey('PCRConcentration');
                if (chipInfo.hasErrorRecursive()) {
                    store.isValid = !chipInfo.hasErrorRecursive();
                }
            }
        }
        if (!store.isValid) {
            alert(Validatable.errorTexts.notValid);
        }
        return true;
    }

    return (
        <React.Fragment>
            <Grid item container>
                <Grid xl lg sm item>
                    <TitledPaper
                        title='Chip distribution infos'>
                        <Grid item container alignItems='center' style={{ marginTop: 10 }}>
                            <Grid item container direction='column'>
                                {store.sids.map((v, i) => (
                                    <Grid item xl lg sm key={i}>
                                        <ChipDistributionInputContainer
                                            workID={workInfo.workID}
                                            index={i}
                                            onListClear={onListClear}
                                            sid={v.Sid}
                                            store={store}
                                            list={v.chipInfos}
                                            designName={store.workInfo.designName} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </TitledPaper>
                </Grid>
            </Grid>
            <Grid item container xl lg sm style={{ marginTop: 10 }}>
                <Grid item xl lg sm>
                    <FormButtonSet
                        validated={store.isValid}
                        onSubmit={onFormSubmit}
                        onFormClear={onFormClear}
                        onValidate={onVlidate} />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}));

export default ChipDistributionForm;