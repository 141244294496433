import React from 'react';
import { observer } from 'mobx-react';
import { Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@material-ui/core';

interface Props<T> {
    datas?: T[];
    headers?: (keyof T)[];
    rows?: JSX.Element[];
    align?: "inherit" | "left" | "center" | "right" | "justify";
    useindex?: boolean;
    headerTranslation?: (key: string) => string;
    preRenderRows?: boolean;
    additionalHeaders?: string[];
}

const renderJSX = (ele: JSX.Element) => {
    return ele;
}

const PTSTable: React.FC<Props<any>> = observer((props) => {
    const headerTranslation = props.headerTranslation || ((key: string) => { return key });
    const datas = props.datas || [];
    const headers = props.headers || [];
    const rows = props.rows || [];
    const useindex = props.useindex || false;
    const align = useindex ?
        props.align || 'right'
        :
        props.align || 'left';
    const preRenderRows = props.preRenderRows || false;
    const additionalHeaders = props.additionalHeaders || [];

    return (
        <React.Fragment>
            <Table style={{ background: 'white', margin: 10 }}>
                <TableHead>
                    {
                        <TableRow>
                            {useindex &&
                                <TableCell component='th' scope='row'>
                                    <Typography variant='subtitle1'>
                                        #
                                    </Typography>
                                </TableCell>
                            }
                            {headers.map((v, i) => (
                                <TableCell align={align} key={i}>
                                    <Typography variant='subtitle1'>
                                        {headerTranslation(v as string)}
                                    </Typography>
                                </TableCell>
                            ))}
                            {
                                additionalHeaders.map((v, i) => (
                                    <TableCell key={`add${i}`}>
                                        {v}
                                    </TableCell>
                                ))
                            }
                        </TableRow>
                    }
                </TableHead>
                <TableBody>
                    {
                        preRenderRows ?
                            <React.Fragment>
                                {rows.map((v, i) => (
                                    v
                                ))}
                            </React.Fragment>
                            :
                            rows.length > 0 ?
                                rows.map((v, i) => (
                                    <TableRow key={`row-${i}`}>
                                        {useindex &&
                                            <TableCell component='th' scope='row'>
                                                {i + 1}
                                            </TableCell>
                                        }
                                        {renderJSX(v)}
                                    </TableRow>
                                ))
                                :
                                datas.map((v, i) => (
                                    <TableRow key={`row-${i}`}>
                                        {useindex &&
                                            <TableCell component='th' scope='row'>
                                                {i + 1}
                                            </TableCell>
                                        }
                                        {
                                            headers.map((e) => (
                                                <TableCell align={align}>
                                                    {v[e]}
                                                </TableCell>
                                            ))
                                        }
                                    </TableRow>
                                ))
                    }
                </TableBody>
            </Table>
        </React.Fragment>
    )
});

export default PTSTable;
