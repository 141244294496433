import React, { useState } from 'react';
import { observer, inject } from 'mobx-react';
import { Grid, TextField, Button } from '@material-ui/core';
import ChipDistributionInputCell from './ChipDistributionInputCell';
import { IRootStore } from '../../../../../../../../data/store/RootStore';
import ChipDistributionStore from '../../../../../../../../data/store/pageStore/design/ChipDistributionStore';
import ChipDistributionInfo from '../../../../../../../../data/model/common/ChipDistributionInfo';
import TitledPaper from '../../../../../../../common/component/TitledPaper';
import Helper from '../../../../../../../../module/Helper';

interface Props extends IRootStore {
    sid: string;
    store: ChipDistributionStore;
    list: ChipDistributionInfo[];
    designName: string;
    index: number;
    workID: number | string;
    onListClear: (e: any, index: number) => void;
}

const ChipDistributionInputContainer: React.FC<Props> = inject('rootStore')(observer((props) => {
    const [subSidNo, setSubSidNo] = useState(0);
    // const globalStore = props.rootStore.globalStore;

    const handleSubSIDList = () => {
        if (props.list.length > 0) {
            return;
        }
        const no = subSidNo;
        for (let i = 0; i < no; i++) {
            const chip = new ChipDistributionInfo(props.workID);
            chip.CDSDID = `${props.sid}-${i + 1}`;
            chip.CDPanelName = `${props.designName}-${props.sid.split('-')[1]}-${i + 1}`;
            //@ts-ignore
            chip.workID = props.workID;
            props.list.push(chip);
        }
    }

    return (
        <React.Fragment>
            <TitledPaper title={props.sid} color='white'>
                <Grid xl lg sm item container alignItems='stretch'>
                    <Grid xl={10} lg={10} sm={10} container item alignItems='stretch'>
                        <TextField
                            fullWidth
                            margin='dense'
                            label='분할수량'
                            type='number'
                            onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                            onChange={(e) => setSubSidNo(parseInt(e.target.value))}
                            value={subSidNo}
                            helperText={
                                Helper.getErrorText(
                                    props.store.sids[props.index].errors.chipInfos
                                )
                            }
                            error={
                                Helper.getErrorState(
                                    props.store.sids[props.index].errors.chipInfos
                                )
                            } />
                    </Grid>
                    <Grid spacing={1} style={{ margin: 10 }} xl lg sm container item direction='column' alignItems='flex-start'>
                        <Grid spacing={1} xl lg sm item container alignItems='center'>
                            <Button
                                style={{ margin: 5 }}
                                fullWidth
                                onClick={(e) => handleSubSIDList()}
                                variant='contained'
                                color='primary'>추가</Button>
                            <Button
                                style={{ margin: 5 }}
                                fullWidth
                                onClick={(e) => { setSubSidNo(0); props.onListClear(e, props.index) }}
                                variant='contained'
                                color='primary'>삭제</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xl lg sm item>
                    {props.list.map((v, i) => (
                        <ChipDistributionInputCell data={v} key={i} designName={props.designName} />
                    ))}
                </Grid>
            </TitledPaper>
        </React.Fragment>
    )
}))

export default ChipDistributionInputContainer;