import { observable } from "mobx";
import ModelBase from "./ModelBase";

export interface IManufactureModelBase {
    type?: string;
    stage?: string;
    projectCode?: string;
    workID?: string;
    createdDate?: Date;
    updatedDate?: Date;
    startDate?: Date;
    endDate?: Date;
    finish?: Boolean;
    _id?: string;
}

export default abstract class ManufactureModelBase extends ModelBase {
    @observable type?: string;
    @observable stage?: string;
    @observable projectCode?: string;
    @observable workID?: string;
    @observable createdDate?: Date;
    @observable updatedDate?: Date;
    @observable startDate?: Date;
    @observable endDate?: Date;
    @observable finishDate?: Date;
    @observable finish?: Boolean;
    @observable _id?: string;
}