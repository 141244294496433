import { observable } from "mobx";
import User from "../../../user/user";
import TaskSubData from "../../DesignTaskSubData";

export default class DesignRequestPersonInfo extends TaskSubData {
    @observable salesRepresentative?: string = '';
    @observable writer?: string;
    @observable salesRepresentativeObj?: User;

    // override
    validateSelf(): void {
        if(!this.salesRepresentative) {
            this.errors.salesRepresentative.setError(true, '값이 필요합니다.');
        }
        if(!this.writer) {
            this.errors.writer.setError(true, '값이 필요합니다.');
        }
    }
}