import React from 'react';
import { inject, observer } from 'mobx-react';
import {
    Grid,
    TextField,
    Theme,
    createStyles,
    withStyles,
    WithStyles,
    InputLabel,
    FormControl,
    Select,
    MenuItem,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Button,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@material-ui/core';
import DateUtil from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import dayjs from 'dayjs';

import TitledPaper from '../../../../../common/component/TitledPaper';
import FormUtil from '../../../../../../module/FormUtil';
import GeneListTable from '../component/GeneListTable';
import FormButtonSet from '../../../../../common/component/FormButtonSet';
import Helper from '../../../../../../module/Helper';
import PTSTooltip from '../../../../../common/component/PTSTooltip';
import { IRootStore } from '../../../../../../data/store/RootStore';

import Lang from '../../../../../../resource/lang/ko.json';
import { IDesignTaskStore } from '../../../../../../data/store/pageStore/design/DesignTaskStore';
import DesignRequestStore from '../../../../../../data/store/pageStore/design/DesignRequestStore';
import PTSMessage from '../../../../../../data/model/common/PTSMessage';
import GeneData from '../../../../../../data/model/design/taskDatas/designReqeust/GeneData';
import DesignRequest from '../../../../../../data/model/design/taskDatas/designReqeust/DesignRequest';
import FindUserDialog from '../../../../../common/component/findUser/FindUserDialog';
import { Search } from '@material-ui/icons';
import SynthSequenceData from '../../../../../../data/model/design/taskDatas/designReqeust/SynthSequenceData';

const styles = (theme: Theme) => createStyles({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginTop: 8,
        marginBottom: 8,
        margin: 4
    },
    forms: {
        marginTop: 8,
        marginBottom: 8,
        margin: 4
    },
    paper: {
        padding: 10,
        paddingBottom: 30,
        margin: 3,
        marginBottom: 6,
    }
});

interface Props extends WithStyles<typeof styles>, IRootStore, IDesignTaskStore<DesignRequest> {
}

@inject('store')
@inject('rootStore')
@observer
class DesignRequestForm extends React.Component<Props> {
    globalStore = this.props.rootStore.globalStore;
    commentStore = this.props.rootStore.commentStore;
    designRequestStore: DesignRequestStore = this.props.store as DesignRequestStore;

    state = {
        fusionList: '',
        hotspotList: '',
        customList: '',
        geneList: '',
        synthRequestSequence: '>[Name1]\n[Sequence1]\n>[Name2]\n[Sequence2]',
    }

    componentDidMount() {
        this.globalStore.currentTaskTitle = Lang.pages.designRequest.words.title;
        this.designRequestStore.data.detail.designRequestPersonInfo.writer = this.globalStore.userInfo.name;
        this.commentStore.mode = 'tmp';
        this.commentStore.stage = '디자인 의뢰';
        this.commentStore.writerName = this.globalStore.userInfo.name;
        this.commentStore.email = this.globalStore.userInfo.email;
        this.globalStore.useComment = true;
    }

    constructor(props: Props) {
        super(props);
        this.designRequestStore.data.detail.initErrorsRecursive();
    }

    resetGeneListStrs = () => {
        this.setState({
            fusionList: '',
            hotspotList: '',
            customList: '',
            geneList: '',
            synthRequestSequence: '',
        })
    }

    onFileChange = (name: string, files: FileList) => {
        const file = files[0];
        const uniqueKey = Helper.generateRandomString();
        const ptsFileObj = Helper.generatePTSFileWithUniqueKey(file, uniqueKey);
        this.designRequestStore.files.set(name, file);
        this.designRequestStore.filesMapping.set(name, ptsFileObj);
        return ptsFileObj;
    }

    handleDateChange = (name: string, data: Date | null) => {
        this.designRequestStore.onChangeDateRecursive(name, data);
    }

    handleListChange = (name: string, data: string) => {
        const list: GeneData[] = [];
        const errorMessage = {
            EmptyValueError: {
                name: 'Empty value error',
                message: '빈값이 있거나 포맷이 맞지 않습니다.'
            }
        }
        try {
            if (!data) {
                return;
            }
            data.trim().split('\n').map((row) => {
                const col = row.split(/[\t\s]/);
                if (col.length !== 4) {
                    throw errorMessage.EmptyValueError;
                }
                if (!col[0] || !col[1] || !col[2] || !col[3]) {
                    throw errorMessage.EmptyValueError;
                }
                if (isNaN(Number(col[1])) || isNaN(Number(col[2]))) {
                    throw errorMessage.EmptyValueError;
                }
                if (!Number.isInteger(Number(col[1])) || !Number.isInteger(Number(col[1]))) {
                    throw errorMessage.EmptyValueError;
                }
                if (Number(col[1]) < 0 || Number(col[2]) < 0) {
                    throw errorMessage.EmptyValueError;
                }
                if (Number(col[1]) > Number(col[2])) {
                    throw errorMessage.EmptyValueError;
                }
                list.push(new GeneData(col[0].trim(), parseInt(col[1]), parseInt(col[2]), col[3].trim(), ''));
                return null;
            });
            const fieldName = name.split('.').pop();
            switch (fieldName) {
                case 'fusionList':
                    this.designRequestStore.data.detail.requestedGeneList.fusionList = list;
                    break;
                case 'hotspotList':
                    this.designRequestStore.data.detail.requestedGeneList.hotspotList = list;
                    break;
                case 'customList':
                    this.designRequestStore.data.detail.requestedGeneList.customList = list;
                    break;
            }
        } catch (err) {
        }

    }

    handleGeneListChange = (): void => {
        try {
            const value = this.state.geneList;
            const split = value.split(/[\t\n\s]/);
            const list: GeneData[] = [];
            split.map((v) => {
                if (v) {
                    list.push(new GeneData(undefined, undefined, undefined, v.trim(), ''));
                    //list.push(v.trim());
                }
                return null;
            })
            this.designRequestStore.data.detail.requestedGeneList.geneList = list;
        } catch (err) {

        }
    }

    handleGeneListClear = (): void => {
        this.setState({ geneList: '' });
        this.designRequestStore.data.detail.requestedGeneList.geneList = [];
    }

    // handleSynthRequestListChange = (): void => {
    //     try {
    //         const value = this.state.synthRequestSequence;
    //         const split = value.split(/[\t\n\s]/);
    //         const list: string[] = [];
    //         split.map((v) => {
    //             if (v) {
    //                 list.push(v.trim());
    //             }
    //             return null;
    //         })
    //         this.designRequestStore.data.detail.requestedGeneList.synthRequestSequence = list;
    //     } catch (err) {

    //     }
    // }

    handleSynthRequestListChange = (): void => {
        try {
            const list: SynthSequenceData[] = [];
            const value = this.state.synthRequestSequence;
            if (value.trim().substring(0, 1) === '>') {
                // Fasta 파일 형식
                const split = value.split(/[>]/);
                split.map((row) => {
                    if (!row) {
                        return null;
                    }
                    const col = row.split(/[\n]/);
                    const firstColSplit = col[0].split(/[\t\s]/);
                    const sequence = row.substring(col[0].length).replaceAll('\t', '').replaceAll(' ', '').replaceAll('\n', '');
                    if (sequence.length < 120) {
                        list.splice(0, list.length);
                        throw alert('합성 시퀀스 사이즈가 120bp 미만입니다.');
                    }
                    list.push(new SynthSequenceData(firstColSplit[0].trim(), sequence, sequence.length, ''));
                    return null;
                })
            } else {
                // Excel 파일 형식
                let namePosition = -1;
                let seqPosition = -1;
                let remarkPosition = -1;

                const split = value.split(/[\n]/);
                const splitFilter = split[0].split(/[\n\t\s]/).filter(i => i === 'Name' || i === 'Sequence' || i === 'Remark');

                if (!splitFilter.includes('Name') || !splitFilter.includes('Sequence') || !splitFilter.includes('Remark')) {
                    return null;
                }

                split.map((row, i) => {
                    if (!row || row === '') {
                        return null;
                    }

                    const col = row.split(/[\t]/);
                    if (i === 0) {
                        namePosition = col.indexOf('Name');
                        seqPosition = col.indexOf('Sequence');
                        remarkPosition = col.indexOf('Remark');
                        return null;
                    }
                    if (col[seqPosition].length < 120) {
                        list.splice(0, list.length);
                        throw alert('합성 시퀀스 사이즈가 120 bp 미만입니다.');
                    }
                    list.push(new SynthSequenceData(col[namePosition], col[seqPosition], col[seqPosition].length, col[remarkPosition]));

                    return null;
                })
            }
            this.designRequestStore.data.detail.requestedGeneList.synthRequestSequence = list;
        } catch (err) {
        }
    }

    handleSynthRequestListClear = (): void => {
        this.setState({ synthRequestSequence: '' });
        this.designRequestStore.data.detail.requestedGeneList.synthRequestSequence = [];
    }

    handleListClear = (name: string) => {
        const fieldName = name.split('.').pop();
        switch (fieldName) {
            case 'fusionList':
                this.designRequestStore.data.detail.requestedGeneList.fusionList = [];
                this.setState({
                    fusionList: '',
                });
                break;
            case 'hotspotList':
                this.designRequestStore.data.detail.requestedGeneList.hotspotList = [];
                this.setState({
                    hotspotList: '',
                });
                break;
            case 'customList':
                this.designRequestStore.data.detail.requestedGeneList.customList = [];
                this.setState({
                    customList: '',
                });
                break;
        }
    }

    onValidate = (e?: any) => {
        this.designRequestStore.data.detail.resetErrorsRecursive();
        this.designRequestStore.data.detail.validateRecursive();
        this.designRequestStore.isValid = !this.designRequestStore.data.detail.hasErrorRecursive();
        if (!this.designRequestStore.isValid) {
            alert(Lang.message.notValid);
        }
    }

    onFormClear = (e?: any | null) => {
        window.location.reload();
    }

    onFormSubmit = async (e: React.MouseEvent<HTMLInputElement>) => {
        this.globalStore.isRunning = true;
        if (!this.designRequestStore.isValid) {
            alert('입력값이 유효 하지 않습니다.');
            this.globalStore.isRunning = false;
            return;
        }
        if (!window.confirm('제출 하시겠습니까?')) {
            return;
        }
        try {
            // upload files
            const files = this.designRequestStore.getFileUploadForm();
            if (files) {
                const fileResult = await this.designRequestStore.uploadFiles(files, this.globalStore.token);
                const mapping = Array.from(this.designRequestStore.filesMapping.values());

                for (let i = 0; i < fileResult.data.data.length; i++) {
                    mapping[i].file_id = fileResult.data.data[i];
                }
            }

            const result = await this.designRequestStore.onDesignRequest();
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                    code: result.data.resCode,
                });
            }
            await this.commentStore.addCommentsMany(result.data.data.workID);
            alert(`등록 성공 ID: ${result.data.data.workID}`);
            window.location.reload();
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
            else {
            }
        } finally {
            this.globalStore.isRunning = false;
        }
    }

    onWarning = (e: any): void => {
        if (!e.target.value || e.target.value === 0) {
            return;
        } else if (e.target.value === 'etc.') {
            this.designRequestStore.data.detail.designRequestInfo.geneDB = "N/A"
            alert("1. 디자인이 가능한지 BI와 사전 협의 필요합니다.\n2. GRCh37, GRCh38이 아닐 경우 pilot test가 진행되지 않습니다.");
        } else {
            this.designRequestStore.data.detail.designRequestInfo.geneDB = "Refseq"
        }
    }

    renderGeneListComponent = (): JSX.Element => {
        return (
            <React.Fragment>
                <Grid item container xl lg sm style={{ marginTop: 10 }}>
                    <Grid item xl lg sm>
                        <TitledPaper title={FormUtil.getFieldLabel('geneList')}>
                            <Grid xl lg sm item container alignItems='stretch'>
                                <Grid xl={10} lg={10} sm={10} container item alignItems='stretch'>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={5}
                                        margin='normal'
                                        label={FormUtil.getFieldLabel('geneList')}
                                        onChange={(e) => this.setState({ geneList: e.target.value })}
                                        value={this.state.geneList}
                                        helperText={
                                            Helper.getErrorText(
                                                this.designRequestStore.data.detail.requestedGeneList.errors.geneList
                                            )
                                        }
                                        error={
                                            Helper.getErrorState(
                                                this.designRequestStore.data.detail.requestedGeneList.errors.geneList
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid spacing={1} style={{ margin: 10 }} xl lg sm container item direction='column' alignItems='stretch'>
                                    <Grid spacing={1} xl lg sm item container alignItems='stretch'>
                                        <Button
                                            style={{ margin: 5 }}
                                            fullWidth
                                            onClick={() => this.handleGeneListChange()}
                                            variant='contained'
                                            color='primary'>적용</Button>
                                        <Button
                                            style={{ margin: 5 }}
                                            fullWidth
                                            variant='contained'
                                            onClick={() => this.handleGeneListClear()}
                                            color='secondary'>지움</Button>
                                    </Grid>
                                </Grid>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                #
                                            </TableCell>
                                            <TableCell>
                                                Gene
                                            </TableCell>
                                            <TableCell>
                                                Remark
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.designRequestStore.data.detail.requestedGeneList.geneList.map((v, i) => (
                                            <TableRow key={i}>
                                                <TableCell>
                                                    {i + 1}
                                                </TableCell>
                                                <TableCell style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                                                    {v.name}
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        fullWidth
                                                        multiline
                                                        label={FormUtil.getFieldLabel('remark')}
                                                        onChange={(e) => { v.remark = e.target.value }}
                                                        value={v.remark}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </TitledPaper>
                    </Grid>
                </Grid>
            </React.Fragment >
        )
    }

    renderCustomRegionListComponent = (): JSX.Element => {
        return (
            <React.Fragment >
                <Grid item container xl lg sm>
                    <Grid item xl lg sm>
                        <TitledPaper title={FormUtil.getFieldLabel('fusionList')}>
                            <Grid xl lg sm item container alignItems='stretch'>
                                <Grid xl={10} lg={10} sm={10} container item alignItems='stretch'>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={5}
                                        margin='normal'
                                        label={FormUtil.getFieldLabel('fusionList')}
                                        onChange={(e) => this.setState({ fusionList: e.target.value })}
                                        value={this.state.fusionList}
                                        helperText={
                                            Helper.getErrorText(
                                                this.designRequestStore.data.detail.requestedGeneList.errors.fusionList
                                            )
                                        }
                                        error={
                                            Helper.getErrorState(
                                                this.designRequestStore.data.detail.requestedGeneList.errors.fusionList
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid spacing={1} style={{ margin: 10 }} xl lg sm container item direction='column' alignItems='stretch'>
                                    <Grid spacing={1} xl lg sm item container alignItems='stretch'>
                                        <Button
                                            style={{ margin: 5 }}
                                            fullWidth
                                            onClick={
                                                () =>
                                                    this.handleListChange(
                                                        'designRequestStore.data.detail.requestedGeneList.fusionList',
                                                        this.state.fusionList
                                                    )
                                            }
                                            variant='contained'
                                            color='primary'>적용</Button>
                                        <Button
                                            style={{ margin: 5 }}
                                            fullWidth
                                            variant='contained'
                                            onClick={
                                                () =>
                                                    this.handleListClear(
                                                        'designRequestStore.data.detail.requestedGeneList.fusionList'
                                                    )
                                            }
                                            color='secondary'>지움</Button>
                                    </Grid>
                                </Grid>
                                <GeneListTable
                                    list={this.designRequestStore.data.detail.requestedGeneList.fusionList} />
                            </Grid>
                        </TitledPaper>
                    </Grid>
                </Grid>
                <Grid item container xl lg sm>
                    <Grid item xl lg sm>
                        <TitledPaper title={FormUtil.getFieldLabel('hotspotList')}>
                            <Grid xl lg sm item container alignItems='stretch'>
                                <Grid xl={10} lg={10} sm={10} container item alignItems='stretch'>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={5}
                                        margin='normal'
                                        label={FormUtil.getFieldLabel('hotspotList')}
                                        onChange={(e) => this.setState({ hotspotList: e.target.value })}
                                        value={this.state.hotspotList}
                                        helperText={
                                            Helper.getErrorText(
                                                this.designRequestStore.data.detail.requestedGeneList.errors.hotspotList
                                            )
                                        }
                                        error={
                                            Helper.getErrorState(
                                                this.designRequestStore.data.detail.requestedGeneList.errors.hotspotList
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid spacing={1} style={{ margin: 10 }} xl lg sm container item direction='column' alignItems='stretch'>
                                    <Grid spacing={1} xl lg sm item container alignItems='stretch'>
                                        <Button
                                            style={{ margin: 5 }}
                                            fullWidth
                                            onClick={
                                                () =>
                                                    this.handleListChange(
                                                        'designRequestStore.data.detail.requestedGeneList.hotspotList',
                                                        this.state.hotspotList
                                                    )
                                            }
                                            variant='contained'
                                            color='primary'>적용</Button>
                                        <Button
                                            style={{ margin: 5 }}
                                            fullWidth
                                            variant='contained'
                                            onClick={
                                                () =>
                                                    this.handleListClear(
                                                        'designRequestStore.data.detail.requestedGeneList.hotspotList'
                                                    )
                                            }
                                            color='secondary'>지움</Button>
                                    </Grid>
                                </Grid>
                                <GeneListTable
                                    list={this.designRequestStore.data.detail.requestedGeneList.hotspotList} />
                            </Grid>
                        </TitledPaper>
                    </Grid>
                </Grid>
                <Grid item container xl lg sm>
                    <Grid item xl lg sm>
                        <TitledPaper title={FormUtil.getFieldLabel('customList')}>
                            <Grid xl lg sm item container alignItems='stretch'>
                                <Grid xl={10} lg={10} sm={10} container item alignItems='stretch'>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={5}
                                        margin='normal'
                                        label={FormUtil.getFieldLabel('customList')}
                                        onChange={(e) => this.setState({ customList: e.target.value })}
                                        value={this.state.customList}
                                        helperText={
                                            Helper.getErrorText(
                                                this.designRequestStore.data.detail.requestedGeneList.errors.customList
                                            )
                                        }
                                        error={
                                            Helper.getErrorState(
                                                this.designRequestStore.data.detail.requestedGeneList.errors.customList
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid spacing={1} style={{ margin: 10 }} xl lg sm container item direction='column' alignItems='stretch'>
                                    <Grid spacing={1} xl lg sm item container alignItems='stretch'>
                                        <Button
                                            style={{ margin: 5 }}
                                            fullWidth
                                            onClick={
                                                () =>
                                                    this.handleListChange(
                                                        'designRequestStore.data.detail.requestedGeneList.customList',
                                                        this.state.customList
                                                    )
                                            }
                                            variant='contained'
                                            color='primary'>적용</Button>
                                        <Button
                                            style={{ margin: 5 }}
                                            fullWidth
                                            variant='contained'
                                            onClick={
                                                () =>
                                                    this.handleListClear(
                                                        'designRequestStore.data.detail.requestedGeneList.customList'
                                                    )
                                            }
                                            color='secondary'>지움</Button>
                                    </Grid>
                                </Grid>
                                <GeneListTable
                                    list={this.designRequestStore.data.detail.requestedGeneList.customList} />
                            </Grid>
                        </TitledPaper>
                    </Grid>
                </Grid>
            </React.Fragment >
        )
    }

    renderSynthesisSequenceListComponent = (): JSX.Element => {
        return (
            <React.Fragment >
                <Grid item container xl lg sm>
                    <Grid item xl lg sm>
                        <TitledPaper title={FormUtil.getFieldLabel('synthRequestSequence')}>
                            <Grid xl lg sm item container alignItems='stretch'>
                                <Grid xl={10} lg={10} sm={10} container item alignItems='stretch'>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={5}
                                        margin='normal'
                                        label={FormUtil.getFieldLabel('synthRequestSequence')}
                                        onChange={(e) => this.setState({ synthRequestSequence: e.target.value })}
                                        value={this.state.synthRequestSequence}
                                        helperText={
                                            Helper.getErrorText(
                                                this.designRequestStore.data.detail.requestedGeneList.errors.synthRequestSequence
                                            )
                                        }
                                        error={
                                            Helper.getErrorState(
                                                this.designRequestStore.data.detail.requestedGeneList.errors.synthRequestSequence
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid spacing={1} style={{ margin: 10 }} xl lg sm container item direction='column' alignItems='stretch'>
                                    <Grid spacing={1} xl lg sm item container alignItems='stretch'>
                                        <Button
                                            style={{ margin: 5 }}
                                            fullWidth
                                            onClick={() => this.handleSynthRequestListChange()}
                                            variant='contained'
                                            color='primary'>적용</Button>
                                        <Button
                                            style={{ margin: 5 }}
                                            fullWidth
                                            variant='contained'
                                            onClick={() => this.handleSynthRequestListClear()}
                                            color='secondary'>지움</Button>
                                    </Grid>
                                </Grid>
                                {/* <GeneListTable
                                    list={this.designRequestStore.data.detail.requestedGeneList.synthRequestSequence} /> */}
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                #
                                            </TableCell>
                                            <TableCell>
                                                Name
                                            </TableCell>
                                            <TableCell>
                                                Sequence
                                            </TableCell>
                                            <TableCell>
                                                Size
                                            </TableCell>
                                            <TableCell>
                                                Remark
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.designRequestStore.data.detail.requestedGeneList.synthRequestSequence.map((v, i) => (
                                            <TableRow key={i}>
                                                <TableCell>
                                                    {i + 1}
                                                </TableCell>
                                                <TableCell>
                                                    {v.name}
                                                </TableCell>
                                                <TableCell>
                                                    {v.sequence.length <= 30 ?
                                                        v.sequence
                                                        : v.sequence.substring(0, 14) + '...' + v.sequence.substring(v.sequence.length - 15, v.sequence.length)
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {v.size}
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        fullWidth
                                                        multiline
                                                        // margin='normal'
                                                        label={FormUtil.getFieldLabel('remark')}
                                                        onChange={(e) => { v.remark = e.target.value }}
                                                        value={v.remark}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </TitledPaper>
                    </Grid>
                </Grid >
                {/* <Grid item container xl lg sm style={{ padding: 10 }}>
                    <Grid item xl={4} lg={4} sm={4} style={{ marginRight: 10 }}>
                        <Typography variant='body1'>
                            {FormUtil.getFieldLabel('geneListFile')}
                        </Typography>
                    </Grid>
                    <Grid item xl lg sm>
                        <input
                            name='geneListFile'
                            multiple={false}
                            onChange={
                                (e) => {
                                    const obj = this.onFileChange(e.target.name, e.target.files);
                                    this.designRequestStore.data.detail.requestedGeneList.onChangeFileDefault(e.target.name, obj);
                                }
                            }
                            type='file' />
                    </Grid>
                </Grid> */}
            </React.Fragment >
        )
    }

    render() {
        return (
            <form autoComplete='off' className={this.props.classes.container}>
                <Grid xl lg sm
                    item
                    spacing={1}
                    container
                    direction='column'
                    alignItems='stretch'
                    style={{ marginTop: 4 }}>
                    <FindUserDialog
                        users={this.designRequestStore.foundUsers}
                        onClose={this.designRequestStore.onFindUserDiagClose}
                        open={this.designRequestStore.findUserOpen}
                        onSearch={this.designRequestStore.onFindUsers}
                        onSelect={(user) => { this.designRequestStore.onSelectUser(user) }} />
                    <Grid item container>
                        <Grid xl lg sm item>
                            <TitledPaper
                                title={Lang.pages.designRequest.words.personInfo}>
                                <Grid item container direction='column' xl lg sm>
                                    <Grid container item xl lg sm alignItems='flex-end'>
                                        <Grid item>
                                            <Search
                                                onClick={this.designRequestStore.openFindUserDialog}
                                                style={{ marginRight: 5 }} />
                                        </Grid>
                                        <Grid item xl lg sm>
                                            <TextField
                                                InputProps={{
                                                    readOnly: true
                                                }}
                                                required
                                                fullWidth
                                                margin='normal'
                                                label={FormUtil.getFieldLabel('salesRepresentative')}
                                                onChange={this.designRequestStore.data.detail.designRequestPersonInfo.onChangeDefault}
                                                name='salesRepresentative'
                                                helperText={
                                                    Helper.getErrorText(
                                                        this.designRequestStore.data.detail.designRequestPersonInfo.errors.salesRepresentative
                                                    )
                                                }
                                                error={
                                                    Helper.getErrorState(
                                                        this.designRequestStore.data.detail.designRequestPersonInfo.errors.salesRepresentative
                                                    )
                                                }
                                                value={
                                                    this.designRequestStore.data.detail.designRequestPersonInfo.salesRepresentative
                                                } />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <TextField
                                    required
                                    fullWidth
                                    margin='normal'
                                    label={FormUtil.getFieldLabel('writer')}
                                    onChange={this.designRequestStore.data.detail.designRequestPersonInfo.onChangeDefault}
                                    name='writer'
                                    helperText={
                                        Helper.getErrorText(
                                            this.designRequestStore.data.detail.designRequestPersonInfo.errors.writer
                                        )
                                    }
                                    error={
                                        Helper.getErrorState(
                                            this.designRequestStore.data.detail.designRequestPersonInfo.errors.writer
                                        )
                                    }
                                    InputProps={{ readOnly: true }}
                                    value={
                                        this.designRequestStore.data.detail.designRequestPersonInfo.writer || ''
                                    } />
                            </TitledPaper>
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <Grid xl lg sm item>
                            <TitledPaper
                                title={Lang.pages.designRequest.words.designRequestInfo}>
                                <TextField
                                    required
                                    fullWidth
                                    margin='normal'
                                    label={FormUtil.getFieldLabel('erpCode')}
                                    onChange={this.designRequestStore.data.detail.designRequestInfo.onChangeDefault}
                                    name='erpCode'
                                    helperText={
                                        Helper.getErrorText(
                                            this.designRequestStore.data.detail.designRequestInfo.errors.erpCode
                                        )
                                    }
                                    error={
                                        Helper.getErrorState(
                                            this.designRequestStore.data.detail.designRequestInfo.errors.erpCode
                                        )
                                    }
                                    value={
                                        this.designRequestStore.data.detail.designRequestInfo.erpCode
                                    } />
                                <TextField
                                    required
                                    fullWidth
                                    margin='normal'
                                    label={FormUtil.getFieldLabel('projectCode')}
                                    onChange={this.designRequestStore.data.detail.designRequestInfo.onChangeDefault}
                                    name='projectCode'
                                    helperText={
                                        Helper.getErrorText(
                                            this.designRequestStore.data.detail.designRequestInfo.errors.projectCode
                                        )
                                    }
                                    error={
                                        Helper.getErrorState(
                                            this.designRequestStore.data.detail.designRequestInfo.errors.projectCode
                                        )
                                    }
                                    value={
                                        this.designRequestStore.data.detail.designRequestInfo.projectCode
                                    } />
                                <TextField
                                    required
                                    fullWidth
                                    margin='normal'
                                    label={FormUtil.getFieldLabel('customerPI')}
                                    onChange={this.designRequestStore.data.detail.designRequestInfo.customer.onChangeDefault}
                                    name='PI'
                                    helperText={
                                        Helper.getErrorText(
                                            this.designRequestStore.data.detail.designRequestInfo.customer.errors.PI
                                        )
                                    }
                                    error={
                                        Helper.getErrorState(
                                            this.designRequestStore.data.detail.designRequestInfo.customer.errors.PI
                                        )
                                    }
                                    value={
                                        this.designRequestStore.data.detail.designRequestInfo.customer.PI
                                    } />
                                <TextField
                                    // required
                                    fullWidth
                                    margin='normal'
                                    label={FormUtil.getFieldLabel('customerRepresentative')}
                                    onChange={this.designRequestStore.data.detail.designRequestInfo.customer.onChangeDefault}
                                    value={
                                        this.designRequestStore.data.detail.designRequestInfo.customer.representative
                                    }
                                    helperText={
                                        Helper.getErrorText(
                                            this.designRequestStore.data.detail.designRequestInfo.customer.errors.representative
                                        )
                                    }
                                    error={
                                        Helper.getErrorState(
                                            this.designRequestStore.data.detail.designRequestInfo.customer.errors.representative
                                        )
                                    }
                                    name='representative'
                                />
                                <TextField
                                    fullWidth
                                    margin='normal'
                                    label={FormUtil.getFieldLabel('customerContact')}
                                    onChange={this.designRequestStore.data.detail.designRequestInfo.customer.onChangeDefault}
                                    value={
                                        this.designRequestStore.data.detail.designRequestInfo.customer.contact
                                    }
                                    helperText={
                                        Helper.getErrorText(
                                            this.designRequestStore.data.detail.designRequestInfo.customer.errors.contact
                                        )
                                    }
                                    error={
                                        Helper.getErrorState(
                                            this.designRequestStore.data.detail.designRequestInfo.customer.errors.contact
                                        )
                                    }
                                    name='contact'
                                />
                                <TextField
                                    fullWidth
                                    type='email'
                                    margin='normal'
                                    label={FormUtil.getFieldLabel('customerEmail')}
                                    onChange={this.designRequestStore.data.detail.designRequestInfo.customer.onChangeDefault}
                                    value={
                                        this.designRequestStore.data.detail.designRequestInfo.customer.email
                                    }
                                    helperText={
                                        Helper.getErrorText(
                                            this.designRequestStore.data.detail.designRequestInfo.customer.errors.email
                                        )
                                    }
                                    error={
                                        Helper.getErrorState(
                                            this.designRequestStore.data.detail.designRequestInfo.customer.errors.email
                                        )
                                    }
                                    name='email'
                                />
                                <TextField
                                    required
                                    fullWidth
                                    margin='normal'
                                    label={FormUtil.getFieldLabel('designName')}
                                    onChange={this.designRequestStore.data.detail.designRequestInfo.onChangeDefault}
                                    value={
                                        this.designRequestStore.data.detail.designRequestInfo.designName
                                    }
                                    helperText={
                                        Helper.getErrorText(
                                            this.designRequestStore.data.detail.designRequestInfo.errors.designName
                                        )
                                    }
                                    error={
                                        Helper.getErrorState(
                                            this.designRequestStore.data.detail.designRequestInfo.errors.designName
                                        )
                                    }
                                    name='designName'
                                />
                                <TextField
                                    fullWidth
                                    margin='normal'
                                    label={FormUtil.getFieldLabel('diseaseName')}
                                    onChange={this.designRequestStore.data.detail.designRequestInfo.onChangeDefault}
                                    value={
                                        this.designRequestStore.data.detail.designRequestInfo.diseaseName
                                    }
                                    helperText={
                                        Helper.getErrorText(
                                            this.designRequestStore.data.detail.designRequestInfo.errors.diseaseName
                                        )
                                    }
                                    error={
                                        Helper.getErrorState(
                                            this.designRequestStore.data.detail.designRequestInfo.errors.diseaseName
                                        )
                                    }
                                    name='diseaseName'
                                />
                                <FormControl fullWidth margin='normal'>
                                    <InputLabel id='referenceVersion'>{FormUtil.getFieldLabel('referenceVersion')}</InputLabel>
                                    <Select
                                        labelId='referenceVersion'
                                        onChange={this.designRequestStore.data.detail.designRequestInfo.onChangeDefault}
                                        value={
                                            this.designRequestStore.data.detail.designRequestInfo.referenceVersion
                                        }
                                        error={
                                            Helper.getErrorState(
                                                this.designRequestStore.data.detail.designRequestInfo.errors.referenceVersion
                                            )
                                        }
                                        name='referenceVersion'
                                        onClick={this.onWarning}
                                    >
                                        <MenuItem value=''>None</MenuItem>
                                        <MenuItem value='GRCh37'>GRCh37</MenuItem>
                                        <MenuItem value='GRCh38'>GRCh38</MenuItem>
                                        <MenuItem value='etc.'>etc.</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    required
                                    fullWidth
                                    margin='normal'
                                    label={FormUtil.getFieldLabel('geneDatabase')}
                                    onChange={this.designRequestStore.data.detail.designRequestInfo.onChangeDefault}
                                    value={
                                        this.designRequestStore.data.detail.designRequestInfo.geneDB
                                    }
                                    helperText={
                                        Helper.getErrorText(
                                            this.designRequestStore.data.detail.designRequestInfo.errors.geneDB
                                        )
                                    }
                                    error={
                                        Helper.getErrorState(
                                            this.designRequestStore.data.detail.designRequestInfo.errors.geneDB
                                        )
                                    }
                                    name='geneDB'
                                    InputProps={{ readOnly: true }}
                                />
                                <MuiPickersUtilsProvider utils={DateUtil}>
                                    <FormControl fullWidth margin='normal'>
                                        <Grid container item>
                                            <Grid item xl lg sm>
                                                <KeyboardDatePicker
                                                    required
                                                    fullWidth
                                                    disableToolbar
                                                    variant="inline"
                                                    format="yyyy/MM/dd"
                                                    label={FormUtil.getFieldLabel('requestDate')}
                                                    value={
                                                        dayjs(this.designRequestStore.data.detail.designRequestInfo.requestDate)
                                                    }
                                                    name='designRequestStore.data.detail.designRequestInfo.requestDate'
                                                    onChange={(date) => this.handleDateChange('designRequestStore.data.detail.designRequestInfo.requestDate', date)}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                    helperText={
                                                        Helper.getErrorText(
                                                            this.designRequestStore.data.detail.designRequestInfo.errors.requestDate
                                                        )
                                                    }
                                                    error={
                                                        Helper.getErrorState(
                                                            this.designRequestStore.data.detail.designRequestInfo.errors.requestDate
                                                        )
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xl lg sm>
                                                <KeyboardDatePicker
                                                    required
                                                    fullWidth
                                                    disableToolbar
                                                    variant="inline"
                                                    format="yyyy/MM/dd"
                                                    label={FormUtil.getFieldLabel('dueDate')}
                                                    value={
                                                        dayjs(this.designRequestStore.data.detail.designRequestInfo.dueDate)
                                                    }
                                                    name='designRequestStore.data.detail.designRequestInfo.dueDate'
                                                    onChange={(date) => this.handleDateChange('designRequestStore.data.detail.designRequestInfo.dueDate', date)}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                    helperText={
                                                        Helper.getErrorText(
                                                            this.designRequestStore.data.detail.designRequestInfo.errors.dueDate
                                                        )
                                                    }
                                                    error={
                                                        Helper.getErrorState(
                                                            this.designRequestStore.data.detail.designRequestInfo.errors.dueDate
                                                        )
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </FormControl>
                                </MuiPickersUtilsProvider>
                                <FormControl
                                    error={
                                        Helper.getErrorState(
                                            this.designRequestStore.data.detail.designRequestInfo.errors.masking
                                        )
                                    }
                                    component='fieldset' margin='normal' fullWidth>
                                    <FormLabel component='legend'>
                                        {FormUtil.getFieldLabel('masking')}
                                    </FormLabel>
                                    <RadioGroup
                                        value={
                                            this.designRequestStore.data.detail.designRequestInfo.masking
                                        }
                                        name='masking'
                                        onChange={this.designRequestStore.data.detail.designRequestInfo.onChangeDefault}
                                    >
                                        <FormControlLabel
                                            value='YES'
                                            control={<Radio color='secondary' />}
                                            label='Masking 함' />
                                        <FormControlLabel
                                            value='NO'
                                            control={<Radio color='secondary' />}
                                            label='Masking 하지않음' />
                                    </RadioGroup>
                                </FormControl>
                                <PTSTooltip
                                    title={Helper.getTooltip('homologCutOffNumber')}>
                                    <TextField
                                        required
                                        helperText={
                                            Helper.getErrorText(
                                                this.designRequestStore.data.detail.designRequestInfo.errors.homologCutOffNumber
                                            )
                                        }
                                        error={
                                            Helper.getErrorState(
                                                this.designRequestStore.data.detail.designRequestInfo.errors.homologCutOffNumber
                                            )
                                        }
                                        fullWidth
                                        type='number'
                                        margin='normal'
                                        label={FormUtil.getFieldLabel('homologCutOffNumber')}
                                        onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                                        onChange={this.designRequestStore.data.detail.designRequestInfo.onChangeDefault}
                                        value={
                                            this.designRequestStore.data.detail.designRequestInfo.homologCutOffNumber
                                        }
                                        name='homologCutOffNumber'
                                    />
                                </PTSTooltip>
                            </TitledPaper>
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <Grid item xl lg sm>
                            <TitledPaper title={Lang.pages.designRequest.words.requestedGeneList}>
                                <FormControl
                                    fullWidth margin='normal'>
                                    <InputLabel id='geneListType'>{FormUtil.getFieldLabel('geneListType')}</InputLabel>
                                    <Select
                                        error={
                                            Helper.getErrorState(
                                                this.designRequestStore.data.detail.requestedGeneList.errors.geneListType
                                            )
                                        }
                                        labelId='geneListType'
                                        onChange={this.designRequestStore.data.detail.requestedGeneList.onChangeDefault}
                                        value={
                                            this.designRequestStore.data.detail.requestedGeneList.geneListType
                                        }
                                        name='geneListType'
                                    >
                                        <MenuItem value='None'>None</MenuItem>
                                        <MenuItem value='CDS'>CDS</MenuItem>
                                        <MenuItem value='CDS+UTR'>CDS+UTR</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    fullWidth
                                    type='number'
                                    margin='normal'
                                    label={FormUtil.getFieldLabel('geneExpand')}
                                    onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                                    onChange={this.designRequestStore.data.detail.requestedGeneList.onChangeDefault}
                                    value={
                                        this.designRequestStore.data.detail.requestedGeneList.geneExpand
                                    }
                                    helperText={
                                        Helper.getErrorText(
                                            this.designRequestStore.data.detail.requestedGeneList.errors.geneExpand
                                        )
                                    }
                                    error={
                                        Helper.getErrorState(
                                            this.designRequestStore.data.detail.requestedGeneList.errors.geneExpand
                                        )
                                    }
                                    name='geneExpand'
                                />
                                {this.renderGeneListComponent()}
                            </TitledPaper>
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <Grid item xl lg sm>
                            <TitledPaper title={Lang.pages.designRequest.words.customRegionList}>
                                {this.renderCustomRegionListComponent()}
                            </TitledPaper>
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <Grid item xl lg sm>
                            <TitledPaper title={Lang.pages.designRequest.words.synthesisSequenceList}>
                                {this.renderSynthesisSequenceListComponent()}
                            </TitledPaper>
                        </Grid>
                    </Grid>
                    <TitledPaper title='Note'>
                        <TextField
                            label='Note'
                            fullWidth
                            multiline
                            rows={10}
                            value={this.designRequestStore.data.detail.designRequestInfo.designRequestNote}
                            onChange={this.designRequestStore.data.detail.designRequestInfo.onChangeDefault}
                            name='designRequestNote'
                        />
                    </TitledPaper>
                    <Grid item container xl lg sm style={{ marginTop: 10 }}>
                        <Grid item xl lg sm>
                            <FormButtonSet
                                validated={this.designRequestStore.isValid}
                                onValidate={this.onValidate}
                                onSubmit={this.onFormSubmit}
                                onFormClear={this.onFormClear} />
                        </Grid>
                    </Grid>
                </Grid>
            </form >
        )
    }
}

export default withStyles(styles)(DesignRequestForm);
