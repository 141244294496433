import React from 'react';
import {
    Redirect,
    Route,
} from 'react-router-dom';
import LoginUtil from '../../../module/LoginUtil';

const PrivateRoute = (
    {
        component,
        ...rest
    }: any) => {
    if (!component) {
        return <Redirect to={{ pathname: '/app-error' }} />
    }

    return <Route {...rest} render={
        props =>
            LoginUtil.isLoggedIn()
                // && LoginUtil.LoginStatusCheck()
                ?
                React.createElement(component, props)
                :
                (<Redirect to={{ pathname: '/login', state: { from: props.location } }} />)
    } />;
}

export default PrivateRoute;