import React from 'react';
import { observer } from 'mobx-react';
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import ManufactureItemFormRow from './ManufactureItemFormRow';
import ExtendedMUISTextField from '../../../../common/component/ExtendedMUISTextfield';
import ManufactureItemFormStore from '../../../../../data/store/pageStore/manufacture/ManufactureItemFormStore';
import ManufactureWork from '../../../../../data/model/manufacture/ManufactureWork';
import ManufactureItem from '../../../../../data/model/manufacture/ManufactureItem';
import PTSTable from '../../../../common/component/task/table/PTSTable';

interface Props {
    data: ManufactureWork;
    inputHeaders: (keyof ManufactureItem)[];
    readonlyHeaders: (keyof ManufactureItem)[];
}

const store = new ManufactureItemFormStore();

const ManufactureItemsForm: React.FC<Props> = observer((props) => {
    const data = props.data;
    store.itemList = props.data.itemList;

    // 테이블 해더
    const inputHeaders = !props.inputHeaders || props.inputHeaders.length < 1 ?
        []
        :
        props.inputHeaders;

    const readonlyHeaders = !props.readonlyHeaders || props.readonlyHeaders.length < 1 ?
        []
        :
        props.readonlyHeaders;

    const tableHeaders = readonlyHeaders.concat(inputHeaders);

    const renderRows = (): JSX.Element[] => {
        const rows: JSX.Element[] = store.itemList.map((v, i) => {
            return (
                <ManufactureItemFormRow
                    inputHeaders={inputHeaders}
                    readonlyHeaders={readonlyHeaders}
                    data={v}
                    productType={data.productType}
                    index={i}
                />)
        })
        return rows;
    }

    const headerTrans = (str: string): string => {
        return (ManufactureItem.HeaderList as any)[str] || str;
    }

    const prodTrans = (str: string) => {
        if (str in ManufactureWork.ProductTypeList) {
            return (ManufactureWork.ProductTypeList as any)[str];
        }
        else {
            return str;
        }
    }
    const indexTrans = (str: string) => {
        if (str in ManufactureWork.IndexList) {
            return (ManufactureWork.IndexList as any)[str];
        }
        else {
            return str;
        }
    }
    const probeTrans = (str: string) => {
        if (str in ManufactureWork.ProbeStateList) {
            return (ManufactureWork.ProbeStateList as any)[str];
        }
        else {
            return str;
        }
    }

    return (
        <React.Fragment>
            <Grid container direction='column'>
                <Grid item xl lg md style={{ marginTop: 10 }}>
                    <ExtendedMUISTextField
                        rootdata={data}
                        value={prodTrans(data.productType)}
                        readOnly
                        name='productType'
                        margin='normal'
                    />
                    <ExtendedMUISTextField
                        rootdata={data}
                        value={indexTrans(data.index)}
                        readOnly
                        name='index'
                        margin='normal'
                    />
                    <ExtendedMUISTextField
                        rootdata={data}
                        value={probeTrans(data.probeState)}
                        readOnly
                        name='probeState'
                        margin='normal'
                    />
                </Grid>
                <Grid item xl lg sm md>
                    <FormControl
                        disabled={readonlyHeaders.length > 0}
                        component="fieldset"
                        error={data.errors.manufactureVersion?.isError}>
                        <FormLabel component="legend">
                            Version
                        </FormLabel>
                        <RadioGroup
                            row
                            onChange={e => data.onChangeDefault(e)}
                            aria-label="manufactureVersion"
                            name="manufactureVersion"
                            value={data.manufactureVersion}>
                            <FormControlLabel
                                value="v1"
                                control={<Radio />} label="V1" />
                            <FormControlLabel
                                value="v2"
                                control={<Radio />} label="V2" />
                            <FormControlLabel
                                value="v3"
                                control={<Radio />} label="V3" />
                        </RadioGroup>
                        <FormHelperText>{data.errors.manufactureVersion?.errorText}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xl lg md>
                    <PTSTable
                        headerTranslation={headerTrans}
                        headers={tableHeaders}
                        align='inherit'
                        useindex
                        preRenderRows
                        rows={renderRows()}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    )
})

export default ManufactureItemsForm;
