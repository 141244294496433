import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import ManufactureInfoForm from '../component/ManufactureInfoForm';
import ManufactureItemsForm from '../component/ManufactureItemsForm';
import ManufactureNoteForm from '../component/ManufactureNoteForm';
import { IRootStore } from '../../../../../data/store/RootStore';
import ManufactureStore from '../../../../../data/store/pageStore/manufacture/ManufactureStore';
import TitledPaper from '../../../../common/component/TitledPaper';
import FormButtonSet from '../../../../common/component/FormButtonSet';
import CommonErrors, { CommonError } from '../../../../../data/model/common/CommonErrors';
import PTSMessage from '../../../../../data/model/common/PTSMessage';
import ManufactureNoteListForm from '../component/ManufactureNoteListForm';
import { Typography } from '@material-ui/core';
import ManufactureCustomerRequestInfoComponent from '../component/ManufactureCustomerRequestInfoComponent';

interface Props extends RouteComponentProps<any>, IRootStore {
}

interface Error {
    name: string;
    message: string;
    code: string;
    errorObj: boolean;
}

@inject('rootStore')
@observer
class ManufactureMainContainer extends React.Component<Props> {
    store = new ManufactureStore();

    globalStore = this.props.rootStore.globalStore;
    commentStore = this.props.rootStore.commentStore;

    readonly errors: { [key: string]: Error } = {
        invalidParams: {
            name: 'Invalid Params',
            message: '잘못된 파라미터',
            code: '0000',
            errorObj: true,
        },
        networkError: {
            name: 'Network Error',
            message: '네트워크 에러',
            code: '0001',
            errorObj: true,
        },
        notValidated: {
            name: 'Data not validated',
            message: '유효하지 않은 값이 있습니다.',
            code: '0002',
            errorObj: true,
        }
    }

    constructor(props: Props) {
        super(props);
        this.globalStore.useComment = true;
        this.commentStore.mode = 'work';
    }

    scrollBottom = () => {
        const obj = document.getElementById('commentContentsList');
        obj.scrollTop = obj.scrollHeight;
    }

    componentDidMount = async () => {
        try {
            this.globalStore.isRunning = true;
            const workID = this.props.match.params.workID;
            const result = await this.store.getManufactureByWorkID(workID, this.globalStore.token);
            const track = await this.store.getManufactureTrack(workID, this.globalStore.token);
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                })
            }
            if (track.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                })
            }
            if (!result.data.data) {
                throw this.errors.networkError;
            }
            if (!track.data.data.current.workType) {
                throw this.errors.networkError;
            }
            if (!track.data.data.current.departType) {
                throw this.errors.networkError;
            }
            if (this.globalStore.userInfo.role.toLocaleLowerCase() !== 'admin') {
                if (track.data.data.current.departType !== this.globalStore.userInfo.departType) {
                    throw CommonErrors.NoAuth;
                }
            }
            this.store.workID = workID;
            this.store.data.fromJS(result.data.data);
            this.globalStore.currentTaskTitle = this.store.getTitleByType(track.data.data.current.workType);
            this.store.setItemList(result.data.data.itemList);
            this.store.setInputHeadersByType(track.data.data.current.workType);
            this.store.setReadonlyHeadersByType(track.data.data.current.workType);
            this.commentStore.workID = workID;
            this.commentStore.stage = this.globalStore.currentTaskTitle;
            await this.commentStore.setCommentsMany();
            this.store.data.shippingRequestDate = this.store.data.shippingRequestDate || this.store.data.determinedReleaseDate;
            this.store.data.shippingRequestAMPM = this.store.data.shippingRequestAMPM || this.store.data.determinedReleaseAMPM;
            this.store.data.changeRequestHistory = result.data.data.changeRequestHistory;
            this.store.setManufactureNumber(result.data.data.manufactureOrderNumberList);
            this.store.setManufactureNoteList(result.data.data.manufactureNoteList);
        } catch (err) {
            if (err instanceof CommonError || err instanceof PTSMessage) {
                alert(err.message);
            }
            else {
                alert('오류 발생');
            }
            this.props.history.push('/main/manufacture/list/process');

        } finally {
            this.globalStore.isRunning = false;
        }
    }

    onFormSubmit = async (e: any) => {
        if (!window.confirm('제출하시겠습니까?')) {
            return;
        }
        try {
            this.globalStore.isRunning = true;

            // api
            const result =
                await this.store.processManufacture(
                    this.globalStore.token
                );

            // result
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                });
            }
            alert('제출완료');
            this.props.history.push(`/main/manufacture/list/process`);
            window.location.reload();
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
            else {
                alert('오류 발생.');
            }
            this.store.isValid = false;
        } finally {
            this.globalStore.isRunning = false;
        }
    }

    onValidate = (e: any) => {
        try {
            // 임시
            this.store.data.resetErrorsRecursive();
            this.store.data.validateRecursive();
            this.store.data.itemList.map((v) => {
                return v.itemName.toLowerCase() === 'tc#3' && v.initErrorByKey('probeIDInputData')
            })
            this.store.isValid = !this.store.data.hasErrorRecursive();
            if (!this.store.isValid) {
                alert('유효하지 않은 값이 있습니다.');
            }
        } catch (err) {

        } finally {

        }
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.store.data.prevWorkID
                    &&
                    <Typography variant='body2'>
                        이전 작업(복사된 작업) ID:&nbsp;
                        <Link to={`/main/manufacture/detail/${this.store.data.prevWorkID}`} target='_blank'>
                            {this.store.data.prevWorkID}
                        </Link>
                    </Typography>
                }
                <TitledPaper title='제조의뢰 정보'>
                    <ManufactureInfoForm data={this.store.data} readOnly />
                    <ManufactureCustomerRequestInfoComponent
                        data={this.store.data}
                        readOnly
                    />
                </TitledPaper>
                <TitledPaper title='제조 아이템 목록' >
                    <ManufactureItemsForm
                        data={this.store.data}
                        readonlyHeaders={this.store.readonlyHeaders}
                        inputHeaders={this.store.inputHeaders} />
                </TitledPaper>
                <TitledPaper title='영업팀 NOTE'>
                    <ManufactureNoteForm data={this.store.data} depart='sales' readOnly />
                </TitledPaper>
                <TitledPaper title='제조파트 NOTE'>
                    <ManufactureNoteListForm data={this.store.data} type={this.store.data.type} />
                </TitledPaper>
                <FormButtonSet
                    onFormClear={() => { window.location.reload() }}
                    onSubmit={this.onFormSubmit}
                    validated={this.store.isValid}
                    onValidate={this.onValidate}
                />
            </React.Fragment>
        )
    }
}

export default withRouter(ManufactureMainContainer);