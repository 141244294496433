import React from 'react';
import { Grid, MenuItem, Paper, Select } from '@material-ui/core';
import SearchFilterForm from './SearchFilterForm';
import { inject, observer } from 'mobx-react';
import { IRootStore } from '../../../../../data/store/RootStore';
import ProjectSearchStore from '../../../../../data/store/pageStore/design/ProjectSearchStore';
import PTSMessage from '../../../../../data/model/common/PTSMessage';
import PTSPagination from './PTSPagination';
import TaskListTable from './TaskListTable';
import WorkInfo from '../../../../../resource/data/works.json';

interface Props extends IRootStore {
}

@inject('rootStore')
@observer
class ProjectSearchTableContainer extends React.Component<Props> {
    store = new ProjectSearchStore(this.props.rootStore);
    globalStore = this.props.rootStore.globalStore;

    componentDidMount = async () => {
        await this.getDatas();
    }

    onPageChange = (e: any, offset: number) => {
        this.store.offSet = offset;
        this.getDatas();
    }

    onFilterChange = (e: React.ChangeEvent<HTMLInputElement> | any) => {
        this.store.onChangeDefault(e);
    }

    onSearch = (e: React.MouseEvent<HTMLElement>) => {
        this.getDatas();
    }

    onFilterClear = (e: React.MouseEvent<HTMLElement>) => {
        this.store.filterText = '';
        this.store.selectedField = '';
    };

    getDatas = async () => {
        try {
            this.globalStore.isRunning = true;
            await this.store.setWorkList(this.globalStore.token);
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err);
            }
        } finally {
            this.globalStore.isRunning = false;
        }
    }

    getFilter = (): any[] => {
        const filter = [];
        let match = {
            $match: { projectCode: { $regex: `.*` } }
        };
        if (this.store.selectedField) {
            (match as any).$match[this.store.selectedField] = {
                $regex: `^.*${this.store.filterText}.*$`,
                $options: 'i'
            }
        }

        filter.push(match);
        filter.push({
            $sort: {
                workID: -1
            }
        });
        filter.push({
            $skip: this.store.offSet
        });
        filter.push({
            $limit: this.store.limit
        });
        filter.push({
            $lookup: {
                from: 'tasks',
                localField: 'workID',
                foreignField: 'workID',
                as: 'tasks',
            }
        });
        filter.push({
            $project: {
                customerAndPI: '$customerAndPI',
                projectCode: '$projectCode',
                workID: '$workID',
                probeID: '$probeID',
                salesRepresentative: '$salesRepresentative',
                designName: '$designName',
                lastTask: { $arrayElemAt: ["$tasks", -1] },
                workStage: '$stage',
                workType: '$type',
                erpCode: '$erpCode',
                workStatus: '$workStatus',
                prevWorkID: '$prevWorkID',
            }
        });
        filter.push({
            $project: {
                customerAndPI: '$customerAndPI',
                projectCode: '$projectCode',
                workID: '$workID',
                probeID: '$probeID',
                salesRepresentative: '$salesRepresentative',
                designName: '$designName',
                taskType: '$lastTask.type',
                taskStage: '$lastTask.stage',
                workStage: '$stage',
                workType: '$type',
                erpCode: '$erpCode',
                workStatus: '$workStatus',
                prevWorkID: '$prevWorkID',
            }
        });
        return filter;
    }

    render() {
        return (
            <React.Fragment>
                <Grid xl lg sm spacing={1} container item direction='column' alignItems='stretch' style={{ marginTop: 4 }}>
                    <Paper style={{ padding: 30 }}>
                        <Grid item xl lg sm container direction='column'>
                            <Grid item container xl lg sm>
                                <Select
                                    style={{ minWidth: 200, marginRight: 8 }}
                                    labelId='selectedStatus'
                                    onChange={this.store.handleStatusChange}
                                    value={this.store.selectedStatus}
                                    name='selectedStatus'>
                                    <MenuItem value={WorkInfo.status.all}>All</MenuItem>
                                    <MenuItem value={WorkInfo.status.running}>진행중</MenuItem>
                                    <MenuItem value={WorkInfo.status.paused}>중단됨</MenuItem>
                                    <MenuItem value={WorkInfo.status.finished}>완료됨</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item container xl lg sm>
                                <SearchFilterForm
                                    selectedField={this.store.selectedField}
                                    filterText={this.store.filterText}
                                    onFilterChange={this.onFilterChange}
                                    onFilterClear={this.onFilterClear}
                                    onSearch={this.onSearch} />
                            </Grid>
                            <Grid item container xl lg sm alignItems='flex-end' direction='column'>
                                <Grid item xl lg sm>
                                    <PTSPagination
                                        limit={this.store.limit}
                                        total={this.store.total}
                                        offset={this.store.offSet}
                                        onClick={this.onPageChange} />
                                </Grid>
                            </Grid>
                            <Grid item xl lg sm>
                                <TaskListTable
                                    headers={this.store.headers}
                                    taskList={this.store.taskList}
                                    perPage={this.store.limit}
                                    total={this.store.total}
                                    offSet={this.store.offSet}
                                    numSelected={this.store.selectedNum}
                                    checkBoxes={false}
                                    onDetailButtonClick={() => { }}
                                />
                            </Grid>
                            <Grid item container xl lg sm alignItems='flex-end' direction='column'>
                                <Grid item xl lg sm>
                                    <PTSPagination
                                        limit={this.store.limit}
                                        total={this.store.total}
                                        offset={this.store.offSet}
                                        onClick={this.onPageChange} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </React.Fragment>
        )
    }
}

export default ProjectSearchTableContainer;