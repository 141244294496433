import { action, observable } from "mobx";
import SynthesisDesign from "../../../model/design/taskDatas/synthesisDesign/SynthesisDesign";
import RebalancingProcessStore from "./RebalancingProcessStore";
import TaskInfo from '../../../../resource/data/tasks.json';
import PTSMessage from "../../../model/common/PTSMessage";

export default class RebalancingSynthDesignProcessStore extends RebalancingProcessStore<SynthesisDesign> {
    @observable prevSIDs: string[] = [];

    @action
    init = async (id: string, token: string) => {
        try {
            await this.prevWorkRecursive(id, token);
            const result = await this.getWholeWorkData(this.prevWorkID, token);
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                });
            }
            this.prevWorkInfo = result.data.data;
            const sids = result.data.data.sids;
            const _sids: string[] = [];
            for (const d of sids) {
                d.sid = d.sid.substring(0, d.sid.indexOf('.'));
                _sids.push(d.sid);
            }
            this.prevSIDs = _sids;
            const synthDesign = this.prevWorkInfo.tasks.filter((v: any) => {
                return v.type === TaskInfo.types.synthDesign
                    || v.type === TaskInfo.types.rebalancingSynthDesign;
            })[0];
            this.taskData.detail.panelName = (synthDesign as any).detail?.panelName ?? '';
        } catch (err) {
            throw err;
        }
    }

    prevWorkRecursive = async (workID: string, token: string) => {
        try {
            if (workID.charAt(0) === 'D') {
                return this.prevWorkID = workID
            }
            const result = await this.getWholeWorkData(workID, token);
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                });
            }
            await this.prevWorkRecursive(result.data.data.prevWorkID, token);
        } catch (err) {
            throw err;
        }
    }
}