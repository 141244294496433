import React from 'react';
import { Tooltip } from '@material-ui/core';
import { TooltipProps } from '@material-ui/core/Tooltip';

interface Props extends TooltipProps {
    placement?: "right-start" | "bottom-end" | "bottom-start" | "bottom" | "left-end" | "left-start" | "left" | "right-end" | "right" | "top-end" | "top-start" | "top";
}

const PTSTooltip: React.FC<Props> = (props) => {
    const placement = props.placement || 'right-start';

    return (
        <React.Fragment>
            <Tooltip
                title={
                    <div style={{ whiteSpace: 'pre-line' }}>
                        {props.title}
                    </div>
                }
                placement={placement}>
                {props.children}
            </Tooltip>
        </React.Fragment>
    )
}

export default PTSTooltip;