import { createStyles, Grid, Paper, Theme, WithStyles, withStyles } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import LayoutStore from '../../../data/store/common/LayoutStore';
import RootStore from '../../../data/store/RootStore';
import Footer from '../component/Footer';
import MainMenuBar from '../component/MainMenuBar';
import MenuDrawer from '../component/MenuDrawer';

interface Props extends WithStyles<typeof styles>{
    rootStore?: RootStore;
}

const styles = (theme?: Theme) => createStyles({
    paper: {
        padding: theme.spacing(2)
    }
})

@inject('rootStore')
@observer
class MainLayoutContainer extends React.Component<Props> {
    rootStore: RootStore = this.props.rootStore;
    layoutStore: LayoutStore;
    classes = this.props.classes;

    constructor(props: Props) {
        super(props);
        this.layoutStore = new LayoutStore(this.rootStore);
    }

    render() {
        return (
            <React.Fragment>
                <div>
                    <div>
                        <MainMenuBar layoutStore={this.layoutStore} />
                        <MenuDrawer layoutStore={this.layoutStore} />
                    </div>
                    <div>
                        <Grid container>
                            <Grid item xl lg sm>
                                <Paper className={this.classes.paper}>
                                    {this.props.children}
                                </Paper>
                            </Grid>
                        </Grid>
                    </div>
                    <div style={{padding: 40}}>
                        <Footer />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(MainLayoutContainer);