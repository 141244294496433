import React from 'react';
import { observer, inject } from 'mobx-react';
import { Grid, Paper } from '@material-ui/core';
import { IRootStore } from '../../../../../../../data/store/RootStore';
import TaskListStore from '../../../../../../../data/store/pageStore/design/TaskListStore';
import PTSPagination from '../../../../../../common/component/task/table/PTSPagination';
import TaskListTable from '../../../../../../common/component/task/table/TaskListTable';
import PTSMessage from '../../../../../../../data/model/common/PTSMessage';

interface Props extends IRootStore {
    taskListStore?: TaskListStore;
}

@inject('rootStore')
@inject('taskListStore')
@observer
class WaitTaskListTableContainer extends React.Component<Props> {
    globalStore = this.props.rootStore.globalStore;
    taskListStore = this.props.taskListStore;

    componentDidMount = () => {
        try {
            this.setList();
        } catch (err) {
        }
    }

    onCheckboxClick = (e: any, key: number) => {
        this.taskListStore.taskList[key].selected = e.currentTarget.checked;
    }

    onHeaderCheckboxClick = (e: any) => {
        this.taskListStore.taskList.map((v) => {
            return v.selected = e.currentTarget.checked;
        });
    }

    onHeaderActionButtonClick = async (e: any) => {
        try {
            const selected = this.taskListStore.taskList.filter((v) => {
                return v.selected;
            });
            if (selected.length < 1) {
                throw new PTSMessage({
                    message: '선택된 항목이 없습니다.',
                })
            }
            if (!window.confirm('접수하시겠습니까?')) {
                return;
            }
            await this.taskListStore.acceptTasksMany(this.globalStore.token);
            alert('접수 완료');
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
        }
    }

    onActionButtonClick = async (e: any, key: number) => {
        try {
            if (!window.confirm('접수하시겠습니까?')) {
                return;
            }
            await this.taskListStore.acceptTask(this.globalStore.token, key);
            alert('접수 완료');
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
        }
    }

    onPageChange = (e: any, offset: number) => {
        this.taskListStore.offSet = offset;
        this.setList();
    }

    setList = async () => {
        try {
            this.globalStore.isRunning = true;
            this.taskListStore.setWaitList(this.globalStore.token);
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
        }
        finally {
            this.globalStore.isRunning = false;
        }
    }

    render() {
        return (
            <React.Fragment>
                <Grid
                    xl
                    lg
                    sm
                    spacing={1}
                    container
                    item
                    direction='column'
                    alignItems='stretch'
                    style={{ marginTop: 4 }}>
                    <Paper style={{ padding: 30 }}>
                        <Grid item xl lg sm container direction='column'>
                            <Grid
                                xl lg
                                sm
                                item
                                container
                                alignItems='flex-end'
                                direction='column'>
                                <Grid item xl lg sm>
                                    <PTSPagination
                                        limit={this.taskListStore.limit}
                                        total={this.taskListStore.total}
                                        offset={this.taskListStore.offSet}
                                        onClick={this.onPageChange} />
                                </Grid>
                            </Grid>
                            <Grid xl lg sm item>
                                <TaskListTable
                                    headers={this.taskListStore.headers}
                                    taskList={this.taskListStore.taskList}
                                    perPage={this.taskListStore.limit}
                                    total={this.taskListStore.total}
                                    offSet={this.taskListStore.offSet}
                                    numSelected={this.taskListStore.selectedNum}
                                    checkBoxes={true}
                                    actionButton={true}
                                    actionButtonText='접수'
                                    headerActionButtonText='선택접수'
                                    onHeaderActionButtonClick={this.onHeaderActionButtonClick}
                                    onHeaderCheckboxClick={this.onHeaderCheckboxClick}
                                    onCheckboxClick={this.onCheckboxClick}
                                    onDetailButtonClick={() => { }}
                                    onActionButtonClick={this.onActionButtonClick}
                                />
                            </Grid>
                            <Grid
                                style={{ padding: 5, margin: 20 }}
                                xl lg
                                sm
                                item
                                container
                                alignItems='flex-end'
                                direction='column'>
                                <Grid item xl lg sm>
                                    <PTSPagination
                                        limit={this.taskListStore.limit}
                                        total={this.taskListStore.total}
                                        offset={this.taskListStore.offSet}
                                        onClick={this.onPageChange} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </React.Fragment >
        )
    }
}

export default WaitTaskListTableContainer;