/**
 * 2023년 10월
 * 신규 업데이트
 */

import { Button, TableCell, TableRow } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import ISnopCell from './ISnopCell';
import NewSnopDesignItem from '../../../../../data/model/snop/NewSnopDesignItem';

interface Props extends ISnopCell<NewSnopDesignItem> {

}

const NewSnopDesignItemCell: React.FC<Props> = (props) => {
    const data = props.data;

    return (
        <TableRow style={{ background: data.colorize }}>
            <TableCell>
                {data.RepName}
            </TableCell>
            <TableCell style={{ whiteSpace: 'pre-line' }}>
                {data.CustomerAndPI}
            </TableCell>
            <TableCell>
                {data.workType}
            </TableCell>
            <TableCell style={{ whiteSpace: 'pre-line' }}>
                {data.WorkStage}
            </TableCell>
            <TableCell>
                {data.designName}
            </TableCell>
            <TableCell>
                {data.sids?.Sid}
            </TableCell>
            <TableCell>
                {data.sids?.commonName}
            </TableCell>
            <TableCell>
                {data.sids?.sdidNumOfTotalProbesIncludingRepeats}
            </TableCell>
            <TableCell>
                {data.DesignEndDate}
            </TableCell>
            <TableCell>
                {data.DesignConfirmDate}
            </TableCell>
            <TableCell>
                {data.ChipDistributionEndDate}
            </TableCell>
            <TableCell>
                {data.Synth1EndDate}
            </TableCell>
            <TableCell>
                <Link
                    style={{ textDecoration: 'none' }}
                    to={
                        {
                            pathname: `/main/design/work/detail/${data.workID}`
                        }
                    }
                    target='_blank'
                >
                    <Button>
                        자세히
                    </Button>
                </Link>
            </TableCell>
        </TableRow>
    )
}

export default observer(NewSnopDesignItemCell);