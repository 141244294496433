import { observable } from "mobx";
import Helper from "../../../../../module/Helper";
import PTSFile from "../../../common/PTSFile";
import Validatable from "../../../common/Validatable";
import TaskSubData from "../../DesignTaskSubData";

export default class Synthesis2 extends TaskSubData {
    @observable usageReport?: PTSFile;

    constructor() {
        super();
        this.usageReport = new PTSFile();
    }

    // override
    validateSelf(): void {
        if (!this.usageReport.name) {
            // this.setErrorTextByName('usageReport', Validatable.errorTexts.fileValid);
        }
        else if (!Helper.checkExtensionRegex(this.usageReport.name, 'xls')) {
            this.setErrorTextByName('usageReport', `${Validatable.errorTexts.badFormat} -> 확장자: *.xlsx 또는 *.xls`);
        }
    }
}