import { Button, Card, Grid, Typography } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import DesignRequest from '../../../../../../../data/model/design/taskDatas/designReqeust/DesignRequest';
import PrintDesignRequestComponent from '../component/PrintDesignRequestComponent';
import { CSVLink } from 'react-csv';
import dayjs from 'dayjs';
import DesignWorkFileContainer from './DesignWorkFileContainer';
import Helper from '../../../../../../../module/Helper';

interface Props {
    data?: DesignRequest;
    isLoaded?: boolean;
    departType?: string;
    workID?: string;
    requestData?: DesignRequest;
}


const PrintDesignRequestContainer: React.FC<Props> = props => {
    const loaded = props.isLoaded || false;
    const data = props.data;
    const ref = useRef();
    const departType = props.departType;
    const workID = props.workID;
    const requestData = props.requestData || null;

    return (
        <React.Fragment>
            <Grid container style={{ marginBottom: 15, marginTop: 10 }}>
                <Grid item xl lg sm md>
                    <ReactToPrint
                        trigger={() =>
                            <Button
                                disabled={!loaded || !data}
                                color='primary'
                                variant='contained'
                                style={{ marginRight: 10 }}>
                                디자인 의뢰 정보 출력
                            </Button>
                        }
                        content={() => ref.current}
                    />
                </Grid>
            </Grid>
            {
                loaded && data
                &&
                <Card style={{ marginBottom: 15 }}>
                    <Typography style={{ background: 'rgb(212, 239, 223)', padding: 5, paddingLeft: 15, fontSize: '0.875rem' }}>
                        디자인 의뢰
                    </Typography>
                    <Grid item xl lg sm md>
                        <CSVLink
                            data={
                                typeof data.requestedGeneList.geneList[0] === 'string' ?
                                    data.requestedGeneList.geneList.map((v, i) => {
                                        return {
                                            '#': i + 1,
                                            'Gene': v,
                                        }
                                    })
                                    :
                                    data.requestedGeneList.geneList
                            }
                            filename={`${data.designRequestInfo.designName}_${dayjs(data.designRequestInfo.requestDate).format('YYYYMMDD')}_genelist`}
                            onClick={() => {
                                if (data.requestedGeneList.geneList.length < 1) {
                                    return false;
                                }
                            }}
                            style={{
                                textDecoration: 'none'
                            }}
                        >
                            <Button disabled={data.requestedGeneList.geneList.length < 1}>
                                Genelist CSV
                                <GetApp />
                            </Button>
                        </CSVLink>
                        <CSVLink
                            data={data.requestedGeneList.fusionList}
                            filename={`${data.designRequestInfo.designName}_${dayjs(data.designRequestInfo.requestDate).format('YYYYMMDD')}_fusionlist`}
                            onClick={() => {
                                if (data.requestedGeneList.fusionList.length < 1) {
                                    return false;
                                }
                            }}
                            style={{
                                textDecoration: 'none'
                            }}
                        >
                            <Button disabled={data.requestedGeneList.fusionList.length < 1}>
                                Fusionlist CSV
                                <GetApp />
                            </Button>
                        </CSVLink>
                        <CSVLink
                            data={data.requestedGeneList.hotspotList}
                            filename={`${data.designRequestInfo.designName}_${dayjs(data.designRequestInfo.requestDate).format('YYYYMMDD')}_hotspotlist`}
                            onClick={() => {
                                if (data.requestedGeneList.hotspotList.length < 1) {
                                    return false;
                                }
                            }}
                            style={{
                                textDecoration: 'none'
                            }}
                        >
                            <Button disabled={data.requestedGeneList.hotspotList.length < 1}>
                                Hotspotlist CSV
                                <GetApp />
                            </Button>
                        </CSVLink>
                        <CSVLink
                            data={data.requestedGeneList.customList}
                            filename={`${data.designRequestInfo.designName}_${dayjs(data.designRequestInfo.requestDate).format('YYYYMMDD')}_customlist`}
                            onClick={() => {
                                if (data.requestedGeneList.customList.length < 1) {
                                    return false;
                                }
                            }}
                            style={{
                                textDecoration: 'none'
                            }}
                        >
                            <Button disabled={data.requestedGeneList.customList.length < 1}>
                                Customlist CSV
                                <GetApp />
                            </Button>
                        </CSVLink>
                        <CSVLink
                            // data={data.requestedGeneList.synthRequestSequence}
                            data={data.requestedGeneList.synthRequestSequence.map((v, i) => {
                                return {
                                    'SynthRequestSequence': '>' + v.name + ':' + v.size + ':' + v.remark + '\n' + v.sequence,
                                }
                            })}
                            filename={`${data.designRequestInfo.designName}_${dayjs(data.designRequestInfo.requestDate).format('YYYYMMDD')}_synthRequestSequence`}
                            onClick={() => {
                                if (data.requestedGeneList.synthRequestSequence.length < 1) {
                                    return false;
                                }
                            }}
                            style={{
                                textDecoration: 'none'
                            }}
                        >
                            <Button disabled={data.requestedGeneList.synthRequestSequence.length < 1}>
                                SynthSequenceList CSV
                                <GetApp />
                            </Button>
                        </CSVLink>
                    </Grid>
                    {
                        Helper.isBipart(departType) &&
                        <DesignWorkFileContainer data={requestData} workID={workID} />
                    }
                </Card>
            }
            {
                loaded && data
                &&
                <div style={{ display: 'none' }}>
                    <PrintDesignRequestComponent data={props.data} ref={ref} />
                </div>
            }
        </React.Fragment >
    )
}

export default observer(PrintDesignRequestContainer);