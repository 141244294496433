import axios from "axios";
import { action, observable } from "mobx";
import PTSMessage from "../../../model/common/PTSMessage";
import SnopDesignItem from "../../../model/snop/SnopDesignItem";
import SnopManufactureItem from "../../../model/snop/SnopManufactureItem";
import RootStore from "../../RootStore";
import StoreBase from "../../StoreBase";
import { words as RouteWords } from '../../../../resource/data/APIRoutes.json';
import NewSnopDesignItem from "../../../model/snop/NewSnopDesignItem";

class SnopStore extends StoreBase {
    @observable tableLimit: number;
    @observable tableTotal: number;
    @observable tableOffset: number;
    @observable manufactureRunningItemList: SnopManufactureItem[];
    @observable manufactureNotRunningItemList: SnopManufactureItem[];
    @observable designRunningItemList: SnopDesignItem[];
    @observable designNotRunningItemList: SnopDesignItem[];
    @observable testNum: number = 0;

    constructor(rootStore: RootStore) {
        super(rootStore);
        this.tableLimit = 0;
        this.tableOffset = 0;
        this.tableTotal = 0;
        this.manufactureRunningItemList = [];
        this.manufactureNotRunningItemList = [];
        this.designRunningItemList = [];
        this.designNotRunningItemList = [];
    }

    @action
    getDatas = async (type: string): Promise<any> => {
        try {
            let result = [];
            if (type.toLowerCase() === 'manufacture') {
                result = await this.getManufactureDatas();
            }
            else if (type.toLowerCase() === 'design') {
                result = await this.getDesignDatas();
            }
            else {

            }
            return result;
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
        }
    }

    getManufactureDatas = async (): Promise<any> => {
        const config = {
            headers: {
                'x-access-token': this.rootStore.globalStore.token,
            }
        }
        return axios.get(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.snop}/${RouteWords.manufacture}`,
            config,
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        });
    }

    getDesignDatas = async (): Promise<any> => {
        const config = {
            headers: {
                'x-access-token': this.rootStore.globalStore.token,
            }
        }
        return axios.get(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.snop}/${RouteWords.design}`,
            config,
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        });
    }

    setManufactureDatas = async () => {
        try {
            const result = await this.getManufactureDatas();
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage
                })
            }
            // this.manufactureItemList = result.data.data.list.map((v: SnopManufactureItem) => {
            //     return new SnopManufactureItem(v);
            // });
            this.manufactureRunningItemList = result.data.data.list.filter((v: SnopManufactureItem) => v.workStatus === 'running').map((v: SnopManufactureItem) => {
                return new SnopManufactureItem(v);
            });
            this.manufactureNotRunningItemList = result.data.data.list.filter((v: SnopManufactureItem) => v.workStatus === 'paused' || v.workStatus === 'finished').map((v: SnopManufactureItem) => {
                return new SnopManufactureItem(v);
            });
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
                window.history.replaceState(null, null, '/');
                window.location.reload();
            }
        }
    }

    setDesignDatas = async () => {
        try {
            const result = await this.getDesignDatas();
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage
                })
            }

            // console.log(JSON.stringify(result, null, '\t'));
            // this.designItemList = result.data.data.list.map((v: SnopDesignItem) => {
            //     return new SnopDesignItem(v);
            // });
            this.designRunningItemList = result.data.data.list.filter((v: NewSnopDesignItem) => v.workStatus === 'running').map((v: NewSnopDesignItem) => {
                return new NewSnopDesignItem(v);
            });
            this.designNotRunningItemList = result.data.data.list.filter((v: NewSnopDesignItem) => v.workStatus === 'paused' || v.workStatus === 'finished').map((v: NewSnopDesignItem) => {
                return new NewSnopDesignItem(v);
            });
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
                window.history.replaceState(null, null, '/');
                window.location.reload();
            }
        }
    }
}

export default SnopStore;