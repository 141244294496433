import { observable } from "mobx";
import ModelBase from "../common/ModelBase";

export interface IDesignModelBase {
    _id?: string;
    version?: string;
    workID?: string;
    type?: string;
    finish?: boolean;
    uuid?: string;
    createdDate?: Date;
    updatedDate?: Date;
    startDate?: Date;
    finishDate?: Date;
}

class DesignModelBase extends ModelBase {
    @observable _id?: string;
    @observable version?: string;
    @observable workID?: string;
    @observable type?: string;
    @observable finish?: boolean;
    @observable uuid?: string;
    @observable createdDate?: Date;
    @observable updatedDate?: Date;
    @observable startDate?: Date;
    @observable finishDate?: Date;

    constructor(params?: IDesignModelBase) {
        super();
        if(params) {
            Object.assign(this, params);
        }
    }
}

export default DesignModelBase;