import React from 'react';
import { observer } from 'mobx-react';
import { Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import ManufactureWork from '../../../../../data/model/manufacture/ManufactureWork';
import ManufactureItem from '../../../../../data/model/manufacture/ManufactureItem';
import { ArrowBack } from '@material-ui/icons';
import Helper from '../../../../../module/Helper';

interface Props {
    data: ManufactureWork;
    readonlyTBDHeaders: (keyof ManufactureItem)[];
}

const ManufactureTBDItemTable: React.FC<Props> = observer((props) => {
    const data = props.data;
    const headers = props.readonlyTBDHeaders || [];

    const headerTrans = (str: string): string => {
        return (ManufactureItem.HeaderList as any)[str] || str;
    }

    return (
        <React.Fragment>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align='center' component='th' scope='row'>
                            <Typography variant='subtitle1'>
                                #
                            </Typography>
                        </TableCell>
                        {
                            headers.map((v, i) => (
                                <TableCell align='center' key={i}>
                                    <Typography variant='subtitle1'>
                                        {headerTrans(v as string)}
                                    </Typography>
                                </TableCell>
                            ))
                        }
                        <TableCell>
                        </TableCell>
                        <TableCell>
                            <Typography align='center' variant='subtitle1'>
                                ProbeID 입력
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data.itemList.filter(v => (v.itemName === 'TC #3' || v.itemName === 'TC#3')
                            && v.probeID && Helper.checkRegex(v.probeID, /^TBD.*/)).map((v, i) => (
                                <TableRow key={`row-${i}`}>
                                    <TableCell align='center' component='th' scope='row'>
                                        {i + 1}
                                    </TableCell>
                                    {
                                        headers.map((e) => (
                                            <TableCell align='center' key={e}>
                                                {v[e]}
                                            </TableCell>
                                        ))
                                    }
                                    <TableCell>
                                        <ArrowBack />
                                    </TableCell>
                                    <TableCell align='center'>
                                        <TextField
                                            fullWidth
                                            onChange={(e) => { v.probeIDInputData = e.target.value }}
                                            value={v.probeIDInputData || ''}
                                            helperText={
                                                Helper.getErrorText(v.errors.probeIDInputData)
                                            }
                                            error={
                                                Helper.getErrorState(v.errors.probeIDInputData)
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                            ))
                    }
                </TableBody>
            </Table >
        </React.Fragment >
    )
})

export default ManufactureTBDItemTable;