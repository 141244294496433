import { observable } from "mobx";
import SynthesisID from "../common/SynthesisID";
import SnopDesignItem from "./SnopDesignItem";
import { ISnopItem } from "./SnopItem";
import TaskInfo from '../../../resource/data/tasks.json';
import WorkInfo from '../../../resource/data/works.json';
import FormUtil from "../../../module/FormUtil";

export interface INewSnopDesignItem extends ISnopItem {
    sids?: SynthesisID;
    colorize?: string;
}

class NewSnopDesignItem extends SnopDesignItem implements INewSnopDesignItem {
    @observable sids?: SynthesisID = null;
    @observable colorize?: string;

    constructor(data?: INewSnopDesignItem) {
        super(data);
        this.setDatas(data);
    }

    /**
     * override
     * @param data
     */
    setDatas(data: INewSnopDesignItem): void {
        try {
            super.setDatas(data);
            this.sids = new SynthesisID(data.sids);
            switch (this.currTaskObj.type) {
                case TaskInfo.types.designConfirm:
                    this.colorize = '#FFFF99';
                    break;
                case TaskInfo.types.chipDistribution:
                    this.colorize = '#F6B26B';
                    break;
                case TaskInfo.types.synth1:
                    this.colorize = '#6AA84F';
                    break;
                case TaskInfo.types.synth2:
                    this.colorize = '#EEEEEE';
                    break;
            }
        } catch (err) {

        }
    }

    get Synth1EndDate() {
        return this.getFormattedDate(this.synth1EndDate, 'YYYY-MM-DD');
    }

    get DesignEndDate() {
        return this.getFormattedDate(this.designEndDate, 'YYYY-MM-DD');
    }

    get DesignConfirmDate() {
        return this.getFormattedDate(this.designConfirmDate, 'YYYY-MM-DD');
    }

    get CustomerAndPI() {
        return this.customerAndPI.replaceAll('_', '\n');
    }

    get WorkStage() {
        return this.currentWorkType + '\n' + this.currentStage + (this.workStatus !== WorkInfo.status.running ? `\n${FormUtil.getFieldLabel(this.workStatus)}` : '');
    }
}

export default NewSnopDesignItem;