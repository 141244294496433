import React from 'react';
import { TableRow, TableCell, Button, Checkbox } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import WorkTaskSummary from '../../../../../data/model/design/taskDatas/designReqeust/WorkTaskSummary';
import Helper from '../../../../../module/Helper';

interface Props {
    taskInfo: WorkTaskSummary;
    headers: string[];
    index: number;
    actionsButtonText?: string;
    checkBoxes?: boolean;
    actionButton?: boolean;
    workID?: string;
    onActionButtonClick?: (e: any, key: number) => void;
    onDetailButtonClick?: (e: any, key: number) => void;
    onCheckboxClick?: (e: any, key: number) => void;
}

const TaskListTableCell: React.FC<Props> = observer((props) => {
    const key = props.index;
    const taskInfo = props.taskInfo;
    const detailLinkBase = '/main/design/work/detail';

    const getBackgroundColor = () => {
        return Helper.taskFieldToString((taskInfo as any).workStatus) === '중단' ? `#e0e0e0` :
            Helper.taskFieldToString((taskInfo.currTaskObj as any).type) !== '완료' && Helper.taskFieldToString((taskInfo as any).workStatus) === '완료' ? '#ddeeed' :
                Helper.taskFieldToString((taskInfo as any).workStatus) === '완료' ? '#c7ebc6' : '';
    }

    return (
        <React.Fragment key={key}>
            <TableRow key={key} style={{ background: getBackgroundColor() }}>
                {props.checkBoxes &&
                    <TableCell padding='checkbox'>
                        <Checkbox
                            checked={taskInfo.selected}
                            onChange={(e) => props.onCheckboxClick(e, key)}
                        />
                    </TableCell>}
                {props.headers.map((v, i) => (
                    <TableCell key={i} align='right'>
                        {(taskInfo as any)[v]}
                    </TableCell>
                ))}
                <TableCell align='right' style={{ whiteSpace: 'pre-line' }}>
                    {Helper.taskFieldToString((taskInfo.currTaskObj as any).type)}
                    {Helper.taskFieldToString((taskInfo.currTaskObj as any).type) !== '완료' &&
                        ` \n` + Helper.taskFieldToString((taskInfo.currTaskObj as any).stage) + ` ` + Helper.taskFieldToString((taskInfo as any).workStatus)}
                </TableCell>
                <TableCell align='right'>
                    <Link
                        target='_blank'
                        to={{
                            pathname: `${detailLinkBase}/${props.workID}`
                        }}
                        style={{ textDecoration: 'none' }}>
                        <Button
                            onClick={(e) => props.onDetailButtonClick(e, key)}
                            variant='text'
                            color='primary'>
                            자세히
                        </Button>
                    </Link>
                </TableCell>
                {
                    props.actionButton &&
                    <TableCell align='right'>
                        <Button
                            onClick={(e) => props.onActionButtonClick(e, key)}
                            variant='contained'
                            color='primary'>
                            {props.actionsButtonText}
                        </Button>
                    </TableCell>
                }

            </TableRow>
        </React.Fragment>
    )
})

export default TaskListTableCell;