import React from 'react';
import { observer } from 'mobx-react';
import { Grid, FormControl, InputLabel, Select, MenuItem, TextField, Button } from '@material-ui/core';

interface Props {
    selectedField?: string;
    filterText?: string;
    onFilterChange?: (e: React.ChangeEvent<HTMLInputElement> | any) => void;
    onFilterClear?: (e: React.MouseEvent<HTMLElement>) => void;
    onSearch?: (e: React.MouseEvent<HTMLElement>) => void;
}

const SearchFilterForm: React.FC<Props> = observer((props) => {
    return (
        <React.Fragment>
            <Grid item container>
                <Grid item xl lg sm>
                    <FormControl margin='normal'>
                        <InputLabel id='selectedField'>
                            검색 필드
                        </InputLabel>
                        <Select
                            style={{ minWidth: 200, marginRight: 8 }}
                            labelId='selectedField'
                            onChange={props.onFilterChange}
                            value={props.selectedField}
                            name='selectedField'>
                            <MenuItem value=''>None</MenuItem>
                            <MenuItem value='projectCode'>고객 코드</MenuItem>
                            <MenuItem value='erpCode'>Project Code (ERP)</MenuItem>
                            <MenuItem value='customerAndPI'>고객 및 PI</MenuItem>
                            <MenuItem value='probeID'>Probe ID</MenuItem>
                            <MenuItem value='designName'>Design Name</MenuItem>
                            <MenuItem value='salesRepresentative'>영업담당자</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        style={{ minWidth: 250, marginRight: 8 }}
                        name='filterText'
                        value={props.filterText}
                        onChange={props.onFilterChange}
                        label='검색어'
                        margin='normal' />
                    <Button onClick={props.onSearch} style={{ marginTop: 30, marginRight: 5 }} variant='contained' color='primary'>찾기</Button>
                    <Button onClick={props.onFilterClear} style={{ marginTop: 30 }} variant='contained' color='secondary'>Clear</Button>
                </Grid>
            </Grid>
        </React.Fragment>
    )
})

export default SearchFilterForm;