import { observable } from "mobx";
import TaskSubData from "../../DesignTaskSubData";
import DesignRquestInfo from "./DesignRequestInfo";
import DesignRequestPersonInfo from "./DesignRequestPersonInfo";
import RebalancingInfo from "./RebalancingInfo";
import RequestedGeneList from "./RequestedGeneList";

export default class DesignRequest extends TaskSubData {
    @observable designRequestInfo: DesignRquestInfo;
    @observable designRequestPersonInfo: DesignRequestPersonInfo;
    @observable requestedGeneList: RequestedGeneList;
    @observable rebalancingInfo: RebalancingInfo;

    constructor() {
        super();
        this.designRequestPersonInfo = new DesignRequestPersonInfo();
        this.designRequestInfo = new DesignRquestInfo();
        this.requestedGeneList = new RequestedGeneList();
        this.rebalancingInfo = new RebalancingInfo();
    }

    // override
    validateSelf(): void {
        return;
    }
}