import React from 'react'
import WorkDetail from './container/WorkDetail';
interface Props {

}

const WorkDetailPage: React.FC<Props> = (props) => {
    return (
        <React.Fragment>
            <WorkDetail />
        </React.Fragment>
    )
}

export default WorkDetailPage;