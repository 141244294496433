import React from 'react';
import { Paper, Button, TextField, Grid, FormControl, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import SynthesisID from '../../../../../../../../data/model/common/SynthesisID';
import PTSFile from '../../../../../../../../data/model/common/PTSFile';
import FormUtil from '../../../../../../../../module/FormUtil';
import TitledPaper from '../../../../../../../common/component/TitledPaper';
import Helper from '../../../../../../../../module/Helper';
import FileWithHelperText from '../../../../../../../common/component/FileWithHelperText';

interface Props {
    data?: SynthesisID;
    index: number;
    onDeleteClick: (e: any, key: number) => void;
    onFileChange: (name: string, files: FileList, index?: number) => PTSFile;
}

const SynthesisIDInputCell: React.FC<Props> = observer((props) => {

    const data = props.data;
    const onDeleteClick = (e: any) => {
        props.onDeleteClick(e, props.index);
    }

    const onCheckboxClick = (e: any) => {
        const { name } = e.target;
        (data as any)[name] = !(data as any)[name];
    }

    return (
        <React.Fragment>
            <Paper style={{ padding: 10, marginTop: 5, marginBottom: 5, background: '#FEF9E7' }} >
                <Grid container direction='column'>
                    <Grid item xl lg sm>
                        <TextField
                            margin='normal'
                            // 요청으로 readonly 해제
                            // InputProps={
                            //     { readOnly: true }
                            // }
                            label={FormUtil.getFieldLabel('sid')}
                            name='sid'
                            onChange={data.onChangeDefault}
                            value={
                                data.sid
                            } />
                        <TextField
                            margin='normal'
                            label={FormUtil.getFieldLabel('designer')}
                            name='designer'
                            onChange={data.onChangeDefault}
                            value={
                                data.designer
                            } />
                        <TextField
                            margin='normal'
                            label={FormUtil.getFieldLabel('commonName')}
                            name='commonName'
                            onChange={data.onChangeDefault}
                            value={
                                data.commonName
                            }
                            helperText={
                                Helper.getErrorText(
                                    data.errors.commonName
                                )
                            }
                            error={
                                Helper.getErrorState(
                                    data.errors.commonName
                                )
                            } />
                        <TextField
                            margin='normal'
                            type='number'
                            label={FormUtil.getFieldLabel('flankSize')}
                            name='flankSize'
                            onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                            onChange={data.onChangeDefault}
                            value={
                                data.flankSize
                            }
                            helperText={
                                Helper.getErrorText(
                                    data.errors.flankSize
                                )
                            }
                            error={
                                Helper.getErrorState(
                                    data.errors.flankSize
                                )
                            } />
                        <TextField
                            margin='normal'
                            type='number'
                            label={FormUtil.getFieldLabel('tiling')}
                            name='tiling'
                            onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                            onChange={data.onChangeDefault}
                            value={
                                data.tiling
                            }
                            helperText={
                                Helper.getErrorText(
                                    data.errors.tiling
                                )
                            }
                            error={
                                Helper.getErrorState(
                                    data.errors.tiling
                                )
                            } />
                        <TextField
                            margin='normal'
                            //type='number'
                            label={FormUtil.getFieldLabel('SDIDnumberOfGene')}
                            name='SDIDnumberOfGene'
                            onChange={data.onChangeDefault}
                            value={
                                data.SDIDnumberOfGene
                            }
                            helperText={
                                Helper.getErrorText(
                                    data.errors.SDIDnumberOfGene
                                )
                            }
                            error={
                                Helper.getErrorState(
                                    data.errors.SDIDnumberOfGene
                                )
                            } />
                        <TextField
                            margin='normal'
                            //type='number'
                            label={FormUtil.getFieldLabel('SDIDtargetSize')}
                            name='SDIDtargetSize'
                            onChange={data.onChangeDefault}
                            value={
                                data.SDIDtargetSize
                            }
                            helperText={
                                Helper.getErrorText(
                                    data.errors.SDIDtargetSize
                                )
                            }
                            error={
                                Helper.getErrorState(
                                    data.errors.SDIDtargetSize
                                )
                            } />
                        <TextField
                            margin='normal'
                            //type='number'
                            label={FormUtil.getFieldLabel('SDIDtargetRegions')}
                            name='SDIDtargetRegions'
                            onChange={data.onChangeDefault}
                            value={
                                data.SDIDtargetRegions
                            }
                            helperText={
                                Helper.getErrorText(
                                    data.errors.SDIDtargetRegions
                                )
                            }
                            error={
                                Helper.getErrorState(
                                    data.errors.SDIDtargetRegions
                                )
                            } />
                        <TextField
                            margin='normal'
                            //type='number'
                            label={FormUtil.getFieldLabel('SDIDuniqueProbes')}
                            name='SDIDuniqueProbes'
                            onChange={data.onChangeDefault}
                            value={
                                data.SDIDuniqueProbes
                            }
                            helperText={
                                Helper.getErrorText(
                                    data.errors.SDIDuniqueProbes
                                )
                            }
                            error={
                                Helper.getErrorState(
                                    data.errors.SDIDuniqueProbes
                                )
                            } />
                        <TextField
                            fullWidth
                            type='number'
                            margin='normal'
                            label={FormUtil.getFieldLabel('sdidNumOfSelectedProbesForRebalance')}
                            onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                            onChange={data.onChangeDefault}
                            name='sdidNumOfSelectedProbesForRebalance'
                            value={
                                data.sdidNumOfSelectedProbesForRebalance
                            }
                            helperText={
                                Helper.getErrorText(
                                    data.errors.sdidNumOfSelectedProbesForRebalance
                                )
                            }
                            error={
                                Helper.getErrorState(
                                    data.errors.sdidNumOfSelectedProbesForRebalance
                                )
                            } />
                        <TextField
                            fullWidth
                            type='number'
                            margin='normal'
                            label={FormUtil.getFieldLabel('sdidNumOfTotalProbesIncludingRepeats')}
                            onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                            onChange={data.onChangeDefault}
                            name='sdidNumOfTotalProbesIncludingRepeats'
                            value={
                                data.sdidNumOfTotalProbesIncludingRepeats
                            }
                            helperText={
                                Helper.getErrorText(
                                    data.errors.sdidNumOfTotalProbesIncludingRepeats
                                )
                            }
                            error={
                                Helper.getErrorState(
                                    data.errors.sdidNumOfTotalProbesIncludingRepeats
                                )
                            } />
                        <Grid item container alignItems='center'>
                            <Grid item>
                                <FormControl>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name='terminalProbes'
                                                checked={data.terminalProbes}
                                                onChange={onCheckboxClick}
                                                value={data.terminalProbes}
                                                color='primary' />
                                        }
                                        label={FormUtil.getFieldLabel('terminalProbes')} />
                                    {/* <FormControlLabel
                                        control={
                                            <Checkbox
                                                name='terminalProbes'
                                                checked={data.terminalProbes}
                                                onChange={onCheckboxClick}
                                                value={data.terminalProbes}
                                                color='primary' />
                                        }
                                        label={FormUtil.getFieldLabel('terminalProbes')}>
                                    </FormControlLabel> */}
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <TitledPaper
                        title='Files' >
                        <Grid item container xl lg sm style={{ padding: 10 }}>
                            <Grid item xl={4} lg={4} sm={4} style={{ marginRight: 10 }}>
                                <Typography variant='body1'>
                                    {FormUtil.getFieldLabel('maskingRegionFile')}
                                </Typography>
                            </Grid>
                            <Grid item xl lg sm>
                                <FileWithHelperText
                                    name='maskingRegionFile'
                                    multiple={false}
                                    onFileChange={props.onFileChange}
                                    data={data}
                                    index={props.index}
                                    helperText={
                                        Helper.getErrorText(
                                            props.data.errors.maskingRegionFile
                                        )
                                    }
                                    error={
                                        Helper.getErrorState(
                                            props.data.errors.maskingRegionFile
                                        )
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid item container xl lg sm style={{ padding: 10 }}>
                            <Grid item xl={4} lg={4} sm={4} style={{ marginRight: 10 }}>
                                <Typography variant='body1'>
                                    {FormUtil.getFieldLabel('synthesisTargetBedFile')}
                                </Typography>
                            </Grid>
                            <Grid item xl lg sm>
                                <FileWithHelperText
                                    name='synthesisTargetBedFile'
                                    multiple={false}
                                    onFileChange={props.onFileChange}
                                    data={data}
                                    index={props.index}
                                    helperText={
                                        Helper.getErrorText(
                                            props.data.errors.synthesisTargetBedFile
                                        )
                                    }
                                    error={
                                        Helper.getErrorState(
                                            props.data.errors.synthesisTargetBedFile
                                        )
                                    }
                                />
                            </Grid>
                        </Grid>
                    </TitledPaper>
                    <Grid item xl lg sm>
                        <Button
                            variant='contained'
                            color='secondary'
                            onClick={onDeleteClick}
                        >
                            delete
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </React.Fragment>
    )
})

export default SynthesisIDInputCell;