import { observer } from 'mobx-react';
import React from 'react';
import TitledPaper from '../../../../common/component/TitledPaper';
import ManufactureCancelReasonComponent from '../component/ManufactureCancelReasonComponent';

interface Props {
    datas?: any[];
}

interface DataProps {
    requestDate?: Date;
    cancelDate?: Date;
    cancelReason?: String;
    requestPerson?: String;
    cancelPerson?: String;
}

class CancelData {
    requestDate: Date = null;
    cancelDate: Date = null;
    cancelReason: String = '';
    requestPerson: String = '';
    cancelPerson: String = '';

    constructor (props: DataProps) {
        Object.assign(this, props);
    }
}

const ManufactureCancelReasonContainer: React.FC<Props> = (props) => {
    return (
        <React.Fragment>
            <TitledPaper title='출하일변경 요청 기록'>
                {
                    Array.isArray(props.datas)
                    &&
                    props.datas.map((v: any) => {
                        const data = new CancelData(v.data);
                        return (
                            <ManufactureCancelReasonComponent
                                data={data}
                            />)
                    })
                }
            </TitledPaper>
        </React.Fragment>
    )
}

export default observer(ManufactureCancelReasonContainer);