import { Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import PTSMessage from '../../../../../../../../data/model/common/PTSMessage';
import Validatable from '../../../../../../../../data/model/common/Validatable';
import BiProbeDetailStore from '../../../../../../../../data/store/pageStore/design/BIProbeDetailStore';
import FormUtil from '../../../../../../../../module/FormUtil';
import Helper from '../../../../../../../../module/Helper';
import FormButtonSet from '../../../../../../../common/component/FormButtonSet';
import TitledPaper from '../../../../../../../common/component/TitledPaper';
import TaskFormProps from '../TaskFormProps';
import TaskInfo from '../../../../../../../../resource/data/tasks.json';
import Synthesis3 from "../../../../../../../../data/model/design/taskDatas/synthesis/Synthesis3";

interface Props extends TaskFormProps, RouteComponentProps<any> {

}

@inject('rootStore')
@observer
class BIProbeDetailForm extends React.Component<Props> {
    @observable synthesis3Data = new Synthesis3();
    globalStore = this.props.rootStore.globalStore;
    store = observable(new BiProbeDetailStore());

    componentDidMount = () => {
        try {
            this.globalStore.isRunning = true;
            const workInfo = this.props.workInfo;
            this.store.workInfo = workInfo;
            this.store.taskData._id = (workInfo.taskList[0] as any)._id;
            this.store.taskData.workID = (workInfo.taskList[0] as any).workID;
            this.props.rootStore.commentStore.stage = 'BI Probe detail';

            /**
             * @TODO 임시로 고쳐 놓음 추후 Synth3 task 두개 생성되는 현상 수정 해야함
             */
            const synth3Filter = workInfo.taskList.filter((v: any) => {
                return (v.type === TaskInfo.types.synth3 && v.finish);
            });
            this.synthesis3Data = ((synth3Filter.pop() as any).detail as Synthesis3);
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
        } finally {
            this.globalStore.isRunning = false;
        }
    }

    onVlidate = () => {
        this.store.taskData.detail.initErrorsRecursive();
        this.store.taskData.detail.resetErrorsRecursive();
        this.store.taskData.detail.validateRecursive();
        this.store.isValid = !this.store.taskData.detail.hasErrorRecursive();
        if (!this.store.isValid) {
            alert(Validatable.errorTexts.notValid);
        }
    }

    onFormClear = () => {
        window.location.reload();
    }

    onFormSubmit = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        try {
            if (!window.confirm('제출하시겠습니까?')) {
                return;
            }
            this.globalStore.isRunning = true;

            this.props.postTaskData(this.store.taskData);
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
        } finally {
            this.globalStore.isRunning = false;
        }
    }

    render() {
        return (
            <React.Fragment>
                <Grid item xl lg sm>
                    <TitledPaper title='패널 개요'>
                        <Grid item container xl lg sm style={{ padding: 10 }}>
                            <TextField
                                fullWidth
                                margin='normal'
                                value={this.synthesis3Data?.probeID || ''}
                                name='probeID'
                                InputProps={{ readOnly: true }}
                                onChange={this.store.taskData.detail.onChangeDefault}
                                label={FormUtil.getFieldLabel('probeID')} />
                            <TextField
                                fullWidth
                                margin='normal'
                                value={this.synthesis3Data?.probeName || ''}
                                name='probeName'
                                InputProps={{ readOnly: true }}
                                onChange={this.store.taskData.detail.onChangeDefault}
                                label={FormUtil.getFieldLabel('probeName')} />
                            {
                                this.synthesis3Data?.sdContents &&
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                #
                                            </TableCell>
                                            <TableCell>
                                                Final SD contents
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {this.synthesis3Data?.sdContents.map((v, i) => (
                                        <TableBody key={i}>
                                            <TableRow>
                                                <TableCell>
                                                    {i + 1}
                                                </TableCell>
                                                <TableCell>
                                                    {v}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    ))}
                                </Table>
                            }
                        </Grid>
                    </TitledPaper>
                </Grid>
                <Grid item xl lg sm>
                    <TitledPaper title='패널 정보 입력'>
                        <Grid item container xl lg sm style={{ padding: 10 }}>
                            <TextField
                                fullWidth
                                margin='normal'
                                value={this.store.taskData.detail.targetSize || ''}
                                name='targetSize'
                                //type='number'
                                onChange={this.store.taskData.detail.onChangeDefault}
                                label={FormUtil.getFieldLabel('targetSize')}
                                helperText={
                                    Helper.getErrorText(
                                        this.store.taskData.detail.errors.targetSize
                                    )
                                }
                                error={
                                    Helper.getErrorState(
                                        this.store.taskData.detail.errors.targetSize
                                    )
                                } />
                            <TextField
                                fullWidth
                                //margin='normal'
                                value={this.store.taskData.detail.numberOfTargetRegion || ''}
                                name='numberOfTargetRegion'
                                //type='number'
                                onChange={this.store.taskData.detail.onChangeDefault}
                                label={FormUtil.getFieldLabel('numberOfTargetRegion')}
                                helperText={
                                    Helper.getErrorText(
                                        this.store.taskData.detail.errors.numberOfTargetRegion
                                    )
                                }
                                error={
                                    Helper.getErrorState(
                                        this.store.taskData.detail.errors.numberOfTargetRegion
                                    )
                                } />
                            <TextField
                                fullWidth
                                margin='normal'
                                value={this.store.taskData.detail.numberOfUniqueProbes || ''}
                                name='numberOfUniqueProbes'
                                type='number'
                                onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                                onChange={this.store.taskData.detail.onChangeDefault}
                                label={FormUtil.getFieldLabel('numberOfUniqueProbes')}
                                helperText={
                                    Helper.getErrorText(
                                        this.store.taskData.detail.errors.numberOfUniqueProbes
                                    )
                                }
                                error={
                                    Helper.getErrorState(
                                        this.store.taskData.detail.errors.numberOfUniqueProbes
                                    )
                                } />
                        </Grid>
                    </TitledPaper>
                </Grid>
                <Grid item xl lg sm>
                    <FormButtonSet
                        validated={this.store.isValid}
                        onFormClear={(e) => this.onFormClear()}
                        onSubmit={(e) => this.onFormSubmit(e)}
                        onValidate={(e) => this.onVlidate()} />
                </Grid>
            </React.Fragment>
        )
    }
}

export default withRouter(BIProbeDetailForm);