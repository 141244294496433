import { observable } from "mobx";
import DesignModelBase, { IDesignModelBase } from "./DesignModelBase";
import TaskSubData from "./DesignTaskSubData";

export interface IDesignTask<T extends TaskSubData> extends IDesignModelBase {
    stage?: string;
    acceptedDate?: string;
    departInfo?: string;
    departID?: string;
    detail?: T;
    taskType?: string;
    writer?: string;
}

class DesignTask<T extends TaskSubData> extends DesignModelBase {
    @observable stage?: string;
    @observable acceptedDate?: string;
    @observable departInfo?: string | object;
    @observable departID?: string;
    @observable departType?: string;
    @observable detail?: T;
    @observable taskType?: string;
    @observable writer?: string;
}

export default DesignTask;