import { action, observable } from "mobx";
import StoreBase from "../StoreBase";
import Package from '../../../../package.json';
import RootStore from "../RootStore";
import User from "../../model/user/user";

export default class GlobalStore extends StoreBase {

    @observable isRunning: boolean;
    @observable isLoggedIn: boolean;
    @observable appVersion: string;
    @observable userInfo: User;
    @observable menuList: JSX.Element[];
    @observable currentTaskTitle: string;
    @observable useComment: boolean;

    token: string;

    constructor(rootStore?: RootStore) {
        super();
        this.isRunning = false;
        this.isLoggedIn = false;
        this.token = '';
        this.appVersion = Package.version;
        this.rootStore = rootStore;
        this.menuList = [];
        this.currentTaskTitle = '';
        this.useComment = false;
    }

    @action
    setIsRunning = (run: boolean) => {
        this.isRunning = run;
    }

    @action
    setMenuList = (menuList: JSX.Element[]) => {
        this.menuList = menuList;
    }
}