import React from 'react';
import { Link } from 'react-router-dom';
import { WithStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Container, Grid } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    center: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }
});

interface Props extends WithStyles<typeof styles> {

}

class TaskNotfound extends React.Component<Props> {
    render() {
        return (
            <React.Fragment>
                <Container >
                    <Grid container direction='column' justify='center' alignItems='stretch'>
                        <Grid item container justify='center' alignItems='center'>
                            <Grid item>
                                <div >
                                    Sorry, Page not found
                                    <div style={{ margin: 5, textAlign: 'center' }}>
                                        <Link to={'/main'}>Go to main</Link>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(TaskNotfound);