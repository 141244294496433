import axios from "axios";
import DesignTask from "../../../model/design/DesignTask";
import DesignTaskStore from "./DesignTaskStore";
import APIRoutes from '../../../../resource/data/APIRoutes.json';
import DesignWork from "../../../model/design/DesignWork";
import RootStore from "../../RootStore";
import DesignRequest from "../../../model/design/taskDatas/designReqeust/DesignRequest";
import User from "../../../model/user/user";
import { observable } from "mobx";
import PTSFile from "../../../model/common/PTSFile";

class DesignRequestStore extends DesignTaskStore<DesignRequest> {

    @observable files: Map<string, File>;
    @observable filesMapping: Map<string, PTSFile>;
    @observable geneListDataType: 'file' | 'text';

    constructor(rootStore?: RootStore) {
        super(rootStore);
        this.data = new DesignTask<DesignRequest>();
        this.data.detail = new DesignRequest();
        this.work = new DesignWork();
        this.geneListDataType = 'text';
        this.files = new Map<string, File>();
        this.filesMapping = new Map<string, PTSFile>();
    }

    setDatasForRequest = () => {

    }

    uploadFiles = (data: FormData, token: string): Promise<any> => {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'multipart/form-data'
            }
        };
        return axios.post(
            `${this.baseURL}/${APIRoutes.words.auth}/${APIRoutes.words.pts}/${APIRoutes.words.api}/${APIRoutes.words.version2}/${APIRoutes.words.files}`,
            data,
            config
        ).then((response) => {
            return response;
        }).catch((err) => {
            alert('파일이 손상되었거나 존재하지 않습니다.')
            throw err;
        })
    }

    onDesignRequest = async (): Promise<any> => {
        const config = {
            headers: {
                'x-access-token': this.rootStore.globalStore.token
            }
        }
        return axios.post(
            `${this.baseURL}/${APIRoutes.words.auth}/${APIRoutes.words.pts}/${APIRoutes.words.api}/${APIRoutes.words.version2}/${APIRoutes.words.design}/${APIRoutes.words.new}`,
            {
                data: {
                    task: this.data,
                    work: this.work,
                },
            },
            config,
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        });
    }

    // override
    onSelectUser = (user: User) => {
        this.selectedUser = user;
        this.data.detail.designRequestPersonInfo.salesRepresentative = user.name;
        this.data.detail.designRequestPersonInfo.salesRepresentativeObj = user;
        this.work.mailingList = [];
        this.work.mailingList.push(user.email);
        this.onFindUserDiagClose();
    }

    resetGeneLists = () => {
        this.data.detail.requestedGeneList.resetAllGeneLists();
        this.data.detail.requestedGeneList.resetGeneListFile();
    }

    getFileUploadForm = (): FormData => {
        const formData = new FormData();
        const fe = Array.from(this.files.values());
        if (fe.length < 1) {
            return null;
        }
        for (const file of fe) {
            formData.append('files', file);
        }
        return formData;
    }
}

export default DesignRequestStore;