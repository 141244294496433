import React from 'react';

import {
    CssBaseline
} from '@material-ui/core';
import FlatPagination from 'material-ui-flat-pagination';

interface Props {
    limit: number;
    total: number;
    offset: number;
    onClick: (e: any, offset: number) => void;
}

const PTSPagination: React.FC<Props> = (props) => {
    return (
        <React.Fragment>
            <CssBaseline />
            <FlatPagination
                limit={props.limit}
                offset={props.offset}
                total={props.total}
                onClick={props.onClick}
                size='large'
            />
        </React.Fragment>
    )
}

export default PTSPagination;