import { Grid, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import React from 'react';
import DesignRequest from '../../../../../../../data/model/design/taskDatas/designReqeust/DesignRequest';
import RebalancingInfo from '../../../../../../../data/model/design/taskDatas/designReqeust/RebalancingInfo';
import FormUtil from '../../../../../../../module/FormUtil';

interface Props {
    data?: DesignRequest;
}

const tdStyle = {
    border: '1px solid',
    padding: 2,
    width: '12.5%'
}

const titleTdStyle = {
    border: '1px solid',
    background: '#e2e2e2',
    padding: 2,
    width: '12.5%'
}

const trStyle = {
    pageBreakInside: 'avoid' as 'avoid',
    pageBreakAfter: 'auto' as 'auto'
    // pageBreakAfter: 'always' as 'always '
}

const renderRebalancing = (info: RebalancingInfo) => {
    if (info.isRebalancing || info.isAddPanelRegion) {
        return (
            <React.Fragment>
                <Grid item xl lg sm>
                    <Typography variant='h5' style={{ marginTop: 25 }}>
                        Rebalancing & 패널영역 추가 정보
                    </Typography>
                </Grid>
                <Grid item xl lg sm>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <tbody>
                            <tr style={trStyle}>
                                <td style={titleTdStyle}>
                                    작성자
                                </td>
                                <td style={tdStyle} colSpan={3}>
                                    {info.writer}
                                </td>
                            </tr>
                            <tr style={trStyle}>
                                <td style={titleTdStyle}>
                                    요청일
                                </td>
                                <td style={tdStyle}>
                                    {dayjs(info.requestDate).format('YYYY/MM/DD')}
                                </td>
                                <td style={titleTdStyle}>
                                    Due date
                                </td>
                                <td style={tdStyle}>
                                    {dayjs(info.dueDate).format('YYYY/MM/DD')}
                                </td>
                            </tr>
                            <tr style={trStyle}>
                                <td style={titleTdStyle}>
                                    Rebalancing 여부
                                </td>
                                <td style={tdStyle}>
                                    {info.isRebalancing ? 'YES' : 'NO'}
                                </td>
                                <td style={titleTdStyle}>
                                    패널영역 추가 여부
                                </td>
                                <td style={tdStyle}>
                                    {info.isAddPanelRegion ? 'YES' : 'NO'}
                                </td>
                            </tr>
                            <tr style={trStyle}>
                                <td style={titleTdStyle}>
                                    Rebalancing Note
                                </td>
                                <td style={tdStyle}>
                                    {info.rebalancingNote}
                                </td>
                                <td style={titleTdStyle}>
                                    패널영역 추가 Note
                                </td>
                                <td style={tdStyle}>
                                    {info.addPanelRegionNote}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Grid>
            </React.Fragment>
        )
    }
}

@observer
class PrintDesignRequestComponent extends React.Component<Props> {

    data = this.props.data;

    render() {
        return (
            <React.Fragment>
                <Grid container direction='column'>
                    <style type='text/css' media='print'>
                        {
                            '@page {margin: 1cm}'
                        }
                    </style>
                    <Grid item xl lg sm>
                        <Typography variant='h4'>
                            디자인 의뢰 정보
                        </Typography>
                    </Grid>
                    <React.Fragment>
                        <Grid item xl lg sm>
                            <Typography variant='h5' style={{ marginTop: 25 }}>
                                기본정보
                            </Typography>
                        </Grid>
                        <Grid item xl lg sm>
                            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                <tbody>
                                    <tr style={trStyle}>
                                        <td style={{ ...titleTdStyle }}>
                                            {FormUtil.getFieldLabel('projectCode')}
                                        </td>
                                        <td style={tdStyle} colSpan={3}>
                                            {this.data.designRequestInfo.projectCode}
                                        </td>
                                    </tr>
                                    <tr style={trStyle}>
                                        <td style={{ ...titleTdStyle }}>
                                            {FormUtil.getFieldLabel('erpCode')}
                                        </td>
                                        <td style={tdStyle} colSpan={3}>
                                            {this.data.designRequestInfo.erpCode}
                                        </td>
                                    </tr>
                                    <tr style={trStyle}>
                                        <td style={titleTdStyle}>
                                            {FormUtil.getFieldLabel('designName')}
                                        </td>
                                        <td style={tdStyle}>
                                            {this.data.designRequestInfo.designName}
                                        </td>
                                        <td style={titleTdStyle}>
                                            {FormUtil.getFieldLabel('customerAndPI')}
                                        </td>
                                        <td style={tdStyle}>
                                            {this.data.designRequestInfo.customer.PI}&nbsp;{this.data.designRequestInfo.customer.representative}
                                        </td>
                                    </tr>
                                    <tr style={trStyle}>
                                        <td style={titleTdStyle}>
                                            {FormUtil.getFieldLabel('customerContact')}
                                        </td>
                                        <td style={tdStyle}>
                                            {this.data.designRequestInfo.customer.contact}
                                        </td>
                                        <td style={titleTdStyle}>
                                            {FormUtil.getFieldLabel('email')}
                                        </td>
                                        <td style={tdStyle}>
                                            {this.data.designRequestInfo.customer.email}
                                        </td>
                                    </tr>
                                    <tr style={trStyle}>
                                        <td style={titleTdStyle}>
                                            {FormUtil.getFieldLabel('diseaseName')}
                                        </td>
                                        <td style={tdStyle}>
                                            {this.data.designRequestInfo.diseaseName}
                                        </td>
                                        <td style={titleTdStyle}>
                                            {FormUtil.getFieldLabel('referenceVersion')}
                                        </td>
                                        <td style={tdStyle}>
                                            {this.data.designRequestInfo.referenceVersion}
                                        </td>
                                    </tr>
                                    <tr style={trStyle}>
                                        <td style={titleTdStyle}>
                                            {FormUtil.getFieldLabel('requestDate')}
                                        </td>
                                        <td style={tdStyle}>
                                            {dayjs(this.data.designRequestInfo.requestDate).format('YYYY/MM/DD')}
                                        </td>
                                        <td style={titleTdStyle}>
                                            {FormUtil.getFieldLabel('dueDate')}
                                        </td>
                                        <td style={tdStyle}>
                                            {dayjs(this.data.designRequestInfo.dueDate).format('YYYY/MM/DD')}
                                        </td>
                                    </tr>
                                    <tr style={trStyle}>
                                        <td style={titleTdStyle}>
                                            {FormUtil.getFieldLabel('dueDateBedFile')}
                                        </td>
                                        <td style={tdStyle}>
                                            {dayjs(this.data.designRequestInfo.dueDateBedFile).format('YYYY/MM/DD')}
                                        </td>
                                        <td style={titleTdStyle}>
                                            {FormUtil.getFieldLabel('masking')}
                                        </td>
                                        <td style={tdStyle}>
                                            {this.data.designRequestInfo.masking}
                                        </td>
                                    </tr>
                                    <tr style={trStyle}>
                                        <td style={titleTdStyle}>
                                            {FormUtil.getFieldLabel('homologCutOffNumber')}
                                        </td>
                                        <td style={tdStyle}>
                                            {this.data.designRequestInfo.homologCutOffNumber}
                                        </td>
                                        <td style={titleTdStyle}>
                                            {FormUtil.getFieldLabel('geneDB')}
                                        </td>
                                        <td style={tdStyle}>
                                            {this.data.designRequestInfo.geneDB}
                                        </td>
                                    </tr>
                                    <tr style={trStyle}>
                                        <td style={titleTdStyle}>
                                            {FormUtil.getFieldLabel('salesRepresentative')}
                                        </td>
                                        <td style={tdStyle}>
                                            {this.data.designRequestPersonInfo.salesRepresentative}
                                        </td>
                                        <td style={titleTdStyle}>
                                            작성자
                                        </td>
                                        <td style={tdStyle}>
                                            {this.data.designRequestPersonInfo.writer}
                                        </td>
                                    </tr>
                                    <tr style={trStyle} >
                                        <td style={titleTdStyle} colSpan={4}>
                                            Note
                                        </td>
                                    </tr>
                                    <tr style={trStyle}>
                                        <td style={{
                                            ...tdStyle,
                                            textAlign: 'start',
                                            height: 50,
                                            whiteSpace: 'pre',
                                        }} colSpan={4}>
                                            {this.data.designRequestInfo.designRequestNote}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Grid>
                    </React.Fragment>
                    {
                        renderRebalancing(this.data.rebalancingInfo)
                    }
                    <Grid item xl lg sm>
                        <Typography variant='h5' style={{ marginTop: 25 }}>
                            {FormUtil.getFieldLabel('requestedGeneList')}&nbsp;정보
                        </Typography>
                    </Grid>
                    <Grid item xl lg sm>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <tbody>
                                <tr style={trStyle}>
                                    <td style={titleTdStyle}>
                                        {FormUtil.getFieldLabel('geneListType')}
                                    </td>
                                    <td style={tdStyle}>
                                        {this.data.requestedGeneList.geneListType}
                                    </td>
                                    <td style={titleTdStyle}>
                                        {FormUtil.getFieldLabel('geneExpand')}
                                    </td>
                                    <td style={tdStyle}>
                                        {this.data.requestedGeneList.geneExpand}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Grid>
                    {
                        this.data.requestedGeneList.geneList.length > 0
                        &&
                        <React.Fragment>
                            <Grid item xl lg sm>
                                <Typography variant='h6'>
                                    {FormUtil.getFieldLabel('geneList')}
                                </Typography>
                            </Grid>
                            <Grid item xl lg sm>
                                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                    <tbody>
                                        <tr style={trStyle}>
                                            <td style={titleTdStyle}>
                                                #
                                            </td>
                                            <td style={titleTdStyle}>
                                                Gene
                                            </td>
                                            <td style={titleTdStyle}>
                                                Remark
                                            </td>
                                        </tr>
                                        {
                                            this.data.requestedGeneList.geneList.map((v, i) => (
                                                typeof v === 'string' ?
                                                    <tr style={trStyle} key={`gene_${i}`}>
                                                        <td style={tdStyle}>
                                                            {i + 1}
                                                        </td>
                                                        <td style={tdStyle}>
                                                            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                                                                {v}
                                                            </div>
                                                        </td>
                                                        <td style={tdStyle}>
                                                            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>

                                                            </div>
                                                        </td>
                                                    </tr>
                                                    :
                                                    <tr style={trStyle} key={`gene_${i}`}>
                                                        <td style={tdStyle}>
                                                            {i + 1}
                                                        </td>
                                                        <td style={tdStyle}>
                                                            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                                                                {v.name}
                                                            </div>
                                                        </td>
                                                        <td style={tdStyle}>
                                                            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                                                                {v.remark}
                                                            </div>
                                                        </td>
                                                    </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </Grid>
                        </React.Fragment>
                    }
                    {
                        this.data.requestedGeneList.fusionList.length > 0
                        &&
                        <React.Fragment>
                            <Grid item xl lg sm style={{ marginTop: 25 }}>
                                <Typography variant='h6'>
                                    {FormUtil.getFieldLabel('fusionList')}
                                </Typography>
                            </Grid>
                            <Grid item xl lg sm>
                                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                    <tbody>
                                        <tr style={trStyle}>
                                            <td style={titleTdStyle}>
                                                #
                                            </td>
                                            <td style={titleTdStyle}>
                                                Chromosome
                                            </td>
                                            <td style={titleTdStyle}>
                                                Start
                                            </td>
                                            <td style={titleTdStyle}>
                                                End
                                            </td>
                                            <td style={titleTdStyle}>
                                                Name
                                            </td>
                                            <td style={titleTdStyle}>
                                                Remark
                                            </td>
                                        </tr>
                                        {
                                            this.data.requestedGeneList.fusionList.map((v, i) => (
                                                <tr style={trStyle} key={`fusionList_${i}`}>
                                                    <td style={tdStyle}>
                                                        {i + 1}
                                                    </td>
                                                    <td style={tdStyle}>
                                                        {v.chromosome}
                                                    </td>
                                                    <td style={tdStyle}>
                                                        {v.start}
                                                    </td>
                                                    <td style={tdStyle}>
                                                        {v.end}
                                                    </td>
                                                    <td style={tdStyle}>
                                                        {v.name.length < 50 ?
                                                            v.name
                                                            :
                                                            <div style={{ width: '700px', wordBreak: 'break-all' }}>
                                                                {v.name}
                                                            </div>
                                                        }
                                                    </td>
                                                    <td style={tdStyle}>
                                                        {v.remark}
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </Grid>
                        </React.Fragment>
                    }
                    {
                        this.data.requestedGeneList.hotspotList.length > 0
                        &&
                        <React.Fragment>
                            <Grid item xl lg sm style={{ marginTop: 25 }}>
                                <Typography variant='h6'>
                                    {FormUtil.getFieldLabel('hotspotList')}
                                </Typography>
                            </Grid>
                            <Grid item xl lg sm>
                                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                    <tbody>
                                        <tr style={trStyle}>
                                            <td style={titleTdStyle}>
                                                #
                                            </td>
                                            <td style={titleTdStyle}>
                                                Chromosome
                                            </td>
                                            <td style={titleTdStyle}>
                                                Start
                                            </td>
                                            <td style={titleTdStyle}>
                                                End
                                            </td>
                                            <td style={titleTdStyle}>
                                                Name
                                            </td>
                                            <td style={titleTdStyle}>
                                                Remark
                                            </td>
                                        </tr>
                                        {
                                            this.data.requestedGeneList.hotspotList.map((v, i) => (
                                                <tr style={trStyle} key={`hotspotList_${i}`}>
                                                    <td style={tdStyle}>
                                                        {i + 1}
                                                    </td>
                                                    <td style={tdStyle}>
                                                        {v.chromosome}
                                                    </td>
                                                    <td style={tdStyle}>
                                                        {v.start}
                                                    </td>
                                                    <td style={tdStyle}>
                                                        {v.end}
                                                    </td>
                                                    <td style={tdStyle}>
                                                        {v.name.length < 50 ?
                                                            v.name
                                                            :
                                                            <div style={{ width: '700px', wordBreak: 'break-all' }}>
                                                                {v.name}
                                                            </div>
                                                        }
                                                    </td>
                                                    <td style={tdStyle}>
                                                        {v.remark}
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </Grid>
                        </React.Fragment>
                    }
                    {
                        this.data.requestedGeneList.customList.length > 0
                        &&
                        <React.Fragment>
                            <Grid item xl lg sm style={{ marginTop: 25 }}>
                                <Typography variant='h6'>
                                    {FormUtil.getFieldLabel('customList')}
                                </Typography>
                            </Grid>
                            <Grid item xl lg sm>
                                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                    <tbody>
                                        <tr style={trStyle}>
                                            <td style={titleTdStyle}>
                                                #
                                            </td>
                                            <td style={titleTdStyle}>
                                                Chromosome
                                            </td>
                                            <td style={titleTdStyle}>
                                                Start
                                            </td>
                                            <td style={titleTdStyle}>
                                                End
                                            </td>
                                            <td style={titleTdStyle}>
                                                Name
                                            </td>
                                            <td style={titleTdStyle}>
                                                Remark
                                            </td>
                                        </tr>
                                        {
                                            this.data.requestedGeneList.customList.map((v, i) => (
                                                <tr style={trStyle} key={`customList_${i}`}>
                                                    <td style={tdStyle}>
                                                        {i + 1}
                                                    </td>
                                                    <td style={tdStyle}>
                                                        {v.chromosome}
                                                    </td>
                                                    <td style={tdStyle}>
                                                        {v.start}
                                                    </td>
                                                    <td style={tdStyle}>
                                                        {v.end}
                                                    </td>
                                                    <td style={tdStyle}>
                                                        {v.name.length < 50 ?
                                                            v.name
                                                            :
                                                            <div style={{ width: '700px', wordBreak: 'break-all' }}>
                                                                {v.name}
                                                            </div>
                                                        }
                                                    </td>
                                                    <td style={tdStyle}>
                                                        {v.remark}
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </Grid>
                        </React.Fragment>
                    }
                    {
                        this.data.requestedGeneList.synthRequestSequence.length > 0
                        &&
                        <React.Fragment>
                            <Grid item xl lg sm style={{ marginTop: 25 }}>
                                <Typography variant='h6'>
                                    {FormUtil.getFieldLabel('synthRequestSequence')}
                                </Typography>
                            </Grid>
                            <Grid item xl lg sm>
                                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                    <tbody>
                                        <tr style={trStyle}>
                                            <td style={titleTdStyle}>
                                                Name
                                            </td>
                                            <td style={titleTdStyle}>
                                                Sequence
                                            </td>
                                            <td style={titleTdStyle}>
                                                Size
                                            </td>
                                            <td style={titleTdStyle}>
                                                Remark
                                            </td>
                                        </tr>
                                        {
                                            this.data.requestedGeneList.synthRequestSequence.map((v, i) => (
                                                <tr style={trStyle} key={`synthRequestSequence_${i}`}>
                                                    <td style={tdStyle}>
                                                        {v.name}
                                                    </td>
                                                    <td style={tdStyle}>
                                                        {v.sequence.length < 50 ?
                                                            <div style={{ width: '550px' }}>
                                                                {v.sequence}
                                                            </div>
                                                            :
                                                            <div style={{ width: '550px', wordBreak: 'break-all' }}>
                                                                {v.sequence}
                                                            </div>
                                                        }
                                                        {/* {v.sequence.length <= 30 ?
                                                        v.sequence
                                                        : v.sequence.substring(0, 14) + '...' + v.sequence.substring(v.sequence.length - 15, v.sequence.length)
                                                    } */}
                                                    </td>
                                                    <td style={tdStyle}>
                                                        {v.size}
                                                    </td>
                                                    <td style={tdStyle}>
                                                        <div style={{ wordBreak: 'break-all' }}>
                                                            {v.remark}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </Grid>
                        </React.Fragment>
                    }
                </Grid >
            </React.Fragment >
        )
    }
}

export default PrintDesignRequestComponent;