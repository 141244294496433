import axios from "axios";
import { action, observable } from "mobx";
import ManufactureItem from "../../../model/manufacture/ManufactureItem";
import ManufactureWork from "../../../model/manufacture/ManufactureWork";
import StoreBase from "../../StoreBase";
import { words as RouteWords } from '../../../../resource/data/APIRoutes.json';
import PTSMessage from "../../../model/common/PTSMessage";
import User from "../../../model/user/user";
import ManufactureOrderNumberList from "../../../model/manufacture/ManufactureOrderNumberList";

interface IReworkTaskInfo {
    name?: string;
    type?: string;
}

export class ReworkTaskInfo {
    @observable name: string;
    @observable type: string;

    constructor(props: IReworkTaskInfo) {
        if (props) {
            Object.assign(this, props);
        }
        else {
            this.name = 'Unknown';
            this.type = 'Unknown';
        }
    }
}

export default class ManufactureStore extends StoreBase {
    @observable data: ManufactureWork;
    @observable itemList: ManufactureItem[];
    @observable inputHeaders: (keyof ManufactureItem)[];
    @observable readonlyHeaders: (keyof ManufactureItem)[];
    @observable tableHeaders: (keyof ManufactureItem)[];
    @observable _workType: string;
    @observable _workStage: string;

    @observable isValid: boolean;
    @observable requestShippingChecked: boolean;
    @observable workStatus: 'paused' | 'running' | 'finished';
    @observable repInfo: User;

    @observable workID: string;

    @observable reworkDialogOpen: boolean;
    @observable reworkTaskList: ReworkTaskInfo[];
    @observable selectedTask: string;
    @observable selectedTaskIndex: number;

    @observable isProbeIDInputDialogOpen: boolean;

    constructor(readonly: Boolean = false) {
        super();
        this.inputHeaders = [];
        this.readonlyHeaders = [];
        this.itemList = [];
        this.data = new ManufactureWork(readonly);
        this._workType = '';
        this._workStage = '';
        this.isValid = false;
        this.requestShippingChecked = false;
        this.workID = '';
        this.reworkDialogOpen = false;
        this.reworkTaskList = [];
        this.selectedTask = '';
        this.selectedTaskIndex = -1;
        this.isProbeIDInputDialogOpen = false;
    }

    onReworkDiagClose = () => {
        this.reworkDialogOpen = false;
    }

    openReworkDiag = () => {
        this.reworkDialogOpen = true;
    }

    private clearList = (list: any[]) => {
        while (list.length > 0) {
            list.pop();
        }
    }

    clearInputHeaders = () => {
        this.clearList(this.inputHeaders);
    }

    clearReadonlyHeaders = () => {
        this.clearList(this.readonlyHeaders);
    }

    clearItemList = () => {
        this.clearList(this.itemList);
    }

    @action
    setItemList = (list: any[], itemOptionsReadonly: boolean = false) => {
        try {
            const setList: ManufactureItem[] = [];
            for (const data of list) {
                const d = new ManufactureItem({ optionReadonly: itemOptionsReadonly });
                d.fromJS(data);
                d.validateList = this.inputHeaders;
                setList.push(d);
            }
            this.data.itemList = setList;
        } catch (err) {
        }
    }

    getItemList = () => {
        return this.data.itemList;
    }

    // OrderNumber 구분 및 정렬
    setManufactureType = (type: String, list: any[]) => {
        let setList: ManufactureOrderNumberList[] = [];
        for (const data of list) {
            const dataSub = data.manufactureOrderNumber.substr(4, 2)
            if (type === dataSub) {
                setList.push(data)
            }
        }
        setList.sort(function (a, b) {
            return Number(a.manufactureOrderNumber.substring(6)) -
                Number(b.manufactureOrderNumber.substring(6));
        })
        return setList;
    }

    setManufactureOrderNumberText = (Text: string, list: any[]) => {
        if (list.length > 1) {
            Text += list[0].manufactureOrderNumber + '~' +
                Number(list[list.length - 1].manufactureOrderNumber.substring(6)) + ", ";
        } else if (list.length === 1) {
            Text += list[0].manufactureOrderNumber + ", "
        }
        return Text;
    }

    @action
    setManufactureNumber = (list: any[]) => {
        try {
            const typeList: string[] = ['PD', 'PO', 'TB', 'XB'];
            let manufactureOrderNumberText = '';
            this.data.manufactureOrderNumberList = list;

            if (Array.isArray(list) && list.length) {
                for (const type of typeList) {
                    manufactureOrderNumberText = this.setManufactureOrderNumberText(manufactureOrderNumberText, this.setManufactureType(type, list));
                }
                if (manufactureOrderNumberText) {
                    this.data.manufactureOrderNumberText = manufactureOrderNumberText.replace(/,\s*$/, "");
                }
            }
        } catch (err) {
            console.log('error');
        }
    }

    @action
    setManufactureNoteList = (list: string[]) => {
        try {
            this.data.manufactureNoteList = list
        } catch (err) {
            console.log('error');
        }
    }

    set workType(type: string) {
        if (type in ManufactureWork.TypeList) {
            this._workType = type;
        }
        else {
            this._workType = '';
        }
    }

    get workType() {
        return this._workType;
    }

    set workStage(stage: string) {
        if (stage in ManufactureWork.StageList) {
            this._workStage = stage;
        }
        else {
            this._workStage = '';
        }
    }

    get workStage() {
        return this._workStage;
    }

    setInputHeadersByType = (type: string) => {
        this.clearList(this.inputHeaders);
        switch (type) {
            case ManufactureWork.TypeList.manufactureRequest:
                this.inputHeaders.push(ManufactureItem.HeaderKeyList.itemName as keyof ManufactureItem);
                this.inputHeaders.push(ManufactureItem.HeaderKeyList.numberPerQty as keyof ManufactureItem);
                this.inputHeaders.push(ManufactureItem.HeaderKeyList.unit as keyof ManufactureItem);
                this.inputHeaders.push(ManufactureItem.HeaderKeyList.qty as keyof ManufactureItem);
                this.inputHeaders.push(ManufactureItem.HeaderKeyList.pooling as keyof ManufactureItem);
                this.inputHeaders.push(ManufactureItem.HeaderKeyList.hybRxn as keyof ManufactureItem);
                this.inputHeaders.push(ManufactureItem.HeaderKeyList.probeID as keyof ManufactureItem);
                this.inputHeaders.push(ManufactureItem.HeaderKeyList.remark as keyof ManufactureItem);
                return;
            case ManufactureWork.TypeList.manufactureExpectShippingRequest:
                return;
            case ManufactureWork.TypeList.manufactureSalesExpectShippingRequest:
                return;
            case ManufactureWork.TypeList.manufactureManufacture:
                this.inputHeaders.push(ManufactureItem.HeaderKeyList.panelName as keyof ManufactureItem);
                this.inputHeaders.push(ManufactureItem.HeaderKeyList.catNO as keyof ManufactureItem);
                this.inputHeaders.push(ManufactureItem.HeaderKeyList.lotNO as keyof ManufactureItem);
                this.inputHeaders.push(ManufactureItem.HeaderKeyList.expDate as keyof ManufactureItem);
                this.inputHeaders.push(ManufactureItem.HeaderKeyList.storageTemp as keyof ManufactureItem);
                return;
            case ManufactureWork.TypeList.manufactureShippingRequest:
                return;
            case ManufactureWork.TypeList.manufacturePackaging:
                this.inputHeaders.push(ManufactureItem.HeaderKeyList.shippingCondition as keyof ManufactureItem);
                this.inputHeaders.push(ManufactureItem.HeaderKeyList.boxNO as keyof ManufactureItem);
                return;
            case ManufactureWork.TypeList.manufactureDelivery:
                return;
            default:
                return;
        }
    }

    setReadonlyHeadersByType = (type: string) => {
        this.clearList(this.readonlyHeaders);
        switch (type) {
            case ManufactureWork.TypeList.manufactureRequest:
                return;
            case ManufactureWork.TypeList.manufactureExpectShippingRequest:
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.itemName as keyof ManufactureItem);
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.numberPerQty as keyof ManufactureItem);
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.unit as keyof ManufactureItem);
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.qty as keyof ManufactureItem);
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.pooling as keyof ManufactureItem);
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.hybRxn as keyof ManufactureItem);
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.probeID as keyof ManufactureItem);
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.remark as keyof ManufactureItem);
                return;
            case ManufactureWork.TypeList.manufactureSalesExpectShippingRequest:
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.itemName as keyof ManufactureItem);
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.numberPerQty as keyof ManufactureItem);
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.unit as keyof ManufactureItem);
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.qty as keyof ManufactureItem);
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.pooling as keyof ManufactureItem);
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.hybRxn as keyof ManufactureItem);
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.probeID as keyof ManufactureItem);
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.remark as keyof ManufactureItem);
                return;
            case ManufactureWork.TypeList.manufactureManufacture:
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.itemName as keyof ManufactureItem);
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.numberPerQty as keyof ManufactureItem);
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.unit as keyof ManufactureItem);
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.qty as keyof ManufactureItem);
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.pooling as keyof ManufactureItem);
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.hybRxn as keyof ManufactureItem);
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.probeID as keyof ManufactureItem);
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.remark as keyof ManufactureItem);
                return;
            case ManufactureWork.TypeList.manufactureShippingRequest:
                return;
            case ManufactureWork.TypeList.manufacturePackaging:
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.itemName as keyof ManufactureItem);
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.numberPerQty as keyof ManufactureItem);
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.unit as keyof ManufactureItem);
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.qty as keyof ManufactureItem);
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.pooling as keyof ManufactureItem);
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.hybRxn as keyof ManufactureItem);
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.probeID as keyof ManufactureItem);
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.remark as keyof ManufactureItem);
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.panelName as keyof ManufactureItem);
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.lotNO as keyof ManufactureItem);
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.catNO as keyof ManufactureItem);
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.expDate as keyof ManufactureItem);
                this.readonlyHeaders.push(ManufactureItem.HeaderKeyList.storageTemp as keyof ManufactureItem);
                return;
            case ManufactureWork.TypeList.manufactureDelivery:
                return;
            default:
                return;
        }
    }

    setTableHeaders = () => {
        this.clearList(this.tableHeaders);
        for (const data of this.readonlyHeaders) {
            this.tableHeaders.push(data);
        }
        for (const data of this.inputHeaders) {
            this.tableHeaders.push(data);
        }
    }

    private getNextType = (type: string): string => {
        switch (type) {
            case ManufactureWork.TypeList.manufactureRequest:
                return ManufactureWork.TypeList.manufactureExpectShippingRequest;
            case ManufactureWork.TypeList.manufactureExpectShippingRequest:
                return ManufactureWork.TypeList.manufactureSalesExpectShippingRequest;
            case ManufactureWork.TypeList.manufactureSalesExpectShippingRequest:
                return ManufactureWork.TypeList.manufactureManufacture;
            case ManufactureWork.TypeList.manufactureManufacture:
                return ManufactureWork.TypeList.manufacturePackaging;
            case ManufactureWork.TypeList.manufactureShippingRequest:
                return ManufactureWork.TypeList.manufacturePackaging;
            case ManufactureWork.TypeList.manufacturePackaging:
                return ManufactureWork.TypeList.manufactureDelivery;
            case ManufactureWork.TypeList.manufactureDelivery:
                return ManufactureWork.TypeList.manufactureComplete;
            default:
                return 'Unknown';
        }
    }

    private getNextStage = (type: string): string => {
        switch (type) {
            case ManufactureWork.StageList.beforeReciept:
                return ManufactureWork.StageList.processing;
            case ManufactureWork.StageList.processing:
                return ManufactureWork.StageList.beforeReciept;
            default:
                return 'Unknown';
        }
    }

    getNextTypeAndStage = (currType: string, currStage: string): { type: string; stage: string; } => {
        const obj = { type: 'Unknown', stage: 'Unknown' };
        obj.type = this.getNextType(currType);
        obj.stage = this.getNextStage(currStage);
        return obj;
    }

    getTitleByType = (type: string): string => {
        switch (type) {
            case ManufactureWork.TypeList.manufactureRequest:
                return '제조 의뢰';
            case ManufactureWork.TypeList.manufactureExpectShippingRequest:
                return '제조 예상 출하 가능일 입력'
            case ManufactureWork.TypeList.manufactureSalesExpectShippingRequest:
                return '영업 예상 출하일 입력'
            case ManufactureWork.TypeList.manufactureManufacture:
                return '제조';
            case ManufactureWork.TypeList.manufacturePackaging:
                return '포장';
            case ManufactureWork.TypeList.manufactureShippingRequest:
                return '출하 요청일 입력';
            case ManufactureWork.TypeList.manufactureDelivery:
                return '배송';
            case ManufactureWork.TypeList.manufactureComplete:
                return '제조 완료';
            default:
                return '';
        }
    }

    getDepartTypeByType = (type: string): string => {
        switch (type) {
            case ManufactureWork.TypeList.manufactureRequest:
                return ManufactureWork.DepartList.sales;
            case ManufactureWork.TypeList.manufactureExpectShippingRequest:
                return ManufactureWork.DepartList.manufacturePart;
            case ManufactureWork.TypeList.manufactureSalesExpectShippingRequest:
                return ManufactureWork.DepartList.sales;
            case ManufactureWork.TypeList.manufactureManufacture:
                return ManufactureWork.DepartList.manufacturePart;
            case ManufactureWork.TypeList.manufactureShippingRequest:
                return ManufactureWork.DepartList.sales;
            case ManufactureWork.TypeList.manufacturePackaging:
                return ManufactureWork.DepartList.manufacturePart;
            case ManufactureWork.TypeList.manufactureDelivery:
                return ManufactureWork.DepartList.sales;
            case ManufactureWork.TypeList.manufactureComplete:
                return ManufactureWork.DepartList.admin;
            default:
                return ManufactureWork.DepartList.admin;
        }
    }

    getManufactureTrack = async (workID: string, token: string) => {
        const config = {
            headers: {
                'x-access-token': token
            }
        }
        return axios.get(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.manufacture}/${RouteWords.tracks}/${workID}`,
            config,
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        });
    }

    getManufactureByWorkID = async (workID: string, token: string) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        return axios.get(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.manufacture}/${workID}`,
            config,
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        })
    }

    processManufacture = async (token: string) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        return axios.put(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.manufacture}/${this.workID}`,
            this.data,
            config,
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        });
    }

    cancelShippingRequest = async (workID: string, data: object, token: string, currentData?: object, nextData?: object) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        return axios.put(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.manufacture}/${RouteWords.shipping}/${RouteWords.cancelRequest}/${workID}`,
            {
                data: data,
                currentTrackData: currentData,
                nextTrackData: nextData,
            },
            config,
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        });
    }

    changeShippingRequest = async (workID: string, data: object, token: string, currentData?: object, nextData?: object) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        return axios.put(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.manufacture}/${RouteWords.shipping}/${RouteWords.changeRequest}/${workID}`,
            {
                data: data,
                currentTrackData: currentData,
                nextTrackData: nextData,
            },
            config,
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        });
    }

    changeStatusRequest = async (workID: string, status: string, token: string) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        return axios.put(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.manufacture}/${RouteWords.status}/${workID}`,
            {
                status: status
            },
            config
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        });
    }

    changeStatus = async (workID: string, status: string, token: string) => {
        try {
            const result = await this.changeStatusRequest(workID, status, token);
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                });
            }
        } catch (err) {
            throw err;
        }
    }

    uploadCoaFile = async (data: FormData, token: string): Promise<any> => {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'multipart/form-data'
            }
        }
        return axios.post(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.manufacture}/${RouteWords.coa}/${this.workID}`,
            data,
            config
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        });
    }

    uploadInvoiceFile = async (data: FormData, token: string): Promise<any> => {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'multipart/form-data'
            }
        }
        return axios.post(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.manufacture}/${RouteWords.invoice}/${this.workID}`,
            data,
            config
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        });
    }

    requestNewReWork = async (id: string, token: string, index: number) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        return axios.post(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.manufacture}/${RouteWords.works}/${RouteWords.rework}/${id}`,
            {
                index
            },
            config,
        ).then((res) => {
            return res;
        }).catch((err) => {
            return err;
        });
    }

    newReWork = async (id: string, token: string, index: number) => {
        try {
            const result = await this.requestNewReWork(id, token, index);
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                    type: result.data.type
                });
            }
            return result;
        } catch (err) {
            throw err;
        }
    }

    changeManufactureProbeID = async (data: object, id: string, token: string) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        return axios.put(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.manufacture}/${RouteWords.probeid}/${id}`,
            data,
            config,
        ).then((res) => {
            return res;
        }).catch((err) => {
            return err;
        });
    }
}