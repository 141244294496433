import { Checkbox, FormControl, FormControlLabel, Grid, TextField } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import User from '../../../../../../../data/model/user/user';
import RebalancingRequestStore from '../../../../../../../data/store/pageStore/design/RebalancingRequestStore';
import TitledPaper from '../../../../../../common/component/TitledPaper';
import DateUtil from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import dayjs from 'dayjs';
import Helper from '../../../../../../../module/Helper';

interface Props {
    userInfo?: User;
    store?: RebalancingRequestStore;
}

const RebalancingBasicInfoForm: React.FC<Props> = (props) => {
    const store = props.store || new RebalancingRequestStore();

    return (
        <React.Fragment>
            <TitledPaper title='Rebalancing 기본 정보'>
                <MuiPickersUtilsProvider utils={DateUtil}>
                    <Grid container direction='column'>
                        <Grid item container xl lg sm md>
                            <TextField
                                fullWidth
                                margin='normal'
                                label='작성자'
                                name='writer'
                                onChange={store.newData.detail.rebalancingInfo.onChangeDefault}
                                value={store.newData.detail.rebalancingInfo.writer} />
                        </Grid>
                        <Grid item container xl lg sm md>
                            <FormControl fullWidth margin='normal'>
                                <Grid container item>
                                    <Grid item xl lg sm>
                                        <KeyboardDatePicker
                                            required
                                            fullWidth
                                            disableToolbar
                                            variant='inline'
                                            format='yyyy/MM/dd'
                                            label='요청일'
                                            value={
                                                dayjs(store.newData.detail.rebalancingInfo.requestDate)
                                            }
                                            name='requestDate'
                                            onChange={(date) => store.onChangeDateDefault('requestDate', date)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            helperText={
                                                Helper.getErrorText(
                                                    store.newData.detail.rebalancingInfo.errors.requestDate
                                                )
                                            }
                                            error={
                                                Helper.getErrorState(
                                                    store.newData.detail.rebalancingInfo.errors.requestDate
                                                )
                                            }
                                        />
                                    </Grid>
                                    <Grid item xl lg sm>
                                        <KeyboardDatePicker
                                            required
                                            fullWidth
                                            disableToolbar
                                            variant='inline'
                                            format='yyyy/MM/dd'
                                            label='Due date'
                                            value={
                                                dayjs(store.newData.detail.rebalancingInfo.requestDate)
                                            }
                                            name='dueDate'
                                            onChange={(date) => store.onChangeDateDefault('requestDate', date)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            helperText={
                                                Helper.getErrorText(
                                                    store.newData.detail.rebalancingInfo.errors.requestDate
                                                )
                                            }
                                            error={
                                                Helper.getErrorState(
                                                    store.newData.detail.rebalancingInfo.errors.requestDate
                                                )
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </Grid>
                        <Grid container item xl lg sm>
                            <Grid item xl lg sm>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={store.newData.detail.rebalancingInfo.isRebalancing}
                                            onChange={(e) => {
                                                store.toggleBoolean(e.target.name)
                                            }}
                                            name='isRebalancing' />
                                    }
                                    label='Rebalancing 여부' />
                            </Grid>
                            <Grid item xl lg sm>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={store.newData.detail.rebalancingInfo.isAddPanelRegion}
                                            onChange={(e) => {
                                                store.toggleBoolean(e.target.name)
                                            }}
                                            name='isAddPanelRegion' />
                                    }
                                    label='영역추가 여부' />
                            </Grid>
                        </Grid>
                        <Grid container item xl lg sm>
                            <Grid item xl lg sm>
                                <TextField
                                    label='Rebalancing 특이사항'
                                    fullWidth
                                    multiline
                                    rows={10}
                                    disabled={!store.newData.detail.rebalancingInfo.isRebalancing}
                                    value={store.newData.detail.rebalancingInfo.rebalancingNote}
                                    onChange={store.newData.detail.rebalancingInfo.onChangeDefault}
                                    name='rebalancingNote' />
                            </Grid>
                            <Grid item xl lg sm>
                                <TextField
                                    label='영역추가 특이사항'
                                    fullWidth
                                    multiline
                                    rows={10}
                                    disabled={!store.newData.detail.rebalancingInfo.isAddPanelRegion}
                                    value={store.newData.detail.rebalancingInfo.addPanelRegionNote}
                                    onChange={store.newData.detail.rebalancingInfo.onChangeDefault}
                                    name='addPanelRegionNote' />
                            </Grid>
                        </Grid>
                    </Grid>
                </MuiPickersUtilsProvider>
            </TitledPaper>
        </React.Fragment>
    )
}

export default observer(RebalancingBasicInfoForm);