import { observable } from "mobx";
import TaskSubData from "../../DesignTaskSubData";

export default class Confirm extends TaskSubData {
    @observable confirm: boolean;

    constructor() {
        super();
        this.confirm = false;
    }

    // override
    validateSelf(): void {
        return;
    }
}