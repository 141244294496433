import { observable } from "mobx";
import Validatable from "../../../common/Validatable";
import TaskSubData from "../../DesignTaskSubData";

export default class Synthesis1 extends TaskSubData {
    @observable synthStartDate?: Date;
    @observable worker?: string;

    constructor() {
        super();
        this.synthStartDate = new Date();
        this.worker = '';
    }

    // override
    validateSelf(): void {
        if (!this.worker) {
            this.setErrorTextByName('worker', Validatable.errorTexts.needValue);
        }
        if (!this.synthStartDate) {
            this.setErrorTextByName('synthStartDate', Validatable.errorTexts.needValue)
        }
    }
}