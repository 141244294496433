import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, TextField } from '@material-ui/core';
import GeneData from '../../../../../../data/model/design/taskDatas/designReqeust/GeneData';
import FormUtil from '../../../../../../module/FormUtil';
import { observer } from 'mobx-react';

interface Props {
    list: GeneData[];
}

const GeneListTable: React.FC<Props> = observer((props: Props) => {
    return (
        <React.Fragment>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            #
                        </TableCell>
                        <TableCell>
                            Chromosome
                        </TableCell>
                        <TableCell>
                            Start
                        </TableCell>
                        <TableCell>
                            End
                        </TableCell>
                        <TableCell>
                            Name
                        </TableCell>
                        <TableCell>
                            Remark
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.list.length > 0 && props.list.map((v, i) => (
                        <TableRow key={i}>
                            <TableCell>
                                {i + 1}
                            </TableCell>
                            <TableCell component='th' scope='row' style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                                {v.chromosome}
                            </TableCell>
                            <TableCell>
                                {v.start}
                            </TableCell>
                            <TableCell>
                                {v.end}
                            </TableCell>
                            <TableCell style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                                {v.name}
                            </TableCell>
                            <TableCell>
                                <TextField
                                    fullWidth
                                    multiline
                                    // margin='normal'
                                    label={FormUtil.getFieldLabel('remark')}
                                    onChange={(e) => { v.remark = e.target.value }}
                                    value={v.remark}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </React.Fragment>
    )
})

export default GeneListTable;