import { observable } from "mobx";
import DesignModelBase, { IDesignModelBase } from "../../DesignModelBase";

export interface IWorkTaskSummary extends IDesignModelBase {
    customerAndPI?: string;
    projectCode?: string;
    probeID?: string;
    salesRepresentative?: string;
    designName?: string;
    stage?: string;
    depart?: string;
    departType?: string;
    erpCode?: string;
    currTaskObj?: object;
    workStatus?: string;
    prevWorkID?: string;
}

export default class WorkTaskSummary extends DesignModelBase {
    @observable customerAndPI?: string;
    @observable projectCode?: string;
    @observable probeID?: string;
    @observable salesRepresentative?: string;
    @observable designName?: string;
    @observable stage?: string;
    @observable depart?: string;
    @observable departType?: string;
    @observable erpCode?: string;
    @observable currTaskObj?: object;
    @observable workStatus?: string;
    @observable prevWorkID?: string;

    @observable selected: boolean;

    constructor(params?: IWorkTaskSummary) {
        super(params);
        if (params) {
            Object.assign(this, params);
            if (this.prevWorkID === null) { this.prevWorkID = 'Original Work'; }
        }
        this.selected = false;
    }
}