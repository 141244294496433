import { observable } from "mobx";
import Helper from "../../../../../module/Helper";
import Validatable from "../../../common/Validatable";
import TaskSubData from "../../DesignTaskSubData";

export default class QCSequencing extends TaskSubData {
    @observable runID?: string;

    constructor() {
        super();
        this.runID = null;
    }

    // override
    validateSelf(): void {
        // if (!this.runID) {
        //     this.setErrorTextByName('runID', Validatable.errorTexts.needValue);
        // }
        if (this.runID !== null) {
            if (!Helper.checkRegex(this.runID, /^[0-9][0-9](0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])_[0-9a-zA-Z]{2}_[0-9a-zA-Z]{2}_[0-9a-zA-Z]{2}$/)) {
                this.setErrorTextByName('runID', `${Validatable.errorTexts.badFormat} -> 포맷: YYMMDD_##_##_## (# : 영문, 숫자)`);
            }
        }
    }
}