import { Container, CssBaseline, Grid, Typography } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import React from "react";
import { BrowserRouter, Route, RouteComponentProps, Switch, withRouter } from "react-router-dom";
import GlobalStore from "../../../../data/store/common/GlobalStore";
import { IRootStore } from "../../../../data/store/RootStore";
import CommentContainer from "../../../common/comment/CommentContainer";
import PrivateRoute from "../../../common/component/PrivateRoute";
import DesignRequestPage from "./designRequest/DesignRequestPage";
import ProcessTaskViewPage from "./list/processTask/ProcessTaskViewPage";
import ProjectSearchPage from "./list/searchWork/WorkSearchPage";
import WaitTaskViewPage from "./list/waitTask/WaitTaskViewPage";
import RebalancingRequestPage from "./rebalancing/request/RebalancingRequestPage";
import TaskProcessContainer from "./taskProcess/container/TaskProcessContainer";
import DesignWorkDetailPage from "./workDetail/DesignWorkDetailPage";

interface Props extends IRootStore, RouteComponentProps<any> {
}

const Stub = () => {
    return (
        <div>
            Stub component
        </div>
    )
}

@inject('rootStore')
@observer
class DesignTaskMain extends React.Component<Props> {
    globalStore: GlobalStore = this.props.rootStore.globalStore;

    render() {
        return (
            <React.Fragment>
                <CssBaseline />
                <Container maxWidth='xl'>
                    <Grid container justify='center'>
                        <Grid xl lg md sm item style={{
                            wordWrap: 'break-word',
                            whiteSpace: 'pre-line',
                            padding: 5
                        }}>
                            {this.globalStore.currentTaskTitle &&
                                <div>
                                    <Typography variant='h4'>
                                        {this.globalStore.currentTaskTitle}
                                    </Typography>
                                    <hr />
                                </div>
                            }
                            <BrowserRouter>
                                <Switch>
                                    <PrivateRoute
                                        exact path={`${this.props.match.url}/request`}
                                        component={DesignRequestPage} />
                                    <PrivateRoute
                                        exact path={`${this.props.match.url}/task/wait`}
                                        component={WaitTaskViewPage} />
                                    <PrivateRoute
                                        exact path={`${this.props.match.url}/task/process`}
                                        component={ProcessTaskViewPage} />
                                    <PrivateRoute
                                        exact path={`${this.props.match.url}/work/list`}
                                        component={ProjectSearchPage} />
                                    <PrivateRoute
                                        exact path={`${this.props.match.url}/process/:workID`}
                                        component={TaskProcessContainer} />
                                    <PrivateRoute
                                        exact path={`${this.props.match.url}/work/detail/:workID`}
                                        component={DesignWorkDetailPage} />
                                    <PrivateRoute
                                        exact path={`${this.props.match.url}/rebalancing`}
                                        component={RebalancingRequestPage} />
                                    <Route
                                        exact
                                        component={Stub} />
                                </Switch>
                            </BrowserRouter>
                        </Grid>
                        {this.globalStore.useComment &&
                            <Grid xl={3} lg={4} md={4} item>
                                <CommentContainer />
                            </Grid>
                        }
                    </Grid>
                </Container>
            </React.Fragment>
        )
    }
}

export default withRouter(DesignTaskMain);