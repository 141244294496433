import React from 'react';

import { Redirect } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
    createStyles,
    Theme,
    withStyles,
    WithStyles
} from '@material-ui/core/styles';
import Linearprogress from '@material-ui/core/LinearProgress';

import { Image } from 'react-bootstrap';

import CopyRight from '../../common/component/CopyRight';
import { inject } from 'mobx-react';

import Logo from '../../../resource/img/1.jpg';
import CelemicsLogo from '../../../resource/img/celemics_logo.png';
import { observer } from 'mobx-react';

import RouteInfo from '../../../resource/routes.json';
import RootStore from '../../../data/store/RootStore';
import GlobalStore from '../../../data/store/common/GlobalStore';
import LoginStore from '../../../data/store/pageStore/LoginStore';
import LoginUtil from '../../../module/LoginUtil';

import Lang from '../../../resource/lang/ko.json';
import PTSMessage from '../../../data/model/common/PTSMessage';

const styles = (theme?: Theme) => {
    return createStyles({
        root: {
            height: '100vh',
        },
        image: {
            backgroundImage: `url(${Logo})`,
            // backgroundColor: '#000000',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center'
        },
        paper: {
            margin: theme.spacing(8, 4),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
            padding: theme.spacing(2, 0, 2, 0)
        }
    })
}

interface Props extends WithStyles<typeof styles> {
    rootStore?: RootStore;
}


@inject('rootStore')
@observer
class LoginPage extends React.Component<Props> {
    readonly classes: any;
    readonly globalStore: GlobalStore;
    readonly loginStore: LoginStore;

    constructor(props: Props) {
        super(props);
        this.classes = props.classes;
        this.globalStore = props.rootStore.globalStore;
        this.loginStore = new LoginStore(this.props.rootStore);
    }

    onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        this.globalStore.isRunning = !this.globalStore.isRunning;
        this.loginStore.loginError = false;
        try {
            const result = await this.loginStore.onLogin();
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                    code: result.data.resCode,
                });
            }
            const loginResult = LoginUtil.login(result.data.data.userInfo, result.data.data.token, this.globalStore);
            if (!loginResult) {
                throw new PTSMessage({
                    message: '로그인 실패.',
                });
            }
            this.globalStore.isLoggedIn = true;
        } catch (err) {
            if (err instanceof PTSMessage) {
                if (err.code === 90002) {
                    this.loginStore.loginError = true;
                }
                else {
                    alert(err.message);
                }
            }
            else {
                alert('오류 발생');
            }
        } finally {
            this.globalStore.isRunning = false;
        }
    }

    render() {
        return (
            <div>
                {LoginUtil.isLoggedIn() &&
                    <Redirect to={{ pathname: RouteInfo.routes.main }} />
                }
                <Grid
                    container
                    component="main"
                    className={this.classes.root}>
                    <CssBaseline />
                    <Grid item xs={false} sm={4} md={8} className={this.classes.image} />
                    <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
                        {this.globalStore.isRunning &&
                            <Linearprogress color="secondary" />
                        }
                        <div className={this.classes.paper}>
                            <Image src={CelemicsLogo} fluid style={{ marginBottom: 5, marginRight: 5, marginLeft: 5 }} />
                            <Typography component="h1" variant="h5" style={{ marginBottom: 5 }}>
                                {Lang.global.words.celemicsPTS}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" align="center">
                                {Lang.pages.login.messages.needLogin}
                            </Typography>
                            <form className={this.classes.form} onSubmit={this.onSubmit} >
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    // required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    value={this.loginStore.email}
                                    onChange={this.loginStore.onChangeDefault}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    // required
                                    fullWidth
                                    name="pw"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    value={this.loginStore.pw}
                                    onChange={this.loginStore.onChangeDefault}
                                />
                                <Typography variant="body1" color="error" align="center">
                                    {this.loginStore.loginError &&
                                        Lang.pages.login.errors.loginFail}
                                </Typography>

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={this.classes.submit}
                                >
                                    {Lang.pages.login.words.login}
                                </Button>
                                <Grid container>
                                </Grid>
                                <Box mt={5}>
                                    <CopyRight />
                                </Box>
                            </form>
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(LoginPage);