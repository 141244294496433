import { Button, Card, Grid, Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import React from "react";
import PTSFile from '../../../../../../../data/model/common/PTSFile';
import PTSFileLink from '../../../../../../common/component/PTSFileLink';
import BIAnalysis from "../../../../../../../data/model/design/taskDatas/bi/BIAnalysis";

interface Props {
    data?: BIAnalysis;
    isLoaded?: boolean
}

const BiAnalyDownloadFileConatiner: React.FC<Props> = props => {
    const data = props.data;
    const loaded = props.isLoaded || false;

    return (
        <React.Fragment>
            {
                loaded && data &&
                <Card style={{ marginBottom: 15 }}>
                    <Typography style={{ background: 'rgb(212, 239, 223)', padding: 5, paddingLeft: 15, fontSize: '0.875rem' }}>
                        BI 분석
                    </Typography>
                    <Grid item xl lg sm md>
                        <Button disabled={data.analysisStat && Object.keys(data.analysisStat).length <= 1} style={{ padding: 0, margin: 8 }}>
                            <PTSFileLink
                                fileID={(data.analysisStat as PTSFile).file_id}
                                fileName='내부용 스탯 파일'
                                mode='text' >
                            </PTSFileLink>
                            <PTSFileLink
                                fileID={(data.analysisStat as PTSFile).file_id}
                                mode='icon' >
                            </PTSFileLink>
                        </Button>
                        <Button disabled={data.analysisRD && Object.keys(data.analysisRD).length <= 1} style={{ padding: 0, margin: 8 }}>
                            <PTSFileLink
                                fileID={(data.analysisRD as PTSFile).file_id}
                                fileName='내부용 Region depth 파일'
                                mode='text' >
                            </PTSFileLink>
                            <PTSFileLink
                                fileID={(data.analysisRD as PTSFile).file_id}
                                mode='icon' >
                            </PTSFileLink>
                        </Button>
                        <Button disabled={data.finalBedFile && Object.keys(data.finalBedFile).length <= 1} style={{ padding: 0, margin: 8 }}>
                            <PTSFileLink
                                fileID={(data.finalBedFile as PTSFile).file_id}
                                fileName='분석 Bed 파일'
                                mode='text' >
                            </PTSFileLink>
                            <PTSFileLink
                                fileID={(data.finalBedFile as PTSFile).file_id}
                                mode='icon' >
                            </PTSFileLink>
                        </Button>
                    </Grid >
                </Card>
            }
        </React.Fragment >
    )
}

export default observer(BiAnalyDownloadFileConatiner);