import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'mobx-react';
import RepositorySettings from './data/repository/RepositorySettings';
import RootStore from './data/store/RootStore';
import LoginUtil from './module/LoginUtil';
import Helper from './module/Helper';

Helper.setEnvs(process.env.NODE_ENV);

export const config = {
  API_SERVER_HOST: process.env.REACT_APP_API_SERVER_HOST || 'pts.celemics.com',
  API_SERVER_PORT: parseInt(process.env.REACT_APP_API_SERVER_PORT as string) || 33192,
  API_SERVER_PROTOCOL: process.env.REACT_APP_API_SERVER_PROTOCOL || 'https://',
  API_TOKEN_NAME: process.env.REACT_APP_API_TOKEN_NAME || 'x-access-token',
  API_CONTENT_TYPE: process.env.REACT_APP_API_CONTENT_TYPE || 'application/x-www-form-urlencoded'
}

RepositorySettings.apiBaseURL = `${config.API_SERVER_PROTOCOL}${config.API_SERVER_HOST}:${config.API_SERVER_PORT}`;
RepositorySettings.apiTokenName = config.API_TOKEN_NAME;
RepositorySettings.contentType = config.API_CONTENT_TYPE;


if (process.env.NODE_ENV === 'development') {
  console.log('Running in dev mode');
  console.log(`Configs: ${JSON.stringify(config, null, '\t')}`);
  console.log(`Repository Settings: ${JSON.stringify(RepositorySettings, null, '\t')}`);
}

const rootStore: RootStore = new RootStore();

if (LoginUtil.isLoggedIn()) {
  window.addEventListener("focus", LoginUtil.onFocus);
  window.addEventListener("blur", LoginUtil.onBlur);
}

ReactDOM.render(
  <Provider rootStore={rootStore}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
