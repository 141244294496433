import React from 'react';
import { observer } from 'mobx-react';
import { Grid, TextField } from '@material-ui/core';
import ChipDistributionInfo from '../../../../../../../../data/model/common/ChipDistributionInfo';
import TitledPaper from '../../../../../../../common/component/TitledPaper';
import FormUtil from '../../../../../../../../module/FormUtil';
import Helper from '../../../../../../../../module/Helper';

interface Props {
    data: ChipDistributionInfo;
    index: number;
}
const Synth2InputCell: React.FC<Props> = observer((props) => {
    const data = props.data;

    return (
        <React.Fragment>
            <Grid item xl lg sm>
                <TitledPaper title={data.CDSDID}>
                    <TextField
                        fullWidth
                        margin='normal'
                        value={data.CDSDID}
                        name='CDSDID'
                        onChange={data.onChangeDefault}
                        InputProps={{ readOnly: true }}
                        label={FormUtil.getFieldLabel('CDSDID')} />
                    <TextField
                        fullWidth
                        margin='normal'
                        value={data.PCRConcentration || ''}
                        type='number'
                        name='PCRConcentration'
                        onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                        onChange={data.onChangeDefault}
                        label={FormUtil.getFieldLabel('PCRConcentration')}
                        helperText={
                            Helper.getErrorText(
                                props.data.errors.PCRConcentration
                            )
                        }
                        error={
                            Helper.getErrorState(
                                props.data.errors.PCRConcentration
                            )
                        } />
                </TitledPaper>
            </Grid>
        </React.Fragment>
    )
})

export default Synth2InputCell;