import PTSModel from "../common/PTSModel";
import ManufactureItem from "./ManufactureItem";
import { observable } from "mobx";
import ValidateErrorObject from "../common/ValidateErrorObject";
import Validatable from "../common/Validatable";
import ManufactureTrackCurrent from "./ManufactureTrackCurrent";
import Helper from "../../../module/Helper";
import Depart from "../user/Depart";
import User from "../user/user";
import PTSFile from "../common/PTSFile";
import ChangeRequestHistory from "./ChangeRequestHistory";
import ManufactureOrderNumberList from "./ManufactureOrderNumberList";
import { AdapterType, IndexType, ItemPresetKeyType, ItemVersion, LPEPOption, Platform, ProbeStateType } from "@celemicsbisw/manufacture-item-structure";
import StringDumpable from "../common/Dumpable";

type PackageOptionType = {
    platform: Platform | null;
    adapter: AdapterType | null;
    lpep: LPEPOption | null;
    indexType: IndexType | null;
    tc1Enhancer: boolean;
    tc2Enhancer: boolean;
    probeState: ProbeStateType | null;
    selectedItemPreset: ItemPresetKeyType;
}

export default class ManufactureWork extends PTSModel implements StringDumpable {
    @observable representativeDepart?: string;
    @observable representativeName?: string;
    @observable writerDepart?: string | Depart;
    @observable writerName?: string;
    @observable erpCode?: string;
    @observable customerAndPI?: string;
    @observable manufacturePurpose?: 'ruo' | 'ivd' | 'service' | '';
    @observable productDivision?: 'forSale' | 'forSample' | 'forSupplement' | 'forReplacement' | '';
    @observable determinedReleaseDate?: Date;
    @observable determinedReleaseAMPM?: 'am' | 'pm' | '';
    @observable productType?: 'illumina' | 'ionTorrent' | 'etc' | '' | 'none';
    @observable index?: 'single' | 'dual' | 'barcode' | 'ionXpress' | '' | 'none';
    @observable probeState?: 'solution' | 'powder' | '' | 'none';
    @observable note?: string;
    @observable itemList: ManufactureItem[];
    @observable orderNumber?: string;
    @observable deliveryDate?: Date;
    @observable deliveryPlace?: string;
    @observable workStatus?: string;
    @observable salesRepresentativeObj?: User;
    @observable mailingList: string[];
    @observable repInfo: User;
    @observable isDeterminedReleaseDateSkipped: boolean;

    @observable customerRequest: string;
    @observable legalCompliance: boolean | null;
    @observable additionalReview: string;

    @observable current?: ManufactureTrackCurrent;
    @observable coa: boolean;
    @observable coaFile: PTSFile;
    @observable invoiceFile: PTSFile;

    @observable errors: { [key: string]: ValidateErrorObject } = {};

    // dates
    @observable requestDate: Date;
    @observable manufactureDate: Date;
    @observable shippingRequestDate: Date;
    @observable shippingRequestAMPM: 'am' | 'pm' | '';
    @observable packagingDate: Date;

    // ExpectShippingRequest
    @observable manufactureExpectShippingRequestDate?: Date;
    @observable manufactureOrderNumberText?: string;
    @observable manufactureOrderNumberList?: ManufactureOrderNumberList[];
    @observable manufactureNoteText?: string;
    @observable manufactureNoteList?: string[];

    @observable changeRequestHistory?: ChangeRequestHistory[];

    @observable validateList?: string[];
    @observable prevWorkID?: string;

    @observable presetProductType?: 'custom' | 'preset' | 'wes' | '';
    @observable presetPanelName?: string

    @observable productEducation?: boolean;

    @observable manufactureVersion?: ItemVersion;
    @observable packageOption?: PackageOptionType | null;

    constructor(readonly: Boolean = false) {
        super();
        this.current = new ManufactureTrackCurrent();
        this.itemList = [];
        this.validateList = [];
        this.representativeDepart = '';
        this.representativeName = '';
        this.writerDepart = '';
        this.writerName = '';
        this.erpCode = '';
        this.customerAndPI = '';
        this.manufacturePurpose = '';
        this.productDivision = '';
        this.determinedReleaseDate = null;
        this.determinedReleaseAMPM = '';
        this.shippingRequestAMPM = '';
        this.productType = '';
        this.presetProductType = '';
        this.index = '';
        this.probeState = '';
        this.presetPanelName = '';
        this.note = '';
        this.type = '';
        this.stage = '';
        this.projectCode = '';
        this.deliveryDate = null;
        this.deliveryPlace = '';
        this.startDate = null;
        this.endDate = null;
        this.finishDate = null;
        this.salesRepresentativeObj = new User();
        this.coa = false;
        this.coaFile = new PTSFile();
        this.invoiceFile = new PTSFile();
        this.repInfo = new User();
        this.productEducation = false;
        this.manufactureVersion = null;
        this.manufactureExpectShippingRequestDate = null;
        // this.manufactureOrderNumber = '';
        this.manufactureOrderNumberText = '';
        this.manufactureOrderNumberList = [];
        this.manufactureNoteText = '';
        this.manufactureNoteList = [];
        this.changeRequestHistory = [];
        this.isDeterminedReleaseDateSkipped = true;
        this.packageOption = null;
        this.customerRequest = '';
        this.legalCompliance = null;
        this.additionalReview = '';
        if (!readonly) {
            this.initErrors();
        }
    }

    static Translations = {
        workID: 'Work ID',
        orderNumber: 'Order Num.',
        projectCode: '고객 코드',
        representativeDepart: '담당자부서',
        representativeName: '담당자',
        writerDepart: '작성자부서',
        writerName: '작성자',
        erpCode: 'Project Code (ERP)',
        customerAndPI: 'Customer & PI',
        manufacturePurpose: '제조목적',
        productDivision: '제품구분',
        determinedReleaseDate: '예상 출하일',
        determinedReleaseAMPM: '예상 출하시',
        shippingRequestDate: '출하 요청일',
        shippingRequestAMPM: '출하 요청시',
        productType: '제품타입',
        index: 'Index',
        probeState: 'Probe state',
        note: 'Note',
        itemList: 'Item list',
        deliveryDate: '배송일',
        deliveryPlace: '배송처',
        coa: 'COA',
    }

    static readonly TypeList = {
        manufactureRequest: 'manufactureRequest',
        manufactureExpectShippingRequest: 'manufactureExpectShippingRequest',
        manufactureSalesExpectShippingRequest: 'manufactureSalesExpectShippingRequest',
        manufactureManufacture: 'manufactureManufacture',
        manufactureShippingRequest: 'manufactureShippingRequest',
        manufacturePackaging: 'manufacturePackaging',
        manufactureDelivery: 'manufactureDelivery',
        manufactureComplete: 'manufactureComplete',
        manufactureCancelShipping: 'manufactureCancelShipping',
        manufactureChangeExpectShipping: 'manufactureChangeExpectShipping',
        manufactureChangeShipping: 'manufactureChangeShipping',
    }

    static readonly StatusList = {
        finished: 'finished',
        running: 'running',
        paused: 'paused',
        all: 'all',
    }

    static readonly StageList = {
        beforeReciept: 'beforeReciept',
        waitAccept: "waitAccept",
        accepted: "accepted",
        processing: 'processing',
        finish: 'finish',
        skipped: 'skipped',
        abort: 'abort',
        stop: 'stop',
        cancel: 'cancel',
        change: 'change',
        none: '',
    }

    static readonly ManufacturePurposeList = {
        ruo: 'RUO',
        ivd: 'IVD',
        serivce: 'Service',
    }

    static readonly ProductDivisonList = {
        forSale: '판매용',
        forSample: '샘플용',
        forSupplement: '보충용',
        forReplacement: '교체용',
    }

    static readonly ProductTypeList = {
        none: '없음',
        illumina: 'Illumina',
        illuminaEP: 'Illumina(EP-KIT)',
        illuminaCS: 'Illumina(CS-KIT)',
        ionTorrent: 'Ion Torrent',
        etc: '기타 (MGI)',
    }

    static readonly ProbeStateList = {
        none: '없음',
        solution: 'Solution',
        powder: 'Powder',
    }

    static readonly IndexList = {
        none: '없음',
        single: 'Single',
        dual: 'Dual',
        barcode: 'Barcode',
        ionXpress: 'Ion Xpress',
    }

    static readonly PresetProductTypeList = {
        custom: 'Custom',
        preset: 'Preset',
    }

    static readonly AMPMList = {
        am: '오전',
        pm: '오후',
    }

    static readonly DepartList = {
        sales: 'sales',
        manufacturePart: 'manufacturePart',
        admin: 'admin',
        Admin: 'Admin',
        All: 'All',
        all: 'all',
    }

    static errorTexts = {
        needValue: "입력값이 필요합니다.",
        notValid: "입력값이 유효하지 않습니다. 입력값을 확인하세요.",
        needInt: "정수값만 가능합니다.",
        positive: "0이나 양수만 가능합니다.",
        badFormat: "포맷(형식)이 맞지 않습니다.",
        exceedCharacter: "제한 글자수를 초과했습니다."
    }

    static readonly changeType = {
        manufactureChangeShipping: 'manufactureChangeShipping',
        manufactureChangeExpectShipping: 'manufactureChangeExpectShipping',
    }

    dump = (_me?: () => {}, _cb?: () => {}) => {
        const data = {
            ...this,
        }
        data.itemList = this.itemList.map(v => new ManufactureItem({ ...v, optionReadonly: true }))
            .map(v => {
                v.catNO = v.manipulatedCatNO;
                return v;
            });
        return JSON.stringify(data, null, '\t');
    }

    setErrorObjectByName = (name: string, newError: ValidateErrorObject) => {
        this.errors[name] = newError;
    }

    setErrorTextByName = (name: string, text?: string) => {
        const error = this.errors[name];
        if (error) {
            if (text) {
                error.setErrorText(text);
            }
            else {
                error.setError(true, null, name);
            }
        }
    }

    initErrors = (): void => {
        for (const key of Object.keys(this)) {
            const prop = (this as any)[key];
            if (typeof prop === 'function') { continue; }
            // if (Array.isArray(prop)) { continue; }
            if (prop instanceof Validatable) { continue; }
            this.errors[key] = new ValidateErrorObject(null, null, key);
        }
    }

    deleteErrors = (): void => {
        // this.errors = {};
        delete this.errors;
    }

    deleteErrorsRecursive = (): void => {
        this.deleteErrors();
        for (const key of Object.keys(this)) {
            const prop = (this as any)[key];
            if (typeof prop === 'function') { continue; }
            // if (Array.isArray(prop)) { continue; }
            if (Array.isArray(prop)) {
                for (const data of prop) {
                    if (data instanceof Validatable) {
                        data.deleteErrorsRecursive();
                    }
                }
            }
            if (prop instanceof Validatable) {
                prop.deleteErrorsRecursive();
            }
        }
    }

    getErrors() {
        return this.errors;
    }

    getErrorList() {
        return Object.keys(this.errors).filter(v =>
            (this.errors as any)[v].isError
        )
    }

    getErrorDeletedThis = (): Validatable => {
        const newObj = Object.assign({}, this);
        newObj.deleteErrorsRecursive();
        return newObj;
    }

    initErrorByKey = (key: string) => {
        const prop = (this as any)[key];
        if (typeof prop === 'function') { return; }
        // if (Array.isArray(prop)) { continue; }
        if (prop instanceof Validatable) { return; }
        this.errors[key] = new ValidateErrorObject(null, null, key);
    }

    initErrorsRecursive = (): void => {
        this.initErrors();
        for (const key of Object.keys(this)) {
            const prop = (this as any)[key];
            if (typeof prop === 'function') { continue; }
            // if (Array.isArray(prop)) { continue; }
            if (Array.isArray(prop)) {
                for (const data of prop) {
                    if (data instanceof Validatable) {
                        data.initErrorsRecursive();
                    }
                }
            }
            if (prop instanceof Validatable) {
                prop.initErrorsRecursive();
            }
        }
    }

    resetErrors = (): void => {
        for (const error of Object.values(this.errors)) {
            error.initError();
        }
    }

    resetErrorsRecursive = (): void => {
        this.resetErrors();
        const This = this as any;
        for (const prop of Object.values(This)) {
            if (typeof prop === 'function') { continue; }
            // if (Array.isArray(prop)) { continue; }
            if (Array.isArray(prop)) {
                for (const data of prop) {
                    if (data instanceof Validatable) {
                        data.resetErrorsRecursive();
                    }
                }
            }
            if (prop instanceof Validatable) {
                prop.resetErrorsRecursive();
            }
        }
    }

    hasError = (): boolean => {
        const errors = this.errors;
        for (const error of Object.values(errors)) {
            if (error.isError) {
                return true;
            }
        }
        return false;
    }

    hasErrorRecursive = (): boolean => {
        let result = false;
        if (this.hasError()) {
            return true;
        }
        const This = this as any;
        for (const prop of Object.values(This)) {
            if (typeof prop === 'function') { continue; }
            // if (Array.isArray(prop)) { continue; }
            if (Array.isArray(prop)) {
                for (const data of prop) {
                    if (data instanceof Validatable) {
                        if (!result) {
                            result = data.hasErrorRecursive();
                        }
                    }
                }
            }
            if (prop instanceof Validatable) {
                if (!result) {
                    result = prop.hasErrorRecursive();
                }
            }
        }
        return result;
    }

    validateRecursive = (): void => {
        this.validateSelf();
        const This = this as any;
        for (const prop of Object.values(This)) {
            if (typeof prop === 'function') { continue; }
            // if (Array.isArray(prop)) { continue; }
            if (Array.isArray(prop)) {
                for (const data of prop) {
                    if (data instanceof Validatable) {
                        data.validateRecursive();
                    }
                }
            }
            if (prop instanceof Validatable) {
                prop.validateRecursive();
            }
        }
    }

    validateSelf = (): void => {
        if (!this.representativeName) {
            this.setErrorTextByName('representativeName', Validatable.errorTexts.needValue);
        }
        if (!this.writerName) {
            this.setErrorTextByName('writerName', Validatable.errorTexts.needValue);
        }
        if (!this.erpCode) {
            this.setErrorTextByName('erpCode', Validatable.errorTexts.needValue);
        }
        else if (!Helper.checkRegex(this.erpCode, /^[0-9][0-9](0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])A[0-9]{3}$/)) {
            this.setErrorTextByName('erpCode', `${Validatable.errorTexts.badFormat} -> 포맷: YYMMDDA###`);
        }
        // if (this.projectCode) {
        //     if (!Helper.checkRegex(this.projectCode, new RegExp(`^[a-zA-Z0-9]{6,7}-${this.erpCode}-[0-9]{3}$`))) {
        //         this.setErrorTextByName('projectCode', `${Validatable.errorTexts.badFormat} -> 포맷: [고객코드]-[ERP Code]-###`);
        //     }
        // }
        if (!this.customerAndPI) {
            this.setErrorTextByName('customerAndPI', Validatable.errorTexts.needValue);
        }
        if (!this.manufacturePurpose) {
            this.setErrorTextByName('manufacturePurpose', Validatable.errorTexts.needValue);
        }
        if (!this.productDivision) {
            this.setErrorTextByName('productDivision', Validatable.errorTexts.needValue);
        }
        // if (!this.determinedReleaseDate) {
        //     this.setErrorTextByName('determinedReleaseDate', Validatable.errorTexts.needValue);
        // }
        // if (!this.determinedReleaseAMPM) {
        //     this.setErrorTextByName('determinedReleaseAMPM', Validatable.errorTexts.needValue);
        // }
        if (this.isDeterminedReleaseDateSkipped) {
            if (!this.shippingRequestDate) {
                this.setErrorTextByName('shippingRequestDate', Validatable.errorTexts.needValue);
            }
            if (!this.shippingRequestAMPM) {
                this.setErrorTextByName('shippingRequestAMPM', Validatable.errorTexts.needValue);
            }
        }
        if (!this.manufactureVersion) {
            this.setErrorTextByName('manufactureVersion', Validatable.errorTexts.needValue);
        }
        // if (!this.index) {
        //     this.setErrorTextByName('index', Validatable.errorTexts.needValue);
        // }
        // if (!this.probeState) {
        //     this.setErrorTextByName('probeState', Validatable.errorTexts.needValue);
        // }
    }
}