import ProcessStore from "./ProcessStore";
import { action, observable } from "mobx";
import ChipDistribution from "../../../model/design/taskDatas/chipDistribution/ChipDistribution";
import SynthesisID from "../../../model/common/SynthesisID";
import axios from 'axios';
import { words as RouteWords } from '../../../../resource/data/APIRoutes.json';
import PTSMessage from "../../../model/common/PTSMessage";
import ChipDistributionInfo from "../../../model/common/ChipDistributionInfo";
import TaskInfo from '../../../../resource/data/tasks.json';

export default class ChipDistributionStore extends ProcessStore<ChipDistribution> {
    @observable sids: SynthesisID[];
    @observable designName: string;

    constructor() {
        super();
        this.sids = [];
        this.designName = '';
        this.taskData.detail = new ChipDistribution();
    }

    getSIDs = async (list: string[], token: string) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        return axios.post(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.sids}/${RouteWords.lists}`,
            {
                list: list
            },
            config,
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        });
    }

    @action
    setSIDs = async (list: string[], token: string) => {
        const sids: SynthesisID[] = [];
        try {
            const result = await this.getSIDs(list, token);
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                })
            }
            if (!Array.isArray(result.data.data)) {
                throw new PTSMessage({
                    message: 'Synthesis id 정보가 잘못되었습니다.',
                })
            }
            else {
                for (const d of result.data.data) {
                    const obj = new SynthesisID();
                    obj.fromJS(d);
                    sids.push(obj);
                }
                this.sids = sids;
            }
        } catch (err) {
            throw err;
        }
    }

    setDesignName = () => {
        this.designName = this.workInfo.designName;
    }

    init = async (token: string) => {
        try {
            this.setDesignName();
            const synthTask = this.workInfo.taskList.filter((v) => {
                return (v as any).type === TaskInfo.types.synthDesign
                    || (v as any).type === TaskInfo.types.rebalancingSynthDesign;
            });
            if (synthTask.length < 1) {
                throw new PTSMessage({
                    message: '오류 발생',
                })
            }
            const tmpSynth = synthTask.pop();
            let list: string[] = (tmpSynth as any).detail.synthesisIDs;
            const sids = list.map((v) => { return v; })
            await this.setSIDs(sids, token);
        } catch (err) {
            throw err;
        }
    }

    postChipInfos = async (token: string) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        const chips: ChipDistributionInfo[] = [];
        for (const syn of this.sids) {
            for (const chip of syn.chipInfos) {
                chip.sid = syn.sid;
                chips.push(chip);
            }
        }
        return axios.post(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.chipInfos}/`,
            chips,
            config
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        })
    }

    setPostData = async (workID: string, token: string) => {
        try {
            const result = await this.postChipInfos(token);
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                });
            }
            const list = result.data.data;
            if (!Array.isArray(list)) {
                throw new PTSMessage({
                    message: '오류발생',
                });
            }
            return list;
        } catch (err) {
            throw err;
        }
    }
}