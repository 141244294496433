import { observable, computed, action } from "mobx";
import WorkTaskSummary from "../../../model/design/taskDatas/designReqeust/WorkTaskSummary";
import StoreBase from "../../StoreBase";
import axios from 'axios';
import APIRoutes from '../../../../resource/data/APIRoutes.json';
import RootStore from "../../RootStore";
import PTSMessage from "../../../model/common/PTSMessage";

export default class TaskListStore extends StoreBase {
    @observable offSet?: number;
    @observable limit?: number;
    @observable total?: number;
    @observable stage?: string;
    @observable depart?: string;
    @observable departCode?: number;
    @observable taskList?: WorkTaskSummary[];
    @observable headers?: string[];

    constructor(rootStore?: RootStore) {
        super();
        this.taskList = [];
        this.headers = ['workID', 'projectCode', 'probeID', 'salesRepresentative', 'customerAndPI', 'designName'];
        this.total = 0;
        this.offSet = 0;
        this.limit = 20;
    }

    @computed
    get selectedNum() {
        return this.taskList.reduce((p, c) => {
            if (c.selected) {
                return p + 1;
            }
            else {
                return p;
            }
        }, 0)
    }

    requestWaitWorkList = async (token: string) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        return axios.post(
            `${this.baseURL}/${APIRoutes.words.auth}/${APIRoutes.words.pts}/${APIRoutes.words.api}/${APIRoutes.words.version2}/${APIRoutes.words.design}/${APIRoutes.words.tasks}/${APIRoutes.words.wait}`,
            {
                offSet: this.offSet,
                limit: this.limit,
            },
            config,
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        })
    }

    requestProcessingWorkList = async (token: string) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        return axios.post(
            `${this.baseURL}/${APIRoutes.words.auth}/${APIRoutes.words.pts}/${APIRoutes.words.api}/${APIRoutes.words.version2}/${APIRoutes.words.design}/${APIRoutes.words.tasks}/${APIRoutes.words.process}`,
            {
                offSet: this.offSet,
                limit: this.limit,
            },
            config,
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        })
    }

    @action
    setWaitList = async (token: string) => {
        try {
            const result = await this.requestWaitWorkList(token);
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    title: result.data.resName,
                    code: result.data.resCode,
                    message: result.data.resMessage,
                });
            }
            const list: WorkTaskSummary[] = [];
            if (result.data.data.list.length > 0) {
                result.data.data.list.map((v: any) => {
                    return list.push(new WorkTaskSummary(v));
                });
            }
            this.taskList = list;
            if (result.data.data.count !== undefined) {
                this.total = result.data.data.count;
            }
        } catch (err) {
            throw err;
        }
    }

    @action
    setProcessingList = async (token: string) => {
        try {
            const result = await this.requestProcessingWorkList(token);
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    title: result.data.resName,
                    code: result.data.resCode,
                    message: result.data.resMessage,
                });
            }
            const list: WorkTaskSummary[] = [];
            if (result.data.data.list.length > 0) {
                result.data.data.list.map((v: any) => {
                    return list.push(new WorkTaskSummary(v));
                });
            }
            this.taskList = list;
            if (result.data.data.count !== undefined) {
                this.total = result.data.data.count;
            }
        } catch (err) {
            throw err;
        }
    }

    requestAcceptTasks = async (list: object[], token: string) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        return axios.put(
            `${this.baseURL}/${APIRoutes.words.auth}/${APIRoutes.words.pts}/${APIRoutes.words.api}/${APIRoutes.words.version2}/${APIRoutes.words.design}/${APIRoutes.words.tasks}/${APIRoutes.words.wait}`,
            {
                list: list,
            },
            config,
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        });
    }

    acceptTasksMany = async (token: string) => {
        try {
            const list = this.taskList.filter(v => v.selected).map((t) => {
                return t.currTaskObj;
            });
            const result = await this.requestAcceptTasks(list, token);
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                });
            }
            await this.setWaitList(token);
        } catch (err) {
            throw err;
        } finally {
        }
    }

    acceptTask = async (token: string, key: number) => {
        try {
            const list = [];
            list.push(this.taskList[key].currTaskObj);
            const result = await this.requestAcceptTasks(list, token);
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                });
            }
            await this.setWaitList(token);
        } catch (err) {
            throw err;
        } finally {
        }
    }
}
