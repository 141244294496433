import dayjs from "dayjs";
import { observable } from "mobx";
import FormUtil from "../../../module/FormUtil";
import ModelBase from "../common/ModelBase";
import User from "../user/user";

export interface ISnopItem {
    workID?: string;
    representativeName?: string;
    requestDate?: Date;
    projectID?: string;
    workType?: string;
    customerAndPI?: string;
    probeID?: string;
    workStatus?: string;
    type?: string;
    currentStage?: string;
    currentWorkType?: string;
    repInfo?: User;
}

abstract class SnopItem extends ModelBase implements ISnopItem {
    @observable workID: string = '';
    @observable representativeName: string = '';
    @observable requestDate: Date = null;
    @observable projectID: string = '';
    @observable workType: string = '';
    @observable customerAndPI: string = '';
    @observable probeID: string = '';
    @observable workStatus: string = '';
    @observable type: string = '';
    @observable currentStage: string = '';
    @observable currentWorkType: string = '';
    @observable repInfo: User = null;

    constructor(data?: ISnopItem) {
        super();
        if (data) {
            Object.assign(this, data);
        }
    }

    abstract setDatas<T extends ISnopItem>(data: T): void;

    getFormattedDate = (date: Date, format?: string) => {
        if (format) {
            if (date) {
                return dayjs(date).format(format);
            }
            else {
                return null;
            }
        }
        else {
            if (date) {
                return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
            }
            else {
                return null;
            }
        }
    }

    get WorkStatus() {
        return FormUtil.getFieldLabel(this.workStatus);
    }

    get RequestDate() {
        return this.getFormattedDate(this.requestDate);
    }

    get WorkStage() {
        return `${this.currentWorkType} ${this.currentStage}`;
    }

    get RepName() {
        return this.repInfo?.name ?? '';
    }
}

export default SnopItem;