import { observable } from "mobx";
import Validatable from "../../../common/Validatable";
import TaskSubData from "../../DesignTaskSubData";

export default class BIProbeDetail extends TaskSubData {
    @observable targetSize?: string;
    @observable numberOfTargetRegion?: string;
    @observable numberOfUniqueProbes?: number;

    // override
    validateSelf(): void {
        if (!this.targetSize) {
            this.setErrorTextByName('targetSize', Validatable.errorTexts.needValue);
        }
        // else if (this.targetSize <= 0) {
        //     this.setErrorTextByName('targetSize', Validatable.errorTexts.positiveNumber)
        // }
        if (!this.numberOfTargetRegion) {
            this.setErrorTextByName('numberOfTargetRegion', Validatable.errorTexts.needValue);
        }
        // else if (this.numberOfTargetRegion <= 0) {
        //     this.setErrorTextByName('numberOfTargetRegion', Validatable.errorTexts.positiveNumber)
        // }
        if (!this.numberOfUniqueProbes) {
            this.setErrorTextByName('numberOfUniqueProbes', Validatable.errorTexts.needValue);
        }
        else if (this.numberOfUniqueProbes <= 0) {
            this.setErrorTextByName('numberOfUniqueProbes', Validatable.errorTexts.positiveNumber)
        }
    }
}