import { observable } from "mobx";
import ChipDistributionInfo from "../../../common/ChipDistributionInfo";
import TaskSubData from "../../DesignTaskSubData";

export default class ChipDistribution extends TaskSubData {
    @observable chipInfos?: ChipDistributionInfo[];
    @observable chipInfosOID?: string[];

    constructor() {
        super();
        this.chipInfos = [];
        this.chipInfosOID = [];
    }

    // override
    validateSelf(): void {
        return;
    }
}