import React, { useRef } from 'react';
import { Grid, Button } from '@material-ui/core';
import { observer } from 'mobx-react';
import ManufactureWork from '../../../../../data/model/manufacture/ManufactureWork';
import ReactToPrint from 'react-to-print';
import PrintManufactureRequestComponent from './print/PrintManufactureRequestComponent';
import PrintManufactureShippingConfirmComponent from './print/PrintManufactureShippingConfirmComponent';
import { CSVLink } from 'react-csv';
import { GetApp } from '@material-ui/icons';
import dayjs from 'dayjs';
import ManufactureItem from '../../../../../data/model/manufacture/ManufactureItem';
// import Helper from '../../../../../module/Helper';

interface Props {
    data?: ManufactureWork;
    isLoaded?: boolean;
    requestPrintable?: boolean;
    shippingPrintable?: boolean;
}

const ManufacturePrintComponent: React.FC<Props> = observer((props) => {
    const loaded = props.isLoaded || false;
    const requestRef = useRef();
    const shippingRef = useRef();

    const requestPrintable = props.requestPrintable || false;
    const shippingPrintable = props.shippingPrintable || false;

    return (
        <React.Fragment>
            <Grid container style={{ marginBottom: 15 }}>
                <Grid item xl lg md sm>
                    <ReactToPrint
                        trigger={() =>
                            <Button
                                disabled={!loaded || !requestPrintable}
                                color='primary'
                                variant='contained'
                                style={{ marginRight: 10 }}>
                                제조의뢰서 출력
                            </Button>
                        }
                        content={() => requestRef.current}
                    />
                    <ReactToPrint
                        trigger={() =>
                            <Button
                                disabled={!loaded || !shippingPrintable}
                                color='primary'
                                variant='contained'
                                style={{ marginRight: 10 }}>
                                출하확인서 출력
                            </Button>
                        }
                        content={() => shippingRef.current}
                    />
                    <CSVLink
                        data={props.data.itemList.map((v, i) => {
                            return {
                                '#': i + 1,
                                'Items': v.itemName,
                                'Unit Q\'ty': v.numberPerQty,
                                'Unit': v.unit,
                                'Q\'ty': v.qty,
                                'Pooling': v.pooling,
                                'ProbeID': v.probeID,
                                'Remark': v.remark,
                                'PanelName': v.panelName,
                                // 'Cat NO.': Helper.padZeroCatNORxn(v.catNO),
                                'Cat NO.': v.manipulatedCatNO ? v.manipulatedCatNO : '',
                                'Lot NO.': v.lotNO,
                                'Exp. Date': v.expDate ? dayjs(v.expDate).format('YYYY-MM-DD') : '',
                                'StorageTemp': (ManufactureItem.StorageTempList as any)[v.storageTemp],
                                'ShippingCondition': (ManufactureItem.ShippingCoditionList as any)[v.shippingCondition],
                                'Box NO.': v.boxNO,
                            }
                        })}
                        filename={`PackingList_${props.data.erpCode}`}
                        onClick={() => {
                            if (props.data.itemList.length < 1) {
                                return false;
                            }
                        }}
                        style={{
                            textDecoration: 'none'
                        }}
                    >
                        <Button
                            disabled={props.data.itemList.length < 1}
                            color='primary'
                            variant='contained'
                            style={{ marginRight: 10 }}>
                            패킹 리스트
                            <GetApp />
                        </Button>
                    </CSVLink>
                </Grid>
            </Grid>
            <div style={{ display: 'none' }}>
                <PrintManufactureRequestComponent data={props.data} ref={requestRef} />
                <PrintManufactureShippingConfirmComponent data={props.data} ref={shippingRef} />
            </div>
        </React.Fragment>
    )
})

export default ManufacturePrintComponent;