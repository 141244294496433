import React from 'react';
import TaskFormProps from '../TaskFormProps';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { FormControl, Grid, TextField } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Synth1Store from '../../../../../../../../data/store/pageStore/design/Synth1Store';
import Helper from '../../../../../../../../module/Helper';
import TitledPaper from '../../../../../../../common/component/TitledPaper';
import FormButtonSet from '../../../../../../../common/component/FormButtonSet';
import PTSMessage from '../../../../../../../../data/model/common/PTSMessage';
import dayjs from 'dayjs';
import DateUtil from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import FormUtil from '../../../../../../../../module/FormUtil';
import Validatable from '../../../../../../../../data/model/common/Validatable';
import Synth1InputCell from './Synth1InputCell';

interface Props extends TaskFormProps, RouteComponentProps<any> {
}

@inject('rootStore')
@observer
class Synth1Form extends React.Component<Props> {
    globalStore = this.props.rootStore.globalStore;
    store = observable(new Synth1Store());

    componentDidMount = async () => {
        try {
            const workInfo = this.props.workInfo;
            this.store.workInfo = workInfo;
            this.store.taskData._id = (workInfo.taskList[0] as any)._id;
            this.store.taskData.workID = (workInfo.taskList[0] as any).workID;
            this.props.rootStore.commentStore.stage = '합성 Ph.1';
            await this.store.setChipInfos(this.globalStore.token);
        } catch (err) {

        } finally {
            this.globalStore.isRunning = false;
        }
    }

    onFileChange = (name: string, files: FileList) => {
        const file = files[0];
        const uniqueKey = Helper.generateRandomString();
        const ptsFileObj = Helper.generatePTSFileWithUniqueKey(file, uniqueKey);
        this.store.files.set(name, file);
        this.store.filesMapping.set(name, ptsFileObj);
        return ptsFileObj;
    }

    onFormSubmit = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        try {
            if (!window.confirm('제출하시겠습니까?')) {
                return;
            }
            this.globalStore.isRunning = true;

            const chipResult = await this.store.replaceChipInfos(this.globalStore.token);
            if (chipResult.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: chipResult.data.resMessage,
                })
            }

            this.props.postTaskData(this.store.taskData);
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
        } finally {
            this.globalStore.isRunning = false;
        }
    }

    getFileUploadForm = (): FormData => {
        const formData = new FormData();
        const fe = Array.from(this.store.files.values());
        for (const file of fe) {
            formData.append('files', file);
        }
        return formData;
    }

    onValidate = () => {
        this.store.taskData.detail.initErrorsRecursive();
        this.store.taskData.detail.resetErrorsRecursive();
        this.store.taskData.detail.validateRecursive();
        this.store.isValid = !this.store.taskData.detail.hasErrorRecursive();

        for (let standardChipInfo of this.store.chipInfos) {
            standardChipInfo.initErrorByKey('synthesisChipID');
            standardChipInfo.resetErrorsRecursive();
            standardChipInfo.validateRecursive();
            for (let choiceChipInfo of this.store.chipInfos) {
                if (standardChipInfo.synthesisChipID === null ||
                    choiceChipInfo.synthesisChipID === null ||
                    standardChipInfo.CDSDID === choiceChipInfo.CDSDID) {
                    continue;
                }
                if (standardChipInfo.chipID === choiceChipInfo.chipID) {
                    if (standardChipInfo.synthesisChipID !== choiceChipInfo.synthesisChipID) {
                        standardChipInfo.setErrorTextByName('synthesisChipID', `BI Chip ID가 같을 경우 Synthesis Chip ID도 같아야 합니다.`);
                    }
                }
                else if (standardChipInfo.chipID !== choiceChipInfo.chipID) {
                    if (standardChipInfo.synthesisChipID === choiceChipInfo.synthesisChipID) {
                        standardChipInfo.setErrorTextByName('synthesisChipID', `BI Chip ID가 다를 경우 Synthesis Chip ID도 달라야 합니다.`);
                    }
                }
            }
            if (standardChipInfo.hasErrorRecursive()) {
                this.store.isValid = !standardChipInfo.hasErrorRecursive();
            }
        }

        if (!this.store.isValid) {
            alert(Validatable.errorTexts.notValid);
        }
    }

    onFormClear = () => {
        window.location.reload();
    }

    render() {
        return (
            <React.Fragment>
                <Grid item container>
                    <Grid item xl lg sm>
                        <TitledPaper title='합성 Ph.1 정보'>
                            <Grid>
                                <TextField
                                    fullWidth
                                    label='작업자'
                                    margin='normal'
                                    onChange={this.store.taskData.detail.onChangeDefault}
                                    value={this.store.taskData.detail.worker}
                                    name='worker'
                                    helperText={
                                        Helper.getErrorText(
                                            this.store.taskData.detail.errors.worker
                                        )
                                    }
                                    error={
                                        Helper.getErrorState(
                                            this.store.taskData.detail.errors.worker
                                        )
                                    }
                                />
                            </Grid>
                            <MuiPickersUtilsProvider utils={DateUtil}>
                                <FormControl fullWidth margin='normal'>
                                    <Grid container item>
                                        <Grid item xl lg sm>
                                            <KeyboardDatePicker
                                                required
                                                fullWidth
                                                disableToolbar
                                                variant='inline'
                                                format='yyyy/MM/dd'
                                                label={FormUtil.getFieldLabel('synthStartDate')}
                                                value={
                                                    dayjs(this.store.taskData.detail.synthStartDate)
                                                }
                                                onChange={(date) => { this.store.taskData.detail.onChangeDateDefault('synthStartDate', date) }}
                                                name='synthStartDate'
                                                helperText={
                                                    Helper.getErrorText(
                                                        this.store.taskData.detail.errors.synthStartDate
                                                    )
                                                }
                                                error={
                                                    Helper.getErrorState(
                                                        this.store.taskData.detail.errors.synthStartDate
                                                    )
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            </MuiPickersUtilsProvider>
                        </TitledPaper>
                    </Grid>
                </Grid>
                <Grid item xl lg sm>
                    <TitledPaper title='Chip Infos'>
                        <Grid xl lg sm item container direction='column'>
                            {this.store.chipInfos.map((v, i) => (
                                <Synth1InputCell data={v} index={i} key={i} />
                            ))}
                        </Grid>
                    </TitledPaper>
                </Grid>
                <Grid item xl lg sm>
                    <FormButtonSet
                        validated={this.store.isValid}
                        onFormClear={(e) => this.onFormClear()}
                        onSubmit={(e) => this.onFormSubmit(e)}
                        onValidate={(e) => this.onValidate()} />
                </Grid>
            </React.Fragment>
        )
    }
}

export default withRouter(Synth1Form);