import ModelBase from "./ModelBase";
import { observable } from "mobx";

export default class ValidateErrorObject extends ModelBase {
    @observable isError: boolean;
    @observable errorText: string;
    @observable name: string;

    constructor(isError?: boolean, errorText?: string, name?: string) {
        super();
        this.isError = isError || false;
        this.errorText = errorText || '';
        this.name = name || '';
    }

    setError = (isError?: boolean, errorText?: string, name?: string): void => {
        this.isError = isError || false;
        this.errorText = errorText || '';
        this.name = name || this.name;
    }

    setErrorText = (text?: string): void => {
        if (text) {
            this.isError = true;
        }
        else {
            this.isError = false;
        }
        this.errorText = text || '';
    }

    initError = (): void => {
        this.setError();
    }
}