import React from 'react';
import { inject, observer } from 'mobx-react';
import TaskFormProps from '../TaskFormProps';
import { observable } from 'mobx';
import { Grid, Typography } from '@material-ui/core';
import ProcessStore from '../../../../../../../../data/store/pageStore/design/ProcessStore';
import BIAnalysis from '../../../../../../../../data/model/design/taskDatas/bi/BIAnalysis';
import Helper from '../../../../../../../../module/Helper';
import TitledPaper from '../../../../../../../common/component/TitledPaper';
import FormUtil from '../../../../../../../../module/FormUtil';
import FormButtonSet from '../../../../../../../common/component/FormButtonSet';
import PTSMessage from '../../../../../../../../data/model/common/PTSMessage';
import Validatable from '../../../../../../../../data/model/common/Validatable';
import FileWithHelperText from '../../../../../../../common/component/FileWithHelperText';

interface Props extends TaskFormProps {
}

@inject('rootStore')
@observer
class BIAnalysisForm extends React.Component<Props> {
    globalStore = this.props.rootStore.globalStore;
    store = observable(new ProcessStore<BIAnalysis>());

    constructor(props: Props) {
        super(props);
        this.store.taskData.detail = new BIAnalysis();
        this.props.rootStore.commentStore.stage = 'BI 분석';
    }

    componentDidMount = async () => {
        try {
            this.globalStore.isRunning = true;
            const workInfo = this.props.workInfo;
            this.store.workInfo = workInfo;
            this.store.taskData._id = (workInfo.taskList[0] as any)._id;
            this.store.taskData.workID = (workInfo.taskList[0] as any).workID;
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
        } finally {
            this.globalStore.isRunning = false;
        }
    }

    onFileChange = (name: string, files: FileList) => {
        const file = files[0];
        const uniqueKey = Helper.generateRandomString();
        const ptsFileObj = Helper.generatePTSFileWithUniqueKey(file, uniqueKey);
        this.store.files.set(name, file);
        this.store.filesMapping.set(name, ptsFileObj);
        return ptsFileObj;
    }

    onFormSubmit = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        try {
            if (!window.confirm('제출하시겠습니까?')) {
                return;
            }
            this.globalStore.isRunning = true;

            // upload files
            const files = this.store.getFileUploadForm();
            if (files) {
                const fileResult = await this.store.uploadFiles(files, this.globalStore.token);
                const mapping = Array.from(this.store.filesMapping.values());

                for (let i = 0; i < fileResult.data.data.length; i++) {
                    mapping[i].file_id = fileResult.data.data[i];
                }
            }

            this.props.postTaskData(this.store.taskData);
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err);
            }
        } finally {
            this.globalStore.isRunning = false;
        }
    }

    onValidate = () => {
        this.store.taskData.detail.initErrorsRecursive();
        this.store.taskData.detail.resetErrorsRecursive();
        this.store.taskData.detail.validateRecursive();
        this.store.isValid = !this.store.taskData.detail.hasErrorRecursive();
        if (!this.store.isValid) {
            alert(Validatable.errorTexts.notValid);
        }
    }

    onFormClear = () => {
        window.location.reload();
    }

    getFileUploadForm = (): FormData => {
        const formData = new FormData();
        const fe = Array.from(this.store.files.values());
        for (const file of fe) {
            formData.append('files', file);
        }
        return formData;
    }

    render() {
        return (
            <React.Fragment>
                <Grid item container>
                    <Grid item xl lg sm>
                        <TitledPaper title='BI analysis datas'>
                            <Grid item container xl lg sm style={{ padding: 10 }} direction='column' spacing={3}>
                                <Grid item xl={4} lg={4} sm={4} style={{ marginRight: 10 }}>
                                    <Typography variant='body1'>
                                        {FormUtil.getFieldLabel('analysisStat')}
                                    </Typography>
                                    <FileWithHelperText
                                        name='analysisStat'
                                        multiple={false}
                                        onFileChange={this.onFileChange}
                                        data={this.store.taskData.detail}
                                        helperText={
                                            Helper.getErrorText(
                                                this.store.taskData.detail.errors.analysisStat
                                            )
                                        }
                                        error={
                                            Helper.getErrorState(
                                                this.store.taskData.detail.errors.analysisStat
                                            )
                                        } />
                                </Grid>
                                <Grid item xl={4} lg={4} sm={4} style={{ marginRight: 10 }}>
                                    <Typography variant='body1'>
                                        {FormUtil.getFieldLabel('analysisRD')}
                                    </Typography>
                                    <FileWithHelperText
                                        name='analysisRD'
                                        multiple={false}
                                        onFileChange={this.onFileChange}
                                        data={this.store.taskData.detail}
                                        helperText={
                                            Helper.getErrorText(
                                                this.store.taskData.detail.errors.analysisRD
                                            )
                                        }
                                        error={
                                            Helper.getErrorState(
                                                this.store.taskData.detail.errors.analysisRD
                                            )
                                        } />
                                </Grid>
                                <Grid item xl={4} lg={4} sm={4} style={{ marginRight: 10 }}>
                                    <Typography variant='body1'>
                                        {FormUtil.getFieldLabel('finalBedFile')}
                                    </Typography>
                                    <FileWithHelperText
                                        name='finalBedFile'
                                        multiple={false}
                                        onFileChange={this.onFileChange}
                                        data={this.store.taskData.detail}
                                        helperText={
                                            Helper.getErrorText(
                                                this.store.taskData.detail.errors.finalBedFile
                                            )
                                        }
                                        error={
                                            Helper.getErrorState(
                                                this.store.taskData.detail.errors.finalBedFile
                                            )
                                        } />
                                </Grid>
                            </Grid>
                        </TitledPaper>
                    </Grid>
                </Grid>
                <Grid item xl lg sm>
                    <FormButtonSet
                        validated={this.store.isValid}
                        onFormClear={(e) => this.onFormClear()}
                        onSubmit={(e) => this.onFormSubmit(e)}
                        onValidate={(e) => this.onValidate()} />
                </Grid>
            </React.Fragment>
        )
    }
}

export default BIAnalysisForm;