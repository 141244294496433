import React from "react";
import { inject, observer } from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import ManufactureStore from "../../../../../data/store/pageStore/manufacture/ManufactureStore";
import { IRootStore } from "../../../../../data/store/RootStore";
import TitledPaper from "../../../../common/component/TitledPaper";
import { Button, FormControl, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateUtil from '@date-io/date-fns';
import FormButtonSet from "../../../../common/component/FormButtonSet";
import ManufactureNoteForm from "../component/ManufactureNoteForm";
import Validatable from "../../../../../data/model/common/Validatable";
import dayjs from "dayjs";
import Helper from "../../../../../module/Helper";
import PTSMessage from "../../../../../data/model/common/PTSMessage";
import CommonErrors, { CommonError } from "../../../../../data/model/common/CommonErrors";
import ManufactureItemsForm from "../component/ManufactureItemsForm";
import ManufactureInfoRefForm from "../component/ManufactureInfoRefForm";
import FormUtil from "../../../../../module/FormUtil";
import HelperText from "../../../../common/component/HelperText";
import ManufactureOrderNumberList from "../../../../../data/model/manufacture/ManufactureOrderNumberList";
import ManufactureNoteListForm from "../component/ManufactureNoteListForm";
import ManufactureCustomerRequestInfoComponent from "../component/ManufactureCustomerRequestInfoComponent";

interface Props extends RouteComponentProps<any>, IRootStore {
}

@inject('rootStore')
@observer
class ManufactureExpectShippingRequestContainer extends React.Component<Props> {
    store = new ManufactureStore();
    state = { manufactureOrderNumberList: '' }
    globalStore = this.props.rootStore.globalStore;
    commentStore = this.props.rootStore.commentStore;
    manufactureOrderNumberError: string[];

    constructor(props: Props) {
        super(props);
        this.globalStore.useComment = true;
        this.commentStore.mode = 'work';
        this.commentStore.stage = '제조 예상 출하 가능일 입력';
        this.manufactureOrderNumberError = [];
    }

    componentDidMount = async () => {
        try {
            this.globalStore.isRunning = true;
            const workID = this.props.match.params.workID;
            const result = await this.store.getManufactureByWorkID(workID, this.globalStore.token);
            const track = await this.store.getManufactureTrack(workID, this.globalStore.token);
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                })
            }
            if (track.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                })
            }
            if (this.globalStore.userInfo.role.toLocaleLowerCase() !== 'admin') {
                if (track.data.data.current.departType !== this.globalStore.userInfo.departType) {
                    throw CommonErrors.NoAuth;
                }
            }
            this.store.workID = workID;
            this.store.data.fromJS(result.data.data);
            this.globalStore.currentTaskTitle = this.store.getTitleByType(track.data.data.current.workType);
            this.store.setItemList(result.data.data.itemList);
            this.store.setInputHeadersByType(track.data.data.current.workType);
            this.store.setReadonlyHeadersByType(track.data.data.current.workType);
            this.commentStore.workID = workID;
            this.commentStore.stage = this.globalStore.currentTaskTitle;
            await this.commentStore.setCommentsMany();
        } catch (err) {
            if (err instanceof CommonError || err instanceof PTSMessage) {
                alert(err.message);
            }
            else {
                alert('오류 발생');
            }
            this.props.history.push('/main/manufacture/list/process');

        } finally {
            this.globalStore.isRunning = false;
        }
    }

    onFormSubmit = async () => {
        if (!window.confirm('제출하시겠습니까?')) {
            return;
        }
        try {
            this.globalStore.isRunning = true;

            // api
            const result =
                await this.store.processManufacture(
                    this.globalStore.token
                );

            // result
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                });
            }
            alert('제출완료');
            this.props.history.push(`/main/manufacture/list/process`);
            window.location.reload();
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
            else {
                alert('오류 발생.');
            }
            this.store.isValid = false;
        } finally {
            this.globalStore.isRunning = false;
        }
    }

    onValidate = () => {
        try {
            this.store.data.resetErrors();
            this.store.isValid = true;
            if (!this.store.data.manufactureExpectShippingRequestDate) {
                this.store.data.setErrorTextByName('manufactureExpectShippingRequestDate', Validatable.errorTexts.needValue);
                this.store.isValid = false;
            }
            else if (dayjs(this.store.data.manufactureExpectShippingRequestDate).diff(dayjs(), 'day') <= -1) {
                this.store.data.setErrorTextByName('manufactureExpectShippingRequestDate', Validatable.errorTexts.pastDate);
                this.store.isValid = false;
            }
            this.handleOrderNumberListChange();
            if (!this.store.isValid) {
                alert('유효하지 않은 값이 있습니다.');
                return;
            }
        } catch (err) {

        } finally {

        }
    }

    handleOrderNumberListChange = (): void => {
        try {
            const value = this.state.manufactureOrderNumberList;
            const split = value.split(/[\t\n\s]/);
            const list: ManufactureOrderNumberList[] = [];
            split.map((v) => {
                if (v) {
                    list.push(new ManufactureOrderNumberList(v.trim()));
                }
                return null;
            })

            this.store.data.manufactureOrderNumberList = list;

            if (this.store.data.manufactureOrderNumberList.length < 1) {
                this.store.data.setErrorTextByName('manufactureOrderNumberList', Validatable.errorTexts.needValue);
                this.store.isValid = false;
            }
            else if (typeof this.store.data.manufactureOrderNumberList !== 'undefined' && this.store.data.manufactureOrderNumberList.length > 0) {
                this.store.data.initErrorByKey('manufactureOrderNumberList');
                for (let index in this.store.data.manufactureOrderNumberList) {
                    this.store.data.manufactureOrderNumberList[index].initErrorByKey('manufactureOrderNumber');
                    if (!Helper.checkRegex(this.store.data.manufactureOrderNumberList[index].manufactureOrderNumber, /^[0-9][0-9](0[1-9]|1[0-2])(PD|PO|TB|XB)[0-9]{4}$/)) {
                        this.store.data.setErrorTextByName('manufactureOrderNumberList', '');
                        this.store.data.manufactureOrderNumberList[index].setErrorTextByName('manufactureOrderNumber', `${Validatable.errorTexts.badFormat} -> 포맷: YYMMPD#### (PD or PO or TB or XB)`);
                        this.store.isValid = false;
                    }
                }
            }

            /*
            this.store.data.manufactureOrderNumberList = list;
            this.manufactureOrderNumberError = [];
            let isErrorCheck = false;

            if (this.store.data.manufactureOrderNumberList.length < 1) {
                this.store.data.setErrorTextByName('manufactureOrderNumberList', Validatable.errorTexts.needValue);
            }
            else if (typeof this.store.data.manufactureOrderNumberList !== 'undefined' && this.store.data.manufactureOrderNumberList.length > 0) {
                this.store.data.initErrorByKey('manufactureOrderNumberList');
                for (let index in this.store.data.manufactureOrderNumberList) {
                    if (!Helper.checkRegex(this.store.data.manufactureOrderNumberList[index], /^[0-9][0-9](0[1-9]|1[0-2])PD[0-9]{4}$/)) {
                        this.store.data.setErrorTextByName('manufactureOrderNumberList', '');
                        this.manufactureOrderNumberError[index] = `${Validatable.errorTexts.badFormat} -> 포맷: YYMMPD####`;
                        this.store.isValid = false;
                        isErrorCheck = true;
                    }
                    else {
                        this.manufactureOrderNumberError[index] = '';
                    }
                }
                if (!isErrorCheck) {
                    this.manufactureOrderNumberError = [];
                }
            }
            */
        } catch (err) {

        }
    }

    handleOrderNumberListClear = (): void => {
        this.setState({ manufactureOrderNumberList: '' });
        this.store.data.manufactureOrderNumberList = [];
        this.store.data.initErrorByKey('manufactureOrderNumberList');
        this.manufactureOrderNumberError = [];
    }

    render() {
        return (
            <React.Fragment>
                <TitledPaper title='제조의뢰 정보'>
                    <ManufactureInfoRefForm data={this.store.data} readOnly />
                    <ManufactureCustomerRequestInfoComponent
                        data={this.store.data}
                        readOnly
                    />
                </TitledPaper>
                <TitledPaper title='제조 예상 출하 가능일 입력'>
                    <Grid container>
                        <Grid item xl lg md>
                            <MuiPickersUtilsProvider utils={DateUtil}>
                                <FormControl margin='normal'>
                                    <KeyboardDatePicker
                                        variant='inline'
                                        format='yyyy/MM/dd'
                                        name='manufactureExpectShippingRequestDate'
                                        label='예상 출하 가능일'
                                        value={
                                            this.store.data.manufactureExpectShippingRequestDate
                                        }
                                        onChange={(date) => { this.store.data.onChangeDateDefault('manufactureExpectShippingRequestDate', date) }}
                                        helperText={
                                            Helper.getErrorText(
                                                this.store.data.errors.manufactureExpectShippingRequestDate
                                            )
                                        }
                                        error={
                                            Helper.getErrorState(
                                                this.store.data.errors.manufactureExpectShippingRequestDate
                                            )
                                        }
                                    />
                                </FormControl>
                            </MuiPickersUtilsProvider>
                        </Grid>
                        {/* <ETSField
                            fullWidth
                            margin='normal'
                            rootdata={this.store.data}
                            value={this.store.data.manufactureOrderNumber}
                            name='manufactureOrderNumber'
                        /> */}
                    </Grid>
                    <Grid item container xl lg sm style={{ marginTop: 10 }}>
                        <Grid item xl lg sm>
                            <TitledPaper title={FormUtil.getFieldLabel('manufactureOrderNumber')}>
                                <Grid xl lg sm item container alignItems='stretch'>
                                    <Grid xl={10} lg={10} sm={10} container item alignItems='stretch'>
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={5}
                                            margin='normal'
                                            label={FormUtil.getFieldLabel('manufactureOrderNumber')}
                                            onChange={(e) => this.setState({ manufactureOrderNumberList: e.target.value })}
                                            value={this.state.manufactureOrderNumberList}
                                            helperText={
                                                Helper.getErrorText(
                                                    this.store.data.errors.manufactureOrderNumberList
                                                )
                                            }
                                            error={
                                                Helper.getErrorState(
                                                    this.store.data.errors.manufactureOrderNumberList
                                                )
                                            }
                                        />
                                    </Grid>
                                    <Grid spacing={1} style={{ margin: 10 }} xl lg sm container item direction='column' alignItems='stretch'>
                                        <Grid spacing={1} xl lg sm item container alignItems='stretch'>
                                            <Button
                                                style={{ margin: 5 }}
                                                fullWidth
                                                onClick={() => this.handleOrderNumberListChange()}
                                                variant='contained'
                                                color='primary'>적용</Button>
                                            <Button
                                                style={{ margin: 5 }}
                                                fullWidth
                                                variant='contained'
                                                onClick={() => this.handleOrderNumberListClear()}
                                                color='secondary'>지움</Button>
                                        </Grid>
                                    </Grid>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    #
                                                </TableCell>
                                                <TableCell>
                                                    OrderNumber
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.store.data.manufactureOrderNumberList.map((v, i) => (
                                                <TableRow key={i}>
                                                    <TableCell>
                                                        {i + 1}
                                                    </TableCell>
                                                    <TableCell width={'75%'} style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                                                        {v.manufactureOrderNumber} &nbsp;
                                                        <HelperText text={
                                                            Helper.getErrorText(
                                                                this.store.data.manufactureOrderNumberList[i].errors.manufactureOrderNumber
                                                            )
                                                        } />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </TitledPaper>
                        </Grid>
                    </Grid>
                </TitledPaper>
                <TitledPaper title='제조 아이템 목록' >
                    <ManufactureItemsForm
                        data={this.store.data}
                        readonlyHeaders={this.store.readonlyHeaders}
                        inputHeaders={this.store.inputHeaders} />
                </TitledPaper>
                <TitledPaper title='영업팀 NOTE'>
                    <ManufactureNoteForm data={this.store.data} depart='sales' readOnly />
                </TitledPaper>
                <TitledPaper title='제조파트 Note'>
                    <ManufactureNoteListForm data={this.store.data} type={this.store.data.type} />
                </TitledPaper>
                <Grid container>
                    <Grid item xl lg md >
                        <FormButtonSet
                            onSubmit={this.onFormSubmit}
                            onValidate={this.onValidate}
                            validated={this.store.isValid}
                            onFormClear={() => { window.location.reload() }} />
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

export default withRouter(ManufactureExpectShippingRequestContainer)