import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { CssBaseline, Container, Grid, Typography, TextField, Button } from '@material-ui/core';
import { IRootStore } from '../../../data/store/RootStore';
import TitledPaper from '../../common/component/TitledPaper';
import FormUtil from '../../../module/FormUtil';
import Axios from 'axios';
import TaskListStore from '../../../data/store/pageStore/design/TaskListStore';
import { words as RouteWords } from '../../../resource/data/APIRoutes.json';
import PTSMessage from '../../../data/model/common/PTSMessage';

interface Props extends RouteComponentProps<any>, IRootStore {
}

const store = new TaskListStore();

const ModifyUserInfoPage: React.FC<Props> = inject('rootStore')(observer((props) => {
    const [pwConfirm, setPWConfirm] = useState('');
    const [newPW, setNewPW] = useState('');
    const [currPW, setCurrPW] = useState('');
    const [pwValid, setPWValid] = useState(false);

    const pwReg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

    const globalStore = props.rootStore.globalStore;

    const onFormSubmit = async (e: React.FormEvent<HTMLElement>) => {
        e.preventDefault();
        try {
            globalStore.isRunning = true;
            if (!pwValid || !newPW || !pwConfirm || !currPW) {
                alert('비밀번호가 올바르지 않습니다.');
                return;
            }
            const obj = {
                newPW: newPW,
                pw: currPW,
                email: globalStore.userInfo.email,
            };
            await changeUserPassword(obj);
            alert('변경되었습니다.');
            window.location.reload();
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
        } finally {
            globalStore.isRunning = false;
        }
    }

    const changeUserPassword = async (obj: object) => {
        try {
            const result = await requestChangeUserPassword(obj);
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                })
            }
        } catch (err) {
            throw err;
        }
    }

    const requestChangeUserPassword = async (obj: object) => {
        const config = {
            headers: {
                'x-access-token': globalStore.token,
            }
        }
        return Axios.put(
            `${store.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.users}`,
            obj,
            config,
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        });
    }

    const onFormClear = () => {
        window.location.reload();
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth='xl'>
                <Grid container justify='center'>
                    <Grid xl={9} lg={8} md={8} sm={8} item style={{
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-line',
                        padding: 5
                    }}>
                        <div>
                            <Typography variant='h4'>
                                사용자 정보 수정
                            </Typography>
                        </div>
                        <TitledPaper title='비밀번호 변경'>
                            <form autoComplete='off' onSubmit={onFormSubmit}>
                                <Grid item xl lg sm style={{ marginTop: 20 }}>
                                    <TextField
                                        required
                                        fullWidth
                                        margin='dense'
                                        value={currPW}
                                        name='currPW'
                                        type='password'
                                        label='현재 비밀번호'
                                        onChange={(e) => setCurrPW(e.target.value)} />
                                    <TextField
                                        error={
                                            newPW
                                            && !pwReg.test(newPW)
                                        }
                                        helperText={
                                            newPW
                                            && !pwReg.test(newPW)
                                            && '영문, 숫자, 특수문자를 모두 사용하여 8자 이상 입력해주세요'
                                        }
                                        required
                                        fullWidth
                                        margin='dense'
                                        value={newPW}
                                        name='pw'
                                        type='password'
                                        label='새 비밀번호'
                                        onChange={
                                            (e) => {
                                                setNewPW(e.target.value);
                                                pwConfirm === e.target.value ? setPWValid(true) : setPWValid(false)
                                            }
                                        } />
                                    <TextField
                                        error={!pwValid && pwConfirm !== '' && newPW !== ''}
                                        helperText={
                                            !pwValid
                                            && pwConfirm !== ''
                                            && newPW !== ''
                                            && '입력한 패스워드와 확인 패스워드가 일치하지 않습니다.'
                                        }
                                        required
                                        fullWidth
                                        type='password'
                                        margin='dense'
                                        value={pwConfirm}
                                        name='pwConfirm'
                                        label={FormUtil.getFieldLabel('pwConfirm')}
                                        onChange={(e) => {
                                            setPWConfirm(e.target.value);
                                            newPW === e.target.value ? setPWValid(true) : setPWValid(false)
                                        }} />
                                </Grid>
                                <Grid container alignItems='flex-end' direction='column' style={{ marginTop: 20 }}>
                                    <Grid item xl lg sm>
                                        <Button
                                            type='submit'
                                            variant='contained'
                                            color='primary'
                                            style={{ marginRight: 10 }}>
                                            수정
                                        </Button>
                                        <Button
                                            onClick={onFormClear}
                                            variant='contained'
                                            color='secondary'>
                                            비우기
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </TitledPaper>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    )
}));

export default withRouter(ModifyUserInfoPage);