import { observable } from "mobx";
import SnopItem, { ISnopItem } from "./SnopItem";
import TaskInfo from '../../../resource/data/tasks.json';
import FormUtil from '../../../module/FormUtil';

export interface ISnopDesignItem extends ISnopItem {
    designName?: string;
    designEndDate?: Date;
    designConfirmDate?: Date;
    synth1EndDate?: Date;
    synth2EndDate?: Date;
    synth3EndDate?: Date;
    qcExpEndDate?: Date;
    runID?: string;
    biAnalysisEndDate?: Date;
    panelConfirmDate?: Date;
    projectCode?: string;
    taskList?: any[];
    currTaskObj?: any;
    chipDistributionEndDate?: Date;
}

class SnopDesignItem extends SnopItem implements ISnopDesignItem {
    @observable designName?: string = '';
    @observable designEndDate?: Date = null;
    @observable designConfirmDate?: Date = null;
    @observable synth1EndDate?: Date = null;
    @observable synth2EndDate?: Date = null;
    @observable synth3EndDate?: Date = null;
    @observable qcExpEndDate?: Date = null;
    @observable runID?: string = '';
    @observable biAnalysisEndDate?: Date = null;
    @observable panelConfirmDate?: Date = null;
    @observable taskList?: any[] = [];
    @observable projectCode?: string = '';
    @observable currTaskObj?: any = null;
    @observable chipDistributionEndDate?: Date = null;

    constructor(data?: ISnopDesignItem) {
        super(data);
        if (data) {
            Object.assign(this, data);
        }
        this.setDatas(data);
    }

    setDatas(data: ISnopDesignItem): void {
        try {
            for (const d of this.taskList) {
                if (d.type) {
                    if (d.type === TaskInfo.types.designRequest) {
                        this.requestDate = d.finishDate;
                        this.representativeName = d.detail.designRequestPersonInfo.salesRepresentative;
                        this.workType = 'Custom panel'
                    }
                    else if (d.type === TaskInfo.types.synthDesign) {
                        this.designEndDate = d.finishDate;
                    }
                    else if (d.type === TaskInfo.types.designConfirm) {
                        this.designConfirmDate = d.finishDate;
                    }
                    else if (d.type === TaskInfo.types.synth1) {
                        this.synth1EndDate = d.finishDate;
                    }
                    else if (d.type === TaskInfo.types.synth2) {
                        this.synth2EndDate = d.finishDate;
                    }
                    else if (d.type === TaskInfo.types.synth3) {
                        this.synth3EndDate = d.finishDate;
                    }
                    else if (d.type === TaskInfo.types.probeDetail) {
                    }
                    else if (d.type === TaskInfo.types.qcSequencing) {
                        this.runID = d.detail?.runID;
                    }
                    else if (d.type === TaskInfo.types.qcExperiment) {
                        this.qcExpEndDate = d.finishDate;
                    }
                    else if (d.type === TaskInfo.types.biUpload) {
                    }
                    else if (d.type === TaskInfo.types.biAnalysis) {
                        this.biAnalysisEndDate = d.finishDate;
                    }
                    else if (d.type === TaskInfo.types.panelConfirm) {
                        this.panelConfirmDate = d.finishDate;
                    }
                    else if (d.type === TaskInfo.types.chipDistribution) {
                        this.chipDistributionEndDate = d.finishDate;
                    }
                    else if (d.type === TaskInfo.types.rebalancingRequest) {
                        this.requestDate = d.finishDate;
                        this.workType = 'Rebalancing & 영역추가';
                    }
                    else if (d.type === TaskInfo.types.rebalancingSynthDesign) {
                        this.designEndDate = d.finishDate;
                    }
                    else {

                    }
                }
                this.currentWorkType = (TaskInfo.translate as any)[data.currTaskObj.type];
                this.currentStage = FormUtil.getFieldLabel(data.currTaskObj.stage);
            }
        } catch (err) {
            console.log(err);
        }
    }

    get DesignEndDate() {
        return this.getFormattedDate(this.designEndDate);
    }

    get DesignConfirmDate() {
        return this.getFormattedDate(this.designConfirmDate);
    }

    get Synth1EndDate() {
        return this.getFormattedDate(this.synth1EndDate);
    }

    get Synth2EndDate() {
        return this.getFormattedDate(this.synth2EndDate);
    }

    get Synth3EndDate() {
        return this.getFormattedDate(this.synth3EndDate);
    }

    get QcExpEndDate() {
        return this.getFormattedDate(this.qcExpEndDate);
    }

    get BiAnalysisEndDate() {
        return this.getFormattedDate(this.biAnalysisEndDate);
    }

    get PanelConfirmDate() {
        return this.getFormattedDate(this.panelConfirmDate);
    }

    get ChipDistributionEndDate() {
        return this.getFormattedDate(this.chipDistributionEndDate, 'YYYY-MM-DD');
    }

    get CustomerAndPI() {
        return this.customerAndPI;
    }

}

export default SnopDesignItem;