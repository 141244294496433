import { action, observable } from "mobx";
import ModelBase from "../../../model/common/ModelBase";
import DesignTask from "../../../model/design/DesignTask";
import TaskSubData from "../../../model/design/DesignTaskSubData";
import DesignWork from "../../../model/design/DesignWork";
import RootStore from "../../RootStore";
import StoreBase from "../../StoreBase";

export interface IDesignTaskStore<T extends TaskSubData> {
    store?: DesignTaskStore<T>;
}

class DesignTaskStore<T extends TaskSubData> extends StoreBase {
    @observable data: DesignTask<T>;
    @observable work: DesignWork;
    @observable isValid: boolean;

    constructor(rootStore?: RootStore) {
        super();
        this.isValid = false;
        if (rootStore) {
            this.rootStore = rootStore;
        }
    }

    @action
    onChangeDefault = (e: React.ChangeEvent<HTMLInputElement> | any): void => {
        try {
            const { name, value } = e.target;
            (this as any)[name] = value;
        } catch (err) {
            console.log(`Error in onChangeDefault ${err}`)
        }
    }

    @action
    onChangeRecursive = (e: React.ChangeEvent<HTMLInputElement | any> | any): void => {
        try {
            const { name, value } = e.target;
            let path = name.split('.');
            let obj: any;
            path = path.slice(1);
            obj = (this as any);
            for (const key of path) {
                if (obj[key] instanceof ModelBase) {
                    obj = obj[key];
                }
                else {
                    obj[key] = value;
                    return;
                }
            }
        } catch (err) {
            console.log(`Error in onChangeRecursive ${err}`)
        }
    }

    @action
    onChangeDateRecursive = (name: string, date: string | Date | null): void => {
        try {
            let path = name.split('.');
            let obj: any;
            path = path.slice(1);
            obj = (this as any);
            for (const key of path) {
                if (obj[key] instanceof ModelBase) {
                    obj = obj[key];
                }
                else {
                    obj[key] = date;
                    return;
                }
            }
        } catch (err) {
            console.log(`Error in onChangeDateRecursive ${err}`)
        }
    }
}

export default DesignTaskStore;