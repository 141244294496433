import axios from "axios";
import { observable, computed, action } from "mobx";
import WorkTaskSummary from "../../../model/design/taskDatas/designReqeust/WorkTaskSummary";
import StoreBase from "../../StoreBase";
import APIRoutes from '../../../../resource/data/APIRoutes.json';
import PTSMessage from "../../../model/common/PTSMessage";
import WorkInfo from '../../../../resource/data/works.json';
import RootStore from "../../RootStore";

export default class ProjectSearchStore extends StoreBase {
    @observable offSet?: number;
    @observable limit?: number;
    @observable total?: number;
    @observable stage?: string;
    @observable taskList?: WorkTaskSummary[];
    @observable headers?: string[];

    // for filter
    @observable selectedField: string;
    @observable filterText: string;
    @observable selectedStatus: string;


    constructor(rootStore: RootStore) {
        super();
        this.taskList = [];
        this.headers = ['prevWorkID', 'workID', 'projectCode', 'probeID', 'salesRepresentative', 'customerAndPI', 'designName'];
        this.total = 0;
        this.offSet = 0;
        this.limit = 20;
        this.selectedField = '';
        this.filterText = '';
        this.selectedStatus = WorkInfo.status.running;
        this.rootStore = rootStore;
    }

    @computed
    get selectedNum() {
        return this.taskList.reduce((p, c) => {
            if (c.selected) {
                return p + 1;
            }
            else {
                return p;
            }
        }, 0)
    }

    requestWorkList = async (token: string) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        return axios.post(
            `${this.baseURL}/${APIRoutes.words.auth}/${APIRoutes.words.pts}/${APIRoutes.words.api}/${APIRoutes.words.version2}/${APIRoutes.words.design}/${APIRoutes.words.works}/${APIRoutes.words.lists}`,
            {
                selectedField: this.selectedField,
                filterText: this.filterText,
                offSet: this.offSet,
                limit: this.limit,
                selectedStatus: this.selectedStatus,
            },
            config,
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        })
    }

    @action
    handleStatusChange = async (e: React.ChangeEvent<any>) => {
        this.offSet = 0;
        this.selectedField = '';
        this.filterText = '';
        this.onChangeDefault(e);
        await this.setWorkList(this.rootStore.globalStore.token);
    }

    @action
    setWorkList = async (token: string) => {
        try {
            const result = await this.requestWorkList(token);
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    title: result.data.resName,
                    code: result.data.resCode,
                    message: result.data.resMessage,
                });
            }
            const list: WorkTaskSummary[] = [];
            if (result.data.data.list.length > 0) {
                result.data.data.list.map((v: any) => {
                    return list.push(new WorkTaskSummary(v));
                });
            }
            this.taskList = list;
            if (result.data.data.count !== undefined) {
                this.total = result.data.data.count;
            }
        } catch (err) {
            throw err;
        }
    }

}
