import axios from "axios";
import { observable } from "mobx";
import PTSMessage from "../../model/common/PTSMessage";
import RootStore from "../RootStore";
import StoreBase from "../StoreBase";
import APIRoutes from '../../../resource/data/APIRoutes.json';

class LoginStore extends StoreBase {
    @observable email: string;
    @observable pw: string;
    @observable loginError: boolean;

    constructor(rootStore?: RootStore) {
        super();
        this.email = '';
        this.pw = '';
        this.loginError = false;
        this.rootStore = rootStore;
    }

    onLogin = async (): Promise<any> => {
        try {
            if (!this.email || !this.pw) {
                throw new PTSMessage({
                    code: 1,
                    type: 'error',
                    title: '로그인',
                    message: '로그인 정보를 입력하세요.',
                });
            }
            return axios.post(
                `${this.baseURL}/${APIRoutes.words.pts}/${APIRoutes.words.api}/${APIRoutes.words.version2}/${APIRoutes.words.login}`,
                {
                    email: this.email,
                    pw: this.pw
                }
            ).then((res) => {
                return res;
            }).catch((err) => {
                throw err;
            });
        } catch (err) {
            throw err
        }
    }
}

export default LoginStore;