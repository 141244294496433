import React from 'react';
import { inject, observer } from 'mobx-react';
import TaskInfo from '../../../../../../resource/data/tasks.json';
import { RouteComponentProps, withRouter, Link, Redirect } from 'react-router-dom';
import { Typography, Grid, Theme, createStyles } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';
import { IRootStore } from '../../../../../../data/store/RootStore';
import SynthesisDesignForm from '../component/form/synthesisDesign/SynthesisDesignForm';
import DesignConfirmForm from '../component/form/sales/DesignConfirmForm';
import ChipDistributionForm from '../component/form/chipDistribution/ChipDistributionForm';
import Synth1Form from '../component/form/synthesis/Synth1Form';
import Synth2Form from '../component/form/synthesis/Synth2Form';
import Synth3Form from '../component/form/synthesis/Synth3Form';
import QCExperimentForm from '../component/form/qc/QCExperimentForm';
import QCSequencingForm from '../component/form/qc/QCSequencingForm';
import BIAnalysisForm from '../component/form/bi/BIAnalysisForm';
import BIUploadForm from '../component/form/bi/BIUploadForm';
import PanelConfirmForm from '../component/form/sales/PanelConfirmForm';
import DesignTask from '../../../../../../data/model/design/DesignTask';
import Helper from '../../../../../../module/Helper';
import { observable } from 'mobx';
import PTSMessage from '../../../../../../data/model/common/PTSMessage';
import ProcessTaskContainerStore from '../../../../../../data/store/pageStore/design/ProcessTaskContainerStore';
import TaskSubData from '../../../../../../data/model/design/DesignTaskSubData';
import RebalancingSynthesisDesignForm from '../component/form/synthesisDesign/RebalancingSynthesisDesignForm';
import BIProbeDetailForm from '../component/form/bi/BIProbeDetailForm';

const style = (theme: Theme) => createStyles({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginTop: 8,
        marginBottom: 8,
        margin: 4
    },
    forms: {
        marginTop: 8,
        marginBottom: 8,
        margin: 4
    },
    paper: {
        padding: 10,
        paddingBottom: 30,
        margin: 3,
        marginBottom: 6,
    }
});

interface Props extends RouteComponentProps<any>, WithStyles<typeof style>, IRootStore {
}

@inject('rootStore')
@observer
class TaskProcessContainer extends React.Component<Props> {
    globalStore = this.props.rootStore.globalStore;
    commentStore = this.props.rootStore.commentStore;
    @observable store = new ProcessTaskContainerStore(this.props.rootStore);

    constructor(props: Props) {
        super(props);
        this.globalStore.currentTaskTitle = '작업';
        this.props.rootStore.commentStore.mode = 'work';
    }

    selectChild = (type: string | null) => {
        switch (type) {
            case TaskInfo.types.synthDesign:
                return (
                    <SynthesisDesignForm
                        postTaskData={this.postTaskData}
                        onFormSubmit={this.onFormSubmit}
                        workInfo={this.store.workInfo} />
                );
            case TaskInfo.types.rebalancingSynthDesign:
                return (
                    <RebalancingSynthesisDesignForm
                        postTaskData={this.postTaskData}
                        onFormSubmit={this.onFormSubmit}
                        workInfo={this.store.workInfo} />
                );
            case TaskInfo.types.designConfirm:
                return (
                    <DesignConfirmForm
                        postTaskData={this.postTaskData}
                        onFormSubmit={this.onFormSubmit}
                        workInfo={this.store.workInfo} />
                )
            case TaskInfo.types.chipDistribution:
                return (
                    <ChipDistributionForm
                        postTaskData={this.postTaskData}
                        onFormSubmit={this.onFormSubmit}
                        workInfo={this.store.workInfo} />
                )
            case TaskInfo.types.synth1:
                return (
                    <Synth1Form
                        postTaskData={this.postTaskData}
                        onFormSubmit={this.onFormSubmit}
                        workInfo={this.store.workInfo} />
                )
            case TaskInfo.types.synth2:
                return (
                    <Synth2Form
                        postTaskData={this.postTaskData}
                        onFormSubmit={this.onFormSubmit}
                        workInfo={this.store.workInfo} />
                )
            case TaskInfo.types.synth3:
                return (
                    <Synth3Form
                        postTaskData={this.postTaskData}
                        onFormSubmit={this.onFormSubmit}
                        workInfo={this.store.workInfo} />
                )
            case TaskInfo.types.qcExperiment:
                return (
                    <QCExperimentForm
                        postTaskData={this.postTaskData}
                        onFormSubmit={this.onFormSubmit}
                        workInfo={this.store.workInfo} />
                )
            case TaskInfo.types.qcSequencing:
                return (
                    <QCSequencingForm
                        postTaskData={this.postTaskData}
                        onFormSubmit={this.onFormSubmit}
                        workInfo={this.store.workInfo} />
                )
            case TaskInfo.types.biAnalysis:
                return (
                    <BIAnalysisForm
                        postTaskData={this.postTaskData}
                        onFormSubmit={this.onFormSubmit}
                        workInfo={this.store.workInfo} />
                )
            case TaskInfo.types.biUpload:
                return (
                    <BIUploadForm
                        postTaskData={this.postTaskData}
                        onFormSubmit={this.onFormSubmit}
                        workInfo={this.store.workInfo} />
                )
            case TaskInfo.types.panelConfirm:
                return (
                    <PanelConfirmForm
                        postTaskData={this.postTaskData}
                        onFormSubmit={this.onFormSubmit}
                        workInfo={this.store.workInfo} />
                )
            case TaskInfo.types.probeDetail:
                return (
                    <BIProbeDetailForm
                        postTaskData={this.postTaskData}
                        onFormSubmit={this.onFormSubmit}
                        workInfo={this.store.workInfo} />
                )
            default:
                return (
                    <React.Fragment>
                        페이지가 없습니다. 페이지 관리자에게 문의하세요.
                    </React.Fragment>
                );
        }
    }

    postTaskData = async (data: DesignTask<TaskSubData>) => {
        try {
            this.globalStore.isRunning = true;
            data.detail.deleteErrorsRecursive();
            const result = await this.store.postTaskData(data, this.props.match.params.workID, this.globalStore.token);
            if (result) {
                alert('제출 완료.');
            }
            this.store.redirect = true;
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
        } finally {
            this.globalStore.isRunning = false;
        }
    }

    componentDidMount = async () => {
        try {
            this.globalStore.isRunning = true;
            await this.getWorkInfo();
            this.commentStore.mode = 'work';
            this.commentStore.workID = this.store.workInfo.workID;
            this.globalStore.useComment = true;
            await this.commentStore.setCommentsMany();
        } catch (err) {

        } finally {
            this.globalStore.isRunning = false;
        }
    }

    getWorkInfo = async () => {
        try {
            await this.store.setDatas(this.props.match.params.workID, this.globalStore.token);
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
            this.store.redirect = true;
        }
    }

    getComments = async (workID: string | number) => {
        // try {
        //     this.commentStore.isRunning = true;
        //     let result = await CommentsApi.getComments(workID, this.props.globalStore.token);
        //     result = result.data.data;
        //     this.props.commentStore.comments = [];
        //     for (const index in result) {
        //         const comment = new Comment();
        //         comment.fromJS(result[index]);
        //         this.props.commentStore.comments.push(comment);
        //     }
        //     this.scrollBottom();
        // } catch (err) {

        // } finally {
        //     this.props.commentStore.isRunning = false;
        // }
    }

    scrollBottom = () => {
        const obj = document.getElementById('commentContentsList');
        obj.scrollTop = obj.scrollHeight;
    }

    componentWillUnmount = () => {
        // this.globalStore.useComment = false;
    }

    onFormSubmit = async (
        curr: DesignTask<any> | null,
        next: DesignTask<any> | null,
    ) => {
        try {

            alert('제출 완료');
        } catch (err) {
            // alert(err.message);
            // 에러 핸들링 코드 필요
        } finally {
            this.globalStore.isRunning = false;
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.store.redirect &&
                    <Redirect to='/main/design/task/process' />}
                <Typography variant='h5' style={{ marginTop: 10, marginBottom: 20, display: 'inline-block' }}>
                    {`${Helper.taskFieldToString(this.store.taskType)} (Work: ${this.store.workInfo.workID})`}
                </Typography>
                <Typography variant='body2' style={{ marginLeft: 5, display: 'inline-block' }}>
                    <Link
                        style={{
                            marginLeft: 10
                        }}
                        target='_blank'
                        to={{ pathname: `/main/design/work/detail/${this.store.workInfo.workID}` }}>[자세히]</Link>
                </Typography>
                {
                    this.store.workInfo.prevWorkID
                    &&
                    <Typography variant='body2'>
                        이전 작업(복사된 작업) ID:&nbsp;
                        <Link to={`/main/design/work/detail/${this.store.workInfo.prevWorkID}`} target='_blank'>
                            {this.store.workInfo.prevWorkID}
                        </Link>
                    </Typography>
                }
                <hr style={{ marginBottom: 15 }} />
                <form autoComplete='off' className={this.props.classes.container}>
                    <Grid xl lg sm
                        item
                        spacing={1}
                        container
                        direction='column'
                        alignItems='stretch'
                        style={{ marginTop: 4 }}>
                        {this.selectChild(this.store.taskType)}
                    </Grid>
                </form>
            </React.Fragment>
        )
    }
}

export default withStyles(style)(withRouter(TaskProcessContainer));