import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import { observer } from "mobx-react";
import React from "react";
import { ManufactureItemOption } from "../../../../../data/model/manufacture/ManufactureItemOption";
import Helper from "../../../../../module/Helper";
import { Platform } from "@celemicsbisw/manufacture-item-structure";

export type PlatformType = Platform;

export interface ItemOptionSelectorProps {
    error?: boolean;
    errorText?: string;
    disabled?: boolean;
    optionKey: (keyof ManufactureItemOption);
    value: any;
    title?: string;
    onValueChange?: (key: keyof ManufactureItemOption, value: any) => void;
}

export interface ManufactureItemOptionRadioSelectorProps extends ItemOptionSelectorProps {
    selections?: { label: string, value: any }[];
}

export interface ManufactureItemOptionCheckboxProps extends ItemOptionSelectorProps {

}

export interface PlatformRadioSeletorProps extends ItemOptionSelectorProps {
    platform: Platform | "";
}

export const PlatformRadioSelector: React.FC<PlatformRadioSeletorProps> = observer(({
    platform,
    error,
    errorText,
    onValueChange,
}) => {
    const onChange = onValueChange ?? (() => { });

    return (
        <FormControl
            component="fieldset"
            error={error}>
            <FormLabel component="legend">
                Platform
            </FormLabel>
            <RadioGroup
                onChange={(e) => onChange("platform", e.target.value)}
                aria-label="platform"
                name="platform"
                value={platform ?? ""}>
                <FormControlLabel
                    value="illumina"
                    control={<Radio />} label="Illumina" />
                <FormControlLabel
                    value="ionTorrent"
                    control={<Radio />} label="Ion Torrent" />
                <FormControlLabel
                    value="mgi"
                    control={<Radio />} label="MGI" />
            </RadioGroup>
            <FormHelperText>{errorText}</FormHelperText>
        </FormControl>
    )
});

export const ManufactureItemOptionRadioSelector: React.FC<ManufactureItemOptionRadioSelectorProps> = observer(({
    optionKey,
    value,
    selections,
    title,
    error,
    errorText,
    onValueChange,
    disabled,
}) => {
    const onChange = onValueChange ?? (() => { });
    const _disabled = disabled ?? false;

    return (
        <FormControl
            component="fieldset"
            error={error}>
            <FormLabel component="legend">
                {title ?? "Unknown"}
            </FormLabel>
            <RadioGroup
                onChange={(e) => onChange(optionKey, e.target.value)}
                aria-label={optionKey}
                name={optionKey}
                value={value ?? ""}>
                {
                    selections.length > 0 && selections.map((v, i) => (
                        <FormControlLabel
                            disabled={_disabled}
                            key={`optsel${Helper.getUUID()}`}
                            value={v.value}
                            control={<Radio />} label={v.label} />
                    ))
                }
            </RadioGroup>
            <FormHelperText>{errorText}</FormHelperText>
        </FormControl>
    )
});

export const ManufactureItemOptionsCheckbox: React.FC<ManufactureItemOptionCheckboxProps> = observer(({
    optionKey,
    value,
    title,
    error,
    errorText,
    onValueChange,
    disabled,
}) => {
    return (
        <FormControl
            component="fieldset"
            error={error}>
            <FormLabel component="legend">
                {title ?? "Unknown"}
            </FormLabel>
            <FormGroup>
                <Checkbox
                    disabled={disabled}
                    onChange={(e) => {
                        onValueChange(optionKey, e.target.checked)
                    }}
                    checked={value ?? false}
                />
            </FormGroup>
            <FormHelperText>{errorText}</FormHelperText>
        </FormControl>
    )
});