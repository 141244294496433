import { observable } from "mobx";
import ModelBase from "./ModelBase";

export interface IComment {
    writerName?: string;
    createdDate?: Date;
    updatedDate?: Date;
    content?: string;
    stage?: string;
    cancel?: Boolean;
    _id?: string;
    workID?: string;
    email?: string;
}

export default class Comment extends ModelBase {
    @observable writerName?: string;
    @observable createdDate?: Date;
    @observable updatedDate?: Date;
    @observable content?: string;
    @observable stage?: string;
    @observable cancel?: Boolean;
    @observable email?: string;
    _id?: string;
    workID?: string;

    constructor(params?: IComment) {
        super();
        this.writerName = '';
        this.content = '';
        this.stage = '';
        this.cancel = false;
        this.workID = '';
        if(params) {
            Object.assign(this, params);
        }
    }
}