import { observable } from "mobx";
import TaskSubData from "../../DesignTaskSubData";

export default class SynthSequenceData extends TaskSubData {
    @observable name?: string;
    @observable sequence?: string;
    @observable size?: number;
    @observable remark?: string;

    constructor(name?: string, sequence?: string, size?: number, remark?: string) {
        super();
        this.name = name;
        this.sequence = sequence;
        this.size = size;
        this.remark = remark;
    }

    // override
    validateSelf(): void {
        return;
    }
}