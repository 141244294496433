import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
    Accordion as ExpansionPanel,
    AccordionSummary as ExpansionPanelSummary,
    Typography,
    AccordionDetails as ExpansionPanelDetails,
    Grid
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import FormUtil from '../../../../../../../module/FormUtil';

interface Props {
    width?: number;
    open?: boolean;
    title?: string;
    marginLeft?: number;
}

const WorkDetailPanel: React.FC<Props> = observer((props) => {
    const [open, setOpen] = useState(props.open || false);
    const titleColor = '#D4EFDF';
    const marginLeft = props.marginLeft || 40;

    return (
        <React.Fragment>
            <Grid
                container
                justify='flex-end'
                style={{
                    paddingTop: 10
                }}>
                <ExpansionPanel style={{
                    width: '100%',
                    marginLeft: marginLeft
                }}
                    expanded={open}>
                    <ExpansionPanelSummary
                        style={{
                            background: titleColor,
                        }}
                        onClick={(e) => setOpen(!open)}
                        expandIcon={<ExpandMore />}>
                        <Typography variant='body1'>
                            {FormUtil.getFieldLabel(props.title)}
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails
                        style={{
                            paddingLeft: 10,
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingRight: 5
                        }}>
                        <Grid item container>
                            <Grid item xl lg sm>
                                {props.children}
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </Grid>
        </React.Fragment>
    )
});

export default WorkDetailPanel;