import React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ManufactureMainContainer from './ManufactureMainContainer';
import ManufactureShippingRequestContainer from './ManufactureShippingRequestContainer';
import ManufactureDeliveryContainer from './ManufactureDeliveryContainer';
import { IRootStore } from '../../../../../data/store/RootStore';
import ManufactureWork from '../../../../../data/model/manufacture/ManufactureWork';
import { CommonError } from '../../../../../data/model/common/CommonErrors';
import axios from 'axios';
import { words as RouteWords } from '../../../../../resource/data/APIRoutes.json';
import PTSMessage from '../../../../../data/model/common/PTSMessage';
import StoreBase from '../../../../../data/store/StoreBase';
import ManufactureExpectShippingRequestContainer from './ManufactureExpectShippingRequestContainer';
import MasnufactureSalesExpectShippingRequestContainer from './MasnufactureSalesExpectShippingRequestContainer';
import ManufactureRequestMainContainer from './ManufactureRequestMainContainer';

interface Props extends RouteComponentProps<any>, IRootStore {
}

const renderPage = (type?: string, stage?: string) => {
    if (!type || !stage) {
        return (
            <React.Fragment>
                Page Loading...
            </React.Fragment>
        )
    }
    else if (stage !== ManufactureWork.StageList.processing) {
        return (
            <React.Fragment>
                Invalid Info
            </React.Fragment>
        )
    }
    else if (!type.startsWith('manufacture')) {
        return (
            <React.Fragment>
                Invalid Info
            </React.Fragment>
        )
    }
    else {
        return selectPage(type);
    }
}

const selectPage = (type: string) => {
    switch (type) {
        case ManufactureWork.TypeList.manufactureExpectShippingRequest:
            return (
                <ManufactureExpectShippingRequestContainer />
            )
        case ManufactureWork.TypeList.manufactureSalesExpectShippingRequest:
            return (
                <MasnufactureSalesExpectShippingRequestContainer />
            )

        case ManufactureWork.TypeList.manufactureRequest:
            return (
                <ManufactureRequestMainContainer />
            )
        case ManufactureWork.TypeList.manufactureManufacture:
            return (
                <ManufactureMainContainer />
            )
        case ManufactureWork.TypeList.manufactureShippingRequest:
            return (
                <ManufactureShippingRequestContainer />
            )
        case ManufactureWork.TypeList.manufacturePackaging:
            return (
                <ManufactureMainContainer />
            )
        case ManufactureWork.TypeList.manufactureDelivery:
            return (
                <ManufactureDeliveryContainer />
            )
        default:
            return (
                <React.Fragment>
                    Unknown Page
                </React.Fragment>
            )
    }
}

@inject('rootStore')
@observer
class ManufactureSelectContainer extends React.Component<Props> {
    @observable type: string = '';
    @observable stage: string = '';

    globalStore = this.props.rootStore.globalStore;
    store = new StoreBase();

    getManufactureTrack = async (workID: string, token: string) => {
        const config = {
            headers: {
                'x-access-token': token
            }
        }
        return axios.get(
            `${this.store.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.manufacture}/${RouteWords.tracks}/${workID}`,
            config,
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        });
    }

    componentDidMount = async () => {
        try {
            this.globalStore.isRunning = true;
            const workID = this.props.match.params.workID;
            const result = await this.getManufactureTrack(workID, this.globalStore.token);
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                })
            }
            const userInfo = this.globalStore.userInfo;
            const track = result.data.data;
            if (!(userInfo.role.toLocaleLowerCase() === 'admin' ||
                userInfo.departType === track.current.departType)) {
                throw new PTSMessage({
                    message: '권한이 없습니다.',
                })
            }
            this.type = result.data.data.current.workType;
            this.stage = result.data.data.current.stage;
        } catch (err) {
            if (err instanceof CommonError || err instanceof PTSMessage) {
                alert(err.message);
            }
            else {
                alert('오류 발생');
            }
            this.props.history.push('/main/manufacture/list/process');
        } finally {
            this.globalStore.isRunning = false;
        }
    }

    render() {
        return (
            <React.Fragment>
                {renderPage(this.type, this.stage)}
            </React.Fragment>
        )
    }
}

export default withRouter(ManufactureSelectContainer);