import { Checkbox, FormControlLabel, Grid, TextField } from "@material-ui/core";
import { observer } from "mobx-react";
import FormUtil from "../../../../../module/FormUtil";
import ManufactureWork from "../../../../../data/model/manufacture/ManufactureWork";

interface ManufactureCustomerRequestInfoComponentProps {
    data?: ManufactureWork;
    readOnly?: boolean;
}

const ManufactureCustomerRequestInfoComponent: React.FC<ManufactureCustomerRequestInfoComponentProps> = (props) => {
    const readonly = props.readOnly || false;

    return (
        <div style={{ marginTop: 20 }}>
            <Grid container direction='column'>
                <Grid item xl lg sm>
                    <TextField
                        InputProps={{ readOnly: readonly }}
                        fullWidth
                        value={props.data?.customerRequest}
                        name='customerRequest'
                        label={FormUtil.getFieldLabel('customerRequest')}
                        rows={7}
                        multiline
                        onChange={props.data.onChangeDefault} />
                </Grid>
                <Grid item xl lg sm style={{ marginTop: 15 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={readonly}
                                checked={props.data?.legalCompliance}
                                onChange={() => {
                                    props.data.legalCompliance = !props.data?.legalCompliance;
                                    props.data.additionalReview = '';
                                }}
                                name='legalCompliance' />
                        }
                        label={FormUtil.getFieldLabel('legalCompliance')} />
                </Grid>
                <Grid item xl lg sm style={{ marginTop: 10 }}>
                    {!props.data.legalCompliance &&
                        <TextField
                            InputProps={{ readOnly: readonly }}
                            fullWidth
                            value={props.data?.additionalReview}
                            name='additionalReview'
                            label={FormUtil.getFieldLabel('additionalReview')}
                            rows={3}
                            multiline
                            onChange={props.data.onChangeDefault} />
                    }
                </Grid>
            </Grid>
        </div>
    )
}

export default observer(ManufactureCustomerRequestInfoComponent);