import ProcessStore from "./ProcessStore";
import { action, observable } from "mobx";
import Synthesis3 from "../../../model/design/taskDatas/synthesis/Synthesis3";
import ChipDistributionInfo from "../../../model/common/ChipDistributionInfo";
import axios from "axios";
import TaskInfo from '../../../../resource/data/tasks.json';
import { words as RouteWords } from '../../../../resource/data/APIRoutes.json';
import PTSMessage from "../../../model/common/PTSMessage";

export default class Synth3Store extends ProcessStore<Synthesis3> {
    @observable chipInfos: ChipDistributionInfo[];
    @observable panelName: string;

    constructor() {
        super();
        this.panelName = '';
        this.chipInfos = [];
        this.taskData.detail = new Synthesis3();
    }

    @action
    setChipInfos = async (token: string) => {
        try {
            const filter = this.workInfo.taskList.filter((v) => {
                return (v as any).type === TaskInfo.types.chipDistribution;
            });
            let list: string[] = [];
            if(filter.length > 0) {
                list = (filter[0] as any).detail.chipInfosOID;
            }
            if(!list) {
                list = [];
            }
            const result = await this.getChipInfos(list, token);
            const arr: ChipDistributionInfo[] = [];
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage
                });
            }
            for (const chip of result.data.data) {
                const obj = new ChipDistributionInfo();
                obj.setDatas(chip);
                arr.push(obj);
            }
            this.chipInfos = arr;
        } catch (err) {
            throw err;
        }
    }

    getChipInfos = async (list: string[], token: string) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        return axios.post(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.chipInfos}/${RouteWords.lists}`,
            list,
            config,
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        })
    }

    replaceChipInfos = async (token: string) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        return axios.put(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.chipInfos}/`,
            this.chipInfos,
            config,
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        })
    }
}