import React from 'react';
import { Provider, inject } from 'mobx-react';
import { CssBaseline, Container, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { IRootStore } from '../../../../../../data/store/RootStore';
import TaskListStore from '../../../../../../data/store/pageStore/design/TaskListStore';
import WaitTaskListTableContainer from './container/WaitTaskListTableContainer';

interface Props extends IRootStore{
}

@inject('rootStore')
@observer
class WaitTaskViewPage extends React.Component<Props> {
    taskListStore = new TaskListStore();
    globalStore = this.props.rootStore.globalStore;

    componentDidMount() {
        this.globalStore.currentTaskTitle = '디자인 접수대기중 작업 목록';
        this.globalStore.useComment = false;
    }

    render() {
        return (
            <React.Fragment>
                <Provider
                    taskListStore={this.taskListStore}>
                    <CssBaseline />
                    <Container maxWidth='xl'>
                        <Grid container justify='center' direction='column'>
                            <Grid item xl lg sm>
                                <WaitTaskListTableContainer />
                            </Grid>
                        </Grid>
                    </Container>
                </Provider>
            </React.Fragment>
        )
    }
}

export default WaitTaskViewPage;
