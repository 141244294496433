import axios from "axios";
import { observable, computed, action } from "mobx";
import PTSMessage from "../../../model/common/PTSMessage";
import ManufactureListItem from "../../../model/manufacture/ManufactureListItem";
import ManufactureWork from "../../../model/manufacture/ManufactureWork";
import StoreBase from "../../StoreBase";
import { words as RouteWords } from '../../../../resource/data/APIRoutes.json';
import WorkInfo from '../../../../resource/data/works.json';

class ManufactureWorkListStore extends StoreBase {
    @observable offset: number = 0;
    @observable total: number = 0;
    @observable limit: number = 20;
    @observable stage: string = '';
    @observable departType: string = '';
    @observable workList: ManufactureListItem[] = [];
    @observable headers: string[] = [];
    @observable mode: string = '';

    @observable filterText: string = '';
    @observable selectedField: string = '';
    @observable selectedStatus: string = WorkInfo.status.running;

    @computed
    get selectedNum() {
        return this.workList.reduce((p, c) => {
            if (c.checked) {
                return p + 1;
            }
            else {
                return p;
            }
        }, 0)
    }

    @computed
    get allSelected() {
        return this.selectedNum > 0 && this.selectedNum === this.workList.length;
    }

    setModeByStage = () => {
        switch (this.stage) {
            case ManufactureWork.StageList.waitAccept:
                this.mode = ManufactureWorkListStore.ModeList.accept;
                break;
            case ManufactureWork.StageList.processing:
                this.mode = ManufactureWorkListStore.ModeList.process;
                break;
            case ManufactureWorkListStore.ModeList.search:
                this.mode = ManufactureWorkListStore.ModeList.search;
                break;
            case ManufactureWorkListStore.ModeList.shipped:
                this.mode = ManufactureWorkListStore.ModeList.shipped;
                break;
            case ManufactureWorkListStore.ModeList.cancelShipping:
                this.mode = ManufactureWorkListStore.ModeList.cancelShipping;
                break;
            case ManufactureWorkListStore.ModeList.changeShipping:
                this.mode = ManufactureWorkListStore.ModeList.changeShipping;
                break;
            default:
                this.mode = '';
                break;
        }
    }

    onFilterClear = () => {
        this.selectedField = '';
        this.filterText = '';
    }

    onFilterChange = (e: any) => {
        this.onChangeDefault(e);
    }

    static readonly ModeList = {
        receipt: 'receipt',
        accept: 'accept',
        process: 'process',
        search: 'search',
        shipped: 'shipped',
        cancelShipping: 'cancelShipping',
        changeShipping: 'changeShipping',
        all: 'all',
    }

    getManufactureListData = async (filter: object, token: string) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        return axios.post(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.manufacture}/${RouteWords.lists}`,
            { findFilter: filter },
            config,
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        });
    }

    @action
    setManufactureListData = async (filter: object, token: string) => {
        try {
            const result = await this.getManufactureListData(filter, token);
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                });
            }
            const list: ManufactureListItem[] = [];
            for (const d of result.data.data.list) {
                const obj = new ManufactureListItem();
                obj.fromJS(d.manufactureWork);
                obj.current = d.current;
                obj.changeRequestHistory = d.manufactureWork.changeRequestHistory
                obj.current.data = Object.assign({}, d.current.data);
                obj.repInfo = d.repInfo;
                obj.deleteErrorsRecursive();
                list.push(obj);
            }
            this.total = result.data.data.count;
            this.workList = list;
        } catch (err) {
            throw err;
        }
    }

    requestAcceptWorks = async (ids: string[], token: string) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        return axios.post(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.manufacture}/${RouteWords.receipt}`,
            ids,
            config,
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        });
    }

    // cancelShipping = async (workID: string, token: string) => {
    //     const config = {
    //         headers: {
    //             'x-access-token': token,
    //         }
    //     }
    //     return axios.put(
    //         `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.manufacture}/${RouteWords.shipping}/${RouteWords.cancel}/${workID}`,
    //         { data: {} },
    //         config,
    //     ).then((res) => {
    //         return res;
    //     }).catch((err) => {
    //         throw err;
    //     });
    // }

    changeShipping = async (workID: string, approve: string, refuseReason: string, token: string) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        return axios.put(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.manufacture}/${RouteWords.shipping}/${RouteWords.change}/${workID}`,
            { data: {}, approve, refuseReason },
            config,
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        });
    }
}

export default ManufactureWorkListStore;