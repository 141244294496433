import React from 'react';
import { ListItemIcon, ListItem, ListItemText } from '@material-ui/core';
import Helper from '../../../module/Helper';

export interface Props {
    name?: string;
    link?: string;
    icon?: JSX.Element;
    index?: number;
    departType?: string;
    type?: string;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const MenuItem: React.FC<Props> = (props) => {
    return (
        <React.Fragment>
            {
                //@ts-ignore
                <ListItem
                    style={{ wordWrap: 'break-word', wordBreak: 'keep-all' }}
                    onClick={props.onClick}
                    //@ts-ignore
                    link={props.link}
                    button
                    key={Helper.generateRandomKey()}>
                    <ListItemIcon>
                        {props.icon}
                    </ListItemIcon>
                    <ListItemText primary={props.name} />
                </ListItem>
            }
        </React.Fragment>
    )
}

export default MenuItem;