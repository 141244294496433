import React from 'react';
import { createStyles, Theme } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';
import { observer } from 'mobx-react';

const styles = (theme: Theme) => createStyles({
    text: {
        fontSize: 12,
        color: 'red',
    }
})

interface Props extends WithStyles<typeof styles> {
    text?: string
}

const FileHelperText: React.FC<Props> = (props) => (
    <label className={props.classes.text}>
        {props.text}
    </label>
)

export default observer(withStyles(styles)(FileHelperText));