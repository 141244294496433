import { Button, Grid } from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import React from "react";
import DesignRequest from "../../../../../../../data/model/design/taskDatas/designReqeust/DesignRequest";

interface Props {
    data?: DesignRequest;
    workID?: string;
}

const DesignWorkFileContainer: React.FC<Props> = props => {
    const data = props.data;
    const workID = props.workID;

    const exportTxt = () => {
        let fileName = `${data.designRequestInfo.designName}_${dayjs(data.designRequestInfo.requestDate).format('YYYYMMDD')}_designRequest.txt`;
        let fileText = `Work_ID\t${workID}
Project_Code(ERP)\t${data.designRequestInfo.erpCode}
고객_코드\t${data.designRequestInfo.projectCode || ''}
고객사&PI\t${data.designRequestInfo.customer.PI}
고객측_담당자\t${data.designRequestInfo.customer.representative}
고객_연락처\t${data.designRequestInfo.customer.contact}
고객_E-mail\t${data.designRequestInfo.customer.email}
Design_Name\t${data.designRequestInfo.designName}
Disease_Name\t${data.designRequestInfo.diseaseName}
Reference_Version\t${data.designRequestInfo.referenceVersion}
Gene_Database\t${data.designRequestInfo.geneDB}
요청일\t${dayjs(data.designRequestInfo.requestDate).format('YYYY/MM/DD')}
Due_Date(Seq.분석결과_전달_기한)\t${dayjs(data.designRequestInfo.dueDate).format('YYYY/MM/DD')}
Bed File 전달 기한\t${dayjs(data.designRequestInfo.dueDateBedFile).format('YYYY/MM/DD')}
마스킹여부\t${data.designRequestInfo.masking}
Homolog_Cut-Off_No.\t${data.designRequestInfo.homologCutOffNumber}
Pilot_Test_Run_ID\t${data.designRequestInfo.pilotTestRunID || ''}
Gene_List_Type\t${data.requestedGeneList.geneListType}
Gene_List_Expand(mer)\t${data.requestedGeneList.geneExpand || ''}
유전자_리스트
${data.requestedGeneList.geneList.map((v, i) => {
            return `${i + 1}\t${v.name}\t${v.remark || ''}
`}).join('')}퓨전_영역_리스트
${data.requestedGeneList.fusionList.map((v, i) => {
                return `${i + 1}\t${v.chromosome}\t${v.start}\t${v.end}\t${v.name}\t${v.remark || ''}
`}).join('')}핫스팟_영역_리스트
${data.requestedGeneList.hotspotList.map((v, i) => {
                    return `${i + 1}\t${v.chromosome}\t${v.start}\t${v.end}\t${v.name}\t${v.remark || ''}
`}).join('')}기타_영역_리스트
${data.requestedGeneList.customList.map((v, i) => {
                        return `${i + 1}\t${v.chromosome}\t${v.start}\t${v.end}\t${v.name}\t${v.remark || ''}
`}).join('')}합성_시퀀스_리스트
${data.requestedGeneList.synthRequestSequence.map((v) => {
                            return `>${v.name}:${v.size}:${v.remark}
${v.sequence}
`}).join('')}Note
${data.designRequestInfo.designRequestNote || ''}`;
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(fileText));
        element.setAttribute('download', fileName);
        document.body.appendChild(element);
        element.click();
    }

    return (
        <React.Fragment>
            {
                data &&
                <Grid item xl lg sm md>
                    <Button onClick={() => exportTxt()}>
                        Design Request File
                        <GetApp />
                    </Button>
                </Grid >
            }
        </React.Fragment >
    )
}

export default observer(DesignWorkFileContainer);