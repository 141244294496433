import DesignTask from "../../../model/design/DesignTask";
import RootStore from "../../RootStore";
import DesignRequestStore from "./DesignRequestStore";
import axios from 'axios';
import { words as RouteWords } from '../../../../resource/data/APIRoutes.json';
import DesignRequest from "../../../model/design/taskDatas/designReqeust/DesignRequest";
import { action, observable } from "mobx";
import PTSMessage from "../../../model/common/PTSMessage";
import GeneData from "../../../model/design/taskDatas/designReqeust/GeneData";
import RequestedGeneList from "../../../model/design/taskDatas/designReqeust/RequestedGeneList";
import TaskInfo from '../../../../resource/data/tasks.json';
import DesignWork from "../../../model/design/DesignWork";
import User from "../../../model/user/user";
import SynthSequenceData from "../../../model/design/taskDatas/designReqeust/SynthSequenceData";
import QCSequencing from "../../../model/design/taskDatas/qc/QCSequencing";

class RebalancingRequestStore extends DesignRequestStore {
    @observable newData: DesignTask<DesignRequest>;
    @observable newWork: DesignWork;
    @observable prevWorkID: string;
    @observable probeID: string;
    @observable finalSDContents: string[];
    @observable isPrevDataLoaded: boolean;

    @observable geneListStr: string;
    @observable fusionListStr: string;
    @observable hotspotListStr: string;
    @observable customListStr: string;
    @observable synthRequestSequenceStr: string;
    @observable finalSDContentsStr: string;

    constructor(rootStore?: RootStore) {
        super(rootStore);
        this.newData = new DesignTask<DesignRequest>();
        this.newData.detail = new DesignRequest();
        this.newWork = new DesignWork();
        this.geneListStr = '';
        this.fusionListStr = '';
        this.hotspotListStr = '';
        this.customListStr = '';
        this.synthRequestSequenceStr = '>[Name1]\n[Sequence1]\n>[Name2]\n[Sequence2]';
        this.isPrevDataLoaded = false;
        this.probeID = '';
        this.finalSDContents = [];
        this.finalSDContentsStr = '';
    }

    @action
    handleListChange = (name: string, data: string) => {
        const list: GeneData[] = [];
        const errorMessage = {
            EmptyValueError: {
                name: 'Empty value error',
                message: '빈값이 있거나 포맷이 맞지 않습니다.'
            }
        }
        try {
            if (!data) {
                return;
            }
            data.trim().split('\n').map((row) => {
                const col = row.split(/[\t\s]/);
                if (col.length !== 4) {
                    throw errorMessage.EmptyValueError;
                }
                if (!col[0] || !col[1] || !col[2] || !col[3]) {
                    throw errorMessage.EmptyValueError;
                }
                if (isNaN(Number(col[1])) || isNaN(Number(col[2]))) {
                    throw errorMessage.EmptyValueError;
                }
                if (!Number.isInteger(Number(col[1])) || !Number.isInteger(Number(col[1]))) {
                    throw errorMessage.EmptyValueError;
                }
                if (Number(col[1]) < 0 || Number(col[2]) < 0) {
                    throw errorMessage.EmptyValueError;
                }
                if (Number(col[1]) > Number(col[2])) {
                    throw errorMessage.EmptyValueError;
                }
                list.push(new GeneData(col[0].trim(), parseInt(col[1]), parseInt(col[2]), col[3].trim(), ''));
                return null;
            });
            const fieldName = name.split('.').pop();
            switch (fieldName) {
                case 'fusionList':
                    this.newData.detail.requestedGeneList.fusionList = list;
                    break;
                case 'hotspotList':
                    this.newData.detail.requestedGeneList.hotspotList = list;
                    break;
                case 'customList':
                    this.newData.detail.requestedGeneList.customList = list;
                    break;
            }
        } catch (err) {
        }

    }

    @action
    handleGeneListChange = (): void => {
        try {
            const value = this.geneListStr;
            const split = value.split(/[\t\n\s]/);
            const list: GeneData[] = [];
            split.map((v) => {
                if (v) {
                    list.push(new GeneData(undefined, undefined, undefined, v.trim(), ''));
                    //list.push(v.trim());
                }
                return null;
            })
            this.newData.detail.requestedGeneList.geneList = list;
        } catch (err) {

        }
    }

    @action
    handleGeneListClear = (): void => {
        this.geneListStr = '';
        this.newData.detail.requestedGeneList.geneList = [];
    }

    // @action
    // handleSynthRequestListChange = (): void => {
    //     try {
    //         const value = this.synthRequestSequenceStr;
    //         const split = value.split(/[\t\n\s]/);
    //         const list: string[] = [];
    //         split.map((v) => {
    //             if (v) {
    //                 list.push(v.trim());
    //             }
    //             return null;
    //         })
    //         this.newData.detail.requestedGeneList.synthRequestSequence = list;
    //     } catch (err) {

    //     }
    // }

    @action
    handleSynthRequestListChange = (): void => {
        try {
            const list: SynthSequenceData[] = [];
            const value = this.synthRequestSequenceStr;
            if (value.substring(0, 1) === '>') {
                // Fasta 파일 형식
                const split = value.split(/[>]/);
                split.map((row) => {
                    if (!row) {
                        return null;
                    }
                    const col = row.split(/[\n]/);
                    const firstColSplit = col[0].split(/[\t\s]/);
                    const sequence = row.substring(col[0].length).replaceAll('\t', '').replaceAll(' ', '').replaceAll('\n', '');
                    if (sequence.length < 120) {
                        list.splice(0, list.length);
                        throw alert('합성 시퀀스 사이즈가 120bp 미만입니다.');
                    }
                    list.push(new SynthSequenceData(firstColSplit[0].trim(), sequence, sequence.length, ''));
                    return null;
                })
            } else {
                // Excel 파일 형식
                let namePosition = -1;
                let seqPosition = -1;
                let remarkPosition = -1;

                const split = value.split(/[\n]/);
                const splitFilter = split[0].split(/[\n\t\s]/).filter(i => i === 'Name' || i === 'Sequence' || i === 'Remark');

                if (!splitFilter.includes('Name') || !splitFilter.includes('Sequence') || !splitFilter.includes('Remark')) {
                    return null;
                }

                split.map((row, i) => {
                    if (!row || row === '') {
                        return null;
                    }

                    const col = row.split(/[\t]/);
                    if (i === 0) {
                        namePosition = col.indexOf('Name');
                        seqPosition = col.indexOf('Sequence');
                        remarkPosition = col.indexOf('Remark');
                        return null;
                    }
                    if (col[seqPosition].length < 120) {
                        list.splice(0, list.length);
                        throw alert('합성 시퀀스 사이즈가 120 bp 미만입니다.');
                    }
                    list.push(new SynthSequenceData(col[namePosition], col[seqPosition], col[seqPosition].length, col[remarkPosition]));

                    return null;
                })
            }
            this.newData.detail.requestedGeneList.synthRequestSequence = list;
        } catch (err) {
        }
    }

    @action
    handleSynthRequestListClear = (): void => {
        this.synthRequestSequenceStr = '';
        this.newData.detail.requestedGeneList.synthRequestSequence = [];
    }

    @action
    handleListClear = (name: string) => {
        const fieldName = name.split('.').pop();
        switch (fieldName) {
            case 'fusionList':
                this.newData.detail.requestedGeneList.fusionList = [];
                this.fusionListStr = '';
                break;
            case 'hotspotList':
                this.newData.detail.requestedGeneList.hotspotList = [];
                this.hotspotListStr = '';
                break;
            case 'customList':
                this.newData.detail.requestedGeneList.customList = [];
                this.customListStr = '';
                break;
        }
    }

    @action
    toggleBoolean = (name: string) => {
        if (this.newData.detail.rebalancingInfo.hasOwnProperty(name)) {
            if (name === 'isRebalancing') {
                if (this.newData.detail.rebalancingInfo[name]) {
                    this.newData.detail.rebalancingInfo.rebalancingNote = '';
                }
                this.newData.detail.rebalancingInfo[name] = !this.newData.detail.rebalancingInfo[name];
            }
            else if (name === 'isAddPanelRegion') {
                if (this.newData.detail.rebalancingInfo[name]) {
                    this.newData.detail.rebalancingInfo.addPanelRegionNote = '';
                    this.geneListStr = '';
                    this.fusionListStr = '';
                    this.hotspotListStr = '';
                    this.customListStr = '';
                    this.newData.detail.requestedGeneList = new RequestedGeneList();
                }
                this.newData.detail.rebalancingInfo[name] = !this.newData.detail.rebalancingInfo[name];
            }
        }
    }

    @action
    resetGeneListStrs = () => {
        this.fusionListStr = '';
        this.hotspotListStr = '';
        this.customListStr = '';
        this.geneListStr = '';
        this.synthRequestSequenceStr = '';
    }

    getWholeWorkData = async (id: string, token: string) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        return axios.get(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.design}/${RouteWords.works}/${RouteWords.details}/${id}`,
            config
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        });
    }

    setPrevWorkInfo = async (id: string, token: string) => {
        try {
            const result = await this.getWholeWorkData(id, token);
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                })
            }
            else if (!result.data.data) {
                throw new PTSMessage({
                    message: '잘못된 데이터입니다.',
                })
            }
            else if (!result.data.data.tasks) {
                throw new PTSMessage({
                    message: '잘못된 데이터입니다.',
                })
            }
            else if (!Array.isArray(result.data.data.tasks)) {
                throw new PTSMessage({
                    message: '잘못된 데이터입니다.',
                })
            }
            else if (result.data.data.tasks.length < 1) {
                throw new PTSMessage({
                    message: '잘못된 데이터입니다.',
                })
            }
            this.data.fromJS(result.data.data.tasks[0]);
            const synth3Filter = result.data.data.tasks.filter((v: any) => {
                return v.type === TaskInfo.types.synth3;
            });
            if (synth3Filter.length > 0) {
                this.finalSDContents = synth3Filter.pop().detail.sdContents;
            }
            this.prevWorkID = result.data.data.workID;
            this.probeID = result.data.data.probeID;
            this.newData.detail.designRequestInfo = this.data.detail.designRequestInfo;
            this.newData.detail.designRequestPersonInfo = this.data.detail.designRequestPersonInfo;
            this.newWork.mailingList = result.data.data.mailingList ?? [];
            this.newWork.salesRepresentativeObj = new User();
            this.newWork.salesRepresentativeObj.fromJS(result.data.data.salesRepresentativeObj);
            for (const d of result.data.data.tasks) {
                if (d.type && d.type === TaskInfo.types.qcSequencing) {
                    this.data.detail.designRequestInfo.pilotTestRunID = (d.detail as QCSequencing)?.runID;
                }
            }
        } catch (err) {
            throw err;
        }
    }

    requestRebalancingToServer = async (data: any, id: string, token: string) => {
        const config = {
            headers: {
                'x-access-token': token,
            }
        }
        return axios.post(
            `${this.baseURL}/${RouteWords.auth}/${RouteWords.pts}/${RouteWords.api}/${RouteWords.version2}/${RouteWords.rebalancing}/${RouteWords.new}/${id}`,
            {
                data: {
                    task: data,
                    work: this.newWork,
                }
            },
            config,
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        })
    }

    requestRebalancing = async (token: string) => {
        try {
            const result = await this.requestRebalancingToServer(this.newData, this.prevWorkID, token);
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                })
            }
            return result;
        } catch (err) {
            throw err;
        }
    }
}

export default RebalancingRequestStore;