import { observable } from 'mobx';
import { inject, observer } from 'mobx-react'
import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom';
import SnopStore from '../../../../data/store/pageStore/view/SnopStore'
import { IRootStore } from '../../../../data/store/RootStore';
import SnopListViewMainContainer from './container/SnopListViewMainContainer'

interface Props extends RouteComponentProps<any>, IRootStore {
}

@inject('rootStore')
@observer
class SnopListViewPage extends React.Component<Props> {
    @observable store = new SnopStore(this.props.rootStore);
    @observable type = this.props.match.params.type;

    componentDidMount = () => {
    }

    render() {
        return (
            <React.Fragment>
                <SnopListViewMainContainer
                    type={this.type}
                    store={this.store} />
            </React.Fragment>
        )
    }
}

export default withRouter(SnopListViewPage);