import React from 'react';
import { IRootStore } from '../../../../../../data/store/RootStore';
import RebalancingRequestMainContainer from './container/RebalancingRequestMainContainer';

interface Props extends IRootStore {

}

const RebalancingRequestPage: React.FC<Props> = (props) => {
    return (
        <React.Fragment>
            <RebalancingRequestMainContainer />
        </React.Fragment>
    )
}

export default RebalancingRequestPage;