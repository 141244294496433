import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Grid, FormControl, Typography, InputLabel, Select, MenuItem } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateUtil from '@date-io/date-fns';
import dayjs from 'dayjs';
import { IRootStore } from '../../../../../data/store/RootStore';
import ManufactureStore from '../../../../../data/store/pageStore/manufacture/ManufactureStore';
import CommonErrors, { CommonError } from '../../../../../data/model/common/CommonErrors';
import TitledPaper from '../../../../common/component/TitledPaper';
import Validatable from '../../../../../data/model/common/Validatable';
import Helper from '../../../../../module/Helper';
import FormButtonSet from '../../../../common/component/FormButtonSet';
import PTSMessage from '../../../../../data/model/common/PTSMessage';
import ManufactureWork from '../../../../../data/model/manufacture/ManufactureWork';

interface Props extends RouteComponentProps<any>, IRootStore {
}

interface Error {
    name: string;
    message: string;
    code: string;
    errorObj: boolean;
}

@inject('rootStore')
@observer
class ManufactureShippingRequestContainer extends React.Component<Props> {
    store = new ManufactureStore();

    globalStore = this.props.rootStore.globalStore;
    commentStore = this.props.rootStore.commentStore;

    readonly errors: { [key: string]: Error } = {
        invalidParams: {
            name: 'Invalid Params',
            message: '잘못된 파라미터',
            code: '0000',
            errorObj: true,
        },
        networkError: {
            name: 'Network Error',
            message: '네트워크 에러',
            code: '0001',
            errorObj: true,
        },
        notValidated: {
            name: 'Data not validated',
            message: '유효하지 않은 값이 있습니다.',
            code: '0002',
            errorObj: true,
        },
        noData: {
            name: 'No data',
            message: '정보가 없습니다.',
            code: '0003',
            errorObj: true,
        },
        invalidType: {
            name: 'Invalid type',
            message: '잘못된 타입입니다.',
            code: '0004',
            errorObj: true,
        }
    }

    constructor(props: Props) {
        super(props);
        this.globalStore.useComment = true;
        this.commentStore.mode = 'work';
        this.commentStore.stage = '제조 출하 요청';
    }

    scrollBottom = () => {
        const obj = document.getElementById('commentContentsList');
        obj.scrollTop = obj.scrollHeight;
    }

    componentDidMount = async () => {
        try {
            this.globalStore.isRunning = true;
            const workID = this.props.match.params.workID;
            const result = await this.store.getManufactureByWorkID(workID, this.globalStore.token);
            const track = await this.store.getManufactureTrack(workID, this.globalStore.token);
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                })
            }
            if (track.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                })
            }
            if (!result.data.data) {
                throw this.errors.networkError;
            }
            if (!track.data.data.current.workType) {
                throw this.errors.networkError;
            }
            if (!track.data.data.current.departType) {
                throw this.errors.networkError;
            }
            if (this.globalStore.userInfo.role.toLocaleLowerCase() !== 'admin') {
                if (track.data.data.current.departType !== this.globalStore.userInfo.departType) {
                    throw CommonErrors.NoAuth;
                }
            }
            this.store.workID = workID;
            this.store.data.fromJS(result.data.data);
            this.globalStore.currentTaskTitle = this.store.getTitleByType(track.data.data.current.workType);
            this.store.setItemList(result.data.data.itemList);
            this.store.setInputHeadersByType(track.data.data.current.workType);
            this.store.setReadonlyHeadersByType(track.data.data.current.workType);
            this.commentStore.workID = workID;
            this.commentStore.stage = this.globalStore.currentTaskTitle;
            await this.commentStore.setCommentsMany();
            this.store.data.changeRequestHistory = result.data.data.changeRequestHistory;
            this.store.setManufactureNumber(result.data.data.manufactureOrderNumberList);
        } catch (err) {
            if (err instanceof CommonError || err instanceof PTSMessage) {
                alert(err.message);
            }
            // else if (err.errorObj) {
            //     alert(err.message);
            // }
            else {
                alert('오류 발생');
            }
            this.props.history.push('/main/manufacture/list/process');

        } finally {
            this.globalStore.isRunning = false;
        }
    }

    onFormSubmit = async (e: any) => {
        if (!window.confirm('제출하시겠습니까?')) {
            return;
        }
        try {
            this.globalStore.isRunning = true;

            // api
            const result =
                await this.store.processManufacture(
                    this.globalStore.token
                );

            // result
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                });
            }
            alert('제출완료');
            this.props.history.push(`/main/manufacture/list/process`);
            window.location.reload();
        } catch (err) {
            if (err instanceof PTSMessage) {
                alert(err.message);
            }
            else {
                alert('오류 발생.');
            }
            this.store.isValid = false;
        } finally {
            this.globalStore.isRunning = false;
        }
    }

    onValidate = (e: any) => {
        try {
            this.store.data.resetErrors();
            if (!this.store.data.shippingRequestDate) {
                this.store.data.setErrorTextByName('shippingRequestDate', Validatable.errorTexts.needValue);
                this.store.isValid = false;
                alert('유효하지 않은 값이 있습니다.');
                return;
            }
            else if (dayjs(this.store.data.shippingRequestDate).diff(dayjs(), 'day') <= -1) {
                this.store.data.setErrorTextByName('shippingRequestDate', Validatable.errorTexts.pastDate);
                this.store.isValid = false;
                alert('유효하지 않은 값이 있습니다.');
                return;
            }
            this.store.isValid = true;
        } catch (err) {

        } finally {

        }
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.store.data.prevWorkID
                    &&
                    <Typography variant='body2'>
                        이전 작업(복사된 작업) ID:&nbsp;
                        <Link to={`/main/manufacture/detail/${this.store.data.prevWorkID}`} target='_blank'>
                            {this.store.data.prevWorkID}
                        </Link>
                    </Typography>
                }
                <TitledPaper title='출하요청 정보'>
                    <Grid container>
                        <Grid item xl lg md>
                            <MuiPickersUtilsProvider utils={DateUtil}>
                                <FormControl margin='normal'>
                                    <KeyboardDatePicker
                                        variant='inline'
                                        format='yyyy/MM/dd'
                                        name='shippingRequestDate'
                                        label='출하요청일'
                                        value={
                                            this.store.data.shippingRequestDate
                                        }
                                        onChange={(date) => { this.store.data.onChangeDateDefault('shippingRequestDate', date) }}
                                        helperText={
                                            Helper.getErrorText(
                                                this.store.data.errors.shippingRequestDate
                                            )
                                        }
                                        error={
                                            Helper.getErrorState(
                                                this.store.data.errors.shippingRequestDate
                                            )
                                        }
                                    />
                                </FormControl>
                            </MuiPickersUtilsProvider>
                            <FormControl
                                style={{ width: 250 }}
                                margin='normal'>
                                <InputLabel id='shippingRequestAMPM'>
                                    AM/PM
                                </InputLabel>
                                <Select
                                    fullWidth
                                    error={
                                        Helper.getErrorState(
                                            this.store.data.errors.shippingRequestAMPM
                                        )
                                    }
                                    labelId='shippingRequestAMPM'
                                    onChange={this.store.data.onChangeDefault}
                                    value={this.store.data.shippingRequestAMPM}
                                    name='shippingRequestAMPM'>
                                    {Object.keys(ManufactureWork.AMPMList).map((v, i) => (
                                        <MenuItem key={`ampm${i}`} value={v}>{(ManufactureWork.AMPMList as any)[v]}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </TitledPaper>
                <Grid container>
                    <Grid item xl lg md >
                        <FormButtonSet
                            onSubmit={this.onFormSubmit}
                            onValidate={this.onValidate}
                            validated={this.store.isValid}
                            onFormClear={() => { window.location.reload() }} />
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

export default withRouter(ManufactureShippingRequestContainer);