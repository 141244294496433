import axios from "axios";
import User, { IUser } from "../data/model/user/user";
import RepositorySettings from "../data/repository/RepositorySettings";
import GlobalStore from "../data/store/common/GlobalStore";
import { words as RouteWords } from "../resource/data/APIRoutes.json"

export default class LoginUtil {
    static isLoggedIn = (): boolean => {
        return LoginUtil.checkUserInfo() && LoginUtil.checkToken() && LoginUtil.checkLoggedIn();
    }

    // static windowFocus = () => {
    //     if (!LoginUtil.isLoggedIn()) {
    //         return;
    //     }
    //     // window.addEventListener("focus", LoginUtil.onFocus);
    //     // window.addEventListener("blur", LoginUtil.onBlur);
    //     LoginUtil.apiTokenCheck();
    // }

    static onFocus = (): boolean => {
        try {
            if (!LoginUtil.getBlurTime()) {
                return;
            }
            const focusTime = new Date().getTime();
            const blurTime = LoginUtil.getBlurTime();
            LoginUtil.removeBlurTime();
            const elapsedTime = focusTime - blurTime;
            // 일(365) / 시간(24) / 분(60) / 초(60) / 밀리초(1000)
            if (elapsedTime > 10 * 60 * 1000) {
                LoginUtil.apiTokenCheck();
            }
            return true;
        } catch {
            return false;
        }
    }

    static onBlur = (): boolean => {
        try {
            LoginUtil.setBlurTime();
            return true;
        } catch (err) {
            return false;
        }
    };

    private static setBlurTime = (): boolean => {
        try {
            localStorage.setItem('blurTime', new Date().getTime().toString());
            return true;
        } catch (err) {
            return false;
        }
    }

    private static getBlurTime = (): number => {
        return Number(localStorage.getItem('blurTime'));
    }

    private static removeBlurTime = (): boolean => {
        try {
            localStorage.removeItem('blurTime');
            return true;
        } catch (err) {
            return false;
        }
    }

    static apiTokenCheck = async () => {
        try {
            const result = await LoginUtil.checkHelloRequest();
            if (result.data.type === 'apiErr') {
                if (result.data.resCode === 30000 || result.data.resCode === 30001 ||
                    result.data.resCode === 30002 || result.data.resCode === 30200) {
                    LoginUtil.logout();
                    window.location.reload();
                    alert(result.data.resMessage);
                }
            }
            if (result.data.data.token) {
                localStorage.removeItem('token');
                LoginUtil.setToken(result.data.data.token)
            }
        } catch (err) {
            return err;
        }
    }

    private static getBaseURL = () => {
        return RepositorySettings.apiBaseURL;
    }

    private static checkHelloRequest = async () => {
        const token = localStorage.getItem('token');
        const baseURL = LoginUtil.getBaseURL();
        const config = {
            headers: {
                'x-access-token': token
            }
        }
        return axios.get(
            `${baseURL}/${RouteWords.auth}/${RouteWords.hello}`,
            config
        ).then((res) => {
            return res;
        }).catch((err) => {
            throw err;
        });
    }

    private static checkUserInfo = (): boolean => {
        const userInfo = localStorage.getItem('userInfo');
        if (!userInfo) {
            return false;
        }
        else {
            return true;
        }
    }

    private static checkToken = (): boolean => {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        else {
            return true;
        }
    }

    private static checkLoggedIn = (): boolean => {
        const loggedIn = localStorage.getItem('loggedIn');
        if (loggedIn !== undefined || loggedIn !== null) {
            localStorage.setItem('loggedIn', 'true');
        }

        if (loggedIn === 'true') {
            return true;
        }
        else {
            return false;
        }
    }

    private static setLoggedIn = (loggedIn: boolean): boolean => {
        try {
            localStorage.setItem('loggedIn', loggedIn.toString());
            return true;
        } catch (err) {
            return false;
        }
    }

    private static setUserInfo = (userInfo: object, store?: GlobalStore): boolean => {
        try {
            localStorage.setItem('userInfo', JSON.stringify(userInfo));
            if (store) {
                store.userInfo = new User(userInfo as IUser);
            }
            return true;
        } catch (err) {
            return false;
        }
    }

    private static setToken = (token: string): boolean => {
        try {
            localStorage.setItem('token', token);
            return true;
        } catch (err) {
            return false;
        }
    }

    static getUserInfo = (): object => {
        return JSON.parse(localStorage.getItem('userInfo'));
    }

    static getToken = (): string => {
        return localStorage.getItem('token');
    }

    static login = (userInfo: object, token: string, store?: GlobalStore): boolean => {
        try {
            window.addEventListener("focus", LoginUtil.onFocus);
            window.addEventListener("blur", LoginUtil.onBlur);

            const tokenR = LoginUtil.setToken(token);
            const userR = LoginUtil.setUserInfo(userInfo, store);
            const logR = LoginUtil.setLoggedIn(true);
            if (store) {
                store.isLoggedIn = true;
                store.token = token;
                store.userInfo = userInfo as User;
            }
            if (tokenR && userR && logR) {
                return true;
            }
            else {
                return false;
            }
        } catch (err) {
            return false;
        }
    }

    static logout = (store?: GlobalStore): boolean => {
        try {
            window.removeEventListener("focus", LoginUtil.onFocus);
            window.removeEventListener("blur", LoginUtil.onBlur);

            localStorage.removeItem('userInfo');
            localStorage.removeItem('token');
            localStorage.removeItem('loggedIn');
            store.isLoggedIn = false;
            return true;
        } catch (err) {
            return false;
        }
    }
}
