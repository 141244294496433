import React from 'react';
import { inject, observer } from 'mobx-react';
import { CssBaseline, Container, Grid, Typography } from '@material-ui/core';
import { IRootStore } from '../../../../../../data/store/RootStore';
import ProjectSearchTableContainer from '../../../../../common/component/task/table/ProjectSearchTableContainer';

interface Props extends IRootStore{
}

@inject('rootStore')
@observer
class ProjectSearchPage extends React.Component<Props> {
    globalStore = this.props.rootStore.globalStore;

    componentDidMount = () => {
        this.globalStore.useComment = false;
    }

    render() {
        return (
            <React.Fragment>
                <CssBaseline />
                <Container maxWidth='xl'>
                    <Grid container justify='center' direction='column'>
                        <Grid item xl lg sm style={{
                            wordWrap: 'break-word',
                            whiteSpace: 'pre-line',
                            padding: 5
                        }}>
                            <div>
                                <Typography variant='h4'>
                                    디자인 Work 조회
                                </Typography>
                                <hr />
                            </div>
                        </Grid>
                        <Grid item xl lg sm>
                            <ProjectSearchTableContainer />
                        </Grid>
                    </Grid>
                </Container>
            </React.Fragment>
        )
    }
}

export default ProjectSearchPage;