import React from 'react';
import { observer } from 'mobx-react';
import { Grid, Typography } from '@material-ui/core';
import FormUtil from '../../../../../../../module/FormUtil';

interface Props {
    tupleKey?: string;
    tupleValue: any;
}

const WorkDetailTuple: React.FC<Props> = observer((props) => {
    const key = typeof props.tupleKey === 'object' ? '' : props.tupleKey;
    const value = typeof props.tupleValue === 'object' ? '' : props.tupleValue;
    return (
        <React.Fragment>
            <Grid item container xl lg sm style={{ margin: 10 }}>
                <Grid item xl={4} lg={4} sm={4}>
                    <Typography variant='body2' style={{ whiteSpace: 'pre-line', marginRight: 5 }}>
                        {FormUtil.getFieldLabel(key)}
                        {/* {JSON.stringify(props.tupleKey, null, '\t')} */}
                    </Typography>
                </Grid>
                <Grid item xl lg sm>
                    <Typography variant='body2'>
                        {value}
                        {/* {props.tupleValue} */}
                        {/* {JSON.stringify(props.tupleValue, null, '\t')} */}
                    </Typography>
                </Grid>
            </Grid>
        </React.Fragment>
    )
});

export default WorkDetailTuple;