import React from 'react';
import { observer, inject } from 'mobx-react';
import { Grid, Paper } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRootStore } from '../../../../../../../data/store/RootStore';
import TaskListStore from '../../../../../../../data/store/pageStore/design/TaskListStore';
import PTSPagination from '../../../../../../common/component/task/table/PTSPagination';
import TaskListTable from '../../../../../../common/component/task/table/TaskListTable';
import PTSMessage from '../../../../../../../data/model/common/PTSMessage';

interface Props extends RouteComponentProps<any>, IRootStore {
    taskListStore?: TaskListStore;
}

@inject('rootStore')
@inject('taskListStore')
@observer
class ProcessTaskListTableContainer extends React.Component<Props> {
    globalStore = this.props.rootStore.globalStore;
    taskListStore = this.props.taskListStore;

    componentDidMount = async () => {
        try {
            this.setList();
        } catch (err) {
        }
    }

    componentDidUpdate = () => {
        window.onpopstate = (e: any) => {
            window.location.reload();
        }
    }

    onCheckboxClick = (e: any, key: number) => {

    }

    onHeaderCheckboxClick = (e: any) => {
        
    }

    onHeaderActionButtonClick = (e: any) => {

    }

    onActionButtonClick = (e: any, key: number) => {
        // 구현 필요
        const id = this.taskListStore.taskList[key].workID;
        this.props.history.push(`/main/design/process/${id}`);
        window.location.reload();
    }

    updateTasksMany = async (list: string[], setter: object) => {

    }

    onPageChange = (e: any, offset: number) => {
        this.taskListStore.offSet = offset;
        this.setList();
    }

    setList = async () => {
        try{
            this.globalStore.isRunning = true;
            await this.taskListStore.setProcessingList(this.globalStore.token);
        }catch(err) {
            if(err instanceof PTSMessage) {
                alert(err.message);
            }
        }finally{
            this.globalStore.isRunning = false;
        }
    }

    render() {
        return (
            <React.Fragment>
                <Grid
                    xl
                    lg
                    sm
                    spacing={1}
                    container
                    item
                    direction='column'
                    alignItems='stretch'
                    style={{ marginTop: 4 }}>
                    <Paper style={{ padding: 30 }}>
                        <Grid item xl lg sm container direction='column'>
                            <Grid
                                xl lg
                                sm
                                item
                                container
                                alignItems='flex-end'
                                direction='column'>
                                <Grid item xl lg sm>
                                    <PTSPagination
                                        limit={this.taskListStore.limit}
                                        total={this.taskListStore.total}
                                        offset={this.taskListStore.offSet}
                                        onClick={this.onPageChange} />
                                </Grid>
                            </Grid>
                            <Grid xl lg sm item>
                                <TaskListTable
                                    headers={this.taskListStore.headers}
                                    taskList={this.taskListStore.taskList}
                                    perPage={this.taskListStore.limit}
                                    total={this.taskListStore.total}
                                    offSet={this.taskListStore.offSet}
                                    numSelected={this.taskListStore.selectedNum}
                                    checkBoxes={false}
                                    actionButtonText='작업하기'
                                    actionButton={true}
                                    onHeaderActionButtonClick={this.onHeaderActionButtonClick}
                                    onHeaderCheckboxClick={this.onHeaderCheckboxClick}
                                    onCheckboxClick={this.onCheckboxClick}
                                    onDetailButtonClick={(e, key) => {}}
                                    onActionButtonClick={this.onActionButtonClick}
                                />
                            </Grid>
                            <Grid
                                style={{ padding: 5, margin: 20 }}
                                xl lg
                                sm
                                item
                                container
                                alignItems='flex-end'
                                direction='column'>
                                <Grid item xl lg sm>
                                    <PTSPagination
                                        limit={this.taskListStore.limit}
                                        total={this.taskListStore.total}
                                        offset={this.taskListStore.offSet}
                                        onClick={this.onPageChange} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </React.Fragment >
        )
    }
}

export default withRouter(ProcessTaskListTableContainer);