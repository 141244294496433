import React from 'react';
import { Theme, WithStyles, createStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import { inject, observer } from 'mobx-react';
import { Grid, GridList } from '@material-ui/core';
import CommentBubble from './CommentBubble';
import { IRootStore } from '../../../data/store/RootStore';

const styles = (theme: Theme) => createStyles({

});

interface Props extends WithStyles<typeof styles>, IRootStore {
    onToggleCancel: any;
    onBottomClick: any;
}

@inject('rootStore')
@observer
class CommentContentsContainer extends React.Component<Props> {
    globalStore = this.props.rootStore.globalStore;
    commentStore = this.props.rootStore.commentStore;

    render() {
        return (
            <GridList id='commentContentsList' cols={1} style={{ margin: 0, background: '#F8F9F9', height: '80vh' }}>
                <Grid item container>
                    <Grid item container direction='column'>
                        {this.commentStore.comments.map((v, i) => (
                            <CommentBubble
                                writerName={v.writerName}
                                createdDate={v.createdDate}
                                updatedDate={v.updatedDate}
                                content={v.content}
                                stage={v.stage}
                                cancel={v.cancel}
                                keyindex={i}
                                key={i}
                                mine={v.email === this.globalStore.userInfo.email ? true : false}
                                isRunning={this.commentStore.isRunning}
                                onCancelClick={this.props.onToggleCancel}
                            />
                        ))}
                    </Grid>
                </Grid>
            </GridList>
        )
    }
}

export default withStyles(styles)(CommentContentsContainer);