import React from 'react';
import { observer, inject } from 'mobx-react';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { observable } from 'mobx';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRootStore } from '../../../../../data/store/RootStore';
import CommonErrors, { CommonError } from '../../../../../data/model/common/CommonErrors';
import ManufactureWork from '../../../../../data/model/manufacture/ManufactureWork';
import TitledPaper from '../../../../common/component/TitledPaper';
import FormButtonSet from '../../../../common/component/FormButtonSet';
import ManufactureStore from '../../../../../data/store/pageStore/manufacture/ManufactureStore';
import PTSMessage from '../../../../../data/model/common/PTSMessage';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateUtil from '@date-io/date-fns';
import dayjs from 'dayjs';

interface Props extends RouteComponentProps<any>, IRootStore {
}

@inject('rootStore')
@observer
class ManufactureShippingChangeRequestContainer extends React.Component<Props> {
    @observable changeType = '';
    @observable prevRequestDate: Date = null;
    @observable prevRequestAMPM: 'am' | 'pm' | '' = '';
    @observable requestDate: Date = null;
    @observable requestAMPM: 'am' | 'pm' | '' = '';
    @observable reason = '';
    @observable isValid = false;
    @observable workID: string = '0';

    store = new ManufactureStore();

    globalStore = this.props.rootStore.globalStore;
    commentStore = this.props.rootStore.commentStore;

    constructor(props: Props) {
        super(props);
        this.globalStore.currentTaskTitle = '제조 출하 변경요청';
        this.globalStore.useComment = true;
        this.commentStore.stage = '제조 출하 변경요청';
        this.requestDate = dayjs(Date.now()).toDate();
    }

    componentWillUnmount = () => {
        this.globalStore.useComment = false;
    }

    componentDidMount = async () => {
        try {
            this.globalStore.isRunning = true;
            const workID = this.props.match.params.workID;
            const result = await this.store.getManufactureByWorkID(workID, this.globalStore.token);
            const track = await this.store.getManufactureTrack(workID, this.globalStore.token);
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                })
            }
            if (track.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                })
            }
            if (this.globalStore.userInfo.role.toLocaleLowerCase() !== 'admin') {
                if (this.globalStore.userInfo.departType !== 'sales'
                    && this.globalStore.userInfo.departType !== ManufactureWork.DepartList.manufacturePart) {
                    throw CommonErrors.NoAuth;
                }
            }
            if (result.data.data.shippingRequestDate) {
                this.globalStore.currentTaskTitle = '출하 요청일 변경 요청';
                this.commentStore.stage = '출하 요청일 변경 요청';
                this.changeType = ManufactureWork.changeType.manufactureChangeShipping;
                this.prevRequestDate = result.data.data.shippingRequestDate;
                this.prevRequestAMPM = result.data.data.shippingRequestAMPM;
            } else {
                this.globalStore.currentTaskTitle = '예상 출하일 변경 요청';
                this.commentStore.stage = '예상 출하일 변경 요청';
                this.changeType = ManufactureWork.changeType.manufactureChangeExpectShipping;
                this.prevRequestDate = result.data.data.determinedReleaseDate;
                this.prevRequestAMPM = result.data.data.determinedReleaseAMPM;
            }
            this.globalStore.useComment = true;
            this.store.workID = workID;
            this.workID = workID;
            this.commentStore.workID = workID;
            await this.commentStore.setCommentsMany();
            this.requestDate = this.prevRequestDate;
            this.requestAMPM = this.prevRequestAMPM;

        } catch (err) {
            if (err instanceof CommonError || err instanceof PTSMessage) {
                alert(err.message);
            }
            else {
                alert('오류 발생');
            }
            this.props.history.push('/main/manufacture/list/shipped');

        } finally {
            this.globalStore.isRunning = false;
        }
    }

    scrollBottom = () => {
        const obj = document.getElementById('commentContentsList');
        obj.scrollTop = obj.scrollHeight;
    }

    onFormClear = () => {
        window.location.reload();
    }

    onFormSubmit = async () => {
        try {
            if (this.globalStore.userInfo.departType !== ManufactureWork.DepartList.sales
                && this.globalStore.userInfo.role.toLocaleLowerCase() !== 'admin') {
                alert('권한이 없습니다.');
                return;
            }
            if (!window.confirm('제출합니까?')) {
                return;
            }
            if (!this.isValid) {
                alert('사유를 2글자 이상 입력해주세요.');
                return;
            }
            this.globalStore.isRunning = true;

            const current = {
                prevRequestDate: this.prevRequestDate,
                prevRequestAMPM: this.prevRequestAMPM,
                changeType: this.changeType,
                changeRequestDate: this.requestDate,
                changeRequestAMPM: this.requestAMPM,
                changeReason: this.reason,
            }

            const result = await this.store.changeShippingRequest(this.workID, {}, this.globalStore.token, null, current);
            if (result.data.type === 'apiErr') {
                throw new PTSMessage({
                    message: result.data.resMessage,
                })
            }
            alert('제출완료');
            this.props.history.goBack();

        } catch (err) {
            if (err instanceof CommonError || err instanceof PTSMessage) {
                alert(err.message);
            }
            else {
                alert('오류 발생');
            }
        } finally {
            this.globalStore.isRunning = false;
        }
    }

    onValidate = () => {
        try {
            if (!this.requestDate) {
                this.isValid = false;
                alert('변경일의 입력값이 필요합니다.');
            }
            else if (dayjs(this.requestDate).toString() === 'Invalid Date') {
                this.isValid = false;
                alert('변경일의 입력값이 잘못되었습니다.');
            }
            else if (dayjs(this.requestDate).diff(dayjs(), 'day') <= -1) {
                this.isValid = false;
                alert('과거의 날짜는 불가능합니다.');
            }
            else if (!this.requestAMPM) {
                this.isValid = false;
                alert('AM/PM의 입력값이 필요합니다.');
            }
            else if (this.reason.length < 2) {
                this.isValid = false;
                alert('사유를 2글자 이상 입력해주세요.');
                return;
            }
            else {
                this.isValid = true;
            }
        } catch (err) {

        } finally {

        }
    }

    render() {
        return (
            <React.Fragment>
                <TitledPaper title={this.globalStore.currentTaskTitle}>
                    <Grid container>
                        <Grid item xl lg md>
                            <MuiPickersUtilsProvider utils={DateUtil}>
                                <FormControl margin='normal'>
                                    <KeyboardDatePicker
                                        variant='inline'
                                        format='yyyy/MM/dd'
                                        name='shippingRequestDate'
                                        label='변경일'
                                        value={this.requestDate}
                                        onChange={(date) => this.requestDate = date}
                                    />
                                </FormControl>
                            </MuiPickersUtilsProvider>
                            <FormControl
                                style={{ width: 250 }}
                                margin='normal'>
                                <InputLabel id='requestAMPM'>
                                    AM/PM
                                </InputLabel>
                                <Select
                                    fullWidth
                                    labelId='requestAMPM'
                                    value={this.requestAMPM}
                                    onChange={(e: React.ChangeEvent<any>) => this.requestAMPM = e.target.value}
                                    name='requestAMPM'>
                                    {Object.keys(ManufactureWork.AMPMList).map((v, i) => (
                                        <MenuItem key={`ampm${i}`} value={v}>{(ManufactureWork.AMPMList as any)[v]}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container direction='column'>
                        <Grid container>
                            <Grid item xl lg sm>
                                <TextField
                                    style={{
                                        width: 500
                                    }}
                                    multiline
                                    rows={5}
                                    name='reason'
                                    value={this.reason}
                                    margin='normal'
                                    label='변경 사유'
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        this.reason = e.target.value;
                                        if (this.reason.length < 3) {
                                            this.isValid = false;
                                        }
                                    }} />
                            </Grid>
                        </Grid>
                    </Grid>
                </TitledPaper>
                <FormButtonSet
                    validated={this.isValid}
                    onFormClear={this.onFormClear}
                    onValidate={this.onValidate}
                    onSubmit={this.onFormSubmit} />
            </React.Fragment>
        )
    }
}

export default withRouter(ManufactureShippingChangeRequestContainer);