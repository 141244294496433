import React from 'react';
import { TextField } from '@material-ui/core';
import { observer } from 'mobx-react';
import ManufactureWork from '../../../../../data/model/manufacture/ManufactureWork';

interface Props {
    data: ManufactureWork;
    readOnly?: boolean;
    depart?: string;
}

const ManufactureNoteForm: React.FC<Props> = observer((props) => {
    const data = props.data;
    const readOnly = props.readOnly || false;
    const depart = props.depart || 'sales';

    return (
        <React.Fragment>
            <TextField
                label='Note'
                fullWidth
                multiline
                rows={10}
                InputProps={{
                    readOnly: readOnly
                }}
                value={depart === 'sales' ? data.note : ''}
                onChange={data.onChangeDefault}
                name={depart === 'sales' ? 'note' : 'manufactureNote'}
            />
        </React.Fragment>
    )
})

export default ManufactureNoteForm;
